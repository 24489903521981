import { enqueueSnackbar } from "notistack";
import { GetClientFriendlyMessage } from "./Error";

export function SuccessSnackbar(message: string) {
  enqueueSnackbar(message, { variant: "success" });
}

export function ErrorSnackbar(error: unknown | string) {
  let message = GetClientFriendlyMessage(error);
  enqueueSnackbar(message, { variant: "error" });
}
