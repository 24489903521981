import { useCallback, useState } from "react";
import { useAppDispatch } from "./Hooks";
import { User, upsertUserInfo } from "../Store/UserSlice";
import { ValidateUser, ValidationFailedPrefix } from "../Services/Validation/Validation";
import { v4 as UuidV4 } from "uuid";
import { MakeClone } from "../Services/Cloning";
import { IntegrityId } from "../Store/CompanySlice";
import { FileUrlPair } from "../Components/EditImage";
import { UserWithAvatarUrl } from "../Components/User/UserAvatar";
import { uploadAvatar } from "../Store/DocumentSlice";
import { ErrorSnackbar, SuccessSnackbar } from "../Services/Snackbars";
import { ValidatePassword } from "../Services/Validation/PasswordValidation";

enum stateEnum {
  start,
  add,
  edit
}

type EditUser = {
  cloneForEditing: User | undefined
  setCloneForEditing: (value: User | undefined) => void
  SetupEditUser: (users: User[]) => void
  EnterEditMode: (id: string, users: User[], isNew: boolean) => void
  isAdding: boolean
  isEditing: boolean
  LeaveEditMode: () => void
  AddEmptyUser: (companyId: string, isIntegrityUser: boolean) => void
  SaveEdit: () => void
  CancelEdit: () => void
  usersList: User[]
  SubmitUserData: (user: User) => Promise<boolean>
  searchedKeyword: string
  setSearchedKeyword: (value: string) => void
  newAvatarFileUrl: FileUrlPair
  setNewAvatarFileUrl: (value: FileUrlPair) => void
  newPassword: string
  setNewPassword: (value: string) => void
  isSavingUser: boolean
}

export function useEditUser(userAvatars: UserWithAvatarUrl[]): EditUser {
  const [state, setState] = useState(stateEnum.start);
  const dispatch = useAppDispatch();

  const [cloneForEditing, setCloneForEditing] = useState<User>();
  const [usersList, setUsersList] = useState<User[]>([]);
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [newAvatarFileUrl, setNewAvatarFileUrl] = useState<FileUrlPair>({ url: "", file: null });
  const [newPassword, setNewPassword] = useState("");
  const [isSavingUser, setIsSavingUser] = useState(false);

  const SetupEditUser = useCallback((users: User[]) => {
    setUsersList(users);
    LeaveEditMode();
  }, []);

  const isAdding = state === stateEnum.add;
  const isEditing = state === stateEnum.edit || isAdding;

  function EnterEditMode(id: string, users: User[], isNew: boolean) {
    if (isEditing)
    {
      ErrorSnackbar("Changes must first be saved.");
      return;
    }

    const currentUser = users.find(u => u.id === id);
    if (!currentUser)
    {
      ErrorSnackbar("Could not find the user to edit.");
      return;
    }

    setState(isNew ? stateEnum.add : stateEnum.edit);
    setCloneForEditing(MakeClone(currentUser));

    const matchingAvatarUrl = userAvatars.find(user => user.id === currentUser.id);
    setNewAvatarFileUrl({ url: matchingAvatarUrl?.docUrl ?? "", file: null });
    setNewPassword("");
  }

  function LeaveEditMode() {
    setState(stateEnum.start);
    setCloneForEditing(undefined);
  }

  function CancelEdit() {
    if (state === stateEnum.add && cloneForEditing)
    {
      let usersClone = MakeClone(usersList);
      usersClone = usersClone.filter(user => user.id !== cloneForEditing.id);

      setUsersList(usersClone);
    }
    LeaveEditMode();
  }

  async function SaveEdit() {
    if (cloneForEditing)
    {
      let usersClone = MakeClone(usersList);
      let newUserIndex = usersClone.findIndex(u => u.id === cloneForEditing?.id);
      if (newUserIndex >= 0)
      {
        const newUser = MakeClone(cloneForEditing)
        usersClone[newUserIndex] = newUser;

        if (CanSubmitUserData(newUser))
        {
          setIsSavingUser(true);
          if (newAvatarFileUrl.file)
            newUser.avatarBlobName = await dispatch(uploadAvatar({ file: newAvatarFileUrl.file })).unwrap();

          const success = await SubmitUserData(newUser);
          if (success)
          {
            LeaveEditMode();
            setUsersList(usersClone);
            SuccessSnackbar("User changes have been saved.");
          }
          setIsSavingUser(false);
        }
      }
    }
  }

  function CanSubmitUserData(user: User): boolean {
    if (isAdding)
    {
      const passwordValidation = ValidatePassword(newPassword);
      if (!passwordValidation.success)
      {
        ErrorSnackbar(ValidationFailedPrefix + passwordValidation.message);
        return false;
      }
    }

    const validation = ValidateUser(user, usersList);
    if (validation.success)
      return true;
    else
      ErrorSnackbar(ValidationFailedPrefix + validation.message);

    return false;
  }

  async function SubmitUserData(user: User): Promise<boolean> {
    if (CanSubmitUserData(user))
    {
      try
      {
        await dispatch(upsertUserInfo({ user, newUserPassword: newPassword })).unwrap();
        return true;
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
    }

    return false;
  }

  function AddEmptyUser(companyId: string, isIntegrityUser: boolean) {
    if (companyId === IntegrityId && !isIntegrityUser)
      companyId = "";
    if (!isEditing)
    {
      let usersClone = MakeClone(usersList);
      let myUuid = UuidV4();
      let newUser: User = { id: myUuid, email: "", companyId: companyId, initiativeRoles: [], name: "", phoneNumber: "", role: "", isAdmin: false, isActive: true, isPasswordReset: true };
      usersClone.unshift(newUser);
      setUsersList(usersClone);
      setSearchedKeyword("");
      EnterEditMode(myUuid, usersClone, true);
    }
  }

  return {
    SetupEditUser,
    EnterEditMode,
    isAdding,
    isEditing,
    LeaveEditMode,
    AddEmptyUser,
    SaveEdit,
    CancelEdit,
    usersList,
    SubmitUserData,
    cloneForEditing,
    setCloneForEditing,
    searchedKeyword,
    setSearchedKeyword,
    newAvatarFileUrl,
    setNewAvatarFileUrl,
    newPassword,
    setNewPassword,
    isSavingUser
  }
}

