import { Stack, Box, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from "react-chartjs-2";
import { Company } from "../../../Store/CompanySlice";
import { plural } from "pluralize";
import { metricsFontLarge } from "../../../Styles";
import { InitiativeThroughputData } from "./ClientLevelMetricsGrid";
ChartJS.register(ArcElement, Tooltip, Legend);

interface InitiativesInFocusChartProps {
  company: Company
  initiativeThroughput: InitiativeThroughputData[]
  useMinWidth: boolean
}
export function InitiativesInFocusChart(props: InitiativesInFocusChartProps) {
  let totalThroughputs = 0;
  props.initiativeThroughput.forEach(initThroughputTotal => {
    totalThroughputs += initThroughputTotal.total;
  });

  const bigTextSX = metricsFontLarge;
  const throughputTotals: number[] = [];
  const percentList: number[] = [];
  const labelList: string[] = [];
  props.initiativeThroughput.forEach(throughput => {
    const percent = Math.round(Math.min((throughput.total / totalThroughputs), 1) * 100);
    throughputTotals.push(throughput.total);
    percentList.push(percent);
    labelList.push(`${throughput.initiativeTitle} ${percent}%`);
  });

  const data = {
    labels: labelList,
    datasets: [{
      data: percentList,
      backgroundColor: [
        'rgba(54, 235, 100, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(101, 207, 242, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(209, 125, 250, 0.2)'
      ],
      borderColor: [
        'rgba(54, 235, 100, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(101, 207, 242, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(209, 125, 250, 1)'
      ],
      borderWidth: 1,
    },
    ]
  };
  return (
    <Stack sx={{ justifyContent: "center", alignItems: "center", paddingY: 3 }}>
      <Box sx={props.useMinWidth ? { minWidth: "50%" } : { width: "50%" }}>
        {throughputTotals.length > 0 ?
          <Pie
            data={data}
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.label || '';
                      label = `${label}: ${(throughputTotals.at(context.dataIndex))} ${plural(props.company.terms.throughputTerm)}`;
                      return label;
                    }
                  }
                }
              }
            }}
          />
          :
          <Typography sx={bigTextSX}>N/A</Typography>
        }
      </Box>
    </Stack>
  )
}