import { Company, Initiative } from "../Store/CompanySlice";
import { ThroughputData } from "./CompanyService";
import { ItemColor } from "../Components/Metrics/InitiativeLevelMetrics/MetricsGrid";

export function GetDisplayItems(throughput: ThroughputData[], company: Company, initiatives: Initiative[], categoryMap: Map<string, { color: ItemColor, categoryTitle: string }>) {
    const startedItems = [];
    const completedItems = [];
    const workflow = company.workflow;
    const startColumn = workflow.columns.find(c => c.id === workflow.startDateColumnId);
    const endColumn = workflow.columns.find(c => c.id === workflow.endDateColumnId);
    if (!startColumn || !endColumn)
      return;
    for (const story of throughput)
    {
      const initiative = initiatives.find(i => i.id === story.initiativeId);
      if (!initiative) continue;
      const startDate = story.progressDates.find(p => p.workflowColumnId === startColumn.id)?.enterColumnDate;
      const endDate = story.progressDates.find(p => p.workflowColumnId === endColumn.id)?.enterColumnDate;
      const { color, categoryTitle } = categoryMap.get(story.categoryId) ?? { color: { code: "#ffffff", name: "White" }, categoryTitle: "" };
      const updatedStory = { ...story, startDate, endDate, categoryTitle, color, initiativeTitle: initiative.title };
      if (startDate)
      {
        const metricStartDateObj = new Date(company.metricStartDate);
        const metricEndDateObj = new Date(company.metricEndDate);
          
        if (endDate)
        {
          const endDateObj = new Date(endDate);
          const initStartDateObj = new Date(initiative.startDate);
          const initTargetDateObj = new Date(initiative.targetDate);
          const isWithinMetricsStartDate = Number.isNaN(metricStartDateObj.getTime()) || endDateObj.getTime() >= metricStartDateObj.getTime();
          const isWithinMetricsEndDate = Number.isNaN(metricEndDateObj.getTime()) || endDateObj.getTime() <= metricEndDateObj.getTime();
          const afterStart = endDateObj.getTime() >= initStartDateObj.getTime();
          const beforeTarget = endDateObj.getTime() <= initTargetDateObj.getTime();
          const noStartOrTargetDates = initiative.startDate === "" && initiative.targetDate === "";

          if (noStartOrTargetDates && isWithinMetricsStartDate && isWithinMetricsEndDate)
          completedItems.push(updatedStory);

          else if (afterStart && initiative.targetDate === "" && (isWithinMetricsEndDate))
            completedItems.push(updatedStory);

          else if (initiative.startDate === "" && beforeTarget && isWithinMetricsStartDate)
            completedItems.push(updatedStory);

          else if (afterStart && beforeTarget)
            completedItems.push(updatedStory);
        }
        else
        {
          if (initiative.targetDate)
          {
            if (new Date(initiative.targetDate).getTime() > Date.now()
              && (Number.isNaN(metricEndDateObj.getTime()) || metricEndDateObj.getTime() > Date.now())
            )
              startedItems.push(updatedStory);
          }
          else
          {
            if ((Number.isNaN(metricEndDateObj.getTime()) || metricEndDateObj.getTime() > Date.now()))
              startedItems.push(updatedStory);
          }
        }
      }
    }
    
    startedItems.sort((a, b) => {
      const dateA = a.startDate;
      const dateB = b.startDate;
      return Math.sign(new Date(dateB ?? Date.now()).getTime() - new Date(dateA ?? Date.now()).getTime());
    });

    completedItems.sort((a, b) => {
      const dateA = a.endDate;
      const dateB = b.endDate;

      return Math.sign(new Date(dateB ?? Date.now()).getTime() - new Date(dateA ?? Date.now()).getTime());
    });

    return {
      items: startedItems.concat(completedItems),
      startColumnName: startColumn.title,
      endColumnName: endColumn.title,
      firstStartedItemId: startedItems.at(0)?.id ?? "",
      firstCompletedItemId: completedItems.at(0)?.id ?? ""
    };
}