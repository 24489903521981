import { ThemeProvider } from "@emotion/react";
import { Grid, Typography, Stack, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, IconButton, TablePagination } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { GoToInitiativeDetailPage, GoToInitiativeDetailPageToAdd } from "../../Pages/InitiativeDetailPage";
import { Company, Initiative } from "../../Store/CompanySlice";
import { IntegrityTheme, TableHeaderStyle, defaultRowStyle, tooltipStyle, tableButtonFontSize, probabilityColors, blueTheme } from "../../Styles";
import { AddButton } from "../AddButton";
import { SearchBar } from "../SearchBar";
import { SortLabel } from "../SortLabel";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ProgressBar } from "./ProgressBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "../../Hooks/useEffectOnce";
import { usePaginator } from "../../Hooks/usePaginator";
import { useSorter } from "../../Hooks/useSorter";
import { initPageStateEnum } from "../../Pages/InitiativesPage";
import { User } from "../../Store/UserSlice";
import { GoToInitiativeDashboard } from "../../Pages/InitiativeDashboardPage";
import { FindItemsRemainingByInitiative, GetItemsBySelectedCategoriesAndInitiative, GetCompletedItems } from "../../Services/CompanyService";
import { InitiativeFilter } from "../../Services/Filters";
import { ForecastCompletionProbability } from "../../Services/ProbabilitySimulationService";
import { MakeClone } from "../../Services/Cloning";
import { ActiveRadioSet } from "../ActiveRadioSet";
import { plural } from "pluralize";

export const InitiativeManagementSectionIds = {
  table: "InitiativeManagementSectionTable",
  keywordFilter: "InitiativeManagementSectionKeywordFilter",
  addButton: "InitiativeManagementSectionAddButton",
  editButton: "InitiativeManagementSectionEditButton",
  title: "InitiativeManagementSectionTitle"
}

export const InitiativeDisplayRadioIds = {
  all: "initDisplayShowAll",
  active: "initDisplayShowActive",
  inactive: "initDisplayShowInactive"
}

interface InitiativesProps {
  company: Company
  currentUser: User
  addInitiative: boolean
  setAddInitiative: (value: boolean) => void
  state: initPageStateEnum
  setState: (newState: initPageStateEnum) => void
}

export interface InitCompanyDisplay {
  initiative: Initiative
  company: Company
  companyName: string
  initiativeTitle: string
  startDateTime: string
  targetDateTime: string
  probabilityValue: number | undefined
  probabilityStatus: string
  itemsRemaining: number
}

export interface SortConfig {
  key: string
  direction: 'asc' | 'desc'
}

export function InitiativeManagementSection(props: InitiativesProps) {
  const [displayItems, setDisplayItems] = useState<InitCompanyDisplay[]>([])
  const { PaginateItems, ...paginator } = usePaginator();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchedInit, setSearchedInit] = useState("");
  const [radioValue, setRadioValue] = useState('active');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffectOnce(() => {
    const initName = searchParams.get("initiativeName");
    if (initName)
      setSearchedInit(initName);
    else
      setSearchedInit("");
  });

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const probabilityCalculatedInitiatives = useMemo(() => {
    const currentCompany = MakeClone(props.company);
    let allInitCompanyDisplays: InitCompanyDisplay[] = [];
    const filteredInits = currentCompany.initiatives.sort((a, b) => a.title.localeCompare(b.title));
    for (const init of filteredInits)
    {
      const itemsRemaining = FindItemsRemainingByInitiative(currentCompany, init);
      const itemsBySelectedCategories = GetItemsBySelectedCategoriesAndInitiative(currentCompany, init);
      const completedItems = GetCompletedItems(itemsBySelectedCategories, currentCompany.workflow, [
        { startDate: currentCompany.metricStartDate, endDate: currentCompany.metricEndDate }
      ]);
      const probability = ForecastCompletionProbability(init.targetDate, completedItems, itemsRemaining, props.company.workflow);
      allInitCompanyDisplays.push({
        initiative: init,
        company: currentCompany,
        initiativeTitle: init.title,
        itemsRemaining: itemsRemaining,
        probabilityValue: probability.value,
        probabilityStatus: probability.status,
        companyName: currentCompany.name,
        startDateTime: init.startDate,
        targetDateTime: init.targetDate
      });
    }
    return allInitCompanyDisplays;
  }, [props.company])

  const filteredItems = useMemo(() => {
    const searchedItems = probabilityCalculatedInitiatives
      .filter(item => item.initiativeTitle.toLowerCase().includes(searchedInit.toLowerCase()));
    const filteredInitiatives = searchedItems.map(item => item.initiative);

    const matchingInitiatives = InitiativeFilter(filteredInitiatives, radioValue);

    return searchedItems.filter(item => matchingInitiatives.find(init => init.id === item.initiative.id));
  }, [probabilityCalculatedInitiatives, searchedInit, radioValue]);

  const sortedItems = useMemo(() => {
    return SortItems(filteredItems);
  }, [filteredItems, SortItems]);

  useEffect(() => {
    const paginatedItems = PaginateItems(sortedItems);
    setDisplayItems(paginatedItems);
  }, [sortedItems, PaginateItems]);

  const RequestSort = (key: string) => {
    UpdateSortConfig(key);
    ResetPageNumber();
  }

  function GetHealthIndicator(probability: number | undefined) {
    if (probability)
    {
      if (probability >= 85)
        return probabilityColors.green;
      if (probability >= 51)
        return probabilityColors.yellow;
      if (probability >= 0)
        return probabilityColors.red;
    }
    return probabilityColors.gray;
  }

  useEffect(() => {
    const maxPages = Math.max(Math.ceil(filteredItems.length / rowsPerPage), 1);
    if (page > maxPages - 1)
      setPage(maxPages - 1);
  }, [filteredItems, rowsPerPage, page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function ResetPageNumber() {
    paginator.HandlePaginationChange(null, 1);
  }

  function SaveInitiativeInURL(initiativeId: string) {
    const initiatives = props.company.initiatives;
    const matchingInitiative = initiatives.find(i => i.id === initiativeId);
    if (matchingInitiative)
      setSearchParams({ initiativeName: matchingInitiative.title });
  }

  function AdminSelectInitiative(initiativeId: string) {
    if (props.currentUser)
    {
      SaveInitiativeInURL(initiativeId);
      GoToInitiativeDashboard(navigate, props.currentUser.id, initiativeId);
    }
  }

  return (
    <>
      <div className="flex flex-col col-span-4">
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={4} sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
            {props.company.initiatives.length !== 0 ?
              <SearchBar cypressData={InitiativeManagementSectionIds.keywordFilter} placeholder="Keyword" value={searchedInit} setValue={(value: string) => { setSearchedInit(value); setPage(0); }} />
              :
              <Typography variant="body2">There are no {plural(props.company.terms.initiativeTerm.toLowerCase())} to display</Typography>
            }
          </Grid>
          <Grid item xs={6} sx={{
            display: 'flex',
            justifyContent: "center"
          }}>
            {props.company.initiatives.length !== 0 &&
              <ActiveRadioSet name="initiativesPage" setRadioValue={setRadioValue} listItems={props.company.initiatives} filterFunc={InitiativeFilter} cypressData={InitiativeDisplayRadioIds} />
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            <ThemeProvider theme={IntegrityTheme}>
              <Stack direction="row" gap={2}>
                <AddButton cypressData={InitiativeManagementSectionIds.addButton} HandleClick={() => GoToInitiativeDetailPageToAdd(navigate, props.company.id)} />
              </Stack>
            </ThemeProvider>
          </Grid>
        </Grid>
        {props.company.initiatives.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Title" sortKey='initiativeTitle' RequestSort={RequestSort} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Start Date" sortKey='startDateTime' RequestSort={RequestSort} />
                    </TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "13vw" }}>
                      <SortLabel sortConfig={sortConfig} heading="Target Completion" sortKey='targetDateTime' RequestSort={RequestSort} />
                    </TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "10vw" }}>Completed Items / Total Items</TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "10vw" }}>Percent Completed</TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "13vw" }}>
                      <SortLabel sortConfig={sortConfig} heading="On-Time Completion Probability" sortKey='probabilityValue' RequestSort={RequestSort} />
                    </TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "7vw" }}>Active Status</TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "10vw" }}>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody data-cy={InitiativeManagementSectionIds.table}>
                  {displayItems.map((displayItem, index) => {
                    const itemsCompleted = displayItem.initiative.totalItems - displayItem.itemsRemaining;
                    const probability = { value: displayItem.probabilityValue, status: displayItem.probabilityStatus };
                    const healthIndicator = GetHealthIndicator(probability.value);
                    const tooltipMessage = probability.value === undefined ? probability.status :
                      probability.value === 0 ? "Data may be insufficient or may indicate a very low probability of success" :
                        probability.value + "%";
                    const completedPercentage = Math.round(Math.min((itemsCompleted / displayItem.initiative.totalItems), 1) * 100);
                    const itemsCompletedTooltipMessage = `${displayItem.company.terms.initiativeTerm} does not have any items`;
                    return (
                      <Fragment key={index}>
                        <TableRow key={index} className={defaultRowStyle} sx={{
                          borderBottom: "1px solid black",
                          "& td": {
                            fontFamily: "Arial, Helvetica",
                            color: "#21345b"
                          }
                        }}>
                          <TableCell>
                            <Typography variant="caption" data-cy={InitiativeManagementSectionIds.title}>{displayItem.initiativeTitle}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{displayItem.initiative.startDate ? new Date(displayItem.initiative.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{displayItem.initiative.targetDate ? new Date(displayItem.initiative.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{itemsCompleted} / {displayItem.initiative.totalItems}</Typography>
                          </TableCell>
                          {displayItem.initiative.totalItems > 0 ?
                            <TableCell align="left" sx={{ width: "25%" }}>
                              <ProgressBar bgcolor="blue" completed={completedPercentage} />
                            </TableCell>
                            :
                            <TableCell align="left" sx={{ width: "25%" }} className={tooltipStyle} title={completedPercentage > 0 ? `${completedPercentage.toString()}%` : itemsCompletedTooltipMessage}>
                              <span>NA</span>
                              <i className="material-icons" style={{ fontSize: '15px', marginLeft: '15px', marginTop: '10px' }}>info_outline</i>
                            </TableCell>}
                          <TableCell className={tooltipStyle} title={tooltipMessage}>
                            {probability.value !== undefined ?
                              <ProgressBar key={index} bgcolor={healthIndicator} completed={probability.value} />
                              :
                              <>
                                <span>NA</span>
                                <i className="material-icons" style={{ fontSize: '15px', marginLeft: '15px', marginTop: '10px' }}>info_outline</i>
                              </>}
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{displayItem.initiative.isActive ? "Active" : "Inactive"}</Typography>
                          </TableCell>
                          <TableCell>
                            <ThemeProvider theme={blueTheme}>
                              <IconButton title="Edit Initiative" data-cy={InitiativeManagementSectionIds.editButton} color="primary" onClick={() => GoToInitiativeDetailPage(navigate, displayItem.initiative.id)}>
                                <EditIcon sx={{ fontSize: tableButtonFontSize }} />
                              </IconButton>
                              <IconButton title="View Dashboard" color="primary" onClick={() => AdminSelectInitiative(displayItem.initiative.id)}>
                                <VisibilityIcon sx={{ fontSize: tableButtonFontSize }} />
                              </IconButton>
                            </ThemeProvider>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={sortedItems.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        }
      </div>
    </>
  )
} 
