import axios from "axios";
import { Category, Company, Initiative, ShallowCompany, ShallowInitiative, Workflow } from "../Store/CompanySlice";
import { LocalizeUrl } from "./Http";
import { SerializeAxiosError } from "./Error";
import { v4 } from "uuid";
import { InCypress } from "./CypressDetection";

export interface DateInfo {
  month: number
  day: number
  year: number
}

export interface ThroughputProgressDate {
  workflowColumnId: string
  enterColumnDate: string
}

export interface ThroughputData {
  id: string
  key: string
  categoryId: string
  initiativeId: string
  title: string
  dateCreated?: string
  createdById?: string
  dateModified?: string
  modifiedById?: string
  progressDates: ThroughputProgressDate[]
}

export interface LinkData {
  id: string
  title: string
  url: string
  dateCreated?: string
  createdById?: string
  dateModified?: string
  modifiedById?: string
}

export interface MappableDecisionParticipant {
  id: string
}

export interface UnmappableDecisionParticipant {
  name: string
}

export interface DecisionData {
  id: string
  description: string
  resolution: string
  participants: {
    mappables: MappableDecisionParticipant[],
    unmappables: UnmappableDecisionParticipant[]
  }
  date: DateInfo
  tags: string[]
}

export interface GetCompanyRequest {
  companyId: string
  //initiativeIds?: string[]
}

interface GetCompanyResponse {
  companies: Company[]
}

export async function GetCompany(request: GetCompanyRequest, sessionId: string): Promise<GetCompanyResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/GetCompany");
    const response = await axios.post(baseUrl, { ...request, sessionId });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertCompanyInfoRequest {
  company: ShallowCompany
  newCategory?: Category
  newWorkflow?: Workflow
}

interface UpsertCompanyInfoResponse {
}

export async function UpsertCompanyInfo(request: UpsertCompanyInfoRequest, sessionId: string): Promise<UpsertCompanyInfoResponse> {
  try
  {
    const company = request.company;

    const info: ShallowCompany = {
      id: company.id,
      name: company.name,
      terms: company.terms,
      isActive: company.isActive,
      logoBlobName: company.logoBlobName,
      defaultCategoryId: company.defaultCategoryId,
      metricStartDate: company.metricStartDate,
      metricEndDate: company.metricEndDate,
      displayMetrics: company.displayMetrics
    }

    const baseUrl = LocalizeUrl("/api/UpsertCompany");
    const response = await axios.post(baseUrl, { ...request, company: info, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertInitiativeInfoRequest {
  initiative: ShallowInitiative
  companyId: string
}

interface UpsertInitiativeInfoResponse {
}

export async function UpsertInitiativeInfo(request: UpsertInitiativeInfoRequest, sessionId: string): Promise<UpsertInitiativeInfoResponse> {
  try
  {
    const initiative = request.initiative;
    const info: ShallowInitiative = {
      id: initiative.id,
      title: initiative.title,
      description: initiative.description,
      targetDate: initiative.targetDate,
      startDate: initiative.startDate,
      totalItems: initiative.totalItems,
      isActive: initiative.isActive,
      displayMetrics: initiative.displayMetrics,
      defaultDocumentId: initiative.defaultDocumentId
    }

    const baseUrl = LocalizeUrl("/api/UpsertInitiative");
    const response = await axios.post(baseUrl, { ...request, initiative: info, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface CloneInitiativeRequest {
  companyId: string
  sourceInitiativeId: string
  title: string
  description: string
  teamMemberIds: string[]
  decisionIds: string[]
  linkIds: string[]
}

export interface CloneInitiativeResponse {
  cloneId: string
}

export async function CloneInitiative(request: CloneInitiativeRequest, sessionId: string): Promise<CloneInitiativeResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/CloneInitiative");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

//initiative level metrics
export function GetItemsByInitiative(company: Company, initiative: Initiative) {
  return company.throughput.filter(c => c.initiativeId === initiative.id);
}
export function GetItemsBySelectedCategoriesAndInitiative(company: Company, initiative: Initiative) {
  const selectedCategories = company.categories.filter(c => c.includeInSims);
  const selectedCategoryIds = selectedCategories.flatMap(c => c.id);
  const throughputByInitiative = GetItemsByInitiative(company, initiative);
  return throughputByInitiative.filter(t => selectedCategoryIds.includes(t.categoryId));
}

export function FindItemsRemainingByInitiative(company: Company, initiative: Initiative) {
  const itemsByCategory = GetItemsBySelectedCategoriesAndInitiative(company, initiative);
  const itemsCompletedByCategory = GetCompletedItems(itemsByCategory, company.workflow, [
    { startDate: company.metricStartDate, endDate: company.metricEndDate },
    { startDate: initiative.startDate, endDate: initiative.targetDate }
  ]);
  const totalItemsCompletedByCategory = itemsCompletedByCategory.length;
  return initiative.totalItems - totalItemsCompletedByCategory;
}

//client level metrics
export function GetItemsBySelectedCategoriesAndCompany(company: Company) {
  const selectedCategories = company.categories.filter(c => c.includeInSims);
  const selectedCategoryIds = selectedCategories.flatMap(c => c.id);
  return company.throughput.filter(t => selectedCategoryIds.includes(t.categoryId));
}

// export function FindItemsRemainingByCompany(company: Company) {
//   const itemsByCategory = GetItemsBySelectedCategoriesAndCompany(company);
//   const allCompletedThroughput: ThroughputData[] = [];
//   let total = 0;
//   if (itemsByCategory) {
//     for (let throughput of itemsByCategory) 
//     {
//       let completedThroughput = throughput.progressDates.find(p => p.workflowColumnId === company?.workflow.endDateColumnId);
//       if (completedThroughput?.enterColumnDate !== "")
//         allCompletedThroughput.push(throughput);
//     }
//     for (const initiative of company.initiatives)
//     {
//       total += initiative.totalItems; 
//     }
//     return total - allCompletedThroughput.length;
//   }
//   return total;
// }


export function GetCompletedItems(items: ThroughputData[], workflow: Workflow, dateRanges: { startDate: string, endDate: string }[]) {
  return items.filter(i => {
    const completionDateString = i.progressDates.find(d => d.workflowColumnId === workflow.endDateColumnId)?.enterColumnDate;
    if (!completionDateString)
      return false;

    for (const range of dateRanges)
    {
      const completionDate = new Date(completionDateString);
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);
      if (completionDate.getTime() < startDate.getTime() || completionDate.getTime() > endDate.getTime())
        return false;
    }
    return true;
  });
}

export function CreateDefaultWorkflow(): Workflow {
  const startColumnId = v4();
  const endColumnId = v4();

  return {
    columns: [
      {
        id: startColumnId,
        title: "In Progress",
        ordering: 1
      },
      {
        id: endColumnId,
        title: "Done",
        ordering: 2
      }
    ],
    startDateColumnId: startColumnId,
    endDateColumnId: endColumnId
  }
}

export function IsInitiativeExpired(initiative: Initiative) {
  const targetDateObj = new Date(initiative.targetDate);
  if (Number.isNaN(targetDateObj.getTime()) || new Date(new Date().toDateString()) <= targetDateObj)
    return false;

  return true;
}


export interface UpsertCompanyThroughputRequest {
  companyId: string
  dataList: ThroughputData[]
}

interface UpsertCompanyThroughputResponse {
  throughput: ThroughputData[]
}

export async function UpsertCompanyThroughput(request: UpsertCompanyThroughputRequest, sessionId: string): Promise<UpsertCompanyThroughputResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertCompanyThroughput");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface DeleteCompanyThroughputRequest {
  companyId: string
  throughputId: string
}

interface DeleteCompanyThroughputResponse {
}

export async function DeleteCompanyThroughput(request: DeleteCompanyThroughputRequest, sessionId: string): Promise<DeleteCompanyThroughputResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/DeleteCompanyThroughput");
    const response = await axios.delete(baseUrl, { data: { ...request, sessionId, inCypress: InCypress() } });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}


export interface UpsertClientWorkflowRequest {
  companyId: string
  workflow: Workflow
}

interface UpsertClientWorkflowResponse {
  workflowTimestamps: {
    dateCreated?: string
    createdById?: string
    dateModified?: string
    modifiedById?: string
  }
}

export async function UpsertClientWorkflow(request: UpsertClientWorkflowRequest, sessionId: string): Promise<UpsertClientWorkflowResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertClientWorkflow");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertLinkDataRequest {
  initiativeId: string | undefined
  companyId: string
  links: LinkData[]
}

interface UpsertLinkDataResponse {
  linkTimestamps: {
    id: string
    dateCreated: string | undefined
    createdById: string | undefined
    dateModified: string | undefined
    modifiedById: string | undefined
  }[]
}

export async function UpsertLinkData(request: UpsertLinkDataRequest, sessionId: string): Promise<UpsertLinkDataResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertLink");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface DeleteLinkDataRequest {
  companyId: string
  initiativeId: string | undefined
  linkId: string
}

interface DeleteLinkDataResponse {
}

export async function DeleteLinkData(request: DeleteLinkDataRequest, sessionId: string): Promise<DeleteLinkDataResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/DeleteLink");
    const response = await axios.delete(baseUrl, { data: { ...request, sessionId, inCypress: InCypress() } });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertDecisionDataRequest {
  companyId: string
  initiativeId: string
  decisions: DecisionData[]
}

interface UpsertDecisionDataResponse {
}

export async function UpsertDecisionData(request: UpsertDecisionDataRequest, sessionId: string): Promise<UpsertDecisionDataResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertDecision");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface DeleteDecisionDataRequest {
  companyId: string
  initiativeId: string
  decisionIds: string[]
}

interface DeleteDecisionDataResponse {
}

export async function DeleteDecisionData(request: DeleteDecisionDataRequest, sessionId: string): Promise<DeleteDecisionDataResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/DeleteDecision");
    const response = await axios.delete(baseUrl, { data: { ...request, sessionId, inCypress: InCypress() } });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertCategoryRequest {
  companyId: string
  category: Category
}

interface UpsertCategoryResponse {
  categoryTimestamps: {
    dateCreated: string | undefined
    createdById: string | undefined
    dateModified: string | undefined
    modifiedById: string | undefined
  }
}

export async function UpsertCategory(request: UpsertCategoryRequest, sessionId: string): Promise<UpsertCategoryResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertCategory");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface DeleteCategoryRequest {
  companyId: string
  categoryId: string
}

interface DeleteCategoryResponse {
}

export async function DeleteCategory(request: DeleteCategoryRequest, sessionId: string): Promise<DeleteCategoryResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/DeleteCategory");
    const response = await axios.delete(baseUrl, { data: { ...request, sessionId, inCypress: InCypress() } });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

