import { AnyAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "./Store";
import { getCompany } from "./CompanySlice";
import { AuthenticatePasswordResetToken, AuthenticatePasswordResetTokenRequest, AuthenticateUser, AuthenticateUserPassword, AuthenticateUserPasswordRequest, AuthenticateUserRequest, EmailUser, EmailUserRequest, GetUser, GetUserRequest, UpsertUserInfo, UpsertUserInfoRequest, UpsertUserPassword, UpsertUserPasswordRequest } from "../Services/UserService";
import { MakeClone } from "../Services/Cloning";
import { setSessionId } from "./SessionSlice";
import { getAvatarDocumentInfos, getLogoDocumentInfos } from "./DocumentSlice";

export interface ShallowUser {
  id: string
  email: string
  companyId: string
  initiativeRoles: InitiativeRole[]
  name?: string
  phoneNumber?: string
  role?: string
  avatarBlobName?: string
  linkedin?: string
  facebook?: string
}

export interface User extends ShallowUser {
  isAdmin: boolean
  isActive: boolean
  isPasswordReset: boolean
}

export interface InitiativeRole {
  initiativeId: string
  isTeamMember: boolean
}

export function isShallowUser(user: User | ShallowUser): user is ShallowUser {
  return (user as User).isAdmin === undefined;
}

export function UserToShallowUser(user: User): ShallowUser {
  return {
    id: user.id,
    email: user.email,
    companyId: user.companyId,
    initiativeRoles: user.initiativeRoles,
    name: user.name,
    phoneNumber: user.phoneNumber,
    role: user.role,
    avatarBlobName: user.avatarBlobName
  }
}

export interface UserState {
  users: User[]
  shallowUsers: ShallowUser[]
  currentUserId: string
  logInAttempts: number
}

const initialState: UserState = {
  users: [],
  shallowUsers: [],
  currentUserId: "-1",
  logInAttempts: 0
}

export const getUser = createAsyncThunk(
  'users/getUser',
  async (args: GetUserRequest, { dispatch, getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetUser(args, false, sessionId);
    const fullUsers = response.users.filter((user): user is User => !isShallowUser(user));
    dispatch(addUsersToStore(fullUsers));
    return fullUsers;
  }
)

export const getShallowUser = createAsyncThunk(
  'users/getShallowUser',
  async (args: GetUserRequest, { dispatch, getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetUser(args, true, sessionId);
    const shallowUsers = response.users.filter((user): user is ShallowUser => isShallowUser(user));
    dispatch(addShallowUsersToStore(shallowUsers));
    return shallowUsers;
  }
)

export const upsertUserInfo = createAsyncThunk(
  'users/upsertUser',
  async (args: UpsertUserInfoRequest, { dispatch, getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await UpsertUserInfo(args, sessionId);
    const newUser = MakeClone(args.user);
    dispatch(addUsersToStore([newUser]));
    let shallowUsers: ShallowUser[] = [];
    shallowUsers.push(
      UserToShallowUser(newUser)
    );
    dispatch(addShallowUsersToStore(shallowUsers));
    return args.user;
  }
)
export const upsertUserPassword = createAsyncThunk(
  'users/upsertUserPassword',
  async (args: UpsertUserPasswordRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await UpsertUserPassword(args, sessionId);
  }
)

export const authenticatePasswordResetToken = createAsyncThunk(
  'tokens/authenticateToken',
  async (args: AuthenticatePasswordResetTokenRequest) => {
    const response = await AuthenticatePasswordResetToken(args);
    return response.isValid;
  }
)

export const authenticateUserPassword = createAsyncThunk(
  'tokens/authenticatePassword',
  async (args: AuthenticateUserPasswordRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await AuthenticateUserPassword(args, sessionId);
    return response.isValid;
  }
)

interface AuthenticationResult {
  success: boolean
  cause: string
}

export const AuthenticationFailureReasons = {
  inactiveAccount: "Your account has been deactivated. Please contact your system administrator for details.",
  invalidCreds: "Incorrect email or password.",
}

export const authenticateUser = createAsyncThunk(
  'users/authenticateUser',
  async (args: AuthenticateUserRequest, { dispatch }): Promise<AuthenticationResult> => {
    const response = await AuthenticateUser(args);
    if (!response.isUserActive)
      return { success: false, cause: AuthenticationFailureReasons.inactiveAccount };

    if (!response.user)
      return { success: false, cause: AuthenticationFailureReasons.invalidCreds };

    dispatch(setSessionId(response.sessionId));

    const user: User = response.user;
    await SetUpUserAfterAuthenticate(user, dispatch);

    return { success: true, cause: "" };
  }
)

export async function SetUpUserAfterAuthenticate(user: User, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  //const getAllInitiatives = user.isAdmin && user.companyId === IntegrityId;
  await dispatch(getCompany({
    companyId: user.companyId,
    //initiativeIds: getAllInitiatives ? undefined : user.initiativeRoles?.flatMap(i => i.initiativeId)
  }));
  try
  {
    await dispatch(getAvatarDocumentInfos({}));
  }
  catch (e)
  {
    console.log("Failed to load avatars: " + (e as Error).message);
  }
  try
  {
    await dispatch(getLogoDocumentInfos({}));
  }
  catch (e)
  {
    console.log("Failed to load logos: " + (e as Error).message);
  }
  dispatch(addUsersToStore([user]));
  dispatch(setCurrentUserId(user.id));
}

export const emailUser = createAsyncThunk(
  'users/emailUser',
  async (args: EmailUserRequest) => {
    const response = await EmailUser(args);
    return response;
  }
)

export const userSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setCurrentUserId: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.currentUserId = id;
    },
    signOut: (state) => {
      state.currentUserId = "-1";
      state.users = [];
      state.shallowUsers = [];
    },
    addUsersToStore: (state, action: PayloadAction<User[]>) => {
      const newUsers = action.payload;
      for (const user of newUsers)
      {
        const userIndex = state.users.findIndex(u => u.id === user.id);
        if (userIndex > -1)
          state.users.splice(userIndex, 1, user);
        else
          state.users.push(user);
      }
    },
    addShallowUsersToStore: (state, action: PayloadAction<ShallowUser[]>) => {
      const newUsers = action.payload;
      for (const user of newUsers)
      {
        const userIndex = state.shallowUsers.findIndex(u => u.id === user.id);
        if (userIndex > -1)
          state.shallowUsers.splice(userIndex, 1, user);
        else
          state.shallowUsers.push(user);
      }
    },
    CloneUserRoles: (state, action: PayloadAction<{ usersToUpdateIds: string[], sourceInitiativeId: string, newInitiativeId: string }>) => {
      const { usersToUpdateIds, sourceInitiativeId, newInitiativeId } = action.payload;
      for (const user of state.users)
      {
        if (usersToUpdateIds.includes(user.id))
        {
          const matchingRole = user.initiativeRoles.find(r => r.initiativeId === sourceInitiativeId);
          if (matchingRole)
            user.initiativeRoles.push({ initiativeId: newInitiativeId, isTeamMember: matchingRole.isTeamMember });
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.fulfilled, (state) => {
        state.logInAttempts = 0;
      })
      .addCase(authenticateUser.rejected, (state) => {
        state.logInAttempts++;
      })
      .addCase(upsertUserPassword.fulfilled, (state) => {
        const currentUser = state.users.find(user => user.id === state.currentUserId)
        if (currentUser)
          currentUser.isPasswordReset = false;
      })
  },
});

export const { setCurrentUserId, signOut, addUsersToStore, addShallowUsersToStore, CloneUserRoles } = userSlice.actions;

export const selectAllUsers = (state: RootState) => state.users.users;
export const selectCurrentUser = (state: RootState) => state.users.users.find((user: User) => user.id === state.users.currentUserId);
export const selectIsLoggedIn = (state: RootState) => state.users.currentUserId !== "-1";
export const selectLogInAttempts = (state: RootState) => state.users.logInAttempts;
export const selectAllShallowUsers = (state: RootState) => state.users.shallowUsers;

export default userSlice.reducer;
