import { useCallback, useEffect, useMemo, useState } from "react";
import { Company, IntegrityId, selectAllCompanies } from "../Store/CompanySlice";
import { getShallowUser, selectAllShallowUsers, selectCurrentUser } from "../Store/UserSlice";
import { Box, CircularProgress, IconButton, ListItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { ThemeProvider, Typography } from "@mui/material";
import { IntegrityTheme } from "../Styles";
import { DashboardDocumentsData } from "../Components/Documents/DashboardDocumentsData";
import { DashboardSection } from "../Components/DashboardSection";
import { DashboardTeamItem } from "../Components/DashboardTeamItem";
import { DocumentInfo, getCachedAvatarDocumentInfos, getDocumentInfos } from "../Store/DocumentSlice";
import Masonry from '@mui/lab/Masonry';
import { UserWithAvatarUrl } from "../Components/User/UserAvatar";
import { DashboardDecisionItem } from "../Components/Decisions/DashboardDecisionItem";
import { DashboardLinkItem } from "../Components/Links/DashboardLinkItem";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { RSSFeed, getRSSFeed } from "../Services/RSS";
import { AgileBitesBanner } from "../Components/AgileBitesBanner";
import { LinkData } from "../Services/CompanyService";
import { GetMatchingInitiativeAndCompany, UpdateInitiativeUserPairs } from "../Services/InitiativeUserPairService";
import InventoryIcon from '@mui/icons-material/Inventory';
import { ArchivedDocsModal } from "../Components/Documents/ArchivedDocsModal";
import { ErrorSnackbar } from "../Services/Snackbars";
import { logOut } from "../Store/SessionSlice";
import { plural } from "pluralize";
import { useGetLogo } from "../Hooks/useGetLogo";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AccountHeader } from "../Components/AccountHeader";
import { MetricsGrid } from "../Components/Metrics/InitiativeLevelMetrics/MetricsGrid";
import EditIcon from "@mui/icons-material/Edit";
import { GoToInitiativeDetailPage } from "./InitiativeDetailPage";
import { GoToClientAccountPage } from "./ClientAccountPage";
import { Article, getArticle } from "../Store/ArticleSlice";
import { DashboardArticleItem } from "../Components/Articles/DashboardArticleItem";

export function GoToInitiativeDashboard(navigate: NavigateFunction, currentUserId: string, initiativeId: string) {
  UpdateInitiativeUserPairs(currentUserId, initiativeId);
  navigate("/InitiativeDashboard");
}

export function InitiativeDashboardPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [docInfos, setDocInfos] = useState<DocumentInfo[]>([]);
  const [defaultDoc, setDefaultDoc] = useState<DocumentInfo>();
  const currentUser = useAppSelector(selectCurrentUser);
  const allCompanies = useAppSelector(selectAllCompanies);
  const shallowUsers = useAppSelector(selectAllShallowUsers);
  const [initiativeArticles, setInitiativeArticles] = useState<Article[]>([]);
  const [allAvatarUsers, setAllAvatarUsers] = useState<UserWithAvatarUrl[]>([]);
  const [initiativeAvatars, setInitiativeAvatars] = useState<UserWithAvatarUrl[]>([]);
  const [podcastFeed, setPodcastFeed] = useState<RSSFeed>();
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  useEffectOnce(async () => {
    try
    {
      const feed = await dispatch(getRSSFeed({ url: "https://anchor.fm/s/af939c48/podcast/rss" })).unwrap();
      setPodcastFeed(feed);
    }
    catch (e)
    {
      console.log("Unable to show podcast feed: " + (e as Error).message);
    }
  })

  const { selectedCompany, selectedInitiative } = useMemo(() => {
    if (currentUser)
      return GetMatchingInitiativeAndCompany(currentUser.id, allCompanies);
    return { selectedCompany: undefined, selectedInitiative: undefined };
  }, [currentUser, allCompanies]);

  const GetData = useCallback(async () => {
    setIsLoadingPage(true);
    try
    {
      setInitiativeArticles(await dispatch(getArticle({companyId: selectedCompany?.id ?? "", initiativeId: selectedInitiative?.id, userCompanyId: currentUser?.companyId ?? ""})).unwrap());
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoadingPage(false);
  }, [selectedCompany, selectedInitiative]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  useEffect(() => {
    if (currentUser && selectedInitiative)
    {
      UpdateInitiativeUserPairs(currentUser.id, selectedInitiative.id);
    }
  }, [currentUser, selectedInitiative]);

  const categoryIds = selectedCompany?.categories.filter(c => c.includeInSims === true) ?? [];
  const docTypes: string[] = [".jpg", ".jpeg", ".png"];

  const GetShallowUsers = useCallback(async (company: Company | undefined) => {
    setIsLoadingPage(true);

    if (company)
    {
      try
      {
        await dispatch(getShallowUser({ companyId: company.id })).unwrap();
        await dispatch(getShallowUser({ companyId: IntegrityId })).unwrap();
      }
      catch (e)
      {
        console.log("Unable to load team members: " + (e as Error).message);

        await dispatch(logOut({}));
        ErrorSnackbar(e);
      }
    }

    setIsLoadingPage(false);
  }, [dispatch]);

  useEffect(() => {
    GetShallowUsers(selectedCompany);
  }, [GetShallowUsers, selectedCompany, selectedInitiative]);

  const GetDocs = useCallback(async () => {
    if (selectedCompany && selectedInitiative) 
    {
      try
      {
        const clientDocs = await dispatch(getDocumentInfos({ companyId: selectedCompany.id })).unwrap();
        const initiativeDocs = await dispatch(getDocumentInfos({ companyId: selectedCompany.id, initiativeId: selectedInitiative.id })).unwrap();
        setDocInfos(initiativeDocs.concat(clientDocs));
        const initiativeDefaultDoc = initiativeDocs.find(doc => doc.blobName === selectedInitiative.defaultDocumentId);
        setDefaultDoc(initiativeDefaultDoc);
      }
      catch (e)
      {
        console.log("Unable to load documents: " + (e as Error).message);
      }
    }
  }, [dispatch, selectedCompany, selectedInitiative]);

  useEffect(() => {
    GetDocs();
  }, [GetDocs]);


  function SortIntegrityLast(a: UserWithAvatarUrl, b: UserWithAvatarUrl) {
    const aIsIntegrity = a.companyId === IntegrityId;
    const bIsIntegrity = b.companyId === IntegrityId;

    if (aIsIntegrity && bIsIntegrity)
      return 0;
    else if (aIsIntegrity)
      return 1;
    else if (bIsIntegrity)
      return -1;
    else
      return 0;
  }

  const {
    FetchLogo,
    companyLogo
  } = useGetLogo();

  useEffect(() => {
    FetchLogo(selectedCompany);
  }, [FetchLogo, selectedCompany])

  const GetAvatars = useCallback(async () => {
    if (selectedCompany && shallowUsers.length > 0)
    {
      const allAvatars = await dispatch(getCachedAvatarDocumentInfos({})).unwrap();
      const allPairs: UserWithAvatarUrl[] = [];
      const pairs: UserWithAvatarUrl[] = [];
      for (const user of shallowUsers)
      {
        const matchingDoc = allAvatars.find(doc => doc.blobName === user.avatarBlobName);
        const pair = { ...user, docUrl: matchingDoc?.tempUrl };
        if (user.initiativeRoles?.find(role => role.initiativeId === selectedInitiative?.id))
          pairs.push(pair);
        allPairs.push(pair);
      }
      pairs.sort(SortIntegrityLast);
      allPairs.sort(SortIntegrityLast);
      setAllAvatarUsers(allPairs);
      setInitiativeAvatars(pairs);
    }
  }, [selectedCompany, shallowUsers, selectedInitiative?.id, dispatch]);

  useEffect(() => {
    GetAvatars();
  }, [GetAvatars])

  const teamAvatars = useMemo(() => {
    if (!selectedInitiative)
      return [];

    return initiativeAvatars.filter(user => user.initiativeRoles?.find(role => role.initiativeId === selectedInitiative.id && role.isTeamMember))
  }, [initiativeAvatars, selectedInitiative])

  const mostRecentPodcast = podcastFeed?.items.at(0);
  const clientDocs = docInfos.filter(doc => !doc.tags.initiativeId);
  const archivedClientDocs = clientDocs.filter(doc => doc.metadata.archived);
  const unarchivedClientDocs = clientDocs.filter(doc => !doc.metadata.archived);
  const initiativeDocs = docInfos.filter(doc => doc.tags.initiativeId);
  const archivedInitiativeDocs = initiativeDocs.filter(doc => doc.metadata.archived);
  const unarchivedInitiativeDocs = initiativeDocs.filter(doc => !doc.metadata.archived);

  const [isArchiveOpen, setIsArchiveOpen] = useState(false);

  const docSection = (docs: DocumentInfo[], heading?: string) => (
    <div>
      <Typography variant="caption" sx={{ marginX: 1 }}>{heading} {plural(selectedCompany?.terms.documentTerm ?? "Document")}</Typography>
      <DashboardDocumentsData docInfos={docs} getDocs={GetDocs} canDelete={false} canArchive={false} documentTerm={selectedCompany?.terms.documentTerm ?? "Document"} />
    </div>
  );

  const initiativeDocSection = docSection(unarchivedInitiativeDocs, selectedInitiative?.title);
  const clientDocSection = docSection(unarchivedClientDocs, selectedCompany?.name);
  const archivedInitiativeDocSection = docSection(archivedInitiativeDocs, selectedInitiative?.title);
  const archivedClientDocSection = docSection(archivedClientDocs, selectedCompany?.name);

  const linkSection = (links: LinkData[], heading?: string) => (
    <div>
      <Typography variant="caption" sx={{ marginX: 1 }}>{heading} {plural(selectedCompany?.terms.linkTerm ?? "Link")}</Typography>
      <DashboardLinkItem linkData={links} />
    </div>
  );

  const initiativeLinkSection = linkSection(selectedInitiative?.links ?? [], selectedInitiative?.title);
  const clientLinkSection = linkSection(selectedCompany?.links ?? [], selectedCompany?.name);

  const isIntegrityAdmin = currentUser?.isAdmin && currentUser.companyId === IntegrityId;

  return (
    <>
      {isLoadingPage &&
        <div className="flex justify-center my-4">
          <CircularProgress color="warning" />
        </div>
      }

      {!isLoadingPage && currentUser &&
        <ThemeProvider theme={IntegrityTheme}>
          <Box sx={{ marginX: 8 }}>
            <AccountHeader currentUser={currentUser} companyLogo={companyLogo}
              endIcon={
                isIntegrityAdmin ?
                  <IconButton title="Edit Client" onClick={() => GoToClientAccountPage(navigate, selectedCompany?.id ?? "")}>
                    <EditIcon sx={{ fontSize: 20, color: "black" }} />
                  </IconButton>
                  :
                  undefined
              }
            />
            {mostRecentPodcast &&
              <AgileBitesBanner feed={mostRecentPodcast} />
            }
            <div style={{ width: '100%' }}>
              {selectedCompany && selectedInitiative && currentUser &&
                <Box sx={{ marginY: 4 }}>
                  <DashboardSection
                    title={
                      <>
                        {`${selectedInitiative.title} ${selectedInitiative.startDate && selectedInitiative.targetDate ? `(${new Date(selectedInitiative.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' })} - ${new Date(selectedInitiative.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' })})` : ""}`}
                        {isIntegrityAdmin &&
                          <IconButton title="Edit Project" onClick={() => GoToInitiativeDetailPage(navigate, selectedInitiative.id)}>
                            <EditIcon sx={{ color: "black", fontSize: 20 }} />
                          </IconButton>
                        }
                      </>
                    }
                    items={<ListItem sx={{ marginY: 2 }}>
                      <Typography>{selectedInitiative.description}</Typography>
                    </ListItem>}
                  />
                  <Box sx={{ marginY: 4 }}>
                    {defaultDoc &&
                      <DashboardSection title={defaultDoc.metadata.shortDescription}
                        items={<>
                          {defaultDoc.metadata.longDescription ?
                            <ListItem>
                              <Typography>{defaultDoc.metadata.longDescription}</Typography>
                            </ListItem>
                            :
                            undefined
                          }
                          <div className="flex justify-center">
                            {docTypes.some(type => defaultDoc.tempUrl.includes(type)) ?
                              <img alt={defaultDoc.metadata.shortDescription} width="100%" height="auto" src={defaultDoc.tempUrl} />
                              :
                              <iframe title={defaultDoc.metadata.shortDescription} src={defaultDoc.tempUrl} width="100%" height="600" />
                            }
                          </div>
                        </>}
                      />
                    }
                  </Box>
                  {(categoryIds.length > 0 || selectedCompany.categories.length < 1) &&
                    <MetricsGrid selectedInitiative={selectedInitiative} selectedCompany={selectedCompany} />
                  }
                  <Masonry columns={{ xs: 1, sm: 1, md: 2, xl: 3 }} spacing={4} sx={{ width: "auto" }}>
                    {teamAvatars.length > 0 &&
                      <DashboardSection title={`${selectedCompany.terms.teamTerm}`} expandable={true}
                        items={teamAvatars.map((user, userIndex) => <DashboardTeamItem key={userIndex} user={user} />)}
                      />
                    }
                    {selectedInitiative.decisions.length > 0 &&
                      <DashboardSection title={`${plural(selectedCompany.terms.decisionTerm)}`} expandable={true}
                        items={selectedInitiative.decisions.slice(0, 2).map((decision, index) => <DashboardDecisionItem key={index} decision={decision} teamUsers={allAvatarUsers} />)}
                      />
                    }
                    {initiativeArticles.length > 0 &&
                      <DashboardSection title="Articles" expandable={true}
                        items={initiativeArticles.map((article, index) => <DashboardArticleItem key={index} article={article} company={selectedCompany} initiative={selectedInitiative}/>)}
                      />
                    }
                    {(selectedInitiative.links.length > 0 || selectedCompany.links.length > 0) &&
                      <DashboardSection title={`${plural(selectedCompany.terms.linkTerm)}`} expandable={true}
                        items={selectedCompany.links.length > 0 ? (selectedInitiative.links.length > 0 ? [clientLinkSection, initiativeLinkSection] : clientLinkSection) : (selectedInitiative.links.length > 0 ? initiativeLinkSection : <Typography variant="caption" sx={{ marginX: 1 }}>There are no {plural(selectedCompany.terms.linkTerm.toLowerCase())} to display</Typography>)}
                      />
                    }
                    {docInfos.length > 0 &&
                      <>
                        <DashboardSection title={`${plural(selectedCompany.terms.documentTerm)}`} expandable={true}
                          button={<IconButton title="View Archive" onClick={() => setIsArchiveOpen(true)}><InventoryIcon /></IconButton>}
                          items={unarchivedClientDocs.length > 0 ? (unarchivedInitiativeDocs.length > 0 ? [clientDocSection, initiativeDocSection] : clientDocSection) : (unarchivedInitiativeDocs.length > 0 ? initiativeDocSection : <Typography variant="caption" sx={{ marginX: 1 }}>There are no {plural(selectedCompany.terms.documentTerm.toLowerCase())} to display</Typography>)}
                        />
                        <ArchivedDocsModal isOpen={isArchiveOpen} setIsOpen={setIsArchiveOpen} clientDocs={archivedClientDocs} initiativeDocs={archivedInitiativeDocs} clientDocSection={archivedClientDocSection} initiativeDocSection={archivedInitiativeDocSection} />
                      </>
                    }
                  </Masonry>
                </Box>
              }
              {!selectedInitiative &&
                <div className="m-2 p-2 text-3xl font-bold">No Initiatives to Display</div>
              }
            </div>
          </Box>
        </ThemeProvider>
      }
    </>
  )
}
