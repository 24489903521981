import { Company, Initiative, IntegrityId } from "../../Store/CompanySlice";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tableCellFontSize } from "../../Styles";
import { DateInfo, DecisionData } from "../../Services/CompanyService";
import { Checkbox, CircularProgress, FormControl, InputLabel, ListItemText, MenuItem, Select, Stack, Typography } from "@mui/material";
import { BaseModal } from "../BaseModal";
import { AddButton } from "../AddButton";
import { MakeClone } from "../../Services/Cloning";
import { SearchBar } from "../SearchBar";
import { ShallowUser, User, getShallowUser } from "../../Store/UserSlice";
import { DecisionCard } from "./DecisionCard";
import { useEditDecision } from "../../Hooks/useEditDecision";
import { DateInfoInput } from "../DateInfoInput";
import { CompareDateInfos } from "../../Services/DateHelpers";
import { DeleteAlert } from "../DeleteAlert";
import { useAppDispatch } from "../../Hooks/Hooks";
import { UserWithAvatarUrl } from "../User/UserAvatar";
import { getCachedAvatarDocumentInfos } from "../../Store/DocumentSlice";
import { plural } from "pluralize";

export const DecisionModalIds = {
  modal: "decisionModal",
  keywordFilter: "decisionModalKeywordFilter",
  addButton: "decisionModalAddButton",
  closeModalButton: "decisionModalCloseModalButton",
  editButton: "decisionModalEditButton",
  deleteButton: "decisionModalDeleteButton",
  saveChangesButton: "decisionModalSaveChangesButton",
  cancelChangesButton: "decisionModalCancelChangesButton",
  description: "decisionModalDescription",
  resolution: "decisionModalResolution",
  participants: "decisionModalParticipants",
  date: "decisionModalDate",
  editDescription: "decisionModalEditDescription",
  editResolution: "decisionModalEditResolution",
  editParticipants: "decisionModalEditParticipants",
  addParticipantButton: "decisionModalAddParticipantButton",
  editDate: "decisionModalEditDate",
  grid: "decisionModalGrid",
  tagSelect: "decisionModalTagSelect"
}

interface DecisionDataProps {
  company: Company
  initiative: Initiative
  isOpen: boolean
  HandleClose: () => void
  currentUser: User
}

export default function DecisionDataModal(props: DecisionDataProps) {
  const dispatch = useAppDispatch();
  const [selectedInitiative, setSelectedInitiative] = useState<Initiative>(props.initiative);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [startDate, setStartDate] = useState<DateInfo>();
  const [endDate, setEndDate] = useState<DateInfo>();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [queryTags, setQueryTags] = useState<string[]>([]);
  function ClearFilters() {
    setSearchedKeyword("");
    setQueryTags([]);
  }

  const titleRef = useRef<HTMLTextAreaElement>(null);
  function SnapToTitle() {
    setTimeout(() => {
      titleRef.current?.focus();
    }, 1);
  }

  const [possibleParticipants, setPossibleParticipants] = useState<UserWithAvatarUrl[]>();

  function AddEmailsToDuplicates(array: ShallowUser[]) {
    let duplicateIndices = new Set();
    array.forEach((user, index) => {
      if (user.name)
      {
        array.forEach((u, i) => {
          if (u.name === user.name && i !== index)
          {
            duplicateIndices.add(i);
            duplicateIndices.add(index);
          }
        })
      }
    })

    let newArray = array.map((user, index) => duplicateIndices.has(index) ? { ...user, name: `${user.name} (${user.email})` } : user);
    return newArray;
  }

  useEffect(() => {

    async function GetPossibleParticipants() {
      try
      {
        const users = await dispatch(getShallowUser({ companyId: props.company.id })).unwrap();
        const integrityUsers = await dispatch(getShallowUser({ companyId: IntegrityId })).unwrap();
        const allAvatars = await dispatch(getCachedAvatarDocumentInfos({})).unwrap();
        const allUsers = AddEmailsToDuplicates(users.concat(integrityUsers));
        const usersWithAvatars = allUsers.map(user => {
          const matchingDoc = allAvatars.find(doc => doc.blobName === user.avatarBlobName);
          if (matchingDoc)
            return { ...user, docUrl: matchingDoc.tempUrl }
          return user;
        });
        setPossibleParticipants(usersWithAvatars);
      }
      catch (e)
      {
        console.log((e as Error).message);
        setPossibleParticipants([]);
      }
    }

    if (props.isOpen)
      GetPossibleParticipants();
  }, [props.isOpen, dispatch, props.company.id]);

  const {
    cloneForEditing,
    setCloneForEditing,
    isEditing,
    isLoading,
    canAddTag,
    canAddParticipant,
    LeaveEditMode,
    DeleteDecision,
    CancelDelete,
    HandleStartEdit,
    HandleCancelEdit,
    HandleSaveEdit,
    HandleAddEmptyDecision,
    HandleAttemptDelete,
    HandleChangeNewTag,
    HandleAddTag,
    HandleRemoveTag,
    HandleChangeNewUnmappedParticipant,
    HandleChangeNewMappedParticipant,
    HandleAddParticipant,
    HandleRemoveUnmappedParticipant,
    HandleRemoveMappedParticipant
  } = useEditDecision(props.company, selectedInitiative, setSelectedInitiative, setIsDeleteOpen, ClearFilters, SnapToTitle);

  const allTags = useMemo(() => {
    let tags: string[] = [];
    for (const decision of props.initiative.decisions)
    {
      for (const tag of decision.tags)
      {
        if (!tags.find(t => t === tag))
          tags.push(tag);
      }
    }
    return tags;
  }, [props.initiative]);

  useEffect(() => {
    setQueryTags(prevState => prevState.filter(tag => allTags.find(t => t === tag)));
  }, [allTags]);

  const UpdateQueryTags = (tag: string | string[]) => {
    let selTags = MakeClone(queryTags);
    if (Array.isArray(tag))
      selTags = tag;
    else
    {
      const index = selTags.findIndex(t => t === tag);
      if (index >= 0)
        selTags.splice(index, 1);
      else
        selTags.push(tag);
    }
    setQueryTags(selTags);
  }

  const OnOpen = useCallback(() => {
    setSelectedInitiative(props.initiative);
    LeaveEditMode();
  }, [props.initiative, LeaveEditMode]);

  useEffect(() => {
    OnOpen();
  }, [props.isOpen, OnOpen]);

  const FindInParticipants = useCallback((participants: DecisionData["participants"], keyword: string) => {
    for (const participant of participants.unmappables)
    {
      if (participant.name.toUpperCase().includes(keyword.toUpperCase()))
        return true;
    }
    if (possibleParticipants)
    {
      for (const participant of participants.mappables)
      {
        const matchingUser = possibleParticipants.find(p => participant.id === p.id);
        if (matchingUser?.name?.toUpperCase().includes(keyword.toUpperCase()))
          return true;
      }
    }
    return false;
  }, [possibleParticipants]);

  const filteredDecisions = useMemo(() => {
    return selectedInitiative.decisions.filter(
      d => {
        const searchMatches = d.description.toUpperCase().includes(searchedKeyword.toUpperCase())
          || d.resolution.toUpperCase().includes(searchedKeyword.toUpperCase())
          || FindInParticipants(d.participants, searchedKeyword);
        const tagMatches = queryTags.length ? d.tags.some(tag => queryTags.find(t => t === tag)) : true;
        const afterStart = startDate ? CompareDateInfos(d.date, startDate) >= 0 : true;
        const beforeEnd = endDate ? CompareDateInfos(d.date, endDate) <= 0 : true;

        return searchMatches && tagMatches && afterStart && beforeEnd;
      }
    );
  }, [selectedInitiative, searchedKeyword, queryTags, startDate, endDate, FindInParticipants]);

  return (
    <>
      <BaseModal
        open={props.isOpen}
        onClose={() => props.HandleClose()}
        cypressData={{ modal: DecisionModalIds.modal, closeModalButton: DecisionModalIds.closeModalButton }}
        title={`${plural(props.company.terms.decisionTerm)}`}
        subtitle={`${props.company.name}${props.initiative ? ` - ${props.initiative.title}` : ``}`}
        maxWidth={false}
      >
        {possibleParticipants === undefined ?
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
          :
          possibleParticipants.length <= 0 ?
            <Stack alignItems="center">
              <Typography>{plural(props.company.terms.decisionTerm)} could not be loaded.</Typography>
            </Stack>
            :
            <div className="mx-1 mb-2">
              <div className="flex flex-row justify-content:space-around">
                <Grid container sx={{
                  display: 'flex',
                  placeItems: 'center',
                  flexDirection: 'row',
                  p: 1,
                  mt: 2,
                  mb: 1,
                  ml: 2,
                  mr: 2,
                  borderRadius: 1,
                }}>
                  <Grid item xs={10} sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}>
                    {selectedInitiative.decisions.length !== 0 &&
                      <div className="flex space-x-2">
                        <SearchBar cypressData={DecisionModalIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={setSearchedKeyword} disabled={isEditing} />
                        <FormControl fullWidth>
                          <InputLabel id="tag-select-label">Select Tags</InputLabel>
                          <Select sx={{ fontSize: tableCellFontSize }} data-cy={DecisionModalIds.tagSelect}
                            multiple labelId="tag-select-label" label="Select Tags"
                            value={queryTags} onChange={(e) => UpdateQueryTags(e.target.value)}
                            renderValue={(selected) => selected.map((x) => x).join(', ')}
                            disabled={isEditing}
                          >
                            {allTags.map((tag, index) => {
                              return (
                                <MenuItem sx={{ fontSize: tableCellFontSize }} key={index} value={tag}>
                                  <Checkbox checked={queryTags.findIndex(t => t === tag) >= 0} />
                                  <ListItemText>{tag}</ListItemText>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <DateInfoInput cypressData="" date={startDate} setDate={setStartDate} label="Start Date" disabled={isEditing} />
                        <DateInfoInput cypressData="" date={endDate} setDate={setEndDate} label="End Date" disabled={isEditing} />
                      </div>
                    }
                  </Grid>
                  {props.currentUser.isAdmin &&
                    <Grid item xs={2} sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <AddButton cypressData={DecisionModalIds.addButton} HandleClick={() => HandleAddEmptyDecision()} disabled={isEditing} />
                    </Grid>
                  }
                </Grid>
              </div>
              {!isLoading && filteredDecisions.length === 0 &&
                <Typography sx={{ marginBottom: 2.5 }} className="m-2 p-2 text-2xl font-bold">There are no {plural(props.company.terms.decisionTerm.toLowerCase())} to display</Typography>
              }
              <Grid container sx={{
                display: 'flex',
                justifyContent: "space-around",
                placeItems: 'start',
                flexDirection: 'row'
              }}
                spacing={4}
                data-cy={DecisionModalIds.grid}>
                {
                  filteredDecisions.map((displayItem, key) => {
                    let matched = displayItem.id === (cloneForEditing?.id ?? -1);
                    let isEdit = matched && isEditing;

                    return (
                      <Grid item md={6} lg={4} key={key}>
                        <DecisionCard decision={displayItem} isEditable={props.currentUser.isAdmin} isEditingMe={isEdit} isEditingAnyone={isEditing}
                          isLoading={isLoading} canAddTag={canAddTag} canAddParticipant={canAddParticipant}
                          handlers={{
                            HandleChangeNewTag,
                            HandleAddTag,
                            HandleRemoveTag,
                            HandleChangeNewUnmappedParticipant,
                            HandleChangeNewMappedParticipant,
                            HandleAddParticipant,
                            HandleRemoveUnmappedParticipant,
                            HandleRemoveMappedParticipant,
                            HandleStartEdit,
                            HandleSaveEdit,
                            HandleCancelEdit,
                            HandleAttemptDelete
                          }}
                          currentValues={cloneForEditing ?? displayItem}
                          setCurrentValues={(decision: DecisionData) => setCloneForEditing(decision)}
                          titleRef={titleRef}
                          allTags={allTags}
                          possibleParticipants={possibleParticipants}
                        />
                      </Grid>
                    )
                  })}
              </Grid>
            </div>
        }
      </BaseModal>
      <DeleteAlert isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} Delete={DeleteDecision} CancelDelete={CancelDelete} id={cloneForEditing?.id} title={`Delete this ${props.company.terms.decisionTerm.toLowerCase()}?`} description={`Once removed, a ${props.company.terms.decisionTerm.toLowerCase()} cannot be recovered.`} />
    </>
  );
}