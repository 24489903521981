import { useEffect, useState } from "react";

export function useEffectOnce<T>(callback: (...args: T[]) => any, ...args: T[]) {
  const [mounted, setMounted] = useState(false);
  const resetInit = () => setMounted(false);

  useEffect(() => {
    if (!mounted)
    {
      setMounted(true);
      callback(...args);
    }
  }, [mounted, callback, args]);

  return [resetInit];
}


