import { useState } from "react";
import { uploadDocument } from "../../Store/DocumentSlice";
import { Company, Initiative } from "../../Store/CompanySlice";
import { useAppDispatch } from "../../Hooks/Hooks";
import { FileUpload } from "../FileUpload";
import { Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { tableCellFontSize } from "../../Styles";
import { ErrorSnackbar, SuccessSnackbar } from "../../Services/Snackbars";

interface DocumentUploadProps {
  cypressData: {
    chooseFileButton: string,
    submitButton: string,
    fileInput: string,
    newShortDescription: string,
    newLongDescription: string
  }
  company: Company
  initiative?: Initiative
  articleId?: string
  GetData: () => Promise<void>
}

export function DocumentUpload(props: DocumentUploadProps) {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentShortDescription, setCurrentShortDescription] = useState("");
  const [currentLongDescription, setCurrentLongDescription] = useState("");

  function ResetFiles() {
    setFile(null);
    setIsUploading(false);
    setCurrentShortDescription("");
    setCurrentLongDescription("");
  }

  async function UploadFile() {
    if (!file)
    {
      ErrorSnackbar("Cannot upload; no file was provided.");
      return;
    }

    try
    {
      setIsUploading(true);
      await dispatch(uploadDocument({
        file: file, companyId: props.company.id,
        shortDescription: currentShortDescription, longDescription: currentLongDescription,
        initiativeId: props.initiative?.id, articleId: props.articleId
      })).unwrap();

      setTimeout(async () => {
        await props.GetData();
        SuccessSnackbar("File uploaded successfully!");
        ResetFiles();
      }, 2000);

    }
    catch (e)
    {
      ErrorSnackbar(e);
      setIsUploading(false);
    }
  }

  return (
    <Card sx={{}}>
      <CardHeader title="Upload Document" />
      <CardContent>
        <Grid container gap={2} direction="column">
          <Grid item>
            <TextField label="Title" sx={{ fontSize: tableCellFontSize, width: "50%" }} data-cy={props.cypressData.newShortDescription} value={currentShortDescription} onChange={(e) => setCurrentShortDescription(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField label="Description" multiline maxRows={4} minRows={2} sx={{ fontSize: tableCellFontSize, width: "100%" }} data-cy={props.cypressData.newLongDescription} value={currentLongDescription} onChange={(e) => setCurrentLongDescription(e.target.value)} />
          </Grid>
          <Grid item>
            <FileUpload cypressData={props.cypressData} file={file} setFile={setFile} isUploading={isUploading} UploadFile={UploadFile} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
