import { Company } from "../Store/CompanySlice";
import { User } from "../Store/UserSlice";

export type CompanyUserPair = { userId: string, companyId: string, dateModified?: string };
export const COMPANY_USER_PAIR = "lastCompanyOnDashboardPage";

export function GetCompanyUserPairs() {
  const pairsString = localStorage.getItem(COMPANY_USER_PAIR);
  if (pairsString)
  {
    const pairs: CompanyUserPair[] = JSON.parse(pairsString);
    return pairs;
  }
  return [];
}

export function UpdateCompanyUserPairs(userId: string, companyId: string) {
  const pair: CompanyUserPair = { userId, companyId, dateModified: new Date().toISOString() };
  const pairs = GetCompanyUserPairs();
  const matchingIndex = pairs.findIndex(p => p.userId === pair.userId);
  if (matchingIndex >= 0)
    pairs.splice(matchingIndex, 1, pair);
  else
    pairs.push(pair);
  localStorage.setItem(COMPANY_USER_PAIR, JSON.stringify(pairs));
}

export function GetMatchingCompany(user: User, allCompanies: Company[]) {
  let recentCompanyId = "";
  const pairs = GetCompanyUserPairs();
  const matchingPair = pairs.find(pair => pair.userId === user.id);
  if (matchingPair)
    recentCompanyId = matchingPair.companyId;

  const company = allCompanies.find(c => c.id === recentCompanyId) ?? allCompanies.find(c => c.id === user.companyId);
  return company;
}