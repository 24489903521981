import { useCallback, useState } from "react";
import { Company, Initiative, deleteDecisionData, upsertDecisionData } from "../Store/CompanySlice";
import { DateInfo, DecisionData, MappableDecisionParticipant, UnmappableDecisionParticipant } from "../Services/CompanyService";
import { MakeClone } from "../Services/Cloning";
import { v4 } from "uuid";
import { DateToDateInfo } from "../Services/DateHelpers";
import { ValidateDecisions, ValidationFailedPrefix } from "../Services/Validation/Validation";
import { useAppDispatch } from "./Hooks";
import { ErrorSnackbar, SuccessSnackbar } from "../Services/Snackbars";

enum State {
  start,
  edit,
  add,
  delete
}

interface EditDecision {
  cloneForEditing: DecisionData | undefined
  setCloneForEditing: (value: DecisionData | undefined) => void
  isEditing: boolean
  isLoading: boolean
  canAddTag: boolean
  canAddParticipant: boolean
  EnterEditMode: (decisionId: string, currentInitiative: Initiative, isNewDecision: boolean) => void
  LeaveEditMode: () => void
  DeleteDecision: (decisionId: string) => void
  CancelDelete: () => void
  HandleStartEdit: (decisionId: string) => void
  HandleCancelEdit: () => void
  HandleSaveEdit: (newDecision: DecisionData) => void
  HandleAddEmptyDecision: () => void
  HandleAttemptDelete: (decisionId: string) => void
  HandleChangeNewTag: (event: any, tag: string | null) => void
  HandleAddTag: () => void
  HandleRemoveTag: (tagToRemove: string) => void
  HandleChangeNewUnmappedParticipant: (event: any, name: string | null) => void
  HandleChangeNewMappedParticipant: (value: {id: string, name: string}) => void
  HandleAddParticipant: () => void
  HandleRemoveUnmappedParticipant: (participantToRemove: UnmappableDecisionParticipant) => void
  HandleRemoveMappedParticipant: (participantToRemove: MappableDecisionParticipant) => void
}

export function useEditDecision(selectedCompany: Company, selectedInitiative: Initiative, setSelectedInitiative: (value: Initiative) => void, setIsDeleteOpen: (value: boolean) => void, ClearFilters: () => void, SnapToTitle: () => void): EditDecision {
  const dispatch = useAppDispatch();
  const [useEditState, setUseEditState] = useState(State.start);
  const [cloneForEditing, setCloneForEditing] = useState<DecisionData>();
  const [isLoading, setIsLoading] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [newUnmappedParticipant, setNewUnmappedParticipant] = useState("");
  const [newMappedParticipant, setNewMappedParticipant] = useState<{id: string, name: string}>();

  const isEditing = useEditState === State.edit || useEditState === State.add;

  function EnterEditMode(decisionId: string, currentInitiative: Initiative, isNewDecision: boolean) {
    let currentDecision = currentInitiative.decisions.find(d => d.id === decisionId);
    if (currentDecision)
    {
      setUseEditState(isNewDecision ? State.add : State.edit);
      setCloneForEditing(MakeClone(currentDecision));
      setNewTag("");
      setNewUnmappedParticipant("");
      setNewMappedParticipant(undefined);

      SnapToTitle();
    }
  }

  const LeaveEditMode = useCallback(() => {
    setCloneForEditing(undefined);
    setUseEditState(State.start);
  }, []);

  function HandleStartEdit(decisionId: string) {
    EnterEditMode(decisionId, selectedInitiative, false);
  }

  function HandleCancelEdit() {
    if (useEditState === State.add && cloneForEditing)
    {
      let initiativeClone = MakeClone(selectedInitiative);
      initiativeClone.decisions = initiativeClone.decisions.filter(d => d.id !== cloneForEditing.id);

      setSelectedInitiative(initiativeClone);
    }
    LeaveEditMode();
  }

  async function HandleSaveEdit(newDecision: DecisionData) {
    const selectedInitiativeClone = MakeClone(selectedInitiative);
    let matchingIndex = selectedInitiativeClone.decisions.findIndex(d => d.id === newDecision.id);

    if (matchingIndex >= 0)
      selectedInitiativeClone.decisions[matchingIndex] = newDecision;
    else
      selectedInitiativeClone.decisions.push(newDecision);
    setIsLoading(true);
    let successfulSubmit = await SubmitDecisionData(selectedInitiativeClone.decisions);
    if (successfulSubmit)
      setSelectedInitiative(selectedInitiativeClone);
    setIsLoading(false);
  }

  async function SubmitDecisionData(decisions: DecisionData[]): Promise<boolean> {
    let validation = ValidateDecisions(decisions);
    if (validation.success)
    {
      try
      {
        await dispatch(upsertDecisionData({ companyId: selectedCompany.id, initiativeId: selectedInitiative.id, decisions: decisions })).unwrap();
        SuccessSnackbar("Decision changes have been saved.");
        LeaveEditMode();
        return true;
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
    }
    else
      ErrorSnackbar(ValidationFailedPrefix + validation.message);

    return false;
  }

  function HandleAddEmptyDecision() {
    if (useEditState === State.start)
    {
      let initiativeClone: Initiative = MakeClone(selectedInitiative);
      const newId = v4();
      const today = new Date();
      const todayInfo: DateInfo = DateToDateInfo(today);
      const newDecision: DecisionData = { id: newId, description: "", resolution: "", participants: {mappables: [], unmappables: []}, date: todayInfo, tags: [] };
      initiativeClone.decisions.unshift(newDecision);

      ClearFilters();
      setSelectedInitiative(initiativeClone);
      EnterEditMode(newId, initiativeClone, true);
    }
    else
      ErrorSnackbar("Save current changes before adding a new decision.");
  }

  function HandleAttemptDelete(decisionId: string) {
    if (useEditState === State.start)
    {
      setIsDeleteOpen(true);
      setCloneForEditing(MakeClone(selectedInitiative.decisions.find(d => d.id === decisionId)));
      setUseEditState(State.delete);
    }
    else
      ErrorSnackbar("Cannot delete with unsaved changes.");
  }

  async function DeleteDecision(decisionId: string) {
    const selectedInitiativeClone: Initiative = MakeClone(selectedInitiative);
    selectedInitiativeClone.decisions = selectedInitiativeClone.decisions.filter(d => d.id !== decisionId);

    setIsLoading(true);
    setIsDeleteOpen(false);
    try
    {
      await dispatch(deleteDecisionData({ companyId: selectedCompany.id, initiativeId: selectedInitiative.id, decisionIds: [decisionId] })).unwrap();

      setSelectedInitiative(selectedInitiativeClone);
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoading(false);
    LeaveEditMode();
  }

  function CancelDelete() {
    setIsDeleteOpen(false);
    LeaveEditMode();
  }

  const canAddTag = newTag !== "" && !cloneForEditing?.tags.find(tag => tag === newTag);

  function HandleChangeNewTag(event: any, tag: string | null) {
    if (tag !== null)
      setNewTag(tag);
  }

  function HandleAddTag() {
    if (cloneForEditing)
    {
      const tags = MakeClone(cloneForEditing.tags);
      if (canAddTag)
      {
        tags.push(newTag);
        setCloneForEditing({ ...cloneForEditing, tags });
      }
    }
  }

  function HandleRemoveTag(tagToRemove: string) {
    if (cloneForEditing)
    {
      const tags = MakeClone(cloneForEditing.tags);
      const index = tags.findIndex(tag => tag === tagToRemove);
      if (index >= 0)
      {
        tags.splice(index, 1);
        setCloneForEditing({ ...cloneForEditing, tags });
      }
    }
  }

  const canAddParticipant = !!newUnmappedParticipant;

  function HandleChangeNewUnmappedParticipant(event: any, val: string | null) {
    if (val !== null)
      setNewUnmappedParticipant(val);
  }

  function HandleChangeNewMappedParticipant(value: {id: string, name: string}){
    setNewMappedParticipant(value);
  }

  function HandleAddParticipant() {
    if (cloneForEditing)
    {
      const participants = MakeClone(cloneForEditing.participants);
      if (canAddParticipant)
      {
        if(newMappedParticipant && newMappedParticipant.name === newUnmappedParticipant)
          participants.mappables.push({id: newMappedParticipant.id});
        else
          participants.unmappables.push({ name: newUnmappedParticipant });
        setCloneForEditing({ ...cloneForEditing, participants });
      }
    }
  }

  function HandleRemoveMappedParticipant(participantToRemove: MappableDecisionParticipant){
    if(cloneForEditing)
    {
      const participants = MakeClone(cloneForEditing.participants);
      const mappableParticipants = participants.mappables;
      const index = mappableParticipants.findIndex(participant => 
        participant.id === participantToRemove.id);
            
      if (index >= 0)
      {
        participants.mappables.splice(index, 1);
        setCloneForEditing({ ...cloneForEditing, participants });
      }
    }
  }

  function HandleRemoveUnmappedParticipant(participantToRemove: UnmappableDecisionParticipant) {
    if (cloneForEditing)
    {
      const participants = MakeClone(cloneForEditing.participants);
      const unmappableParticipants = participants.unmappables;
      const index = unmappableParticipants.findIndex(participant => 
        participant.name === participantToRemove.name);
            
      if (index >= 0)
      {
        participants.unmappables.splice(index, 1);
        setCloneForEditing({ ...cloneForEditing, participants });
      }
    }
  }

  return {
    cloneForEditing,
    setCloneForEditing,
    isLoading,
    isEditing,
    canAddTag,
    canAddParticipant,
    EnterEditMode,
    LeaveEditMode,
    DeleteDecision,
    CancelDelete,
    HandleStartEdit,
    HandleCancelEdit,
    HandleSaveEdit,
    HandleAddEmptyDecision,
    HandleAttemptDelete,
    HandleChangeNewTag,
    HandleAddTag,
    HandleRemoveTag,
    HandleChangeNewUnmappedParticipant,
    HandleChangeNewMappedParticipant,
    HandleAddParticipant,
    HandleRemoveUnmappedParticipant,
    HandleRemoveMappedParticipant
  }
}