import { Box, Grid, ListItemIcon, MenuItem, Stack, ThemeProvider, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IntegrityTheme } from "../Styles";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { selectCurrentUser, selectIsLoggedIn } from "../Store/UserSlice";
import { IntegrityId, selectAllCompanies } from "../Store/CompanySlice";
import { logOut } from "../Store/SessionSlice";
import { Fragment, useEffect, useMemo, useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { UserAvatar } from "../Components/User/UserAvatar";
import { GetInitiativesService } from "../Services/GetInitiativesService";
import { plural } from "pluralize";
import { useGetAvatar } from "../Hooks/useGetAvatar";
import { GoToInitiativeDashboard } from "../Pages/InitiativeDashboardPage";
import { GoToClientDashboard } from "../Pages/ClientDashboardPage";

export const AdminMenuIds = {
  menuButton: "AdminMenuButton",
  initiatives: "AdminMenuInitiatives",
  users: "AdminMenuUsers",
  developers: "AdminMenuDevelopers",
  clients: "AdminMenuClients"
}

export const AccountMenuIds = {
  menuButton: "AccountMenuButton",
  account: "AccountMenuAccount",
  logout: "AccountMenuLogout"
}

export function Header() {
  const logo = "https://static.wixstatic.com/media/4f8b60_2899998071014662a534db34be03a3d1~mv2.png/v1/fill/w_438,h_118,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Integrity-Logo_2x%20(3)_edited.png";
  const imageLink = "https://www.integrityinspired.com/";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const currentUser = useAppSelector(selectCurrentUser);
  const allCompanies = useAppSelector(selectAllCompanies);
  const [showInitiativesTooltip, setShowInitiativesTooltip] = useState(false);
  const [showAdminTooltip, setShowAdminTooltip] = useState(false);
  const [showAccountTooltip, setShowAccountTooltip] = useState(false);
  const isUserAdmin = currentUser?.isAdmin && currentUser?.companyId !== IntegrityId;
  const isIntegrity = currentUser?.companyId === IntegrityId;
  const isIntegrityAdmin = (currentUser?.isAdmin && currentUser.companyId === IntegrityId) ?? false;
  const showInactive = false;


  const displayInitiatives = useMemo(() => {
    if (currentUser)
      return (GetInitiativesService(currentUser, allCompanies, isIntegrityAdmin, showInactive));
    return [];
  }, [isIntegrityAdmin, currentUser, allCompanies, showInactive]);

  const totalInitiativesDisplayed = useMemo(() => {
    let total = 0;
    for (const displayItem of displayInitiatives)
      total += displayItem.initiatives.length;
    return total;
  }, [displayInitiatives]);

  const currentUserCompany = useMemo(() => {
    return allCompanies.find(c => c.id === currentUser?.companyId);
  }, [allCompanies, currentUser?.companyId]);

  const userTerm = plural(currentUserCompany?.terms.userTerm || "User");

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  function SelectInitiative(initiativeId: string) {
    const companyWithThisInit = allCompanies.find(c => c.initiatives.find(i => i.id === initiativeId));
    if (currentUser && companyWithThisInit)
    {
      setShowInitiativesTooltip(false);
      GoToInitiativeDashboard(navigate, currentUser.id, initiativeId);
      if (InUrl('/InitiativeDashboard'))
        window.location.reload();
    }
  }

  function SelectCompany(companyId: string) {
    const matchingCompany = allCompanies.find(c => c.id === companyId);
    if (currentUser && matchingCompany)
    {
      setShowInitiativesTooltip(false);
      GoToClientDashboard(navigate, currentUser.id, companyId);
      if (InUrl('/ClientDashboard'))
        window.location.reload();
    }
  }

  async function LogOut() {
    await dispatch(logOut({}));
    navigate('/Login');
  }

  function InUrl(value: string) {
    return location.pathname.includes(value);
  }

  const { avatar: currentAvatar, FetchAvatar } = useGetAvatar();
  useEffect(() => {
    FetchAvatar(currentUser);
  }, [currentUser, FetchAvatar])

  /*function GetMostRecentlyViewedInitiative() {
    if (InUrl("InitiativeDetail"))
    {
      const urlId = searchParams.get("initiative");
      return allCompanies.flatMap(c => c.initiatives).find(i => i.id === urlId);
    }
    else
    {
      const { selectedInitiative } = GetMatchingInitiativeAndCompany(currentUser?.id ?? "", allCompanies);
      return selectedInitiative;
    }
  }*/

  return (
    <ThemeProvider theme={IntegrityTheme}>
      <Box sx={{ backgroundColor: "white", paddingY: 2.75, outlineColor: "black" }}>
        <Grid container sx={{ display: "flex", placeItems: "center", flexDirection: "row", justifyContent: "space-between", paddingX: 2 }}>
          <Grid item>
            <Link to={imageLink}>
              <img className="w-[219px]" src={logo} alt='Integrity Inspired Solutions Logo' />
            </Link>
          </Grid>
          {/* <Grid item>
            {!InUrl('Initiatives') && !InUrl('Login') && !InUrl('ResetPassword') && !InUrl('UserAccount') && !InUrl('ClientAccount') && isIntegrityAdmin &&
              <Typography
                onClick={() => {
                  const selectedInitiative = GetMostRecentlyViewedInitiative();
                  navigate(`./Initiatives${selectedInitiative ? `?initiativeName=${selectedInitiative.title}` : ""}`)
                }}
                sx={{ cursor: "pointer", color: "blue", fontSize: 22, justifyContent: "center" }}>
                <ArrowBackIcon className="mr-2" />Return to Initiatives
              </Typography>
            }
          </Grid> */}
          <Grid item>
            {isLoggedIn && !InUrl('ResetPassword') && currentUser &&
              <div className="flex space-x-4 place-items-center">
                <Typography
                  onClick={() => { navigate("./ClientDashboard") }}
                  sx={{ cursor: "pointer" }}>Home</Typography>
                {displayInitiatives.length > 0 && totalInitiativesDisplayed > 0 &&
                  <LightTooltip
                    open={showInitiativesTooltip}
                    onOpen={() => { setShowInitiativesTooltip(true); setShowAccountTooltip(false); }}
                    onClose={() => setShowInitiativesTooltip(false)}
                    title={displayInitiatives.map((i, index) => {
                      const showCompanyName = isIntegrity;
                      return (
                        i.initiatives.length ?
                          <Stack key={index} sx={{ marginTop: (index !== 0) ? 2 : 0 }}>
                            {showCompanyName &&
                              <MenuItem sx={{ fontWeight: "bold" }} onClick={() => SelectCompany(i.companyId)}>{i.companyName}</MenuItem>
                            }
                            {i.initiatives.map((init, key) => {
                              return (
                                <MenuItem key={key} sx={{ marginLeft: showCompanyName ? 2 : 0, lineHeight: 1, whiteSpace: "normal" }} data-cy={AdminMenuIds.initiatives} onClick={() => SelectInitiative(init.id)} >
                                  {init.title}
                                </MenuItem>
                              )
                            })}
                          </Stack>
                          :
                          <Fragment key={index} />
                      )
                    })}
                  >
                    <Typography sx={{ cursor: "pointer" }}>
                      Dashboard{displayInitiatives.length > 1 ? "s" : ""}
                    </Typography>
                  </LightTooltip>
                }
                {isUserAdmin &&
                  <button data-cy={AdminMenuIds.users} onClick={() => { navigate("./Users"); setShowAdminTooltip(false); }}>{userTerm}</button>
                }
                {isIntegrityAdmin &&
                  <LightTooltip data-cy={AdminMenuIds.menuButton}
                    open={showAdminTooltip}
                    onOpen={() => { setShowAdminTooltip(true); setShowAccountTooltip(false); }}
                    onClose={() => setShowAdminTooltip(false)}
                    title={<>
                      {/* <MenuItem data-cy={AdminMenuIds.initiatives} onClick={() => { navigate("./Initiatives"); setShowAdminTooltip(false); }}>{initiativeTerm}</MenuItem> */}
                      <MenuItem data-cy={AdminMenuIds.users} onClick={() => { navigate("./Users"); setShowAdminTooltip(false); }}>{userTerm}</MenuItem>
                      <MenuItem data-cy={AdminMenuIds.developers} onClick={() => { navigate("./Integrity"); setShowAdminTooltip(false); }}>Developers</MenuItem>
                      <MenuItem data-cy={AdminMenuIds.clients} onClick={() => { navigate("./ClientPage"); setShowAdminTooltip(false); }}>Clients</MenuItem>
                    </>}
                  >
                    <Typography sx={{ cursor: "pointer" }}>
                      Admin
                    </Typography>
                  </LightTooltip>
                }
                <LightTooltip data-cy={AccountMenuIds.menuButton}
                  open={showAccountTooltip}
                  onOpen={() => { setShowAccountTooltip(true); setShowAdminTooltip(false); }}
                  onClose={() => setShowAccountTooltip(false)}
                  title={
                    <>
                      <MenuItem data-cy={AccountMenuIds.account} onClick={() => { navigate("./UserAccount?user=" + currentUser.id); setShowAccountTooltip(false); }}>
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        My Account
                      </MenuItem>
                      <MenuItem data-cy={AccountMenuIds.logout} onClick={() => { LogOut(); setShowAccountTooltip(false); }}>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        Log Out
                      </MenuItem>
                    </>
                  }>
                  <Box sx={{ cursor: "pointer" }}>
                    {currentUser &&
                      <UserAvatar user={currentAvatar ?? currentUser} />
                    }
                  </Box>
                </LightTooltip>
              </div>
            }
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}
