import axios from "axios";
import { LocalizeUrl } from "./Http";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SerializeAxiosError } from "./Error";

export interface RSSFeedItem {
  title: string
  link: string
  pubDate: string
  creator: string
  content: string
  contentSnippet: string
  guid: string
  isoDate: string
  enclosure: {
    length: string
    type: string
    url: string
  }
}

export interface RSSFeed {
  feedUrl: string
  title: string
  description: string
  link: string
  items: RSSFeedItem[]
}

interface GetRSSFeedRequest {
  url: string
}

interface GetRSSFeedResponse {
  feed: RSSFeed
  status: string
}

export async function GetRSSFeed(request: GetRSSFeedRequest): Promise<GetRSSFeedResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/GetRSSFeed");
    const response = await axios.post(baseUrl, request);
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export const getRSSFeed = createAsyncThunk(
  'RSSFeed/getRSSFeed',
  async (args: GetRSSFeedRequest) => {
    const response = await GetRSSFeed(args);
    return response.feed;
  }
)
