import { Box, Card, Collapse, Divider, IconButton, IconButtonProps, List, Stack, Typography, styled } from "@mui/material"
import { Fragment, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface DashboardSectionProps {
  items: JSX.Element | JSX.Element[] | undefined
  title?: string | JSX.Element
  button?: JSX.Element
  expandable?: boolean
  borderWidth?: number
  hideDividers?: boolean
  fullHeight?: boolean
  centerItems?: boolean
  color?: string
  textColor?: string
  tooltipMessage?: string
  tooltipSubtitle?: string | JSX.Element
}

export function DashboardSection(props: DashboardSectionProps) {

  const fullDivider = <Divider variant="fullWidth" sx={{ borderBottomWidth: 2 }} />
  const [expanded, setExpanded] = useState(true);
  const [fullHeight, setFullHeight] = useState(props.fullHeight !== undefined ? true : false);

  const handleExpandClick = () => {
    if (props.fullHeight === true) setFullHeight(!fullHeight);
    setExpanded(!expanded);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: '#f5f5f9',
      backgroundColor: '#21355B',
      maxWidth: 275,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    },
  }));

  return (
    <Card variant="outlined" sx={{ borderRadius: 2, borderWidth: props.borderWidth ?? 0, height: fullHeight ? "100%" : "auto", backgroundColor: props.color, color: props.textColor }}>
      <List dense={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1, marginX: 2 }}>
          {props.title &&
            <Typography variant="body1" sx={{ fontSize: 26 }}><b>{props.title}</b>
              {(props.tooltipMessage || props.tooltipSubtitle) &&
                <HtmlTooltip
                  title={<Fragment>
                    <Typography>{props.tooltipMessage}</Typography>
                    {props.tooltipSubtitle}
                  </Fragment>}
                >
                  <IconButton>
                    <HelpIcon sx={{ fontSize: 18, color: "blue" }}/>
                  </IconButton>
                </HtmlTooltip>
              }
            </Typography>
          }
          <Box>
            {props.button}
            {props.expandable &&
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          </Box>
        </Stack>
        <Collapse in={expanded} sx={{ display: "flex", height: "100%" }}>
          {Array.isArray(props.items) ?
            props.items.map((item, key) => {
              return (
                <Fragment key={key}>
                  {(props.title || key > 0) && !props.hideDividers &&
                    fullDivider
                  }
                  {item}
                </Fragment>
              )
            })
            :
            <Box sx={{ height: "100%" }}>
              {props.title && props.items && !props.hideDividers &&
                fullDivider
              }
              <Box sx={props.centerItems ? { height: "350px", display: "flex", justifyContent: "center", alignItems: "center" } : {}}>
                {props.items}
              </Box>
            </Box>
          }
        </Collapse>
      </List>
    </Card>
  )
}
