import { FailValidation, SuccessValidation, Validation } from "./Validation";

const MIN_PASSWORD_LENGTH = 8;

const PasswordValidationReasonPrefix = "Password must";

const PasswordValidationSubReasons = {
  tooShort: `be at least ${MIN_PASSWORD_LENGTH} characters long`,
  needsUppercase: "contain at least one uppercase letter",
  needsLowercase: "contain at least one lowercase letter",
  needsNumber: "contain at least one number"
}

export const PasswordValidationReasons = {
  tooShort: `${PasswordValidationReasonPrefix} ${PasswordValidationSubReasons.tooShort}.`,
  needsUppercase: `${PasswordValidationReasonPrefix} ${PasswordValidationSubReasons.needsUppercase}.`,
  needsLowercase: `${PasswordValidationReasonPrefix} ${PasswordValidationSubReasons.needsLowercase}.`,
  needsNumber: `${PasswordValidationReasonPrefix} ${PasswordValidationSubReasons.needsNumber}.`
}

function AnyCharsInRange(word: string, minChar: string, maxChar: string) {
  const minCode = minChar.charCodeAt(0);
  const maxCode = maxChar.charCodeAt(0);
  for(let i = 0; i < word.length; i++)
  {
    const currentCode = word.charCodeAt(i);
    if(currentCode <= maxCode && currentCode >= minCode)
      return true;
  }

  return false;
}

export function ValidatePasswordHasLength(password: string): Validation{
  return { success: password.length >= MIN_PASSWORD_LENGTH, message: PasswordValidationSubReasons.tooShort };
}

export function ValidatePasswordHasUppercase(password: string): Validation{
  return { success: AnyCharsInRange(password,"A","Z"), message: PasswordValidationSubReasons.needsUppercase };
}

export function ValidatePasswordHasLowercase(password: string): Validation{
  return { success: AnyCharsInRange(password,"a","z"), message: PasswordValidationSubReasons.needsLowercase };
}

export function ValidatePasswordHasNumber(password: string): Validation{
  return { success: AnyCharsInRange(password,"0","9"), message: PasswordValidationSubReasons.needsNumber };
}

export function ValidatePassword(password: string): Validation {
  if(!ValidatePasswordHasLength(password).success)
    return FailValidation(PasswordValidationReasons.tooShort);
  if(!ValidatePasswordHasUppercase(password).success)
    return FailValidation(PasswordValidationReasons.needsUppercase);
  if(!ValidatePasswordHasLowercase(password).success)
    return FailValidation(PasswordValidationReasons.needsLowercase);
  if(!ValidatePasswordHasNumber(password).success)
    return FailValidation(PasswordValidationReasons.needsNumber);
  
  return SuccessValidation("Password passes validation.");
}

export function GetPasswordValidationFailureString(password: string){
  const validations = [
    ValidatePasswordHasLength(password),
    ValidatePasswordHasUppercase(password),
    ValidatePasswordHasLowercase(password),
    ValidatePasswordHasNumber(password)
  ];

  const failedValidations = validations.filter(v => !v.success);
  if(failedValidations.length === 0)
    return "";

  const validationReasons = validations.filter(v => !v.success).map(v => v.message);
  const reasonCount = validationReasons.length;
  if (reasonCount > 1)
  {
    const lastReason = validationReasons[reasonCount - 1];
    validationReasons[reasonCount - 1] = "and " + lastReason;
  }

  let joinedValidationReasons = "";
  if (reasonCount > 2)
    joinedValidationReasons = validationReasons.join(", ");
  else
    joinedValidationReasons = validationReasons.join(" ");

  return `${PasswordValidationReasonPrefix} ${joinedValidationReasons}.`;
}
