import { AxiosError } from "axios";
import { ValidationFailedPrefix } from "./Validation/Validation";


export function SerializeAxiosError(error: unknown) {
  let axiosError = error as AxiosError;
  if (axiosError.response && axiosError.response.data !== null && typeof axiosError.response.data === "object" && "message" in axiosError.response.data)
  {
    return {
      name: axiosError.name,
      message: axiosError.response.data.message,
      code: axiosError.response?.status.toString(),
      stack: axiosError.stack
    }
  }
  return error;
}

export function GetClientFriendlyMessage(error: unknown) {
  let message = "Something went wrong. Please try again later.";
  if (typeof error === "string")
    message = error;
  else if(typeof error === "object" && error !== null && "code" in error && "message" in error)
  {
    switch (error.code)
    {
      case "401":
        message = "Session has expired. Please log in again.";
        break;
      case "422":
        message = ValidationFailedPrefix + error.message;
        break;
    }
  }
  return message;
}