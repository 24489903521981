import { ThemeProvider } from "@emotion/react";
import { TableHeaderStyle, blueTheme, defaultRowStyle } from "../../../Styles";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Company, Workflow, WorkflowColumn, upsertClientWorkflow } from "../../../Store/CompanySlice";
import { AddButton } from "../../AddButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, { useMemo, useState } from "react";
import { MakeClone } from "../../../Services/Cloning";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useEffectOnce } from "../../../Hooks/useEffectOnce";
import { ClientWorkflowUpsertModal } from "./ClientWorkflowUpsertModal";
import { useAppDispatch } from "../../../Hooks/Hooks";
import { UpsertClientWorkflowRequest } from "../../../Services/CompanyService";
import { ErrorSnackbar, SuccessSnackbar } from "../../../Services/Snackbars";
import { ValidateWorkflow } from "../../../Services/Validation/WorkflowValidation";
import { ValidationFailedPrefix } from "../../../Services/Validation/Validation";
import { DeleteAlert } from "../../DeleteAlert";

export const WorkflowManagementSectionIds = {
  table: "WorkflowManagementSectionTable",
  title: "WorkflowManagementSectionTitle",
  startCheckboxChecked: "WorkflowManagementSectionStartCheckboxChecked",
  startCheckboxUnchecked: "WorkflowManagementSectionStartCheckboxUnchecked",
  endCheckboxChecked: "WorkflowManagementSectionEndCheckboxChecked",
  endCheckboxUnchecked: "WorkflowManagementSectionEndCheckboxUnchecked",
  addButton: "WorkflowManagementSectionAddButton",
  editButton: "WorkflowManagementSectionEditButton",
  deleteButton: "WorkflowManagementSectionDeleteButton",
  deleteModal: {
    modal: "WorkflowManagementSectionDeleteModal",
    confirmButton: "WorkflowManagementSectionDeleteConfirmButton",
    cancelButton: "WorkflowManagementSectionDeleteCancelButton"
  }
}

interface WorkflowManagementSectionProps {
  workflow: Workflow
  UpdateWorkflow: (workflow: Workflow) => void
  company: Company
}

export function ClientWorkflowManagementSection(props: WorkflowManagementSectionProps) {
  const dispatch = useAppDispatch();
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>(props.workflow);
  const [columnToEdit, setColumnToEdit] = useState<WorkflowColumn>();
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
  const [isUpserting, setIsUpserting] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const dragItem = React.useRef<number | null>(null);
  const dragOverItem = React.useRef<number | null>(null);

  useEffectOnce(() => {
    const currentWorkflowClone = MakeClone(currentWorkflow);
    currentWorkflowClone.columns.sort((a, b) => a.ordering - b.ordering);
    UpdateWorkflow(currentWorkflowClone);
  })

  function UpdateWorkflow(currentWorkflowClone: Workflow) {
    setCurrentWorkflow(currentWorkflowClone);
    props.UpdateWorkflow(currentWorkflowClone);
  }

  const handleSort = () => {
    if (dragItem.current === null || dragOverItem.current === null || dragItem.current === dragOverItem.current)
      return;

    const currentWorkflowClone = MakeClone(currentWorkflow);

    const draggedItem = currentWorkflowClone.columns.splice(dragItem.current, 1)[0];
    draggedItem.ordering = dragItem.current;
    currentWorkflowClone.columns.splice(dragOverItem.current, 0, draggedItem);
    currentWorkflowClone.columns.forEach(column => {
      column.ordering = currentWorkflowClone.columns.findIndex(w => w.id === column.id) + 1;
    });
    SaveWorkflow(currentWorkflowClone);
    dragItem.current = null;
    dragOverItem.current = null;
  }

  function HandleSelectStart(id: string) {
    const currentWorkflowClone = MakeClone(currentWorkflow);
    if (currentWorkflowClone.endDateColumnId !== id)
      currentWorkflowClone.startDateColumnId = id;
    SaveWorkflow(currentWorkflowClone);
  }

  function HandleSelectEnd(id: string) {
    const currentWorkflowClone = MakeClone(currentWorkflow);
    if (currentWorkflowClone.startDateColumnId !== id)
      currentWorkflowClone.endDateColumnId = id;
    SaveWorkflow(currentWorkflowClone);
  }

  function EnterEditMode(column: WorkflowColumn) {
    setColumnToEdit(column);
    setIsWorkflowModalOpen(true);
  }

  function EnterAddMode() {
    LeaveEditMode();
    setIsWorkflowModalOpen(true);
  }

  function HandleCancelEdit() {
    LeaveEditMode();
  }

  function LeaveEditMode() {
    setColumnToEdit(undefined);
    setIsWorkflowModalOpen(false);
  }

  async function HandleSaveEditColumn(editedColumn: WorkflowColumn) {
    const currentWorkflowClone = MakeClone(currentWorkflow);
    const order = currentWorkflowClone.columns.flatMap(c => c.ordering);
    const maxOrderNumber = Math.max(...order);
    const matchingIndex = currentWorkflowClone.columns.findIndex(c => c.id === editedColumn.id);
    if (matchingIndex > -1) 
    {
      currentWorkflowClone.columns[matchingIndex] = editedColumn;
    }
    else 
    {
      editedColumn.ordering = maxOrderNumber + 1;
      currentWorkflowClone.columns.push(editedColumn);
    }
    SaveWorkflow(currentWorkflowClone);
  }

  async function SaveWorkflow(workflow: Workflow) {
    if (!isUpserting)
    {
      setIsUpserting(true);
      const validation = ValidateWorkflow(workflow);
      if (!validation.success)
      {
        ErrorSnackbar(ValidationFailedPrefix + validation.message);
        setIsUpserting(false);
        return;
      }

      const workflowRequest: UpsertClientWorkflowRequest = {
        companyId: props.company.id,
        workflow: workflow
      }

      try
      {
        await dispatch(upsertClientWorkflow(workflowRequest)).unwrap();
        SuccessSnackbar("Workflow updated successfully.");
        LeaveEditMode();
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      UpdateWorkflow(workflow);
      setIsUpserting(false);
    }
  }

  function EnterDeleteMode(column: WorkflowColumn) {
    setIsDeleteOpen(true);
    setColumnToEdit(column);
  }

  async function HandleDeleteColumn(columnToDeleteId: string) {
    const currentWorkflowClone = MakeClone(currentWorkflow);
    const matchingIndex = currentWorkflowClone.columns.findIndex(c => c.id === columnToDeleteId);
    if (matchingIndex > -1) 
    {
      currentWorkflowClone.columns.splice(matchingIndex, 1);
    }
    SaveWorkflow(currentWorkflowClone); 
    LeaveDeleteMode();    
  }

  function HandleCancelDelete() {
    LeaveDeleteMode();
  }

  function LeaveDeleteMode() {
    setIsDeleteOpen(false);
    setColumnToEdit(undefined);
  }

  const { startColumn, endColumn } = useMemo(() => {
    return {
      startColumn: currentWorkflow.columns.find(c => c.id === currentWorkflow.startDateColumnId),
      endColumn: currentWorkflow.columns.find(c => c.id === currentWorkflow.endDateColumnId)
    };
  }, [currentWorkflow]);

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4">
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: "bold", marginY: 1 }}>Workflow Columns</Typography>
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            <AddButton cypressData={WorkflowManagementSectionIds.addButton} HandleClick={() => { EnterAddMode() }} />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="my-2">
          <Table className="w-full outline outline-3 bg-gray-100" size="small">
            <TableHead className="outline outline-1">
              <TableRow sx={{
                borderBottom: "1px solid black",
                "& th": {
                  fontWeight: "bold",
                  fontFamily: "Arial, Helvetica"
                }
              }}>
                <TableHeaderStyle></TableHeaderStyle>
                <TableHeaderStyle>Title</TableHeaderStyle>
                <TableHeaderStyle>Start Date</TableHeaderStyle>
                <TableHeaderStyle>End Date</TableHeaderStyle>
                <TableHeaderStyle>Actions</TableHeaderStyle>
              </TableRow>
            </TableHead>
            <TableBody data-cy={WorkflowManagementSectionIds.table}>
              {currentWorkflow.columns.map((column, index) => {
                const isDefaultStart = currentWorkflow.startDateColumnId === column.id;
                const isDefaultEnd = currentWorkflow.endDateColumnId === column.id;
                const beforeStart = column.ordering < (startColumn?.ordering ?? 0);
                const afterEnd = column.ordering > (endColumn?.ordering ?? (currentWorkflow.columns.length - 1));
                return (
                  <TableRow draggable key={index}
                    onDragStart={() => dragItem.current = index}
                    onDrop={handleSort}
                    onDragEnter={() => dragOverItem.current = index} className={defaultRowStyle}
                    onDragOver={(e) => e.preventDefault()}
                    sx={{
                      borderBottom: "1px solid black",
                      "& td": {
                        fontFamily: "Arial, Helvetica",
                        color: "#21345b"
                      }
                    }}
                  >
                    <>
                      <TableCell><DragIndicatorIcon sx={{ cursor: "grab", color: "lightgrey" }} /></TableCell>
                      <TableCell>
                        <Typography variant="caption" data-cy={WorkflowManagementSectionIds.title}>{column.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton data-cy={isDefaultStart ? WorkflowManagementSectionIds.startCheckboxChecked : WorkflowManagementSectionIds.startCheckboxUnchecked} disabled={isDefaultStart || isDefaultEnd || afterEnd} title={"Mark as start date"} onClick={() => HandleSelectStart(isDefaultStart ? "" : column.id)}>
                          {isDefaultStart ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton data-cy={isDefaultEnd ? WorkflowManagementSectionIds.endCheckboxChecked : WorkflowManagementSectionIds.endCheckboxUnchecked} disabled={isDefaultEnd || isDefaultStart || beforeStart} title={"Mark as end date"} onClick={() => HandleSelectEnd(isDefaultEnd ? "" : column.id)}>
                          {isDefaultEnd ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton data-cy={WorkflowManagementSectionIds.editButton} onClick={() => EnterEditMode(column)} color="primary">
                          <EditIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton data-cy={WorkflowManagementSectionIds.deleteButton} onClick={() => EnterDeleteMode(column)} color="primary">
                          <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </TableCell>
                    </>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <ClientWorkflowUpsertModal columnToEdit={columnToEdit} isOpen={isWorkflowModalOpen} Save={HandleSaveEditColumn} Cancel={HandleCancelEdit} handleClose={() => setIsWorkflowModalOpen(false)} isUpserting={isUpserting} />
        <DeleteAlert isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} Delete={HandleDeleteColumn} CancelDelete={HandleCancelDelete} id={columnToEdit?.id}
          title={`Delete this ${props.company.terms.throughputTerm.toLowerCase()} column?`} description={`Once removed, a column cannot be recovered.`}
          cypressData={WorkflowManagementSectionIds.deleteModal}
        />
      </div>
    </ThemeProvider>
  )
}