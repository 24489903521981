import { Breakpoint, Container, Dialog, DialogTitle, Grid, IconButton, Paper, SxProps, ThemeProvider, Typography } from "@mui/material";
import { ReactNode } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { IntegrityTheme } from "../Styles";
import LoadingButton from '@mui/lab/LoadingButton';


interface BaseModalProps {
  children: ReactNode
  open: boolean
  onClose: () => void
  cypressData: {
    modal: string
    closeModalButton: string
  }
  title?: string
  subtitle?: string
  maxWidth?: false | Breakpoint | undefined
  height?: string | number
  saveButton?: {
    cypressData: string
    saveFunction: () => void
    position: "top" | "bottom"
    spinner?: boolean
  }
}

interface SaveButtonProps {
  sx: SxProps
}

const stopPropagation = (event: any) => {
  event.stopPropagation();
};

export function BaseModal(props: BaseModalProps) {
  function SaveButton(saveButtonProps: SaveButtonProps) {
    return (
      <LoadingButton loading={props.saveButton?.spinner} sx={saveButtonProps.sx} variant="contained" data-cy={props.saveButton?.cypressData} onClick={() => props.saveButton?.saveFunction()}>
        <span>Save</span>
      </LoadingButton>
    )
  }
  return (
    <ThemeProvider theme={IntegrityTheme}>
      <Container>
        <Dialog
          onKeyDown={stopPropagation}
          open={props.open}
          disableScrollLock
          fullWidth
          maxWidth={props.maxWidth}
          data-cy={props.cypressData.modal}
          PaperProps={{
            sx: {
              height: 'auto'
            }
          }}
        >
          <Paper sx={{ backgroundColor: "#2E4A7F", color: "white" }} elevation={5}>
            <DialogTitle sx={{ width: "100%" }}>
              <Grid container display="flex" columns={12} alignItems="center" justifyContent="space-between" wrap="nowrap">
                <Grid item xs={3}>
                  {/* nothing; used to center the title */}
                </Grid>
                <Grid item xs={9}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Typography textAlign="center" variant="h5">{props.title}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography textAlign="center" variant="body1">{props.subtitle}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} zeroMinWidth>
                  {props.saveButton && props.saveButton.position === "top" &&
                    <SaveButton sx={{
                      position: "absolute",
                      right: 8,
                      top: 8
                    }} />
                  }
                </Grid>
              </Grid>
              <IconButton data-cy={props.cypressData.closeModalButton} onClick={() => props.onClose()}
                sx={{
                  position: "absolute",
                  left: 8,
                  top: 8,
                  color: "white"
                }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </Paper>
          <Container className="m-4" maxWidth={props.maxWidth}>
            {props.children}
          </Container>
          {props.saveButton && props.saveButton.position === "bottom" &&
            <SaveButton sx={{
              position: "absolute",
              right: 8,
              bottom: 8
            }} />
          }
        </Dialog>
      </Container>
    </ThemeProvider>
  )
}
