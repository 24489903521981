import { Box, Stack, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { metricsFontLarge } from '../../../Styles';
import { plural } from 'pluralize';
ChartJS.register(ArcElement, Tooltip, Legend);

interface ItemCompletionChartProps {
  itemsRemaining: number
  totalItems: number
  itemTerm?: string
  useMinWidth?: boolean
}

export function ItemCompletionChart(props: ItemCompletionChartProps) {
  const fractionRemaining = Math.min((props.itemsRemaining / props.totalItems), 1);
  const percentRemaining = Number.isNaN(fractionRemaining) ? 0 : Math.round(fractionRemaining * 100);
  const percentComplete = 100 - percentRemaining;

  const data = {
    labels: [`${percentComplete}% Completed`, `${percentRemaining}% Remaining`],
    datasets: [
      {
        label: `  Number of ${plural(props.itemTerm?.toLowerCase() ?? "item")}`,
        data: [percentComplete, percentRemaining],
        backgroundColor: [
          'rgba(54, 235, 100, 0.2)',
          'rgba(255, 99, 132, 0.2)'
        ],
        borderColor: [
          'rgba(54, 235, 100, 1)',
          'rgba(255, 99, 132, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Stack sx={{ justifyContent: "center", alignItems: "center", paddingY: 3 }}>
      <Typography sx={metricsFontLarge}>Percent Completed</Typography>
      <Box sx={props.useMinWidth ? { minWidth: "50%" } : { width: "50%" }}>
        <Pie
          data={data}
          options={{
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';
                    if (context.dataIndex === 0)
                      label = `${label}: ${props.totalItems - props.itemsRemaining}`;
                    else if (context.dataIndex === 1)
                      label = `${label}: ${(props.itemsRemaining)}`;
                    return label;
                  }
                }
              }
            }
          }}
        />
      </Box>
    </Stack>
  )
}
