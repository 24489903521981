import axios from "axios";
import { BlobClient, BlobItem, BlobServiceClient, ContainerClient, FilterBlobItem } from "@azure/storage-blob";
import { DocumentInfo } from "../Store/DocumentSlice";
import { LocalizeUrl } from "./Http";
import { v4 } from "uuid";
import { SerializeAxiosError } from "./Error";

export interface GenerateSASTokenRequest {
  read?: boolean
  write?: boolean
  sessionId: string
}

interface GenerateSASTokenResponse {
  sasToken: string
}

export async function GenerateSASToken(request: GenerateSASTokenRequest): Promise<GenerateSASTokenResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/GenerateSASToken");
    const response = await axios.post(baseUrl, request);
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

const NO_ARTICLE_ID = ""; //Probably a better way to handle this, but we need a way to stop the document modal from retrieving docs that are tied
//to articles; just omitting the article id pulls docs regardless of whether they have the id or not.
const NO_INITIATIVE_ID = "";
export interface GetDocumentInfosByTagsRequest {
  companyId: string
  initiativeId?: string
  articleId?: string
}

export interface GetDocumentInfosByTagsResponse {
  documents: DocumentInfo[]
}

export async function GetDocumentInfosByTags(request: GetDocumentInfosByTagsRequest, containerName: string, sessionId: string): Promise<GetDocumentInfosByTagsResponse> {
  const docs: DocumentInfo[] = [];

  const tokenResponse = await GenerateSASToken({ read: true, write: false, sessionId });
  const sasToken = tokenResponse.sasToken;

  const uploadUrl = `https://iisclientstorage.blob.core.windows.net/${sasToken}`;

  const blobService = new BlobServiceClient(uploadUrl);
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);

  let query = `companyId='${request.companyId}'`;
  query += ` AND initiativeId='${request.initiativeId ?? NO_INITIATIVE_ID}'`;
  query += ` AND articleId='${request.articleId ?? NO_ARTICLE_ID}'`;

  for await (const blob of containerClient.findBlobsByTags(query))
  {
    const docItem = await BlobToDocInfo(blob, containerClient);
    if (docItem)
      docs.push(docItem);
  }
  return { documents: docs };
}

export interface GetAllDocumentInfosRequest {

}

interface GetAllDocumentInfosResponse {
  documents: DocumentInfo[]
}

export async function GetAllDocumentInfos(request: GetAllDocumentInfosRequest, containerName: string, sessionId: string): Promise<GetAllDocumentInfosResponse> {
  const docs: DocumentInfo[] = [];

  const tokenResponse = await GenerateSASToken({ read: true, write: false, sessionId });
  const sasToken = tokenResponse.sasToken;

  const uploadUrl = `https://iisclientstorage.blob.core.windows.net/${sasToken}`;

  const blobService = new BlobServiceClient(uploadUrl);
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);

  for await (const blob of containerClient.listBlobsFlat())
  {
    const docItem = await BlobToDocInfo(blob, containerClient);
    if (docItem)
      docs.push(docItem);
  }
  return { documents: docs };
}

async function BlobToDocInfo(blob: BlobItem | FilterBlobItem, containerClient: ContainerClient) {
  //const blobUrl = `https://iisclientstorage.blob.core.windows.net/${containerName}/${blob.name}${sasToken}`;
  const blobClient = containerClient.getBlobClient(blob.name);

  const properties = await GetBlobProperties(blobClient);
  if (properties)
  {
    const { metadata, createdOn, lastModified } = properties;
    const tags = await blobClient.getTags();

    const docItem: DocumentInfo = {
      tempUrl: blobClient.url,
      blobName: blob.name,
      createdOn: createdOn,
      lastModified: lastModified,
      metadata: {
        fileName: metadata.fileName,
        shortDescription: metadata.shortDescription,
        longDescription: metadata.longDescription,
        archived: metadata.archived
      },
      tags: {
        companyId: tags.tags.companyId,
        initiativeId: tags.tags.initiativeId,
        articleId: tags.tags.articleId
      }
    }
    return docItem;
  }
}

async function GetBlobProperties(blobClient: BlobClient): Promise<{ createdOn: number | undefined, lastModified: number | undefined, metadata: DocumentInfo["metadata"] } | undefined> {
  try
  {
    const properties = await blobClient.getProperties();
    const fileName = properties.metadata?.filename ?? "";
    const shortDescription = properties.metadata?.shortdescription ?? "";
    const longDescription = properties.metadata?.longdescription ?? "";
    const archived: boolean = JSON.parse(properties.metadata?.archived ?? JSON.stringify(false));
    const createdOn = properties.createdOn?.getTime();
    const lastModified = properties.lastModified?.getTime();

    return { createdOn, lastModified, metadata: { fileName, shortDescription, longDescription, archived } };
  }
  catch (e)
  {
    console.log((e as Error).message);
  }
}

export interface GetDocumentObjectUrlsRequest {
  documentInfos: DocumentInfo[]
}

export interface GetDocumentObjectUrlsResponse {
  docUrlPair: { documentInfo: DocumentInfo, objUrl: string }[]
}

export async function GetDocumentObjectUrls(request: GetDocumentObjectUrlsRequest): Promise<GetDocumentObjectUrlsResponse> {
  let docUrlPair = []

  for (const doc of request.documentInfos)
  {
    const blobClient = new BlobClient(doc.tempUrl);
    const downloadBlockBlobResponse = await blobClient.download();
    const downloadedBlob = await downloadBlockBlobResponse.blobBody;
    if (downloadedBlob)
    {
      const objUrl = window.URL.createObjectURL(
        downloadedBlob
      );
      docUrlPair.push({ documentInfo: doc, objUrl })
    }
  }

  return { docUrlPair };
}

export interface DownloadDocumentRequest {
  fileName: string
  objectUrl: string
}

export function DownloadDocument(request: DownloadDocumentRequest) {
  const link = document.createElement('a');
  link.href = request.objectUrl;
  link.setAttribute(
    'download',
    request.fileName,
  );

  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export interface UploadDocumentRequest {
  file: File
  shortDescription?: string
  longDescription?: string
  companyId?: string
  initiativeId?: string
  articleId?: string
}

interface UploadDocumentResponse {
  blobName: string
}

export async function UploadDocument(request: UploadDocumentRequest, containerName: string, sessionId: string): Promise<UploadDocumentResponse> {
  const tokenResponse = await GenerateSASToken({ read: false, write: true, sessionId });
  const sasToken = tokenResponse.sasToken;

  const uploadUrl = `https://iisclientstorage.blob.core.windows.net/${sasToken}`;

  const documentId = v4();
  const file = request.file;
  const fullFileName = documentId + "." + file.name.split(".").at(-1);

  try
  {
    const blobService = new BlobServiceClient(uploadUrl);
    const containerClient: ContainerClient = blobService.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(fullFileName);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    await blobClient.uploadData(file, options);

    if (request.companyId)
    {
      let tags: any = { companyId: request.companyId };
      tags = { ...tags, initiativeId: request.initiativeId ?? NO_INITIATIVE_ID };
      tags = { ...tags, articleId: request.articleId ?? NO_ARTICLE_ID };

      await blobClient.setTags(tags);
    }

    await blobClient.setMetadata({ fileName: file.name, shortDescription: request.shortDescription ?? "", longDescription: request.longDescription ?? "" });
  }
  catch (e)
  {
    console.log((e as Error).message);
  }

  return { blobName: fullFileName }
}

export interface UpdateDocumentRequest {
  blobName: string
  fileName?: string
  shortDescription?: string
  longDescription?: string
  archived?: boolean
}

interface UpdateDocumentResponse {
}

export async function UpdateDocument(request: UpdateDocumentRequest, containerName: string, sessionId: string): Promise<UpdateDocumentResponse> {
  const tokenResponse = await GenerateSASToken({ read: true, write: true, sessionId });
  const sasToken = tokenResponse.sasToken;

  const uploadUrl = `https://iisclientstorage.blob.core.windows.net/${sasToken}`;

  try
  {
    const blobService = new BlobServiceClient(uploadUrl);
    const containerClient: ContainerClient = blobService.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(request.blobName);

    const properties = await GetBlobProperties(blobClient);
    if (properties)
    {
      const metadata = properties.metadata;
      await blobClient.setMetadata({
        fileName: request.fileName ?? metadata.fileName,
        shortDescription: request.shortDescription ?? metadata.shortDescription ?? "",
        longDescription: request.longDescription ?? metadata.longDescription ?? "",
        archived: JSON.stringify(request.archived ?? metadata.archived ?? false)
      });
    }
  }
  catch (e)
  {
    console.log((e as Error).message);
  }

  return {};
}

export interface DeleteDocumentRequest {
  blobName: string
}

interface DeleteDocumentResponse {
}

export async function DeleteDocument(request: DeleteDocumentRequest, containerName: string, sessionId: string): Promise<DeleteDocumentResponse> {

  const tokenResponse = await GenerateSASToken({ read: true, write: true, sessionId });
  const sasToken = tokenResponse.sasToken;

  const uploadUrl = `https://iisclientstorage.blob.core.windows.net/${sasToken}`;

  try
  {
    const blobService = new BlobServiceClient(uploadUrl);
    const containerClient: ContainerClient = blobService.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(request.blobName);

    await blobClient.delete({ deleteSnapshots: 'include' });
  }
  catch (e)
  {
    console.log((e as Error).message);
  }

  return {};
}

