import { BaseModal } from "../BaseModal";
import { ThroughputData } from "../../Services/CompanyService";
import { Grid, Stack, Button, Card, Divider, Typography, IconButton, Select, MenuItem } from "@mui/material";
import { labelStyle } from "../../Styles";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../Hooks/Hooks";
import { selectAllUsers } from "../../Store/UserSlice";
import { v4 } from "uuid";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import { Company, WorkflowColumn } from "../../Store/CompanySlice";
import { AccountDatePicker } from "../AccountDatePicker";
import { AccountField } from "../AccountField";
import { MakeClone } from "../../Services/Cloning";

export const EditThroughputIds = {
  modal: "editThroughputModal",
  closeModalButton: "editThroughputCloseModalButton",
  key: "editThroughputKey",
  title: "editThroughputTitle",
  readyDate: "editThroughputReadyDate",
  startDate: "editThroughputStartDate",
  endDate: "editThroughputEndDate",
  progressDate: "editThroughputProgressDate",
  category: "editThroughputCategory",
  initiative: "editThroughputInitiative",
  saveButton: "editThroughputSaveButton",
  cancelButton: "editThroughputCancelButton"
}

interface ThroughputModalProps {
  isOpen: boolean
  story: ThroughputData
  company: Company
  HandleCloseEdit: () => void
  SubmitUpdateThroughput: (companyId: string, dataList: ThroughputData[], emptyDataCheck: boolean) => Promise<boolean>
  UpdateSearchbar?: (query: string) => void
}

export function EditCompanyThroughputModal(props: ThroughputModalProps) {
  const [throughputClone, setThroughputClone] = useState<ThroughputData>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [columnWidth, setColumnWidth] = useState(12);
  const users = useAppSelector(selectAllUsers);
  const createdByUser = users.find(u => u.id === props.story.createdById);
  const modifiedByUser = users.find(m => m.id === props.story.modifiedById);

  useEffect(() => {
    setIsLoading(false);
    setThroughputClone(props.story);
  }, [props.story, props.isOpen]);

  function CancelEdit() {
    setThroughputClone(props.story);
    props.HandleCloseEdit();
  }

  async function SaveEdit() {
    if (throughputClone)
    {
      const editedStory: ThroughputData = {
        id: throughputClone.id,
        key: throughputClone.key ? throughputClone.key : v4(),
        categoryId: throughputClone.categoryId,
        initiativeId: throughputClone.initiativeId,
        title: throughputClone.title,
        progressDates: throughputClone.progressDates
      };

      setIsLoading(true);
      const success = await props.SubmitUpdateThroughput(props.company.id, [editedStory], false);
      if (success)
      {
        props.HandleCloseEdit();
        if(props.UpdateSearchbar)
          props.UpdateSearchbar(editedStory.key);
      }
      setIsLoading(false);
    }
  }

  function CollapseColumn(collapsed: boolean) {
    if (collapsed)
      setColumnWidth(12);
    else
      setColumnWidth(8);
    setIsCollapsed(!isCollapsed);
  }

  function UpdateProgressDates(dateString: string, columnId: string) {
    if (throughputClone?.progressDates)
    {
      const progressDates = MakeClone(throughputClone.progressDates);
      const matchingProgress = progressDates.find(p => p.workflowColumnId === columnId);
      if (matchingProgress)
        matchingProgress.enterColumnDate = dateString;
      else
        progressDates.push({ enterColumnDate: dateString, workflowColumnId: columnId });
      setThroughputClone({ ...throughputClone, progressDates });
    }
  }

  const workflowColumns: (WorkflowColumn & { enterDate: string })[] = useMemo(() => {
    const columns = props.company.workflow.columns.map(c => {
      return {
        ...c,
        title: c.title + (c.id === props.company.workflow.startDateColumnId ? " (Start Date)" : (c.id === props.company.workflow.endDateColumnId ? " (End Date)" : "")),
        enterDate: throughputClone?.progressDates.find(p => p.workflowColumnId === c.id)?.enterColumnDate ?? ""
      };
    });
    columns.sort((a, b) => a.ordering - b.ordering);
    return columns;
  }, [props.company, throughputClone?.progressDates]);

  return (
    <BaseModal
      data-cy={EditThroughputIds.modal}
      open={props.isOpen}
      onClose={() => props.HandleCloseEdit()}
      cypressData={{ modal: EditThroughputIds.modal, closeModalButton: EditThroughputIds.closeModalButton }}
      title={`Edit ${props.company.terms.throughputTerm}`}
      subtitle={props.story.title}
      maxWidth="md"
    >
      <Card variant="outlined" sx={{ borderRadius: 2, borderWidth: 0 }}>
        <Stack alignItems="end">
          <IconButton onClick={() => CollapseColumn(!isCollapsed)}>
            {isCollapsed ?
              <ArrowBack />
              :
              <ArrowForward />
            }
          </IconButton>
        </Stack>
        <Grid container display="flex" columns={12} justifyContent="space-between" wrap="nowrap">
          <Grid item xs={columnWidth}>
            {throughputClone &&
              <Stack gap={3}>
                <div>
                  <label className={labelStyle} htmlFor="initiative">Initiative</label>
                  <Select data-cy={EditThroughputIds.initiative} id="initiative" value={throughputClone.initiativeId} onChange={(e) => setThroughputClone({ ...throughputClone, initiativeId: e.target.value })} sx={{ width: "100%" }}>
                    {props.company.initiatives.map((initiative, index) =>
                      <MenuItem value={initiative.id} key={index}>{initiative.title}</MenuItem>
                    )}
                  </Select>
                </div>
                <div>
                  <label className={labelStyle} htmlFor="category">Category</label>
                  <Select data-cy={EditThroughputIds.category} id="category" value={throughputClone.categoryId} onChange={(e) => setThroughputClone({ ...throughputClone, categoryId: e.target.value })} sx={{ width: "100%" }}>
                    {props.company.categories?.map((category, index) =>
                      <MenuItem value={category.id} key={index}>{category.title}</MenuItem>
                    )}
                  </Select>
                </div>
                <AccountField cypressData={EditThroughputIds.key} title="Key" value={throughputClone.key} onChange={e => setThroughputClone({ ...throughputClone, key: e.target.value })} />
                <AccountField cypressData={EditThroughputIds.title} title="Title" value={throughputClone.title} onChange={e => setThroughputClone({ ...throughputClone, title: e.target.value })} />
                {workflowColumns.length > 0 &&
                  <Divider sx={{ marginY: 2 }} />
                }
                {workflowColumns.map((column, index) => {
                  return (
                    <Fragment key={index}>
                      <AccountDatePicker title={column.title} dateString={column.enterDate} setDateString={value => UpdateProgressDates(value, column.id)} cypressData={EditThroughputIds.progressDate} />
                    </Fragment>
                  );
                })}
              </Stack>
            }
            <div className="flex w-full justify-end my-4">
              <Button data-cy={EditThroughputIds.cancelButton} disabled={isLoading} sx={{ mx: 4 }} size="small" onClick={() => CancelEdit()}>Cancel</Button>
              <LoadingButton data-cy={EditThroughputIds.saveButton} loading={isLoading} sx={{ mx: 4 }} variant="contained" onClick={() => SaveEdit()}>Save Changes</LoadingButton>
            </div>
          </Grid>
          {!isCollapsed &&
            <>
              <Divider orientation="vertical" variant="middle" sx={{ marginX: 2 }} flexItem />
              <Grid item xs={4}>
                <div className="mt-4">
                  {props.story.dateCreated &&
                    <>
                      <Typography sx={{ mt: 2 }}>Created:  </Typography>
                      <Typography>{new Date(props.story.dateCreated.split('T')[0]).toLocaleDateString('en-US', { timeZone: 'UTC' })}{createdByUser && ` by ${createdByUser?.name}`}</Typography>
                    </>
                  }
                  {props.story.dateModified &&
                    <>
                      <Typography sx={{ mt: 2 }}>Modified:  </Typography>
                      <Typography>{new Date(props.story.dateModified?.split('T')[0]).toLocaleDateString('en-US', { timeZone: 'UTC' })}{modifiedByUser && ` by ${modifiedByUser?.name}`}</Typography>
                    </>
                  }
                </div>
              </Grid>
            </>
          }
        </Grid>
      </Card>
    </BaseModal>
  )
}