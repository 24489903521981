import { Company, Initiative } from "../Store/CompanySlice";
import { User } from "../Store/UserSlice";

export function CompanyFilter(companyList: Company[], activeStatus: string){
  let filteredCompanies: Company[] = [];
  
  companyList.forEach(company => {
    if(company !== undefined)
    {      
      switch(activeStatus)
      {
        case 'all':
          filteredCompanies.push(company);
        break;
        case 'active':
          if(company.isActive)
            filteredCompanies.push(company);
        break;
        case 'inactive':
          if(!company.isActive)
            filteredCompanies.push(company);
        break;
      }
    }
  })
  return filteredCompanies;
}

export function InitiativeFilter<T extends Initiative>(initiativesList: T[], isActive: string): T[] {
  const filteredInitiatives = initiativesList.filter((initiative) => {
    if (isActive === 'active')
      return (initiative.isActive)
    else if(isActive === 'inactive')
      return (!initiative.isActive)
    else
      return true;
  })

  return filteredInitiatives;
}

export function UserFilter(usersList: User[], isActive: string) {
  let filteredUsers: User[] = [];
    usersList.forEach((user) => {
      if(isActive === 'all') {
        filteredUsers.push(user)
      }
      if(isActive === 'active') {
        if(user.isActive === true) filteredUsers.push(user);
      }
      if(isActive === 'inactive') {
        if(user.isActive !== true) filteredUsers.push(user);
      }
  })
  return filteredUsers;
}