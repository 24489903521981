import { Box, Divider, Grid, IconButton, ListItem, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { TableOfContentsDrawer } from "./TableOfContentsDrawer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export interface TOCSection {
  title: string
  content: JSX.Element | undefined | false
  hideTitleHeader?: boolean
  onClick?: () => void
  endIcon?: JSX.Element
}


interface TableOfContentsProps {
  children: JSX.Element | (JSX.Element | undefined)[] | undefined
  contents: TOCSection[]
}

export function TableOfContents(props: TableOfContentsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleClickMenuIcon = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const isScreenBigEnough = useMediaQuery('(min-width:1000px)');
  const [isTableOpen, setIsTableOpen] = useState(isScreenBigEnough);
  const [animEnd, setAnimEnd] = useState(!isTableOpen);

  const handleDrawerOpen = () => {
    setIsTableOpen(true);
    setMenuAnchorEl(null);
    setAnimEnd(false);
  };

  const handleDrawerClose = () => {
    setIsTableOpen(false);
    setAnimEnd(false);
  };

  const handleContentSelect = (event: React.MouseEvent<HTMLDivElement | HTMLLIElement>, section: TOCSection, saveHistory?: boolean) => {

    if(section.onClick)
    {
      section.onClick();
      return;
    }

    const newLocation = '#' + section.title.replaceAll(" ", "");

    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(newLocation);

    if (anchor)
    {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });

      if (saveHistory)
        navigate(location.pathname + location.search + newLocation, { preventScrollReset: true });
    }
  };

  useEffect(() => {
    if (!isScreenBigEnough && isTableOpen)
      setIsTableOpen(false);
  }, [isScreenBigEnough, isTableOpen]);

  return (
    <Grid container direction="row">
      <Grid item xs={(isTableOpen || !animEnd) ? 2 : 0} >
        <Box sx={{ position: "sticky", top: 4, width: "100%", marginTop: 2 }}>
          <TableOfContentsDrawer sections={props.contents} open={isTableOpen} handleSelect={handleContentSelect} handleDrawerClose={handleDrawerClose} setAnimEnd={setAnimEnd} />
        </Box>
      </Grid>
      <Grid item xs={(isTableOpen || !animEnd) ? 10 : 12}>
        <Box sx={{ marginX: 8, marginY: 4 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleClickMenuIcon}
            edge="start"
            sx={{ marginLeft: -8, left: 8, top: 4, float: "left", position: "sticky", ...((isTableOpen || !animEnd) && { display: 'none' }) }}
          >
            <FormatListBulletedIcon />
          </IconButton>
          <Menu
            open={isMenuOpen}
            onClose={handleCloseMenu}
            anchorEl={menuAnchorEl}
          >
            <ListItem>
              <ListItemText>Contents {isScreenBigEnough ? <>[<Typography display="inline" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' }, cursor: "pointer", color: "blue" }} onClick={() => handleDrawerOpen()}>move to sidebar</Typography>]</> : <></>}</ListItemText>
            </ListItem>
            <Divider />
            {props.contents.map((sec, index) =>
              <MenuItem key={index} onClick={(e) => { handleContentSelect(e, sec); handleCloseMenu() }}>
                <ListItemText>{sec.title}</ListItemText>
                {sec.endIcon}
              </MenuItem>
            )}
          </Menu>
          {props.children}
        </Box>
      </Grid>
    </Grid>
  );
}
