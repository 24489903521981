import { Box, Stack } from "@mui/material";
import { DashboardSection } from "../../DashboardSection";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { Fragment, useCallback, useMemo } from "react";
import { ForecastCompletionProbability } from "../../../Services/ProbabilitySimulationService";
import Masonry from "@mui/lab/Masonry";
import plural from "pluralize";
import { FindItemsRemainingByInitiative, GetCompletedItems, GetItemsByInitiative, GetItemsBySelectedCategoriesAndInitiative, ThroughputData } from "../../../Services/CompanyService";
import { CapitalizeAll } from "../../../Services/Capitalize";
import { ProbabilityMetrics } from "./ProbabilityMetrics";
import { CompletionDateMetrics } from "./CompletionDateMetrics";
import { HowManyItemsMetrics } from "./HowManyItemsMetrics";
import { ItemCompletionChart } from "./ItemCompletionChart";
import { CycleTimeMetrics } from "./CycleTimeMetrics";
import { ItemDisplayMetrics } from "./ItemDisplayMetrics";
import { probabilityColors } from "../../../Styles";
import { GetDisplayItems } from "../../../Services/ItemDisplayService";

export interface ItemColor {
  code: string
  name: string
}

type DisplayItem = ThroughputData & { color: ItemColor, categoryTitle: string, startDate: string | undefined, endDate: string | undefined };

export interface DisplayInfo {
  items: DisplayItem[]
  startColumnName: string
  endColumnName: string
  firstStartedItemId: string
  firstCompletedItemId: string
}

interface MetricsGridProps {
  selectedInitiative: Initiative
  selectedCompany: Company
}

export function MetricsGrid(props: MetricsGridProps) {
  const itemsRemaining = useMemo(() => Math.max(FindItemsRemainingByInitiative(props.selectedCompany, props.selectedInitiative), 0), [props.selectedCompany, props.selectedInitiative]);
  const itemsBySelectedCategories = GetItemsBySelectedCategoriesAndInitiative(props.selectedCompany, props.selectedInitiative);
  const throughputCompleted = GetCompletedItems(itemsBySelectedCategories, props.selectedCompany.workflow, [
    { startDate: props.selectedCompany.metricStartDate, endDate: props.selectedCompany.metricEndDate },
    { startDate: props.selectedInitiative.startDate, endDate: props.selectedInitiative.targetDate }
  ]);

  const pastTargetDate = new Date(props.selectedInitiative.targetDate) < new Date();
  const initiativeComplete = throughputCompleted.length >= props.selectedInitiative.totalItems;
  const displayMetricsSection = !pastTargetDate && !initiativeComplete;
  const GetProbabilityColor = useCallback((value: number | undefined) => {
    if (value)
    {
      if (value >= 85)
        return probabilityColors.green;
      if (value >= 51)
        return probabilityColors.yellow;
    }
    return probabilityColors.red;
  }, []);

  const { completionProbability, probabilityColor } = useMemo(() => {
    const completedItems = GetCompletedItems(itemsBySelectedCategories, props.selectedCompany.workflow, [
      { startDate: props.selectedCompany.metricStartDate, endDate: props.selectedCompany.metricEndDate }
    ]);
    if (props.selectedInitiative.targetDate !== "")
    {
      const completionProbability = ForecastCompletionProbability(props.selectedInitiative.targetDate, completedItems, itemsRemaining, props.selectedCompany.workflow);
      const probabilityColor = completedItems.length < 4 ? "FFFFF" : GetProbabilityColor(completionProbability.value);
      return { completionProbability, probabilityColor };
    }
    return { completionProbability: undefined, probabilityColor: undefined };
  }, [props.selectedInitiative, props.selectedCompany, itemsRemaining, GetProbabilityColor, itemsBySelectedCategories]);


  const categoryColors: ItemColor[] = useMemo(() => [
    { code: "#8cd18f", name: "Green" },
    { code: "#f55351", name: "Red" },
    { code: "#e388d6", name: "Pink" },
    { code: "#f7a16a", name: "Orange" },
    { code: "#4fa0bd", name: "Blue" }
  ], []);

  const categoryMap = useMemo(() => {
    const theMap = new Map<string, { color: ItemColor, categoryTitle: string }>();
    let colorIndex = 0;
    for (const category of props.selectedCompany.categories)
    {
      theMap.set(category.id, { color: categoryColors.at(colorIndex) ?? { code: "#ffffff", name: "White" }, categoryTitle: category.title });
      colorIndex++;
    }
    return theMap;
  }, [props.selectedCompany, categoryColors]);

  const displayInfo: DisplayInfo | undefined = useMemo(() => {
    const initiativeThroughput = GetItemsByInitiative(props.selectedCompany, props.selectedInitiative);
    return GetDisplayItems(initiativeThroughput, props.selectedCompany, [props.selectedInitiative], categoryMap);
  }, [props.selectedInitiative, props.selectedCompany, categoryMap]);

  const companyThroughputTerm = props.selectedCompany.terms.throughputTerm;
  const probabilityTooltipMessage = `The probability that this ${props.selectedCompany.terms.initiativeTerm.toLowerCase()} will be completed by the target date.`;
  const probabilitySubtitle = (<Stack gap={.5} sx={{ marginTop: 1 }}>
    <p style={{ color: probabilityColors.green }}>{'Green: Has the highest likelihood that the team will be able to deliver to the target expectation.'}</p>
    <p style={{ color: probabilityColors.yellow }}>{'Yellow: While the target expectation is not necessarily unlikely to be hit, our confidence level is lower. Prioritization is especially important in this state since there is risk to the items being done last by the target date.'}</p>
    <p style={{ color: probabilityColors.red }}>{'Red: It is unlikely that the team will hit the target expectation at the current rate of delivery.'}</p>
  </Stack>);
  const completionTooltipMessage = `Choose the percent probability to see the date the remaining ${plural(companyThroughputTerm.toLowerCase())} will be completed.`;
  const projectionTooltipMessage = `Choose a date and percent probability to see the number of ${plural(companyThroughputTerm.toLowerCase())} that can be completed.`;
  const cycleTimeTooltipMessage = `The number of days it took to complete each ${companyThroughputTerm.toLowerCase()}.`;
  const itemDisplayMessage = ""//`The ${plural(companyThroughputTerm.toLowerCase())} that were delivered most recently.`;
  const itemDisplaySubtitle = useMemo(() => {
    const categories: JSX.Element[] = [];
    categoryMap.forEach(({ color, categoryTitle }) => {
      categories.push(<p style={{ color: color.code }}>{color.name}: {categoryTitle}</p>);
    });
    return <Stack sx={{ marginTop: 0 }}>
      {categories.map((category, index) => <Fragment key={index}>{category}</Fragment>)}
    </Stack>;
  }, [categoryMap]);

  const sections: (JSX.Element | false)[] = [
    (displayMetricsSection && props.selectedInitiative.displayMetrics.completionProbability && completionProbability !== undefined &&
      <DashboardSection title="Statistical Probability" expandable={true} borderWidth={2} centerItems color={probabilityColor}
        items={<Stack sx={{ alignItems: "center", paddingY: 3 }}>
          <ProbabilityMetrics initiative={props.selectedInitiative} company={props.selectedCompany} probability={completionProbability} />
        </Stack>}
        tooltipMessage={probabilityTooltipMessage}
        tooltipSubtitle={probabilitySubtitle}
      />
    ),
    (displayMetricsSection && props.selectedInitiative.displayMetrics.projectedCompletionDate &&
      <DashboardSection title="Projected Completion Date" expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center", paddingY: 3 }}>
          <CompletionDateMetrics company={props.selectedCompany} initiative={props.selectedInitiative} />
        </Stack>}
        tooltipMessage={completionTooltipMessage}
      />
    ),
    (props.selectedInitiative.displayMetrics.itemProjection &&
      <DashboardSection title={`${CapitalizeAll(props.selectedCompany.terms.throughputTerm)} Projection`} expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center", paddingY: 3 }}>
          <HowManyItemsMetrics company={props.selectedCompany} initiative={props.selectedInitiative} />
        </Stack>}
        tooltipMessage={projectionTooltipMessage}
      />
    ),
    (displayMetricsSection && props.selectedInitiative.displayMetrics.itemsCompleted && throughputCompleted.length > 0 &&
      <DashboardSection title={`${CapitalizeAll(plural(companyThroughputTerm))} Completed`} expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center" }}>
          <ItemCompletionChart totalItems={props.selectedInitiative.totalItems} itemsRemaining={itemsRemaining} itemTerm={props.selectedCompany.terms.throughputTerm} useMinWidth />
        </Stack>}
      />
    ),
    (props.selectedInitiative.displayMetrics.cycleTime &&
      <DashboardSection title="Cycle Time" expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center", paddingY: 3 }}>
          <CycleTimeMetrics company={props.selectedCompany} initiative={props.selectedInitiative} />
        </Stack>}
        tooltipMessage={cycleTimeTooltipMessage}
      />
    ),
    (props.selectedInitiative.displayMetrics.deliveredItems && !!displayInfo && displayInfo.items.length > 0 &&
      <DashboardSection title={`Delivered ${CapitalizeAll(plural(props.selectedCompany.terms.throughputTerm))}`} expandable={true} borderWidth={2} centerItems
        items={
          <ItemDisplayMetrics company={props.selectedCompany} displayInfo={displayInfo} />
        }
        tooltipMessage={itemDisplayMessage}
        tooltipSubtitle={itemDisplaySubtitle}
      />
    )
  ];


  if (!props.selectedInitiative || !props.selectedCompany || sections.filter(s => s).length === 0)
    return <></>;
  return (
    <Box sx={{ marginY: 4 }}>
      <DashboardSection title={"Metrics"} hideDividers={true}
        items={
          <Masonry columns={{ xs: 1, sm: 1, md: 2, xl: 3 }} spacing={4} sx={{ width: "auto", marginX: 1 }}>
            {sections.map((section, index) =>
              <Fragment key={index}>{section}</Fragment>
            )}
          </Masonry>
        }
      />
    </Box>
  );
}