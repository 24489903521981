import { Typography } from "@mui/material";
import { metricsFontLarge } from "../../../Styles";
import { plural } from "pluralize";
import { Company } from "../../../Store/CompanySlice";

interface InsufficientThroughputSectionProps {
  children: JSX.Element[] | JSX.Element
  company: Company
  completedItemCount: number
  textColor?: string
}

export function InsufficientThroughputSection(props: InsufficientThroughputSectionProps) {
  return (
    <>
      {props.completedItemCount >= 4 ?
        props.children
        :
        <>
          <Typography sx={props.textColor ? { ...metricsFontLarge, color: props.textColor } : metricsFontLarge}>Report will be available after</Typography>
          <Typography sx={props.textColor ? { ...metricsFontLarge, color: props.textColor } : metricsFontLarge}>completion of 4 {plural(props.company.terms.throughputTerm).toLowerCase()}.</Typography>
        </>
      }
    </>
  );
}

