import { useMemo, useState } from "react";
import { LinkData } from "../../Services/CompanyService"
import { tooltipStyle } from "../../Styles"
import { Link, ListItem, ListItemText, Stack, TablePagination, Tooltip, Typography } from "@mui/material"

interface DashboardLinkItemProps {
  linkData: LinkData[]
}

export function DashboardLinkItem(props: DashboardLinkItemProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedLinks = useMemo(() => {
    const clone = props.linkData.slice();
    clone.sort((a, b) => {
      const modifiedA = a.dateModified;
      const modifiedB = b.dateModified;

      return Math.sign(new Date(modifiedB ?? Date.now()).getTime() - new Date(modifiedA ?? Date.now()).getTime());
    });
    return clone;
  }, [props.linkData]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedLinks = sortedLinks.slice(indexOfFirstItem, indexOfLastItem);
  const remainingSpaces = useMemo(() => sortedLinks.length > rowsPerPage ? Array.from(new Array(rowsPerPage - slicedLinks.length)) : [], [rowsPerPage, slicedLinks, sortedLinks]);

  return (
    <ListItem>
      <ListItemText>
        {slicedLinks.map((link, index) => {
          const tooltipMessage = link.url;
          const lastModified = link.dateModified ? (new Date(link.dateModified)).toLocaleDateString('en-US', { timeZone: 'UTC' }) : undefined;
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" key={index} sx={{ marginY: 1 }}>
              <Link href={link.url} target="_blank" rel="noreferrer noopener" style={{ color: "blue", width: "100%" }}>
                <Tooltip className={tooltipStyle} title={tooltipMessage} key={index} sx={{ width: "100%" }}>
                  <Typography variant="body2">{link.title}</Typography>
                </Tooltip>
              </Link>
              <Typography variant="caption" sx={{ marginLeft: 1 }}>
                {lastModified}
              </Typography>
            </Stack>
          )
        })}
        {remainingSpaces.map((i, index) =>
          <Stack direction="row" justifyContent="space-between" alignItems="center" key={index} sx={{ marginY: 1 }}>
            <Typography variant="body2">&nbsp;</Typography>
            <Typography variant="caption" sx={{ marginLeft: 1 }}>
              &nbsp;
            </Typography>
          </Stack>
        )}
        {sortedLinks.length > 5 &&
          <TablePagination
            component="div"
            count={sortedLinks.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        }
      </ListItemText>
    </ListItem>
  )
}