import { Avatar } from "@mui/material";
import { ShallowUser } from "../../Store/UserSlice";

export interface UserWithAvatarUrl extends ShallowUser {
  docUrl?: string
}

interface UserAvatarProps {
  user: UserWithAvatarUrl
  length?: number
  variant?: "circular" | "rounded" | "square"
}

export function UserAvatar(props: UserAvatarProps) {
  return (
    <Avatar variant={props.variant} alt={props.user.name || props.user.email} src={props.user.docUrl} sx={{ width: props.length, height: props.length, fontSize: props.length ? props.length * 0.6 : undefined }}>
      {(props.user.name || props.user.email).split(' ').slice(0, 2).map(word => word.toUpperCase().at(0))}
    </Avatar>
  )
}
