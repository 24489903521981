import { Badge, IconButton } from "@mui/material";
import { UserAvatar, UserWithAvatarUrl } from "./User/UserAvatar";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { useState } from "react";
import { FileUpload } from "./FileUpload";
import { BaseModal } from "./BaseModal";
import { ClientLogo, CompanyWithLogoUrl } from "./Client/ClientLogo";

export interface FileUrlPair {
  url: string,
  file: File | null
}

interface EditImageProps {
  user?: UserWithAvatarUrl
  company?: CompanyWithLogoUrl
  newFileUrl: FileUrlPair
  setNewFileUrl: (value: FileUrlPair) => void
  onUpload?: (value: FileUrlPair) => Promise<void>
  avatarLength?: number
}

export function EditImage(props: EditImageProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  function HandleChooseFile(newFile: File | null) {
    if (newFile)
    {
      if (uploadUrl)
        window.URL.revokeObjectURL(uploadUrl);
      const objUrl = window.URL.createObjectURL(newFile);
      setUploadUrl(objUrl);
    }
    else
      setUploadUrl("");

    setFile(newFile);
  }

  async function UploadAvatar() {
    if (uploadUrl && file)
    {
      setIsUploading(true);
      if (props.onUpload)
        await props.onUpload({ url: uploadUrl, file });
      props.setNewFileUrl({ url: uploadUrl, file });
      setIsOpen(false);
      setIsUploading(false);
    }
  }

  return (
    <>
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <IconButton onClick={() => setIsOpen(true)}>
            <PhotoCameraOutlinedIcon />
          </IconButton>
        }
      >
        {props.user &&
          <UserAvatar user={{ ...props.user, docUrl: props.newFileUrl.url !== "" ? props.newFileUrl.url : props.user?.docUrl }} length={props.avatarLength} />
        }
        {props.company &&
          <ClientLogo company={{ ...props.company, docUrl: props.newFileUrl.url ? props.newFileUrl.url : props.company?.docUrl }} length={props.avatarLength} />
        }
      </Badge>

      <BaseModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        cypressData={{ modal: "", closeModalButton: "" }}
        title="Upload New Image"
        subtitle={props.user?.name ?? props.company?.name}
        height="full"
      >
        <div className="space-y-2">
          <FileUpload cypressData={{ chooseFileButton: "", submitButton: "", fileInput: "" }} file={file} setFile={HandleChooseFile}
            isUploading={isUploading} UploadFile={UploadAvatar} accept={"image/png, image/jpeg"}
          />
          <div className="flex justify-center">
            {props.user &&
              <UserAvatar user={{ ...props.user, docUrl: uploadUrl ? uploadUrl : props.user.docUrl }} length={200} />
            }
            {props.company &&
              <ClientLogo company={{ ...props.company, docUrl: uploadUrl ? uploadUrl : props.company.docUrl }} length={200} />
            }
          </div>
        </div>
      </BaseModal>
    </>
  )
}

