import axios from "axios";
import { User } from "../Store/UserSlice";
import { LocalizeUrl } from "./Http";

export interface AuthenticateSessionRequest {
  sessionId: string
}

interface AuthenticateSessionResponse {
  user: User
}

export async function AuthenticateSession(request: AuthenticateSessionRequest): Promise<AuthenticateSessionResponse> {
  const baseUrl = LocalizeUrl("/api/AuthenticateSession");

  const response = await axios.post(baseUrl, request);
  return response.data;
}

export interface EndSessionRequest {
  sessionId: string
}

interface EndSessionResponse {
}

export async function EndSession(request: EndSessionRequest): Promise<EndSessionResponse> {
  const baseUrl = LocalizeUrl("/api/EndSession");

  const response = await axios.post(baseUrl, request);
  return response.data;
}