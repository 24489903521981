import { Typography, ListItem, ListItemText, Stack, Tooltip, TablePagination, IconButton } from "@mui/material";
import { tooltipStyle } from "../../Styles";
import { DocumentInfo } from "../../Store/DocumentSlice";
import { DocumentActions } from "./DocumentActions";
import { useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface DashboardDocumentsDataProps {
  docInfos: DocumentInfo[]
  getDocs: () => Promise<void>
  canDelete: boolean
  canArchive: boolean
  documentTerm: string
}

export function DashboardDocumentsData(props: DashboardDocumentsDataProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedDocs = useMemo(() => {
    const clone = props.docInfos.slice();
    clone.sort((a, b) => {
      const modifiedA = a.lastModified;
      const modifiedB = b.lastModified;

      return Math.sign((modifiedB ?? 0) - (modifiedA ?? 0));
    });
    return clone;
  }, [props.docInfos]);


  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedDocs = sortedDocs.slice(indexOfFirstItem, indexOfLastItem);
  const remainingSpaces = useMemo(() => sortedDocs.length > rowsPerPage ? Array.from(new Array(rowsPerPage - slicedDocs.length)) : [], [rowsPerPage, slicedDocs, sortedDocs]);

  return (
    <ListItem>
      <ListItemText>
        {slicedDocs.map((doc, index) => {
          const title = doc.metadata.shortDescription ? doc.metadata.shortDescription : doc.metadata.fileName;
          const description = doc.metadata.longDescription;
          const tooltipMessage = description ? title + " - " + description : title;

          const lastModified = doc.lastModified ? (new Date(doc.lastModified)).toLocaleDateString('en-US', { timeZone: 'UTC' }) : undefined;
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" key={index}>
              <Tooltip className={tooltipStyle} title={tooltipMessage} key={index} sx={{ width: "100%" }}>
                <Typography noWrap textOverflow="ellipsis" variant="body2">
                  {doc.metadata.shortDescription ? doc.metadata.shortDescription : doc.metadata.fileName}
                </Typography>
              </Tooltip>
              <Stack direction="row" alignItems="center" sx={{ marginLeft: 1 }} gap={1}>
                <Typography variant="caption">
                  {lastModified}
                </Typography>
                <Stack direction="row" alignItems="center">
                  <DocumentActions docInfo={doc} RefreshData={props.getDocs} canDelete={props.canDelete} canArchive={props.canArchive} documentTerm={props.documentTerm} />
                </Stack>
              </Stack>
            </Stack>
          )
        })}
        {remainingSpaces.map((i, index) =>
          <Stack direction="row" justifyContent="space-between" alignItems="center" key={index}>
            <Typography noWrap textOverflow="ellipsis" variant="body2">
              &nbsp;
            </Typography>
            <Stack direction="row" alignItems="center" sx={{ marginLeft: 1 }} gap={1}>
              <Typography variant="caption">
                &nbsp;
              </Typography>
              <Stack direction="row" alignItems="center">
                <IconButton disabled hidden>
                  <VisibilityIcon sx={{ fontSize: 20, opacity: 0 }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        )}
        {sortedDocs.length > 5 &&
          <TablePagination
            component="div"
            count={sortedDocs.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        }
      </ListItemText>
    </ListItem >
  )
}