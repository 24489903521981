import { Stack } from "@mui/material"
import { Company } from "../../Store/CompanySlice"
import { BaseModal } from "../BaseModal"
import { AccountField } from "../AccountField"
import { useEffect, useState } from "react"
import { MakeClone } from "../../Services/Cloning"
import { AccountActions } from "../AccountActions"
import { LinkData } from "../../Services/CompanyService"
import { v4 } from "uuid"

interface LinkUpsertModalProps {
  company: Company
  isOpen: boolean
  handleClose: () => void
  linkToEdit: LinkData | undefined
  isUpserting: boolean
  Save: (editedLink: LinkData) => Promise<void>
  Cancel: () => void
  cypressData: {
    modal: string,
    closeModalButton: string,
    title: string,
    url: string,
    saveButton: string,
    cancelButton: string
  }
}

export function LinkUpsertModal(props: LinkUpsertModalProps) {
  const [linkClone, setLinkClone] = useState<LinkData>();

  useEffect(() => {
    if (props.isOpen)
    {
      if (props.linkToEdit)
        setLinkClone(MakeClone(props.linkToEdit));
      else
        setLinkClone({
          id: v4(),
          title: "",
          url: ""
        });
    }
    else
    {
      setLinkClone(undefined);
    }
  }, [props.isOpen, props.linkToEdit])

  return (
    <BaseModal
      open={props.isOpen}
      onClose={props.handleClose}
      cypressData={{ modal: props.cypressData.modal, closeModalButton: props.cypressData.closeModalButton }}
      title={`${props.linkToEdit ? "Edit" : "Add"} ${props.company.terms.linkTerm}`}
      subtitle={props.linkToEdit?.title}
    >
      {linkClone &&
        <Stack gap={2}>
          <AccountField title="Title" cypressData={props.cypressData.title} value={linkClone?.title} onChange={(e) => { setLinkClone({ ...linkClone, title: e.target.value }) }} />
          <AccountField title="URL" cypressData={props.cypressData.url} value={linkClone?.url} onChange={(e) => { setLinkClone({ ...linkClone, url: e.target.value }) }} />
          <AccountActions cypressData={{ saveButton: props.cypressData.saveButton, cancelButton: props.cypressData.cancelButton }} isSaving={props.isUpserting} Cancel={props.Cancel} Save={() => props.Save(linkClone)} />
        </Stack>
      }
    </BaseModal >
  )
}
