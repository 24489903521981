import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { createTheme, styled } from '@mui/material/styles';
import { createStyled } from '@mui/system';
import { Button, Card, CardActions, CardContent, FormControlLabel, Paper, StepConnector, StepLabel, TableRow, TextField, TextareaAutosize, stepConnectorClasses, stepLabelClasses, tableRowClasses } from '@mui/material';

import "@mui/material/styles/createPalette";
import LoadingButton from '@mui/lab/LoadingButton';
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    darkBlue: Palette['primary'];
    lightBlue: Palette['primary'];
    yellow: Palette['primary'];
    grayBlue: Palette['primary'];
    darkGray: Palette['primary'];
    lightGray: Palette['primary'];
    veryLightGray: Palette['primary'];
  }
  interface PaletteOptions {
    darkBlue?: Palette['primary'];
    lightBlue?: Palette['primary'];
    yellow?: Palette['primary'];
    grayBlue?: Palette['primary'];
    darkGray?: Palette['primary'];
    lightGray?: Palette['primary'];
    veryLightGray?: Palette['primary'];
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    lightBlue: true;
    darkBlue: true;
    yellow: true;
    grayBlue: true;
    darkGray: true;
    lightGray: true;
    veryLightGray: true;
  }
  interface IconButtonPropsColorOverrides {
    lightBlue: true;
    darkBlue: true;
    yellow: true;
    grayBlue: true;
    darkGray: true;
    lightGray: true;
    veryLightGray: true;
  }
  interface CheckboxPropsColorOverrides {
    lightBlue: true;
    darkBlue: true;
    yellow: true;
    grayBlue: true;
    darkGray: true;
    lightGray: true;
    veryLightGray: true;
  }
  interface TextFieldPropsColorOverrides {
    lightBlue: true;
    darkBlue: true;
    yellow: true;
    grayBlue: true;
    darkGray: true;
    lightGray: true;
    veryLightGray: true;
  }
  interface TypographyPropsColorOverrides {
    lightBlue: true;
    darkBlue: true;
    yellow: true;
    grayBlue: true;
    darkGray: true;
    lightGray: true;
    veryLightGray: true;
  }
  interface ChipPropsColorOverrides {
    lightBlue: true;
    darkBlue: true;
    yellow: true;
    grayBlue: true;
    darkGray: true;
    lightGray: true;
    veryLightGray: true;
  }
}

export const IntegrityTheme = createTheme({
  palette: {
    darkBlue: {
      light: '#2E4A7F',
      main: '#21355B',
      dark: '#131F34',
      contrastText: '#FFFFFF'
    },
    lightBlue: {
      light: '#A7E6DC',
      main: '#69D5C3',
      dark: '#40C9B2',
      contrastText: '#21355B'
    },
    yellow: {
      light: '#FBCC79',
      main: '#FAB947',
      dark: '#F9A616',
      contrastText: '#21355B'
    },
    grayBlue: {
      light: '#5A6E81',
      main: '#445362',
      dark: '#303B45',
      contrastText: '#FFFFFF'
    },
    darkGray: {
      light: '#7E7F75',
      main: '#65665E',
      dark: '#4A4A44',
      contrastText: '#FFFFFF'
    },
    lightGray: {
      light: '#A2AEAC',
      main: '#879794',
      dark: '#6D7E7B',
      contrastText: '#FFFFFF'
    },
    veryLightGray: {
      light: '#F3F1F3',
      main: '#E4E1E5',
      dark: '#CBC6CD',
      contrastText: '#21355B'
    }
  }
});

export const integrityColors = {
  integrityDarkBlue: '#21355B',
  integrityLightBlue: '#69D5C3',
  integrityYellow: '#FAB947',
  integrityGrayBlue: '#445362',
  integrityDarkGray: '#65665E',
  integrityLightGray: '#879794',
  integrityVeryLightGray: '#E4E1E5'
}

export const probabilityColors = {
  green: "#b5ed9d",
  yellow: "#dede66",
  red: "#f09797",
  gray: "#e5e7eb"
}

export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    outline: '3px solid #879794',
  }
}

export const TableHeaderStyle =
  styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#21345b'
    }
  }));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 8,
  textAlign: 'left',
  color: '#21355B',
  elevation: 12,
}));

export const UserItem = styled(Paper)(() => ({
  backgroundColor: '#E4E1E5',
  padding: 2,
  marginBottom: 6,
  marginTop: 1,
  textAlign: 'center',
  color: 'black',
  width: "99%",
  marginLeft: ".5%",
  marginRight: ".5%"
}));

export const StyledCard = styled(Card)(() => ({
}))

export const StyledCardContent = styled(CardContent)(() => ({
  alignItems: "center"
}))

export const StyledCardActions = styled(CardActions)(() => ({
  height: 50
}))

export const StyledTextField =
  styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#65665E",
      color: "#65665E"
    },
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 2,
    width: "100%"
  }));

export const UserTextField =
  styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#65665E",
      color: "#65665E"
    },

  }));

export const FormTextField =
  styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#65665E",
      color: "#65665E"
    },
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 2,
    width: "75%"
  }));

export const StyledTextarea =
  styled(TextareaAutosize)(() => ({
    width: "100%",
    borderColor: '#65665E',
    borderWidth: 1,
    borderRadius: 1,
    marginBottom: 10,
    padding: "0.5rem"
  }));

export const StyleFormControlLabel =
  styled(FormControlLabel)(() => ({
    display: 'block'
  }))

const IntegrityStyled = createStyled({ defaultTheme: IntegrityTheme });

export const SubmitLoadingButton =
  IntegrityStyled(LoadingButton)(({ theme }) => ({
    textTransform: 'none',
    width: "100%",
    background: theme.palette.yellow.main,
    color: theme.palette.yellow.contrastText,
    variant: "contained",
    paddingY: 1,
    "&:hover": {
      background: theme.palette.yellow.dark
    },
    "&.Mui-disabled": {
      background: "#eaeaea",
      color: "#c0c0c0"
    },
    "&.MuiLoadingButton-loading": {
      background: "#eaeaea",
      color: "#eaeaea"
    }
  }))

export const SubmitButton =
  IntegrityStyled(Button)(({ theme }) => ({
    textTransform: 'none',
    width: "100%",
    background: theme.palette.yellow.main,
    color: theme.palette.yellow.contrastText,
    variant: "contained",
    paddingY: 1,
    "&:hover": {
      background: theme.palette.yellow.dark
    },
    "&.Mui-disabled": {
      background: "#eaeaea",
      color: "#c0c0c0"
    }
  }))

export const CancelButton =
  styled(Button)(() => ({
    textTransform: "none",
    size: "small",
    width: "100%"
  }))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "10px",
    '&:nth-of-type(odd)': {
      backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "gray-200",
    },
  }
}))

export const StyleTableCell =
  styled(TableCell)(({ theme }) => ({
    padding: 0,
    margin: 0
  }))

export const CustomizedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "blue",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "blue",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: "5px",
    backgroundColor: "gray",
    borderRadius: 1,
  },
}));

export const CustomizedStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    fontSize: 20,
    color: "lightgray"
  },
  [`&.${stepLabelClasses.active}`]: {
    color: "#1488CC"
  }
}))

export const blueTheme = createTheme({
  palette: {
    primary: {
      main: "#3d5afe",
    }
  }
});

export const labelStyle = "font-bold text-lg";
export const tooltipStyle = "hover:bg-slate-50 cursor-pointer";
export const inputStyle = "outline rounded outline-1 p-2 hover:outline-2 focus:outline-2 mt-2 mb-2";
export const selectStyle = "outline rounded outline-1 p-2 hover:outline-2 focus:outline-2";
export const cardHeader = "text-2xl font-semibold mb-4";

export const submitButtonStyle = "rounded h-3/4 max-h-7 w-[90px] mx-2 bg-lime-600 text-white enabled:hover:bg-lime-700 disabled:opacity-75";
export const cancelButtonStyle = "rounded h-3/4 max-h-7 w-[90px] mx-2 bg-red-600 text-white enabled:hover:bg-red-700 disabled:opacity-75";
export const genericButtonStyle = "outline outline-[#445362] rounded bg-[#21345b] text-white h-10 w-24 transition ease-in-out enabled:hover:bg-white enabled:hover:text-[#445362] disabled:opacity-75";
export const yellowButtonStyle = "outline outline-[#445362] h-[40px] w-32 bg-[#FAB947] text-[#445362] rounded-md transition ease-in-out enabled:hover:bg-white enabled:hover:text-[#445362] disabled:opacity-75";

export const darkBlueToWhiteButtonStyle = "outline outline-[#445362] rounded bg-[#21345b] text-white h-10 w-24 transition ease-in-out enabled:hover:bg-white enabled:hover:text-[#445362] disabled:opacity-75";

export const defaultRowStyle = "odd:bg-gray-200";

export const redProbabilityStyle = "bg-[#FF6464]";
export const greenProbabilityStyle = "bg-[#69D5C3]";

export const tableCellFontSize = "min(calc(14px + 0.390625vw),16px)";
export const tableHeaderFontSize = "min(calc(17px + 0.390625vw),20px)";
export const tableButtonFontSize = tableHeaderFontSize;

export const metricsFontLarge = { fontSize: 25, fontWeight: "bold", textAlign: "center", color: "blue" };
export const metricsFontSmall = { fontSize: 20, textAlign: "center" };

export const IntegrityLogo = "https://static.wixstatic.com/media/4f8b60_dee59e701c0d4b34b4a35b0e96250141%7Emv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/4f8b60_dee59e701c0d4b34b4a35b0e96250141%7Emv2.png";
  
