import { FormControl, InputLabel, Select, SelectChangeEvent, Typography } from "@mui/material";

export function AccountSelect(props: {
  cypressData: string
  value: string | undefined
  onChange: (e: SelectChangeEvent) => void
  options: JSX.Element[]
  title?: string
  label?: string
}) {
  return (
    <div>
      <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id={`account-select-${props.label}`}>{props.label}</InputLabel>
        <Select label={props.label} labelId={`account-select-${props.label}`} data-cy={props.cypressData} value={props.value} onChange={props.onChange} sx={{ width: "100%" }}>
          {props.options}
        </Select>
      </FormControl>
    </div>
  );
}