import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { MakeClone } from "../../../Services/Cloning";
import { WorkflowColumn } from "../../../Store/CompanySlice";
import { BaseModal } from "../../BaseModal";
import { Stack } from "@mui/material";
import { AccountActions } from "../../AccountActions";
import { AccountField } from "../../AccountField";

export const WorkflowUpsertModalIds = {
  modal: "WorkflowUpsertModal",
  title: "WorkflowUpsertModalTitle",
  saveButton: "WorkflowUpsertModalSaveButton",
  cancelButton: "WorkflowUpsertModalCancelButton",
  closeModalButton: "WorkflowUpsertModalCloseModalButton"
}

interface ClientWorkflowUpsertModalProps {
  columnToEdit: WorkflowColumn | undefined
  isOpen: boolean
  Save: (editedColumn: WorkflowColumn) => Promise<void>
  Cancel: () => void
  handleClose: () => void
  isUpserting: boolean
}

export function ClientWorkflowUpsertModal(props: ClientWorkflowUpsertModalProps) {
  const [columnClone, setColumnClone] = useState<WorkflowColumn>();

  useEffect(() => {
    if (props.isOpen)
    {
      if (props.columnToEdit)
        setColumnClone(MakeClone(props.columnToEdit));
      else
        setColumnClone({
          id: v4(),
          title: "",
          ordering: 0
        });
    }
    else
    {
      setColumnClone(undefined);
    }
  }, [props.isOpen, props.columnToEdit])

  return (
    <BaseModal
      open={props.isOpen}
      onClose={props.handleClose}
      title={`${props.columnToEdit?.title ? "Edit" : "Add"}`}
      subtitle={props.columnToEdit?.title}
      cypressData={{ modal: WorkflowUpsertModalIds.modal, closeModalButton: WorkflowUpsertModalIds.closeModalButton }}
    >
      {columnClone &&
        <Stack gap={2}>
          <AccountField title="Title" value={columnClone?.title} onChange={(e) => { setColumnClone({ ...columnClone, title: e.target.value }); }} cypressData={WorkflowUpsertModalIds.title} />
          <AccountActions isSaving={props.isUpserting} Cancel={props.Cancel} Save={() => props.Save(columnClone)} cypressData={{ cancelButton: WorkflowUpsertModalIds.cancelButton, saveButton: WorkflowUpsertModalIds.saveButton }} />
        </Stack>
      }
    </BaseModal>
  )
}