import { Typography } from "@mui/material"
import { DateInput } from "./DateInput"

interface AccountDatePickerProps {
  dateString: string
  setDateString: (value: string) => void
  cypressData: string
  title?: string
  label?: string
}

export function AccountDatePicker(props: AccountDatePickerProps) {

  return (
    <div>
      <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
      <DateInput label={props.label} sx={{ width: "100%" }} cypressData={props.cypressData} dateString={props.dateString} setDateString={props.setDateString} />
    </div>
  )
}
