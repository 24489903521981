import { Company, Initiative } from "../Store/CompanySlice";
import { User } from "../Store/UserSlice";
import { IsInitiativeExpired } from "./CompanyService";

export interface InitCompany {
  companyName: string
  companyId: string
  initiatives: Initiative[]
}

export function GetInitiativesService(currentUser: User, allCompanies: Company[], isIntegrityAdmin: boolean, showInactive: boolean) {
  function SetNewInits(company: Company, initiatives: Initiative[]) {
    if (!showInactive)
      initiatives = initiatives.filter(i => i.isActive && !IsInitiativeExpired(i));
    newInitCompany = {
      companyId: company.id,
      companyName: company.name,
      initiatives: initiatives,
    }
    initCompanies.push(newInitCompany);
  }

  let initCompanies: InitCompany[] = [];
  let newInitCompany: InitCompany;
  if (isIntegrityAdmin)
  {
    for (let company of allCompanies)
    {
      SetNewInits(company, company.initiatives);
    }
  }
  else
  {
    const userInitiativeIds = currentUser.initiativeRoles.flatMap(i => i.initiativeId);
    for (const company of allCompanies)
    {
      const userInitiatives: Initiative[] = [];
      for (const initiative of company.initiatives)
      {
        if (userInitiativeIds.includes(initiative.id))
          userInitiatives.push(initiative);
      }
      if (userInitiatives.length > 0)
        SetNewInits(company, userInitiatives);
    }
  }
  return initCompanies;
}
