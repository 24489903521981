import { Button } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

export function StepperActionButtons(props: {
  HandleSaveCopy?: () => Promise<void>
  HandleCancelCopy?: () => void
  previousStep?: () => void
  nextStep?: () => void
  lastStep?: () => void
  currentStep?: number
  totalSteps?: number
  disableNext?: boolean
}) {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBack = () => {
    if(props.currentStep !== undefined && props.currentStep > 1)
    {
      if (props.previousStep)
        props.previousStep();
    }
    else if(props.HandleCancelCopy)
      props.HandleCancelCopy();
  };

  const handleNext = () => {
    if (props.nextStep)
      props.nextStep();
  };

  const handleFinish = async () => {
    if(!isSubmitting)
    {
      setIsSubmitting(true);
    
    if (props.HandleSaveCopy)
      await props.HandleSaveCopy();
    if (props.lastStep)
      props.lastStep();
    
      setIsSubmitting(false);
    }
  };

  return (
    <div className="justifyContent-center">
      {props.currentStep && props.totalSteps &&
        <>
          <Button sx={{ fontSize: 20, color: "primary" }} onClick={handleBack}><ArrowLeftIcon />Back</Button>
          <LoadingButton sx={{ width: "25%", marginX: 2, fontSize: 20 }} onClick={props.HandleCancelCopy}>Cancel</LoadingButton>
          {props.currentStep < props.totalSteps && (
            <Button disabled={props.disableNext} sx={{ fontSize: 20, color: "primary" }} onClick={handleNext}>Next<ArrowRightIcon /></Button>
          )}
          {props.currentStep === props.totalSteps && (
            <LoadingButton loading={isSubmitting} sx={{ marginX: 2, fontSize: 20 }} variant="contained" onClick={handleFinish}>Submit</LoadingButton>
          )}
        </>
      }
    </div>
  );
};