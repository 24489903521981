import { Workflow } from "../../Store/CompanySlice";
import { FailValidation, SuccessValidation, Validation } from "./Validation";

export const WorkflowValidationReasons = {
  emptyColumnTitle: "Column title cannot be left empty.",
  duplicateColumnTitle: "A column with this title already exists.",
  duplicateColumnOrder: "Two columns may not have the same order.",
  minimumColumns: "A workflow requires at least two columns.",
  missingStartColumn: "A start date column must be set.",
  missingEndColumn: "An end date column must be set.",
  endDateBeforeStartDate: "The start date column cannot be ordered after the end date column.",
  startColumnMatchesEndColumn: "The start date column must be a different column than the end date column.",
}

export function ValidateWorkflow(workflow: Workflow): Validation {
  if (workflow.columns.length < 2)
    return FailValidation(WorkflowValidationReasons.minimumColumns);

  const startColumn = workflow.columns.find(c => c.id === workflow.startDateColumnId);
  if (!startColumn)
    return FailValidation(WorkflowValidationReasons.missingStartColumn);

  const endColumn = workflow.columns.find(c => c.id === workflow.endDateColumnId);
  if (!endColumn)
    return FailValidation(WorkflowValidationReasons.missingEndColumn);

  if (startColumn.id === endColumn.id)
    return FailValidation(WorkflowValidationReasons.startColumnMatchesEndColumn);

  if (endColumn.ordering < startColumn.ordering)
    return FailValidation(WorkflowValidationReasons.endDateBeforeStartDate);

  const orderSet = new Set<number>();
  const titleSet = new Set<string>();
  for (const column of workflow.columns)
  {
    if (!column.title)
      return FailValidation(WorkflowValidationReasons.emptyColumnTitle);

    if (orderSet.has(column.ordering))
      return FailValidation(WorkflowValidationReasons.duplicateColumnOrder);
    orderSet.add(column.ordering);

    if (titleSet.has(column.title.toUpperCase()))
      return FailValidation(WorkflowValidationReasons.duplicateColumnTitle);
    titleSet.add(column.title.toUpperCase());
  }

  return SuccessValidation("Workflow validated successfully.");
}

