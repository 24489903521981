import { useCallback, useState } from "react";
import { Company } from "../Store/CompanySlice";
import { DocumentInfo, getCachedLogoDocumentInfos } from "../Store/DocumentSlice";
import { useAppDispatch } from "./Hooks";
import { CompanyWithLogoUrl } from "../Components/Client/ClientLogo";

export function useGetLogo(){
  const dispatch = useAppDispatch();
  const [companyLogo, setCompanyLogo] = useState<CompanyWithLogoUrl>();

  const FetchLogo = useCallback(async (company: Company | undefined) => {
    let docs: DocumentInfo[] = [];
    try
    {
      docs = await dispatch(getCachedLogoDocumentInfos({})).unwrap();
    }
    catch (e)
    {
      //console.log("unable to load logo: " + (e as Error).message);
    }
    const matchingDoc = docs.find(doc => doc.blobName === company?.logoBlobName);
    if (matchingDoc && company)
      setCompanyLogo({ ...company, docUrl: matchingDoc?.tempUrl });
    else
      setCompanyLogo(undefined);
  }, [dispatch]);

  return {
    FetchLogo,
    companyLogo
  };
}
