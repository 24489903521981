import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Breakpoint } from "@mui/material";
import { useEffect, useState } from "react";

interface AlertDialogProps {
  title: string
  description: string
  descriptionExtra?: string
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  Confirm: () => void
  Cancel: () => void
  cypressData: {
    modal: string
    confirmButton: string
    cancelButton: string
  }
  maxWidth?: false | Breakpoint | undefined
}

export function AlertDialog(props: AlertDialogProps) {

  const [chosen, setChosen] = useState(false);

  useEffect(() => {
    setChosen(false);
  },[props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      maxWidth={props.maxWidth}
      data-cy={props.cypressData.modal}
    >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.description}
        </DialogContentText>
        <DialogContentText>
          {props.descriptionExtra}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-between">
          <Button disabled={chosen} data-cy={props.cypressData.cancelButton} onClick={() => { setChosen(true); props.Cancel() }}>Cancel</Button>
          <Button disabled={chosen} data-cy={props.cypressData.confirmButton} onClick={() => { setChosen(true); props.Confirm() }}>OK</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

