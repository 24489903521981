import { Box, Stack, Button, Divider, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { tableButtonFontSize } from "../Styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { getUser, selectCurrentUser } from "../Store/UserSlice";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { Initiative, MetricsDisplay, selectAllCompanies, upsertInitiativeInfo } from "../Store/CompanySlice";
import GavelIcon from '@mui/icons-material/Gavel';
import { AccountField } from "../Components/AccountField";
import { MakeClone } from "../Services/Cloning";
import { AccountHeader } from "../Components/AccountHeader";
import { AccountActions } from "../Components/AccountActions";
import { AccountDatePicker } from "../Components/AccountDatePicker";
import { ErrorSnackbar, SuccessSnackbar } from "../Services/Snackbars";
import ValidateNewInitiative, { ValidationFailedPrefix } from "../Services/Validation/Validation";
import DecisionDataModal from "../Components/Decisions/DecisionDataModal";
import { plural } from "pluralize";
import { useGetLogo } from "../Hooks/useGetLogo";
import { v4 } from "uuid";
import { TOCSection } from "../Components/TableOfContents";
import { CapitalizeAll } from "../Services/Capitalize";
import { TableOfContentsPageTemplate } from "./TableOfContentsPageTemplate";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { DocumentManagementSection } from "../Components/Documents/DocumentManagementSection";
import { LinkManagementSection } from "../Components/Links/LinkManagementSection";
import { GoToInitiativeDashboard } from "./InitiativeDashboardPage";
import { GoToClientAccountPage } from "./ClientAccountPage";
import { GoToHomePage } from "../App";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { UserManagementSection } from "../Components/User/UserManagementSection";
import { ArticleManagementSection } from "../Components/Articles/ArticleManagementSection";
import { DecisionManagementSection } from "../Components/Decisions/DecisionManagementSection";

export function GoToInitiativeDetailPage(navigate: NavigateFunction, initiativeId: string) {
  let where = "/InitiativeDetail";
  if (initiativeId)
    where += "?initiative=" + initiativeId;
  navigate(where);
}

export function GoToInitiativeDetailPageToAdd(navigate: NavigateFunction, companyId: string){
  let where = "/InitiativeDetail";
  if (companyId)
    where += "?company=" + companyId;
  navigate(where);
}

export const InitiativeDetailsPageIds = {
  title: "InitiativeDetailsPageTitle",
  description: "InitiativeDetailsPageDescription",
  startDate: "InitiativeDetailsPageStartDate",
  targetDate: "InitiativeDetailsPageTargetDate",
  totalItems: "InitiativeDetailsPageTotalItems",
  saveButton: "InitiativeDetailsPageSaveButton",
  cancelButton: "InitiativeDetailsPageCancelButton",
  throughputButton: "InitiativeDetailsPageThroughputButton"
}

export function InitiativeDetailPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allCompanies = useAppSelector(selectAllCompanies);
  const currentUser = useAppSelector(selectCurrentUser);
  const [queryParameters] = useSearchParams();
  const [isSavingInitiative, setIsSavingInitiative] = useState(false);
  const [isDecisionModalOpen, setIsDecisionModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { sourceCompany, initiativeToEdit, isAddingNewInitiative } = useMemo(() => {
    const queryInitiativeId = queryParameters.get("initiative");
    const queryCompanyId = queryParameters.get("company");
    if (queryInitiativeId)
    {
      for (const company of allCompanies)
      {
        const matchingInitiative = company.initiatives.find(i => i.id === queryInitiativeId);
        if (matchingInitiative)
          return { sourceCompany: company, initiativeToEdit: matchingInitiative, isAddingNewInitiative: false };
      }
    }
    else if (queryCompanyId)
    {
      const matchingCompany = allCompanies.find(company => company.id === queryCompanyId);
      const today = new Date();
      const newInit: Initiative = {
        id: v4(),
        title: "",
        description: "",
        targetDate: today.toISOString(),
        startDate: today.toISOString(),
        totalItems: 1,
        decisions: [],
        links: [],
        isActive: true,
        displayMetrics: {
          completionProbability: true,
          projectedCompletionDate: true,
          itemProjection: true,
          itemsCompleted: true,
          cycleTime: true,
          deliveredItems: true
        }
      };

      return { sourceCompany: matchingCompany, initiativeToEdit: newInit, isAddingNewInitiative: true };
    }

    return { sourceCompany: undefined, initiativeToEdit: undefined, isAddingNewInitiative: false };
  }, [allCompanies, queryParameters]);

  useEffect(() => {
    if (!sourceCompany || !initiativeToEdit)
    {
      ErrorSnackbar(Error());
      if (currentUser)
        GoToHomePage(navigate, currentUser);
    }
  }, [navigate, currentUser, initiativeToEdit, sourceCompany]);

  useEffectOnce(async () => {
    dispatch(getUser({}));
  });

  const [cloneForEditing, setCloneForEditing] = useState<Initiative | undefined>(MakeClone(initiativeToEdit));

  const {
    companyLogo,
    FetchLogo
  } = useGetLogo();

  useEffect(() => {
    FetchLogo(sourceCompany);
  }, [sourceCompany, FetchLogo]);

  const CanSubmitInitiative = useCallback((initiative: Initiative, companyId: string) => {
    const validation = ValidateNewInitiative(initiative, companyId, allCompanies);
    if (validation.success)
      return true;
    else
      ErrorSnackbar(ValidationFailedPrefix + validation.message);

    return false;
  }, [allCompanies]);

  const SubmitInitiative = useCallback(async (initiative: Initiative, companyId: string) => {
    try
    {
      await dispatch(upsertInitiativeInfo({ initiative, companyId })).unwrap();
      return true;
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    return false;
  }, [dispatch]);

  const SaveEdit = useCallback(async () => {
    if (!isSavingInitiative && cloneForEditing)
    {
      const companyId = sourceCompany?.id ?? "";
      if (CanSubmitInitiative(cloneForEditing, companyId))
      {
        setIsSavingInitiative(true);

        const success = await SubmitInitiative(cloneForEditing, companyId);
        if (success)
          SuccessSnackbar("Initiative changes have been saved.");

        GoToClientAccountPage(navigate, sourceCompany?.id);
        setIsSavingInitiative(false);
      }
    }
  }, [isSavingInitiative, cloneForEditing, sourceCompany, CanSubmitInitiative, navigate, SubmitInitiative]);

  const CancelEdit = useCallback(() => {
    GoToClientAccountPage(navigate, sourceCompany?.id)
  }, [navigate, sourceCompany]);

  type MappedMetric = { title: string, propertyName: keyof MetricsDisplay };
  const displayMetrics: (MappedMetric & { show: boolean })[] = useMemo(() => {
    const metricsMapping: MappedMetric[] = [
      {
        title: "Statistical Probability",
        propertyName: "completionProbability"
      },
      {
        title: "Projected Completion Date",
        propertyName: "projectedCompletionDate"
      },
      {
        title: `${CapitalizeAll(sourceCompany?.terms.throughputTerm ?? "Item")} Projection`,
        propertyName: "itemProjection"
      },
      {
        title: `${CapitalizeAll(plural(sourceCompany?.terms.throughputTerm ?? "Item"))} Completed`,
        propertyName: "itemsCompleted"
      },
      {
        title: "Cycle Time",
        propertyName: "cycleTime"
      },
      {
        title: `Delivered ${CapitalizeAll(plural(sourceCompany?.terms.throughputTerm ?? "Item"))}`,
        propertyName: "deliveredItems"
      }
    ];
    return metricsMapping.map(m => {
      return { title: m.title, propertyName: m.propertyName, show: cloneForEditing?.displayMetrics[m.propertyName] ?? false };
    })
  }, [cloneForEditing, sourceCompany]);

  const allMetricsSelected = useMemo(() => {
    for (const metric of displayMetrics)
    {
      if (!metric.show)
        return false;
    }
    return true;
  }, [displayMetrics]);

  const initiativeProperties = useMemo(() => {
    return (cloneForEditing && initiativeToEdit && currentUser &&
      <>
        <Typography sx={{ fontWeight: "bold" }}>{CapitalizeAll(sourceCompany?.terms.initiativeTerm ?? "Initiative")} Info</Typography>
        <Stack gap={3}>
          <AccountField label="Title" cypressData={InitiativeDetailsPageIds.title} value={cloneForEditing.title} onChange={(e) => setCloneForEditing({ ...cloneForEditing, title: e.target.value })} />
          <AccountField label="Description" multiline
            cypressData={InitiativeDetailsPageIds.description}
            value={cloneForEditing.description}
            onChange={(e) => setCloneForEditing({ ...cloneForEditing, description: e.target.value })} />
          <Stack direction="row" gap={4} alignItems="center" sx={{ marginBottom: 2 }}>
            <AccountDatePicker label="Start Date" cypressData={InitiativeDetailsPageIds.startDate} dateString={cloneForEditing.startDate} setDateString={(value) => setCloneForEditing({ ...cloneForEditing, startDate: value })} />
            <Typography variant="h4" textAlign="center">-</Typography>
            <AccountDatePicker label="Target Date" cypressData={InitiativeDetailsPageIds.targetDate} dateString={cloneForEditing.targetDate} setDateString={(value) => setCloneForEditing({ ...cloneForEditing, targetDate: value })} />
          </Stack>
          <AccountField label="Total Items" cypressData={InitiativeDetailsPageIds.totalItems} value={Number.isNaN(cloneForEditing.totalItems) ? "0" : cloneForEditing.totalItems.toString()} onChange={(e) => setCloneForEditing({ ...cloneForEditing, totalItems: Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) })} />
          <FormGroup sx={{ marginLeft: 1 }}>
            <FormControlLabel control={<Checkbox checked={cloneForEditing.isActive} onChange={(e) => setCloneForEditing({ ...cloneForEditing, isActive: e.target.checked })} />} label="Active" />
          </FormGroup>
          <Box>
            <Typography sx={{ marginY: 2 }} variant="body2">Metrics Display</Typography>
            <FormGroup sx={{ marginLeft: 1 }}>
              <FormControlLabel label="Show All"
                control={
                  <Checkbox checked={allMetricsSelected} onChange={e => {
                    const newDisplayMetrics = MakeClone(cloneForEditing.displayMetrics);
                    for (const metric of displayMetrics)
                      newDisplayMetrics[metric.propertyName] = e.target.checked;
                    setCloneForEditing({ ...cloneForEditing, displayMetrics: newDisplayMetrics });
                  }} />
                }
              />
              <Divider />
              {displayMetrics.map((metric, index) =>
                <FormControlLabel key={index} label={metric.title}
                  control={
                    <Checkbox checked={metric.show}
                      onChange={e => {
                        const newDisplayMetrics = MakeClone(cloneForEditing.displayMetrics);
                        newDisplayMetrics[metric.propertyName] = e.target.checked;
                        setCloneForEditing({ ...cloneForEditing, displayMetrics: newDisplayMetrics });
                      }}
                    />
                  }
                />
              )}
            </FormGroup>
          </Box>
          <AccountActions cypressData={{ saveButton: InitiativeDetailsPageIds.saveButton, cancelButton: InitiativeDetailsPageIds.cancelButton }} Cancel={CancelEdit} Save={SaveEdit} isSaving={isSavingInitiative} />
        </Stack>
      </>
    )
  }, [sourceCompany, cloneForEditing, initiativeToEdit, currentUser, allMetricsSelected, displayMetrics, isSavingInitiative, CancelEdit, SaveEdit]);

  const initiativeUsers = useMemo(() => 
  (sourceCompany && initiativeToEdit &&
  <UserManagementSection company={sourceCompany} initiative={initiativeToEdit}/>
  ), [sourceCompany, initiativeToEdit]);

  const initiativeDecisions = useMemo(() =>
  (sourceCompany && initiativeToEdit &&
    <DecisionManagementSection initiative={initiativeToEdit} company={sourceCompany}/>
    ), [sourceCompany, initiativeToEdit]);

  const initiativeDocuments = useMemo(() => (!isAddingNewInitiative && sourceCompany && initiativeToEdit && currentUser &&
    <DocumentManagementSection articleWithDocsId={undefined} company={sourceCompany} initiative={initiativeToEdit} currentUser={currentUser} userHasUploadPermission={true} />
  ), [isAddingNewInitiative, sourceCompany, initiativeToEdit, currentUser]);

  const initiativeLinks = useMemo(() => (!isAddingNewInitiative && sourceCompany && initiativeToEdit && currentUser &&
    <LinkManagementSection company={sourceCompany} initiative={initiativeToEdit} currentUser={currentUser} userHasUploadPermission={true} />
  ), [isAddingNewInitiative, sourceCompany, initiativeToEdit, currentUser]);

  const initiativeArticles = useMemo(() => (!isAddingNewInitiative && sourceCompany && initiativeToEdit && currentUser &&
    <ArticleManagementSection company={sourceCompany} initiative={initiativeToEdit} currentUser={currentUser} userHasUploadPermission={true}/>
    ), [isAddingNewInitiative, sourceCompany, initiativeToEdit, currentUser]);

  const sections: TOCSection[] = useMemo(() => {
    return [
      {
        title: "View Dashboard",
        content: undefined,
        onClick: () => { if (currentUser && initiativeToEdit) GoToInitiativeDashboard(navigate, currentUser.id, initiativeToEdit.id) },
        endIcon: <OpenInNew />,
        hideWhenAdding: true
      },
      {
        title: `Copy ${CapitalizeAll(sourceCompany?.terms.initiativeTerm ?? "Initiative")}`,
        content: undefined,
        onClick: () => { if (initiativeToEdit) navigate(`/InitiativeCopy?initiative=${initiativeToEdit.id}`) },
        endIcon: <OpenInNew />,
        hideWhenAdding: true
      },
      {
        title: `${CapitalizeAll(sourceCompany?.terms.initiativeTerm ?? "Initiative")} Info`,
        content: initiativeProperties,
        hideTitleHeader: true
      },
      {
        title: `${CapitalizeAll(sourceCompany?.terms.userTerm ?? "User")} Info`,
        content: initiativeUsers,
        hideWhenAdding: true
      },
      {
        title: CapitalizeAll(plural(sourceCompany?.terms.decisionTerm ?? "Decision")),
        content: initiativeDecisions,
        hideWhenAdding: true
      },
      {
        title: CapitalizeAll(plural(sourceCompany?.terms.documentTerm ?? "Document")),
        content: initiativeDocuments,
        hideWhenAdding: true
      },
      {
        title: CapitalizeAll(plural(sourceCompany?.terms.linkTerm ?? "Link")),
        content: initiativeLinks,
        hideWhenAdding: true
      },
      {
        title: CapitalizeAll(plural("Article")),
        content: initiativeArticles,
        hideWhenAdding: true
      }
    ].filter(s => (!isAddingNewInitiative || !s.hideWhenAdding))
  }, [
    currentUser, sourceCompany, initiativeToEdit, initiativeUsers, initiativeDecisions, 
    initiativeDocuments, initiativeLinks, initiativeProperties, initiativeArticles, isAddingNewInitiative, navigate
  ]);

  return (
    <TableOfContentsPageTemplate sections={sections}>
      {currentUser &&
        <AccountHeader currentUser={currentUser} companyLogo={companyLogo} subtitle={initiativeToEdit ? (isAddingNewInitiative ? <p>Adding new initiative</p> : <p>Editing {sourceCompany?.terms.initiativeTerm} : <span className="text-2xl font-bold">{initiativeToEdit.title}</span></p>) : ""} />
      }
    </TableOfContentsPageTemplate>
  )
}