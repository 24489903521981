import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { IntegrityTheme, TableHeaderStyle, defaultRowStyle, tableButtonFontSize, tableCellFontSize, tableHeaderFontSize } from "../Styles";
import { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { Company, IntegrityId, selectAllCompanies } from "../Store/CompanySlice";
import { CompanyFilter } from "../Services/Filters";
import { selectCurrentUser } from "../Store/UserSlice";
import { AddButton } from "../Components/AddButton";
import { SearchBar } from "../Components/SearchBar";
import { Paginator } from "../Components/Paginator";
import { usePaginator } from "../Hooks/usePaginator";
import { ActiveRadioSet } from "../Components/ActiveRadioSet";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { getLogoDocumentInfos } from "../Store/DocumentSlice";
import { ClientLogo, CompanyWithLogoUrl } from "../Components/Client/ClientLogo";
import { useNavigate } from "react-router-dom";
import { GoToClientDashboard } from "./ClientDashboardPage";

export const ClientPageIds = {
  modal: "clientPageModal",
  closeModalButton: "clientPageModalCloseModalButton",
  addClientButton: "clientPageAddButton",
  editClientButton: "clientPageEditButton",
  saveClientChangesButton: "clientPageSaveChangesButton",
  cancelClientChangesButton: "clientPageCancelChangesButton",
  deleteButton: "clientPageDeleteButton",
  keywordFilter: "clientPageKeywordFilter",
  table: "clientPageTable",
  name: "clientPageName",
  terms: "clientPageTerms",
  isActive: "clientPageIsActive",
  radioIds: {
    active: "clientPageRadioActive",
    inactive: "clientPageRadioInactive",
    all: "clientPageRadioAll",
  },
  actionMenu: {
    menuButton: "clientPageMenuButton",
    articleButton: "clientPageArticlebutton",
    documentButton: "clientPageDocumentButton"
  }
}

export function ClientPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allCompanies = useAppSelector(selectAllCompanies);
  const currentUser = useAppSelector(selectCurrentUser);
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [displayCompanies, setDisplayCompanies] = useState<Company[]>([]);
  const [companyLogos, setCompanyLogos] = useState<CompanyWithLogoUrl[]>([]);
  const [companyLogoNames, setCompanyLogoNames] = useState<string[]>([]);

  const [radioValue, setRadioValue] = useState("active");
  const [nonIntegrityCompanies, setNonIntegrityCompanies] = useState<Company[]>(allCompanies.filter(a => a.id !== IntegrityId));

  const { PaginateItems, ...paginator } = usePaginator();

  const isIntegrityUser = currentUser?.companyId === IntegrityId;
  const isReadOnly = !isIntegrityUser || !currentUser?.isAdmin;
  const isIntegrityAdmin = isIntegrityUser && currentUser?.isAdmin;

  useEffect(() => {
    const filteredCompanies = CompanyFilter(nonIntegrityCompanies, radioValue).filter(c => c.name?.toUpperCase().includes(searchedKeyword.toUpperCase()));
    filteredCompanies.sort((a: Company, b: Company) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
    const paginatedCompanies = PaginateItems(filteredCompanies);
    setNonIntegrityCompanies(nonIntegrityCompanies);
    setDisplayCompanies(paginatedCompanies);
  }, [allCompanies, nonIntegrityCompanies, radioValue, PaginateItems, searchedKeyword]);

  useEffectOnce(() => {
    if (!isIntegrityUser)
      setRadioValue("all");
  })

  const GetLogos = useCallback(async (companies: Company[]) => {
    if (companies.map(company => company.logoBlobName).length > 0)
    {
      const currentLogoCount = companyLogos.length;
      setTimeout(async () => {
        try
        {
          const docs = await dispatch(getLogoDocumentInfos({})).unwrap();
          const pairs: CompanyWithLogoUrl[] = [];
          for (const company of companies)
          {
            const matchingDoc = docs.find(doc => doc.blobName === company.logoBlobName);
            if (matchingDoc)
            {
              const newPair = { ...company, docUrl: matchingDoc.tempUrl };
              pairs.push(newPair);
            }
          }
          setCompanyLogos(pairs);
        }
        catch (e)
        {
          console.log((e as Error).message);
        }
      }, currentLogoCount ? 2000 : 1);
    }
  }, [dispatch, companyLogos.length]);

  useEffect(() => {
    let newLogoDetected = false;
    let names: string[] = [];
    for (const company of allCompanies)
    {
      if (company.logoBlobName)
      {
        names.push(company.logoBlobName);
        if (!companyLogoNames.find(name => name === company.logoBlobName) || companyLogoNames.length === 0)
          newLogoDetected = true;
      }
    }

    if (newLogoDetected)
    {
      setCompanyLogoNames(names);
      GetLogos(allCompanies);
    }

  }, [GetLogos, allCompanies, companyLogoNames]);

  function HandleAddEmptyClient() {
    GoToClientAccountPage();
  }

  function GoToClientAccountPage(companyId?: string) {
    let where = '/ClientAccount';
    if (companyId)
      where += "?company=" + companyId;
    navigate(where);
  }

  return (
    <>
      <ThemeProvider theme={IntegrityTheme}>
        {currentUser &&
          <div className="mx-[2%] mb-2">
            <div className="flex flex-row justify-content:space-between">
              <Grid container sx={{
                display: 'flex',
                placeItems: 'center',
                flexDirection: 'row',
                p: 1,
                mt: 2,
                mb: 1,
                ml: 2,
                mr: 2,
                borderRadius: 1,
              }}>
                <Grid item xs={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
                  {isIntegrityUser &&
                    <SearchBar cypressData={ClientPageIds.keywordFilter} placeholder="Keyword in Name" value={searchedKeyword} setValue={setSearchedKeyword} />}
                </Grid>
                <Grid item xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center"
                  }}>
                  {isIntegrityUser &&
                    <ActiveRadioSet cypressData={ClientPageIds.radioIds} name="clientPage" setRadioValue={setRadioValue} listItems={nonIntegrityCompanies} filterFunc={CompanyFilter} />
                  }
                </Grid>
                {allCompanies.length !== 0 && !isReadOnly &&
                  <Grid item xs={3}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                    <AddButton cypressData={ClientPageIds.addClientButton} HandleClick={() => HandleAddEmptyClient()} />
                  </Grid>
                }
              </Grid>
            </div>
            <div className="col-span-1 py-2">
              <TableContainer elevation={12} component={Paper}>
                <Table className="table-auto w-full outline outline-3 bg-gray-100" size="small">
                  <colgroup>
                    <col style={{ width: '10vw' }} />
                    <col style={{ width: '14vw' }} />
                    <col style={{ width: '10vw' }} />
                    {!isReadOnly &&
                      <col style={{ width: '20vw' }} />
                    }
                    <col style={{ width: '12vw' }} />
                    <col style={{ width: '10vw' }} />
                  </colgroup>
                  <TableHead className="outline outline-1">
                    <TableRow sx={{
                      borderBottom: "2px solid black",
                      "& th": {
                        fontSize: tableHeaderFontSize,
                        fontWeight: "bold",
                        fontFamily: "Arial, Helvetica"
                      }
                    }}>
                      <TableHeaderStyle>Company Logo</TableHeaderStyle>
                      <TableHeaderStyle>Name</TableHeaderStyle>
                      {isIntegrityAdmin &&
                        <TableHeaderStyle>Initiative Count</TableHeaderStyle>
                      }
                      <TableHeaderStyle>Client Terminology</TableHeaderStyle>
                      <TableHeaderStyle>Active Status</TableHeaderStyle>
                      <TableHeaderStyle></TableHeaderStyle>
                    </TableRow>
                  </TableHead>
                  <TableBody data-cy={ClientPageIds.table}>
                    {displayCompanies.map((displayItem: Company, key: number) => {
                      return (
                        <TableRow className={defaultRowStyle} sx={{
                          borderBottom: "1px solid black",
                          "& td": {
                            fontSize: tableCellFontSize,
                            fontFamily: "Arial, Helvetica",
                            color: "#21345b"
                          }
                        }}
                          key={key}
                        >
                          <TableCell>
                            <ClientLogo company={companyLogos.find(company => company.id === displayItem.id) ?? displayItem} length={100} />
                          </TableCell>
                          <TableCell data-cy={ClientPageIds.name}>{displayItem.name}</TableCell>
                          {isIntegrityAdmin &&
                            <TableCell>{displayItem.initiatives.length}</TableCell>
                          }
                          <TableCell data-cy={ClientPageIds.terms}>
                            {Object.values(displayItem.terms)?.filter(v => v !== "").join(", ")}
                          </TableCell>
                          <TableCell data-cy={ClientPageIds.isActive}>{displayItem.isActive ? "Active" : "Inactive"}</TableCell>
                          <TableCell>
                            {!isReadOnly &&
                              <IconButton title="Edit Client" color="primary" data-cy={ClientPageIds.editClientButton} onClick={() => GoToClientAccountPage(displayItem.id)}>
                                <EditIcon sx={{ fontSize: tableButtonFontSize }} />
                              </IconButton>
                            }
                              <IconButton title="View Dashboard" color="primary" onClick={() => { GoToClientDashboard(navigate, currentUser.id, displayItem.id) }}>
                                <VisibilityIcon sx={{ fontSize: tableButtonFontSize }} />
                              </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {currentUser?.companyId === IntegrityId && currentUser.isAdmin &&
                <Paginator paginator={{ PaginateItems, ...paginator }} />
              }
            </div>
          </div>
        }
      </ThemeProvider>
    </>
  )
}
