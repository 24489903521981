import LoadingButton from "@mui/lab/LoadingButton";
import { CancelButton } from "../Styles";

interface AccountActionsProps {
  isSaving: boolean
  cypressData: {
    cancelButton: string
    saveButton: string
  }
  Cancel: () => void
  Save: () => void
}

export function AccountActions(props: AccountActionsProps) {

  return (
    <div className="flex justify-end my-4 mx-10">
      <CancelButton data-cy={props.cypressData.cancelButton} sx={{ width: "25%", marginRight: 4 }} disabled={props.isSaving} size="small" onClick={() => props.Cancel()}>Cancel</CancelButton>
      <LoadingButton data-cy={props.cypressData.saveButton} sx={{ width: "40%" }} loading={props.isSaving} variant="contained" onClick={() => props.Save()}>Save Changes</LoadingButton>
    </div>
  )
}
