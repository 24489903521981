import { useEffect, useMemo, useState } from "react";
import InitiativesTable from "../Components/Initiative/InitiativesTable";
import { useAppSelector } from "../Hooks/Hooks";
import { Company, IntegrityId, selectAllCompanies } from "../Store/CompanySlice";
import { Grid } from "@mui/material";
import { selectCurrentUser } from "../Store/UserSlice";
import { SearchBar } from "../Components/SearchBar";
import { InitiativeFilter } from "../Services/Filters";
import { ActiveRadioSet } from "../Components/ActiveRadioSet";

export const InitiativeDisplayRadioIds = {
  all: "initDisplayShowAll",
  active: "initDisplayShowActive",
  inactive: "initDisplayShowInactive"
}

export const InitiativesPageIds = {
  addInitiativeButton: "initiativesPageAddInitiativeButton",
  companyNameFilter: "initiativesPageCompanyNameFilter",
  initiativeTitleFilter: "initiativesPageInitiativeTitleFilter"
}

export enum initPageStateEnum {
  start,
  edit,
  add
}

export default function InitiativesPage() {
  const companiesFromStore = useAppSelector(selectAllCompanies);
  const [allCompanies, setAllCompanies] = useState<Company[]>(companiesFromStore);
  const currentUser = useAppSelector(selectCurrentUser);

  const [searchedComp, setSearchedComp] = useState("");
  const [searchedInit, setSearchedInit] = useState("");
  const [addInitiative, setAddInitiative] = useState(false);
  const [state, setState] = useState(initPageStateEnum.start);
  const [radioValue, setRadioValue] = useState('active');
  const isEditing = state !== initPageStateEnum.start;

  const allInitiatives = useMemo(() => allCompanies.flatMap(c => c.initiatives), [allCompanies]);

  useEffect(() => {
    setAllCompanies(companiesFromStore);
  }, [companiesFromStore]);

  return (
    <>
      <div className="mx-[2%] mb-2">
        <div className="flex flex-row justify-content:space-between">
          {currentUser?.isAdmin &&
            <Grid container sx={{
              display: 'flex',
              flexDirection: 'row',
              placeItems: 'center',
              mt: 2,
              mb: 1,
              ml: 2,
              mr: 2,
              borderRadius: 1
            }}>
              <Grid item xs={4} sx={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
                {currentUser?.companyId === IntegrityId &&
                  <SearchBar cypressData={InitiativesPageIds.companyNameFilter} placeholder="Company Name" value={searchedComp} setValue={setSearchedComp} disabled={isEditing} />
                }
                <SearchBar cypressData={InitiativesPageIds.initiativeTitleFilter} placeholder="Initiative Name" value={searchedInit} setValue={setSearchedInit} disabled={isEditing} />
              </Grid>
              <Grid item xs={6} sx={{
                display: 'flex',
                justifyContent: "center"
              }}>
                <ActiveRadioSet cypressData={InitiativeDisplayRadioIds} name="initiativesPage" setRadioValue={setRadioValue} listItems={allInitiatives} filterFunc={InitiativeFilter} disabled={isEditing} />
              </Grid>
              <Grid item xs={2} sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              </Grid>
            </Grid>
          }
        </div>
        {allCompanies.length > 0 && currentUser &&
          <InitiativesTable addInitiative={addInitiative} currentUser={currentUser} companyList={allCompanies} radioStatus={radioValue}
            setAddInitiative={setAddInitiative}
            searchedComp={searchedComp} setSearchedComp={setSearchedComp}
            searchedInit={searchedInit} setSearchedInit={setSearchedInit}
            state={state} setState={setState}
          />}
      </div>
    </>
  )
}