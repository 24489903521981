import { useCallback, useState } from "react";
import { MakeClone } from "../Services/Cloning";
import { MakeDateString } from "../Services/DateHelpers";

export interface SortConfig {
  key: string
  direction: 'asc' | 'desc'
}

type SortItems = {
  UpdateSortConfig: (key: string) => void
  SortItems: <T extends Record<string, any>>(itemsToSort: T[]) => T[]
  sortConfig: SortConfig
}

export function useSorter(defaultKey?: string): SortItems {
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: defaultKey ?? '', direction: 'desc' });

  function UpdateSortConfig(key: string) {
    let direction: 'desc' | 'asc' = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc')
      direction = 'asc';

    setSortConfig({ key: key, direction: direction });
  }

  const SortItems = useCallback(<T extends Record<string, any>,>(itemsToSort: T[]) => {
    const sortedItemsClone = MakeClone(itemsToSort);

    sortedItemsClone.sort((a: T, b: T) => {
      let aValue;
      let bValue;

      if (typeof (a[sortConfig.key]) !== "string" && typeof (a[sortConfig.key]) !== "number")
        aValue = MakeDateString(a[sortConfig.key]);
      if (typeof (b[sortConfig.key]) !== "string" && typeof (b[sortConfig.key]) !== "number")
        bValue = MakeDateString(b[sortConfig.key]);
      else
      {
        aValue = a[sortConfig.key];
        bValue = b[sortConfig.key];
      }
      
      if (aValue === undefined)
        aValue = -0.001;
      if (bValue === undefined)
        bValue = -0.001;

      if (typeof (aValue) === 'string')
        aValue = aValue.toUpperCase();
      if (typeof (bValue) === 'string')
        bValue = bValue.toUpperCase();

      if (aValue > bValue)
        return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue < bValue)
        return sortConfig.direction === 'asc' ? 1 : -1;

      return 0;
    });
    return sortedItemsClone;
  }, [sortConfig]);

  return {
    UpdateSortConfig,
    SortItems,
    sortConfig
  }
}

