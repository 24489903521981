import { CircularProgress, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ThemeProvider, Typography } from '@mui/material';
import { Company, Initiative, upsertInitiativeInfo } from '../../Store/CompanySlice';
import { TableHeaderStyle, blueTheme, defaultRowStyle } from '../../Styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentInfo, getDocumentInfos, updateDocument, uploadDocument } from '../../Store/DocumentSlice';
import { useAppDispatch } from '../../Hooks/Hooks';
import { User } from '../../Store/UserSlice';
import EditIcon from "@mui/icons-material/Edit";
import InventoryIcon from '@mui/icons-material/Inventory';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { MakeClone } from '../../Services/Cloning';
import { UpdateDocumentRequest, UploadDocumentRequest } from '../../Services/DocumentService';
import { DocumentActions } from './DocumentActions';
import { SearchBar } from '../SearchBar';
import { useSorter } from '../../Hooks/useSorter';
import { SortLabel } from '../SortLabel';
import { ErrorSnackbar, SuccessSnackbar } from '../../Services/Snackbars';
import { plural } from 'pluralize';
import { AddButton } from '../AddButton';
import { DocumentUpsertModal } from './DocumentUpsertModal';

export const DocumentManagementSectionIds = {
  upsertModal: {
    modal: "documentManagementSectionModal",
    closeModalButton: "documentManagementSectionCloseModalButton",
    newShortDescription: "documentManagementSectionNewShortDescription",
    newLongDescription: "documentManagementSectionNewLongDescription",
    saveButton: "documentManagementSectionSaveButton",
    cancelButton: "documentManagementSectionCancelButton",
    fileUpload: {
      chooseFileButton: "documentManagementSectionChooseNewDocButton",
      submitButton: "documentManagementSectionNewDocSubmitButton",
      fileInput: "documentManagementSectionFileInput",
    }
  },
  addDocumentButton: "documentManagementSectionAddDocument",
  editDocumentButton: "documentManagementSectionEditDocument",
  editCurrentIsDefault: "documentManagementSectionEditisDefault",
  keywordFilter: "documentManagementSectionKeywordFilter",
}

interface DocumentManagementSectionProps {
  articleWithDocsId: string | undefined
  company: Company
  initiative: Initiative | undefined
  currentUser: User
  userHasUploadPermission: boolean
}

export function DocumentManagementSection(props: DocumentManagementSectionProps) {
  const dispatch = useAppDispatch();
  const [docInfos, setDocInfos] = useState<DocumentInfo[]>([]);
  const [documentToEdit, setDocumentToEdit] = useState<DocumentInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDefaultDoc, setLoadingDefaultDoc] = useState<DocumentInfo>();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [showArchived, setShowArchived] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);
  const [isUpserting, setIsUpserting] = useState(false);

  const currentInitiativeId = props.initiative?.id;
  const defaultDocId = props.initiative?.defaultDocumentId;

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GetData = useCallback(async () => {
    setIsLoading(true);
    try
    {
      const docs = await dispatch(getDocumentInfos({ companyId: props.company.id, initiativeId: currentInitiativeId, articleId: props.articleWithDocsId })).unwrap();
      setDocInfos(docs);
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoading(false);
  }, [dispatch, props.articleWithDocsId, props.company.id, currentInitiativeId]);

  const defaultDocIndex = useMemo(() => {
    const foundIndex = docInfos.findIndex(doc => doc.blobName === defaultDocId);
    if (foundIndex >= 0)
      return foundIndex;
  }, [docInfos, defaultDocId]);

  function LeaveEditMode() {
    setDocumentToEdit(undefined);
    setIsDocModalOpen(false);
  }

  function EnterEditMode(doc: DocumentInfo) {
    setDocumentToEdit(doc);
    setIsDocModalOpen(true);
  }

  function EnterAddMode() {
    LeaveEditMode();
    setIsDocModalOpen(true);
  }

  async function HandleSaveEdit(editedDocument: DocumentInfo) {
    if (!isUpserting)
    {
      setIsUpserting(true);
      let documentClone = MakeClone(editedDocument);
      if (documentClone)
      {
        const docRequest: UpdateDocumentRequest = {
          blobName: documentClone.blobName,
          shortDescription: documentClone.metadata.shortDescription,
          longDescription: documentClone.metadata.longDescription
        }
        try
        {
          await dispatch(updateDocument(docRequest)).unwrap();
          SuccessSnackbar(`${props.company.terms.documentTerm} updated successfully.`);
        }
        catch (e)
        {
          ErrorSnackbar(e);
        }
      }
      await GetData();
      LeaveEditMode();
      setIsUpserting(false);
    }
  }

  function HandleCancelEdit() {
    LeaveEditMode();
  }

  useEffect(() => {
    GetData();
  }, [GetData]);

  async function HandleChangeDefaultDoc(doc: DocumentInfo) {
    if (props.initiative !== undefined)
    {
      const newDocId = props.initiative.defaultDocumentId === doc.blobName ? "" : doc.blobName;
      const editedInitiative: Initiative = {
        ...props.initiative,
        defaultDocumentId: newDocId
      }
      setLoadingDefaultDoc(doc);
      try
      {
        await dispatch(upsertInitiativeInfo({ initiative: editedInitiative, companyId: props.company.id })).unwrap();
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      setLoadingDefaultDoc(undefined);
    }
  }

  async function UploadFile(file: File | null, shortDescription: string, longDescription: string) {
    if (!file)
    {
      ErrorSnackbar("Cannot upload; no file was provided.");
      return;
    }

    try
    {
      setIsUpserting(true);
      const request: UploadDocumentRequest = {
        file,
        companyId: props.company.id,
        shortDescription,
        longDescription,
        initiativeId: props.initiative?.id
      };
      await dispatch(uploadDocument(request)).unwrap();

      setTimeout(async () => {
        await GetData();
        SuccessSnackbar("File uploaded successfully!");
        LeaveEditMode();
        setIsUpserting(false);
      }, 2000);

    }
    catch (e)
    {
      ErrorSnackbar(e);
      setIsUpserting(false);
      LeaveEditMode();
    }
  }

  const filteredDocs = useMemo(() => {
    const upperKeyword = searchedKeyword.toUpperCase();
    return docInfos.filter(doc =>
      (doc.metadata.fileName.toUpperCase().includes(upperKeyword)
        || doc.metadata.shortDescription?.toUpperCase().includes(upperKeyword)
        || doc.metadata.longDescription?.toUpperCase().includes(upperKeyword)
      )
      &&
      (doc.metadata.archived === showArchived
        || showArchived
      )
    );
  }, [docInfos, searchedKeyword, showArchived]);

  const sortedDocs = useMemo(() => {
    return SortItems(filteredDocs);
  }, [filteredDocs, SortItems]);

  useEffect(() => {
    const maxPages = Math.max(Math.ceil(filteredDocs.length / rowsPerPage), 1);
    if (page > maxPages - 1)
      setPage(maxPages - 1);
  }, [filteredDocs, rowsPerPage, page]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedDocs = sortedDocs.slice(indexOfFirstItem, indexOfLastItem);

  const showDefaultDocColumn = props.initiative && !props.articleWithDocsId && props.userHasUploadPermission;

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4">
        {isLoading && docInfos.length === 0 &&
          <div className="flex justify-center w-full h-full my-2">
            <CircularProgress size={20} color={"warning"} />
          </div>
        }
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            {docInfos.length !== 0 ?
              <SearchBar cypressData={DocumentManagementSectionIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => { setSearchedKeyword(value); setPage(0) }} />
              :
              <Typography variant="body2">There are no {plural(props.company.terms.documentTerm.toLowerCase())} to display</Typography>
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            {props.userHasUploadPermission &&
              <AddButton cypressData={DocumentManagementSectionIds.addDocumentButton} HandleClick={() => { EnterAddMode() }} />
            }
            {/*
                <Button onClick={() => setShowArchived(!showArchived)} variant={showArchived ? "contained" : "outlined"} startIcon={<InventoryIcon />}>{showArchived ? "Hide" : "Show"} Archived</Button>
              */}
          </Grid>
        </Grid>
        {docInfos.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    {showDefaultDocColumn &&
                      <TableHeaderStyle></TableHeaderStyle>
                    }
                    <TableHeaderStyle>File Name</TableHeaderStyle>
                    <TableHeaderStyle>Title</TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="createdOn" heading="Created On" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="lastModified" heading="Last Modified" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    slicedDocs.map((doc, index) => {
                      const documentActions = <DocumentActions docInfo={doc}
                        canDelete={props.userHasUploadPermission}
                        canArchive={props.userHasUploadPermission && !doc.metadata.archived}
                        canUnarchive={props.userHasUploadPermission && doc.metadata.archived}
                        RefreshData={GetData}
                        disabled={!!loadingDefaultDoc}
                        documentTerm={props.company.terms.documentTerm}
                      />;

                      const fileNameDisplay =
                        <Stack direction="row" alignItems="center" gap={1}>
                          {doc.metadata.archived &&
                            <InventoryIcon sx={{ fontSize: 18 }} titleAccess='Archived' />
                          }
                          <Typography variant="caption">{doc.metadata.fileName}</Typography>
                        </Stack>;

                      const isDefault = index === defaultDocIndex;
                      const defaultDocSetter =
                        <IconButton title={isDefault ? "Unmark as default" : "Mark as default"} onClick={() => HandleChangeDefaultDoc(doc)} disabled={!!loadingDefaultDoc}>
                          {loadingDefaultDoc?.blobName === doc.blobName ?
                            <CircularProgress color="warning" size="20px" />
                            :
                            (isDefault ?
                              <StarIcon />
                              :
                              <StarBorderIcon />
                            )
                          }
                        </IconButton>;

                      return (
                        <TableRow className={defaultRowStyle}
                          sx={{
                            borderBottom: "1px solid black",
                            "& td": {
                              fontFamily: "Arial, Helvetica",
                              color: "#21345b"
                            }
                          }}
                          key={index}
                        >
                          {showDefaultDocColumn &&
                            <TableCell>{defaultDocSetter}</TableCell>
                          }
                          <TableCell>{fileNameDisplay}</TableCell>
                          <TableCell>
                            <Typography variant="caption">{doc.metadata.shortDescription}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{doc.createdOn ? new Date(doc.createdOn).toLocaleString() : "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{doc.lastModified ? new Date(doc.lastModified).toLocaleString() : "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            {props.userHasUploadPermission &&
                              <IconButton data-cy={DocumentManagementSectionIds.editDocumentButton} disabled={!!loadingDefaultDoc} onClick={() => EnterEditMode(doc)} color="primary">
                                <EditIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            }
                            {documentActions}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={sortedDocs.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        }
        <DocumentUpsertModal company={props.company} documentToEdit={documentToEdit} isOpen={isDocModalOpen} handleClose={() => setIsDocModalOpen(false)} isUpserting={isUpserting}
          Upload={UploadFile} Save={HandleSaveEdit} Cancel={HandleCancelEdit} cypressData={DocumentManagementSectionIds.upsertModal}
        />
      </div>
    </ThemeProvider>
  )
}
