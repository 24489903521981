import { IntegrityTheme, TableHeaderStyle, defaultRowStyle, tableButtonFontSize, tableCellFontSize, tableHeaderFontSize } from "../Styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { User, getUser, selectAllUsers, selectCurrentUser } from "../Store/UserSlice";
import { IntegrityId } from "../Store/CompanySlice";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { Grid, IconButton, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEditUser } from "../Hooks/useEditUser";
import { AddButton } from "../Components/AddButton";
import { SearchBar } from "../Components/SearchBar";
import { Paginator } from "../Components/Paginator";
import { usePaginator } from "../Hooks/usePaginator";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { UserAvatar, UserWithAvatarUrl } from "../Components/User/UserAvatar";
import { getAvatarDocumentInfos } from "../Store/DocumentSlice";
import { ErrorSnackbar } from "../Services/Snackbars";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { GoToUserAccountPage } from "./UserAccountPage";

export function GoToIntegrityPage(navigate: NavigateFunction, initialPage?: number) {
  let where = "/Integrity";
  if (initialPage !== undefined)
    where += "?initialPage=" + initialPage.toString();
  navigate(where);
}

export const IntegrityPageIds = {
  modal: "adminEditUserModal",
  closeModalButton: "adminEditUserModalCloseModalButton",
  email: "adminEditUserEmail",
  password: "adminEditUserPassword",
  initiativeIds: "adminEditUserInitiativeIds",
  name: "adminEditUserName",
  phone: "adminEditUserPhone",
  role: "adminEditUserRole",
  isAdmin: "adminEditIsAdmin",
  isActive: "adminEditUserIsActive",
  addButton: "adminEditUserAddButton",
  editButton: "adminEditUserEditButton",
  saveChangesButton: "adminEditUserSaveChangesButton",
  cancelChangesButton: "adminEditUserCancelChangesButton",
  deleteButton: "adminEditUserDeleteButton",
  keywordFilter: "adminEditUserKeywordFilter",
  table: "adminEditUserTable"
}

export function IntegrityPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const allUsers = useAppSelector(selectAllUsers);
  const currentUser = useAppSelector(selectCurrentUser);

  const [userAvatars, setUserAvatars] = useState<UserWithAvatarUrl[]>([]);
  const [userAvatarNames, setUserAvatarNames] = useState<string[]>([]);

  const initialPage = useMemo(() => {
    const queryPage = queryParameters.get("initialPage") ?? "1";
    return parseInt(queryPage);
  }, [queryParameters]);

  const {
    SetupEditUser,
    usersList,
    searchedKeyword,
    setSearchedKeyword
  } = useEditUser(userAvatars);

  const { PaginateItems, ...paginator } = usePaginator({ initialPage });

  useEffectOnce(() => {
    async function GetDevs() {
      try
      {
        if (currentUser?.isAdmin && currentUser.companyId === IntegrityId)
          await dispatch(getUser({})).unwrap();
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
    }

    GetDevs();
  });

  useEffect(() => {
    const filteredUsers = allUsers.filter(user => user.companyId === IntegrityId).filter(u => u.email.toUpperCase().includes(searchedKeyword.toUpperCase()) || u.name?.toUpperCase().includes(searchedKeyword.toUpperCase()));
    filteredUsers.sort((a: User, b: User) => a.email.toUpperCase() > b.email.toUpperCase() ? 1 : -1);
    const paginatedUsers = PaginateItems(filteredUsers);
    SetupEditUser(paginatedUsers);

  }, [allUsers, PaginateItems, searchedKeyword, SetupEditUser]);

  const GetAvatars = useCallback(async (users: User[]) => {
    if (users.map(user => user.avatarBlobName).length > 0)
    {
      const currentAvatarCount = userAvatars.length;
      setTimeout(async () => {
        try
        {
          const docs = await dispatch(getAvatarDocumentInfos({})).unwrap();
          const pairs: UserWithAvatarUrl[] = [];
          for (const user of users)
          {
            const matchingDoc = docs.find(doc => doc.blobName === user.avatarBlobName);
            if (matchingDoc)
            {
              const newPair = { ...user, docUrl: matchingDoc.tempUrl };
              pairs.push(newPair);
            }
          }
          setUserAvatars(pairs);
        }
        catch (e)
        {
          console.log((e as Error).message);
        }
      }, currentAvatarCount ? 2000 : 1);
    }
  }, [dispatch, userAvatars.length]);

  useEffect(() => {
    let newAvatarDetected = false;
    let names: string[] = [];
    for (const user of allUsers)
    {
      if (user.avatarBlobName)
      {
        names.push(user.avatarBlobName);
        if (!userAvatarNames.find(name => name === user.avatarBlobName) || userAvatarNames.length === 0)
          newAvatarDetected = true;
      }
    }

    if (newAvatarDetected)
    {
      setUserAvatarNames(names);
      GetAvatars(allUsers);
    }

  }, [GetAvatars, allUsers, userAvatarNames]);

  return (
    <ThemeProvider theme={IntegrityTheme}>
      <div className="mx-[2%] mb-2">
        {allUsers.length !== 0 && currentUser?.isAdmin &&
          <div className="flex flex-row justify-content:space-between">
            <Grid container sx={{
              display: 'flex',
              placeItems: 'center',
              flexDirection: 'row',
              p: 1,
              mt: 2,
              mb: 1,
              ml: 2,
              mr: 2,
              borderRadius: 1,
            }}>
              <Grid item xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}>
                <SearchBar cypressData={IntegrityPageIds.keywordFilter} placeholder="Keyword in Name or Email" value={searchedKeyword} setValue={setSearchedKeyword} />
              </Grid>
              <Grid item xs={6} sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
                {currentUser?.isAdmin &&
                  <AddButton cypressData={IntegrityPageIds.addButton} HandleClick={() => GoToUserAccountPage(navigate, undefined, paginator.page)} />
                }
              </Grid>
            </Grid>
          </div>
        }
        <div className="col-span-1 py-2">
          <TableContainer elevation={12} component={Paper}>
            <Table className="table-auto w-full outline outline-3 bg-gray-100">
              <colgroup>
                <col style={{ width: '8vw' }} />
                <col style={{ width: '12vw' }} />
                <col style={{ width: '12vw' }} />
                <col style={{ width: '12vw' }} />
                <col style={{ width: '12vw' }} />
                <col style={{ width: '6vw' }} />
                <col style={{ width: '6vw' }} />
                <col style={{ width: '8vw' }} />
              </colgroup>
              <TableHead className="outline outline-1">
                <TableRow sx={{
                  borderBottom: "2px solid black",
                  "& th": {
                    fontSize: tableHeaderFontSize,
                    fontWeight: "bold",
                    fontFamily: "Arial, Helvetica"
                  }
                }}>
                  <TableHeaderStyle>Avatar</TableHeaderStyle>
                  <TableHeaderStyle>Name</TableHeaderStyle>
                  <TableHeaderStyle>Email</TableHeaderStyle>
                  <TableHeaderStyle>Phone</TableHeaderStyle>
                  <TableHeaderStyle>Role</TableHeaderStyle>
                  <TableHeaderStyle>Admin Status</TableHeaderStyle>
                  <TableHeaderStyle>Active Status</TableHeaderStyle>
                  <TableHeaderStyle>Edit User</TableHeaderStyle>
                </TableRow>
              </TableHead>
              <TableBody data-cy={IntegrityPageIds.table}>
                {usersList.map((displayItem: User, key: number) => {
                  return (
                    <TableRow className={defaultRowStyle} sx={{
                      borderBottom: "1px solid black",
                      "& td": {
                        fontSize: tableCellFontSize,
                        fontFamily: "Arial, Helvetica",
                        color: "#21345b"
                      }
                    }}
                      key={key}
                    >
                      <TableCell><UserAvatar user={userAvatars.find(user => user.id === displayItem.id) ?? displayItem} /></TableCell>
                      <TableCell data-cy={IntegrityPageIds.name}>{displayItem.name}</TableCell>
                      <TableCell data-cy={IntegrityPageIds.email}>{displayItem.email}</TableCell>
                      <TableCell data-cy={IntegrityPageIds.phone}>{displayItem.phoneNumber}</TableCell>
                      <TableCell data-cy={IntegrityPageIds.role}>{displayItem.role}</TableCell>
                      <TableCell data-cy={IntegrityPageIds.isAdmin}>{displayItem.isAdmin ? "Admin" : "User"}</TableCell>
                      <TableCell data-cy={IntegrityPageIds.isActive}>{displayItem.isActive ? "Active" : "Inactive"}</TableCell>
                      <TableCell>
                        <IconButton data-cy={IntegrityPageIds.editButton} onClick={() => GoToUserAccountPage(navigate, displayItem.id, paginator.page)}>
                          <EditIcon sx={{ fontSize: tableButtonFontSize }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paginator paginator={{ PaginateItems, ...paginator }} />
        </div>
      </div>
    </ThemeProvider >
  )
}