import { Box, Card, Grid, Typography } from "@mui/material";
import { IntegrityTheme } from "../Styles";
import { ClientLogo, CompanyWithLogoUrl } from "./Client/ClientLogo";
import { User } from "../Store/UserSlice";

interface AccountHeaderProps {
  currentUser: User
  subtitle?: string | JSX.Element
  companyLogo?: CompanyWithLogoUrl
  editableCompanyLogo?: JSX.Element
  logoSize?: number
  endIcon?: JSX.Element
}

export function AccountHeader(props: AccountHeaderProps) {
  return (
    <Card variant="outlined" sx={{ marginTop: 3, marginBottom: 1, padding: 3, borderRadius: 2, backgroundColor: IntegrityTheme.palette.lightBlue.main, borderColor: IntegrityTheme.palette.veryLightGray.main }}>
      <Grid container columns={24} sx={{ display: "flex", placeItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
        <Grid item xs={12}>
          <Typography variant="h4" color={"white"} textAlign="left">
            <b>Hello, {props.currentUser.name || props.currentUser.email}</b>
          </Typography>
          <Typography variant="h6" color={"white"} textAlign="left">
            {props.subtitle ? props.subtitle : "Welcome to the Integrity Client Portal"}
          </Typography>
        </Grid>
        <Grid item xs={11} justifyContent="end" sx={{ display: "flex" }}>
          <Box>
            {props.editableCompanyLogo ?
              props.editableCompanyLogo
              :
              props.companyLogo &&
              <ClientLogo company={props.companyLogo} length={props.logoSize ?? 150} />
            }
          </Box>
        </Grid>
        <Grid item xs={1}>
          {props.endIcon}
        </Grid>
      </Grid>
    </Card>
  )
}
