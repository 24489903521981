import { useCallback, useEffect, useState } from "react";
import { Article } from "../../Store/ArticleSlice"
import { ListItem, Stack, ListItemIcon, ListItemText, Box, Button, Typography } from "@mui/material";
import { ReadOnlyRichText } from "../Quill/ReadOnlyRichText";
import { DashboardDocumentsData } from "../Documents/DashboardDocumentsData";
import ArticleIcon from "@mui/icons-material/Article";
import { ErrorSnackbar } from "../../Services/Snackbars";
import { DocumentInfo, getDocumentInfos } from "../../Store/DocumentSlice";
import { useAppDispatch } from "../../Hooks/Hooks";
import { Company, Initiative } from "../../Store/CompanySlice";
import { plural } from "pluralize";

interface DashboardArticleItemProps {
    article: Article
    company: Company
    initiative?: Initiative
}

export function DashboardArticleItem(props: DashboardArticleItemProps) {
    const articleText = props.article.text.slice(0, 175);
    const truncatedArticleText = articleText.slice(0, articleText.lastIndexOf(" "));
    const [docInfos, setDocInfos] = useState<DocumentInfo[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();

    const GetData = useCallback(async () => {
        try
        {
          const docs = await dispatch(getDocumentInfos({ companyId: props.company.id, articleId: props.article.id })).unwrap();
          setDocInfos(docs);
        }
        catch (e)
        {
          ErrorSnackbar(e);
        }
      }, [dispatch, props.article, props.company.id]);

    useEffect(() => {
        GetData();
    }, [GetData]);
    
    return (
        <>
          <ListItem sx={{
            marginLeft: 1,
            marginTop: 1
          }}>
            <Stack direction="row" spacing={.5} width="100%">
              <ListItemIcon>
                <ArticleIcon sx={{ color: "#69D5C3", fontSize: 25, marginTop: 1.5 }} />
              </ListItemIcon>
              <ListItemText>
              <Typography variant="body1"><b>{props.article.title}</b></Typography>
            {isOpen ?
                <Box sx={{ marginBottom: 4 }}>
                <ReadOnlyRichText cypressData={""} text={props.article.text} />
                {docInfos.length > 0 &&
                    <><Typography sx={{marginTop: 4}} variant="body1"><b>Related {plural(props.company.terms.documentTerm)}</b></Typography>
                    <DashboardDocumentsData docInfos={docInfos} getDocs={GetData} canDelete={false} canArchive={false} documentTerm={"Article Documents"}></DashboardDocumentsData></>}
                {(props.article.text.length > 175 || docInfos.length > 0) &&
                <Button sx={{ color: "blue", fontSize: 16 }} style={{ position: 'absolute', right: 25, textTransform: "none" }} onClick={() => setIsOpen(false)}>Less...</Button>}
                </Box>
                :
                <Box sx={{ marginBottom: 4 }}>
                <ReadOnlyRichText cypressData={""} text={props.article.text.length > 175 ? truncatedArticleText + "..." : props.article.text} />
                {(props.article.text.length > 175 || docInfos.length > 0) &&
                <Button sx={{ color: "blue", fontSize: 16, height: 10 }} style={{ position: 'absolute', right: 25, textTransform: "none" }} onClick={() => setIsOpen(true)}>More...</Button>}
                </Box>
            }
              </ListItemText>
            </Stack>
          </ListItem>
        </>
      )
    }