import { useEffect, useMemo, useState } from "react";
import { MakeClone } from "../Services/Cloning";

type CheckboxSelect = {
  SelectAll: () => string[]
  SelectThis: (id: string) => string[]
  IsChecked: (id: string) => boolean
  isSelectAll: boolean
}

interface CheckboxInfo {
  id: string
  checked: boolean
}

// change allBoxIds to be CheckboxInfo[] if we don't want it to always default to unchecked for all boxes
export function useCheckboxSelect(allBoxIds: string[]): CheckboxSelect {
  const [boxes, setBoxes] = useState<CheckboxInfo[]>([]);

  const isSelectAll = useMemo(() => {
    for (const box of boxes)
      if (!box.checked)
        return false;
    return true;
  }, [boxes]);

  function SelectAll() {
    const boxesClone = MakeClone(boxes);
    for (const box of boxesClone)
      box.checked = !isSelectAll;
    setBoxes(boxesClone);
    return boxesClone.filter(box => box.checked).map(box => box.id);
  }

  function SelectThis(id: string) {
    const boxesClone = MakeClone(boxes);
    const matchingBox = boxesClone.find(box => box.id === id);
    if (matchingBox)
      matchingBox.checked = !matchingBox.checked;
    setBoxes(boxesClone);
    return boxesClone.filter(box => box.checked).map(box => box.id);
  }

  function IsChecked(id: string){
    return !!boxes.find(b => b.id === id && b.checked);
  }

  useEffect(() => {
    if(allBoxIds.length !== boxes.length)
    {
    const newBoxes = allBoxIds.map(id => {
      const matchingBox = boxes.find(b => b.id === id);
      if (matchingBox)
        return { id, checked: matchingBox.checked };
      return { id, checked: false };
    });
      setBoxes(newBoxes);
  }
  }, [allBoxIds, boxes]);

  return {
    isSelectAll,
    SelectThis,
    SelectAll,
    IsChecked
  }
}
