import { Grid, Box, Stack } from "@mui/material";
import { StepperActionButtons } from "../../StepperActionButtons";
import { StepWizardChildProps } from "react-step-wizard";

interface BaseStepStackProps {
  disableNext?: boolean
  children: JSX.Element | JSX.Element[] | undefined
}

export function BaseStepStack(props: Partial<StepWizardChildProps> & BaseStepStackProps) {

  return (
    <Grid container sx={{
      display: 'flex',
      placeItems: 'start',
      flexDirection: 'row'
    }}>
      <Grid item xs={12} sx={{ marginX: 4, marginY: 2 }}>
        <Stack gap={2}>
          {props.children}
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <StepperActionButtons {...props} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
