import { Grid, CircularProgress, Button } from "@mui/material";
import { DateInfo } from "../../Services/CompanyService";
import { useAppDispatch, useAppSelector } from "../../Hooks/Hooks";
import { AddButton } from "../AddButton";
import { BaseModal } from "../BaseModal";
import { useEffect, useMemo, useRef, useState } from "react";
import { Company, Initiative, IntegrityId } from "../../Store/CompanySlice";
import { MakeClone } from "../../Services/Cloning";
import { v4 as UuidV4 } from "uuid";
import { Article, clearArticles, getArticle, selectAllArticles, upsertArticle } from "../../Store/ArticleSlice";
import { ValidateArticle, ValidationFailedPrefix } from "../../Services/Validation/Validation";
import { User } from "../../Store/UserSlice";
import { SearchBar } from "../SearchBar";
import { DocumentManagementModal } from "../Documents/DocumentManagementModal";
import { DateToDateInfo } from "../../Services/DateHelpers";
import { ArticleCard } from "./ArticleCard";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { ErrorSnackbar, SuccessSnackbar } from "../../Services/Snackbars";

enum stateEnum {
  start,
  edit,
  add,
  delete
}

export const ArticleModalIds = {
  modal: "articleModal",
  addButton: "articleModalAddButton",
  closeModalButton: "articleModalCloseModalButton",
  keywordFilter: "articleModalKeywordFilter",
  editButton: "articleModalEditButton",
  deleteButton: "articleModalDeleteButton",
  saveChangesButton: "articleModalSaveChangesButton",
  cancelChangesButton: "articleModalCancelChangesButton",
  title: "articleModalTitle",
  text: "articleModalText",
  updatedDate: "articleModalUpdatedDate",
  updatedBy: "articleModalUpdatedBy",
  editTitle: "articleEditTitle",
  editText: "articleEditText",
  editUpdatedBy: "editUpdatedBy",
  editUpdatedDate: "editUpdatedDate",
  isIntegrityOnly: "articleModalIntegrityOnly",
  companyId: "articleModalCompanyId",
  initiativeId: "articleInitiativeId",
  grid: "articleModalGrid",
  documents: "articleModalDocuments",
  articleListItem: "articleModalListItem",
  allArticlesButton: "articleModalAllArticlesButton"
}

interface ArticleDataProps {
  company: Company
  initiative: Initiative | undefined
  isOpen: boolean
  currentUser: User
  HandleClose: () => void
}

export default function ArticleDataModal(props: ArticleDataProps) {
  const dispatch = useAppDispatch();
  const allArticles = useAppSelector(selectAllArticles);
  const today = new Date();
  const todayInfo: DateInfo = { month: today.getMonth() + 1, day: today.getDate(), year: today.getFullYear() }

  const [modalState, setModalState] = useState(stateEnum.start);
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentText, setCurrentText] = useState("");
  const [isIntegrityOnly, setIsIntegrityOnly] = useState(false);
  const [selectedInitiative, setSelectedInitiative] = useState<Initiative>();
  const [selectedCompany, setSelectedCompany] = useState<Company>(props.company);
  const [articleToEdit, setArticleToEdit] = useState<Article>();

  const isEditing = modalState === stateEnum.edit || modalState === stateEnum.add;
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [baseArticles, setBaseArticles] = useState<Article[]>([]);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState("");

  const titleRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setBaseArticles(allArticles);
  }, [allArticles]);

  const selectedArticle = useMemo(() => {
    return baseArticles.find(a => a.id === selectedArticleId);
  }, [selectedArticleId, baseArticles]);

  useEffect(() => {
    if (props.isOpen)
      CallDispatch();

    if (props.initiative)
      setSelectedInitiative(props.initiative);

    setSelectedCompany(props.company);
    setIsSubmitting(false);
    LeaveEditMode();
    setSelectedArticleId("");

    async function CallDispatch() {
      setLoadingModal(true);
      try
      {
        await dispatch(getArticle({ companyId: props.company.id, initiativeId: props.initiative?.id, userCompanyId: props.currentUser.companyId })).unwrap();
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      setLoadingModal(false);
    }

  }, [props.isOpen, props.initiative, props.company, props.currentUser.companyId, dispatch]);

  const filteredArticles = useMemo(() => {
    return baseArticles.filter(a => a.title.toUpperCase().includes(searchedKeyword.toUpperCase()))
      .sort((a, b) => a.title > b.title ? 1 : -1);
  }, [searchedKeyword, baseArticles]);

  function CloseModalAndReset() {
    dispatch(clearArticles());
    props.HandleClose();
  }

  function HandleEmptyArticle() {
    if (modalState === stateEnum.start)
    {
      let articlesClone = MakeClone(baseArticles);
      const newId = UuidV4();
      const newArticle: Article = {
        id: newId,
        title: "",
        text: "",
        updatedBy: "",
        updatedDate: todayInfo,
        companyId: selectedCompany.id,
        initiativeId: selectedInitiative?.id,
        isIntegrityOnly: false
      };
      articlesClone.unshift(newArticle);
      setBaseArticles(articlesClone);
      //setSearchedKeyword("");
      EnterEditMode(newId, articlesClone, true);
      setSelectedArticleId(newId);
    }
    else
      ErrorSnackbar("Save current changes before adding a new article.");
  }

  function EnterEditMode(id: string, articles: Article[], isNew: boolean) {
    if (!isEditing)
    {
      let currentArticle = articles.find(u => u.id === id);
      if (currentArticle)
      {
        setModalState(isNew ? stateEnum.add : stateEnum.edit);
        setArticleToEdit(currentArticle);
        setCurrentTitle(currentArticle.title);
        setCurrentText(currentArticle.text);
        setIsIntegrityOnly(currentArticle.isIntegrityOnly);

        setTimeout(() => {
          titleRef.current?.focus();
        }, 1);
      }
    }
  }

  async function HandleEditArticle(id: string, newTitle: string, newText: string, newIsIntegrityOnly: boolean) {
    let selectedArticlesClone: Article[] = MakeClone(baseArticles);
    let newArticle = selectedArticlesClone.find(a => a.id === id);
    if (newArticle)
    {
      newArticle.title = newTitle;
      newArticle.text = newText;
      newArticle.updatedBy = props.currentUser.name ?? props.currentUser.email;
      newArticle.updatedDate = DateToDateInfo(new Date());
      newArticle.isIntegrityOnly = newIsIntegrityOnly;

      setIsSubmitting(true);
      await SubmitArticle(newArticle);
      setIsSubmitting(false);
    }
  }

  async function SubmitArticle(article: Article): Promise<boolean> {

    const validation = ValidateArticle(article);
    if (!validation.success)
    {
      ErrorSnackbar(ValidationFailedPrefix + validation.message);
      return false;
    }

    try
    {
      await dispatch(upsertArticle({ articles: [article] })).unwrap();
      SuccessSnackbar("Article changes have been saved.");
      LeaveEditMode();
      return true;
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }

    return false;
  }

  function HandleCancelEdit() {
    if (modalState === stateEnum.add && articleToEdit)
    {
      const articlesClone = baseArticles.filter(a => a.id !== articleToEdit.id);
      setBaseArticles(articlesClone);
    }
    LeaveEditMode();
  }

  function LeaveEditMode() {
    setArticleToEdit(undefined);
    setModalState(stateEnum.start);
  }

  function HandleClose() {
    setDocumentModalOpen(false);
  }

  function HandleGoToAllArticles() {
    setSelectedArticleId("");
    HandleCancelEdit();
  }

  return (
    <>
      <BaseModal
        open={props.isOpen}
        onClose={() => CloseModalAndReset()}
        cypressData={{ modal: ArticleModalIds.modal, closeModalButton: ArticleModalIds.closeModalButton }}
        title="Articles"
        subtitle={selectedCompany?.name + (selectedInitiative ? " - " + selectedInitiative.title : "")}
        maxWidth={false}
      >
        {!loadingModal &&
          <div className="mx-1 mb-2">
            {!selectedArticle &&
              <>
                <div className="flex flex-row justify-content:space-between mb-4">
                  <Grid container sx={{
                    display: 'flex',
                    placeItems: 'center',
                    flexDirection: 'row',
                    p: 1,
                    mt: 2,
                    mb: 1,
                    ml: 2,
                    mr: 2,
                    borderRadius: 1,
                  }}>
                    <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}>
                      {allArticles.length !== 0 &&
                        <SearchBar cypressData={ArticleModalIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={setSearchedKeyword} />
                      }
                    </Grid>
                    <Grid item xs={4} sx={{
                      display: "flex",
                      justifyContent: "center"
                    }}>
                      {isSubmitting &&
                        <CircularProgress color={"warning"} />
                      }
                      {!isSubmitting && filteredArticles.length === 0 &&
                        <p className="m-2 p-2 text-2xl font-bold">There are no articles to display</p>
                      }
                    </Grid>
                    {props.currentUser.companyId === IntegrityId &&
                      <Grid item xs={4} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}>
                        <AddButton cypressData={ArticleModalIds.addButton} HandleClick={() => HandleEmptyArticle()} disabled={isEditing} />
                      </Grid>
                    }
                  </Grid>
                </div>
                <Grid container sx={{
                  display: 'flex',
                  justifyContent: "space-between",
                  placeItems: 'center',
                  flexDirection: 'row'
                }}
                  spacing={2}
                  data-cy={ArticleModalIds.grid}>
                  {
                    filteredArticles.map((displayItem, key) => {
                      return (
                        <Grid item xs={12} key={key}>
                          <Button data-cy={ArticleModalIds.articleListItem} onClick={() => { setSelectedArticleId(displayItem.id) }} startIcon={<NewspaperIcon />}>{displayItem.title}</Button>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </>
            }
            {selectedArticle &&
              <>
                <Button data-cy={ArticleModalIds.allArticlesButton} onClick={() => HandleGoToAllArticles()} startIcon={<ArrowBackIcon />}>All Articles</Button>
                <ArticleCard article={selectedArticle} currentUser={props.currentUser} isEditingAnyone={isEditing} isEditingMe={isEditing}
                  currentTitle={currentTitle} setCurrentTitle={setCurrentTitle} currentText={currentText} setCurrentText={setCurrentText}
                  isIntegrityOnly={isIntegrityOnly} setIsIntegrityOnly={setIsIntegrityOnly} isSubmitting={isSubmitting}
                  HandleDocButton={() => { setDocumentModalOpen(true); }}
                  HandleStartEdit={() => EnterEditMode(selectedArticle.id, baseArticles, false)}
                  HandleCancelEdit={() => HandleCancelEdit()}
                  HandleSubmit={() => HandleEditArticle(selectedArticle.id, currentTitle, currentText, isIntegrityOnly)}
                  autofocusRef={titleRef}
                />
              </>
            }
          </div>
        }
        {loadingModal &&
          <div className="flex justify-center">
            <CircularProgress color="warning" />
          </div>
        }
      </BaseModal>
      <DocumentManagementModal articleWithDocsId={selectedArticleId} userHasUploadPermission={props.currentUser.companyId === IntegrityId} company={props.company} currentUser={props.currentUser} title={"Related Documentation"} initiative={props.initiative} isOpen={documentModalOpen} HandleClose={HandleClose}/>
    </>
  );
}

