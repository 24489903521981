import { List, ListItem, Checkbox, ListItemText, Divider, Typography, Box, Link } from "@mui/material";
import { plural } from "pluralize";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { useMemo } from "react";
import { useCheckboxSelect } from "../../../Hooks/useCheckboxSelect";
import { MakeClone } from "../../../Services/Cloning";
import { StepWizardChildProps } from "react-step-wizard";
import { BaseStepStack } from "./InitiativeCopyBaseStepStack";

interface LinkStepProps {
  initiativeToCopy: Initiative
  company: Company
  updatedInitiative: Initiative
  setUpdatedInitiative: (value: Initiative) => void
}

export const LinkStep: React.FC<Partial<StepWizardChildProps> & LinkStepProps> = ({ setUpdatedInitiative, ...props }) => {
  const allLinkIds = useMemo(() => {
    return props.initiativeToCopy.links.flatMap(l => l.id);
  }, [props.initiativeToCopy]);

  const {
    isSelectAll,
    IsChecked,
    SelectAll,
    SelectThis
  } = useCheckboxSelect(allLinkIds);

  function HandleCopy(linkId?: string) {
    const newInitiativeClone = MakeClone(props.updatedInitiative);
    if (linkId)
    {
      const checkedIds = SelectThis(linkId);
      if(checkedIds.includes(linkId))
      {
        const matchingLink = props.initiativeToCopy.links.find(d => d.id === linkId);
        if(matchingLink)
          newInitiativeClone.links.push(MakeClone(matchingLink));
      }
      else
      {
        const removalIndex = newInitiativeClone.links.findIndex(d => d.id === linkId);
        if(removalIndex > -1)
          newInitiativeClone.links.splice(removalIndex, 1);
      }
    }
    else
    {
      const checkedIds = SelectAll();
      if (checkedIds.length === allLinkIds.length)
        newInitiativeClone.links = MakeClone(props.initiativeToCopy.links);
      else
        newInitiativeClone.links = [];
    }
    setUpdatedInitiative(newInitiativeClone);
  }

  return (
    <BaseStepStack {...props}>
      {props.initiativeToCopy.links.length > 0 ?
        <>
          <ListItem disablePadding>
            <ListItemText sx={{ fontWeight: "bold" }}>
              <Checkbox sx={{ marginX: 2 }} checked={isSelectAll} onChange={() => HandleCopy()} />
              Select All</ListItemText>
          </ListItem>
          <Divider />
          <Box>
            {props.initiativeToCopy.links.map((link, index) => {
              const check = IsChecked(link.id);
              return (
                <List key={index}>
                  <ListItem disablePadding>
                    <ListItemText sx={{ alignText: "center" }}>
                      <Checkbox sx={{ marginX: 2 }} value={link.title} checked={check} onChange={() => HandleCopy(link.id)} />
                      <Link href={link.url} target="_blank" rel="noopener">
                        {link.title}
                      </Link>
                    </ListItemText>
                  </ListItem>
                </List>
              );
            })}
          </Box>
        </>
        :
        <Typography>There are no {plural(props.company.terms.linkTerm.toLowerCase())} on the source {props.company.terms.initiativeTerm.toLowerCase()}.</Typography>
      }
    </BaseStepStack>
  );
};