import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, TablePagination } from "@mui/material";
import { tableCellFontSize, TableHeaderStyle } from "../../Styles";
import { ThroughputData } from "../../Services/CompanyService";
import { useMemo, useState } from "react";
import { Company } from "../../Store/CompanySlice";
import { CapitalizeAll } from "../../Services/Capitalize";

interface ThroughputPreviewProps {
  throughput: ThroughputData[]
  company: Company
}

export function CompanyThroughputPreview(props: ThroughputPreviewProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentThroughputList = useMemo(() => props.throughput.slice(indexOfFirstItem, indexOfLastItem), [props.throughput, indexOfFirstItem, indexOfLastItem]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="w-full outline outline-3 bg-gray-100">
          <TableHead className="outline outline-1">
            <TableRow sx={{
              borderBottom: "2px solid black",
              "& th": {
                fontSize: tableCellFontSize,
                fontFamily: "Arial, Helvetica"
              }
            }}>
              <TableHeaderStyle>
                Key
              </TableHeaderStyle>
              <TableHeaderStyle>
                Title
              </TableHeaderStyle>
              <TableHeaderStyle>
                Category
              </TableHeaderStyle>
              <TableHeaderStyle>
                {CapitalizeAll(props.company.terms.initiativeTerm)}
              </TableHeaderStyle>
              {props.company.workflow.columns.map((column, index) =>
                <TableHeaderStyle key={index}>
                  {column.title}
                </TableHeaderStyle>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.throughput.length ?
              (
                currentThroughputList.map((data, index) =>
                  <TableRow key={index}>
                    <TableCell>
                      {data.key}
                    </TableCell>
                    <TableCell>
                      {data.title}
                    </TableCell>
                    <TableCell>
                      {(props.company.categories.find(c => c.id === data.categoryId) ?? props.company.categories.find(c => c.id === props.company.defaultCategoryId))?.title ?? "..."}
                    </TableCell>
                    <TableCell>
                      {props.company.initiatives.find(i => i.id === data.initiativeId)?.title ?? ""}
                    </TableCell>
                    {props.company.workflow.columns.map((column, index) => {
                      const date = data.progressDates.find(p => p.workflowColumnId === column.id)?.enterColumnDate;
                      return (
                        <TableCell key={index}>
                          {date ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "..."}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              )
              :
              (
                <>
                  {[0, 1].map(i =>
                    <TableRow key={i}>
                      <TableCell>
                        key{i}
                      </TableCell>
                      <TableCell>
                        title{i}
                      </TableCell>
                      <TableCell>
                        category{i}
                      </TableCell>
                      <TableCell>
                        {props.company.terms.initiativeTerm.toLowerCase()}{i}
                      </TableCell>
                      {props.company.workflow.columns.map(column =>
                        <TableCell key={column.id}>
                          MM/DD/YYYY
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                  <TableRow>
                    {[0, 1, 2, 3].map(i =>
                      <TableCell key={i}>
                        ...
                      </TableCell>
                    )}
                    {props.company.workflow.columns.map(column =>
                      <TableCell key={column.id}>
                        ...
                      </TableCell>
                    )}
                  </TableRow>
                </>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.throughput.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]} />
    </>
  );
}
