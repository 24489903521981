import { Box, Card, Grid, Step, Stepper, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import StepWizard from "react-step-wizard";
import { AccountHeader } from "../Components/AccountHeader";
import { CustomizedConnector, CustomizedStepLabel, IntegrityTheme } from "../Styles";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { User, selectAllUsers, selectCurrentUser } from "../Store/UserSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Initiative, cloneInitiative, selectAllCompanies } from "../Store/CompanySlice";
import { plural } from "pluralize";
import { BasicInformationStep } from "../Components/Initiative/Copy/InitiativeCopyBasicInformation";
import { TeamStep } from "../Components/Initiative/Copy/InitiativeCopyTeam";
import { DecisionStep } from "../Components/Initiative/Copy/InitiativeCopyDecisions";
import { LinkStep } from "../Components/Initiative/Copy/InitiativeCopyLinks";
import { ErrorSnackbar, SuccessSnackbar } from "../Services/Snackbars";
import ValidateNewInitiative, { ValidateUser, ValidationFailedPrefix } from "../Services/Validation/Validation";
import { v4 } from "uuid";
import { MakeClone } from "../Services/Cloning";
import { useEffectOnce } from "../Hooks/useEffectOnce";

export function InitiativeCopyPage() {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const allCompanies = useAppSelector(selectAllCompanies);
  const allUsers = useAppSelector(selectAllUsers);
  const [isSavingInitiative, setIsSavingInitiative] = useState(false);
  const [associatedUserIds, setAssociatedUserIds] = useState<string[]>([]);
  const [updatedInitiative, setUpdatedInitiative] = useState<Initiative>();

  const [queryParameters] = useSearchParams();
  const queryInitiativeId = queryParameters.get("initiative");

  const allInitiatives = useMemo(() => allCompanies.flatMap(c => c.initiatives), [allCompanies]);
  const initiativeToCopy = useMemo(() => {
    if (queryInitiativeId)
    {
      const matchingInitiative = allInitiatives.find(init => init.id === queryInitiativeId);
      if (matchingInitiative)
        return MakeClone(matchingInitiative);
    }
    return undefined;
  }, [queryInitiativeId, allInitiatives]);

  const GoBack = useCallback(() => {
    navigate('/InitiativeDetail' + (queryInitiativeId ? ('?initiative=' + queryInitiativeId) : ""));
  }, [navigate, queryInitiativeId]);

  useEffect(() => {
    if (!initiativeToCopy)
    {
      ErrorSnackbar(Error());
      navigate("/Initiatives");
    }
  }, [initiativeToCopy, navigate]);

  const initiativeUsers = useMemo(() => allUsers.filter(user => user.initiativeRoles.find(role => role.initiativeId === initiativeToCopy?.id)), [initiativeToCopy, allUsers]);

  useEffectOnce(() => {
    if(!initiativeToCopy)
      return;
    
    const newInitiative: Initiative = {
      id: v4(),
      title: initiativeToCopy.title + " (Copy)",
      description: "",
      targetDate: initiativeToCopy.targetDate,
      startDate: initiativeToCopy.startDate,
      totalItems: initiativeToCopy.totalItems,
      decisions: [],
      links: [],
      isActive: true,
      displayMetrics: {
        completionProbability: true,
        projectedCompletionDate: true,
        itemProjection: true,
        itemsCompleted: true,
        cycleTime: true,
        deliveredItems: true
      }
    };
    setUpdatedInitiative(newInitiative);
  });

  const company = useMemo(() => {
    if (!initiativeToCopy)
      return undefined;
    const matchingCompany = allCompanies.find(company => company.initiatives.find(i => i.id === initiativeToCopy.id));
    return matchingCompany;
  }, [allCompanies, initiativeToCopy]);

  const handleStepChange = (e: { activeStep: number }) => {
    setActiveStep(e.activeStep - 1);
  };

  async function HandleSaveCopy() {
    if (!updatedInitiative)
    {
      ErrorSnackbar(new Error());
      return;
    }

    if (isSavingInitiative)
      return;
    setIsSavingInitiative(true);

    const updatedUsers = [];
    for (const userId of associatedUserIds)
    {
      const user = MakeClone(initiativeUsers.find(u => u.id === userId));
      if (user)
      {
        const isTeamMember = user.initiativeRoles.find(r => r.initiativeId === initiativeToCopy?.id)?.isTeamMember ?? false;
        user.initiativeRoles.push({
          initiativeId: updatedInitiative?.id,
          isTeamMember
        });
        updatedUsers.push(user);
      }
    }

    await SaveCopy(updatedInitiative, updatedUsers);
    setIsSavingInitiative(false);
  }

  async function SaveCopy(newInit: Initiative, updatedUsers: User[]) {
    const companyId = company?.id ?? "";
    const initValidation = ValidateNewInitiative(newInit, companyId, allCompanies);
    if (!initValidation.success)
    {
      ErrorSnackbar(ValidationFailedPrefix + initValidation.message);
      return;
    }

    let areUsersValid = true;
    for (const user of updatedUsers)
    {
      const userValidation = ValidateUser(user, allUsers);
      if (!userValidation.success)
        areUsersValid = false;
    }
    if (!areUsersValid)
    {
      ErrorSnackbar(new Error(""));
      return;
    }

    await SubmitInitiative(newInit, companyId, updatedUsers);

    navigate("/Initiatives");
  }

  async function SubmitInitiative(initiative: Initiative, companyId: string, updatedUsers: User[]) {
    try
    {
      await dispatch(cloneInitiative({ 
        sourceInitiativeId: initiativeToCopy?.id ?? "",
        title: initiative.title,
        description: initiative.description,
        teamMemberIds: updatedUsers.map(u => u.id),
        decisionIds: initiative.decisions.map(d => d.id),
        linkIds: initiative.links.map(l => l.id),
        companyId,
      })).unwrap();
      SuccessSnackbar("Initiative copy has been saved.");
      return true;
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    return false;
  }


  function HandleCancelCopy() {
    setUpdatedInitiative(undefined);
    GoBack();
  }

  const stepperProps = {
    HandleCancelCopy,
    HandleSaveCopy
  };

  return (
    <ThemeProvider theme={IntegrityTheme}>
      <Box sx={{ marginX: 8, marginY: 4 }}>
        {currentUser &&
          <AccountHeader currentUser={currentUser} subtitle={"Now copying: " + initiativeToCopy?.title} />
        }
        {initiativeToCopy && company && updatedInitiative &&
          <Grid container sx={{
            display: 'flex',
            justifyContent: "space-between",
            placeItems: 'start',
            flexDirection: 'row'
          }}>
            <Card variant="outlined" sx={{ borderRadius: 2, borderWidth: 0, width: "100%" }}>
              <Stepper connector={<CustomizedConnector />} activeStep={activeStep} sx={{ height: 75, marginX: 4 }} >
                <Step><CustomizedStepLabel>Basic Information</CustomizedStepLabel></Step>
                <Step><CustomizedStepLabel>{company.terms.teamTerm}</CustomizedStepLabel></Step>
                <Step><CustomizedStepLabel>{plural(company.terms.decisionTerm)}</CustomizedStepLabel></Step>
                <Step><CustomizedStepLabel>{plural(company.terms.linkTerm)}</CustomizedStepLabel></Step>
              </Stepper>
              <StepWizard onStepChange={handleStepChange}>
                <BasicInformationStep {...stepperProps} initiativeToCopy={initiativeToCopy} company={company} updatedInitiative={updatedInitiative} setUpdatedInitiative={setUpdatedInitiative} />
                <TeamStep {...stepperProps} initiativeUsers={initiativeUsers} initiativeToCopy={initiativeToCopy} company={company} updatedInitiative={updatedInitiative} setAssociatedUserIds={setAssociatedUserIds} />
                <DecisionStep {...stepperProps} initiativeToCopy={initiativeToCopy} company={company} updatedInitiative={updatedInitiative} setUpdatedInitiative={setUpdatedInitiative} />
                <LinkStep {...stepperProps} initiativeToCopy={initiativeToCopy} company={company} updatedInitiative={updatedInitiative} setUpdatedInitiative={setUpdatedInitiative} />
              </StepWizard>
            </Card>
          </Grid>
        }
      </Box>
    </ThemeProvider>
  )
}
