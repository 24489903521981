import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useRef } from "react";

interface FileUploadProps {
  file: File | null
  setFile: (value: File | null) => void
  isUploading: boolean
  UploadFile: () => Promise<void>
  cypressData: {
    chooseFileButton: string
    submitButton: string
    fileInput: string
  }
  accept?: string
  invalidFile?: boolean
}

export function FileUpload(props: FileUploadProps) {
  const fileRef = useRef<HTMLInputElement>(null);

  function CanUpload(myFile: File | null): myFile is File {
    return (myFile !== null && !props.isUploading && !props.invalidFile);
  }

  function HandleChooseFiles(files: FileList | null) {
    if (files)
      props.setFile(files[0]);
  }

  async function HandleUpload() {
    if (CanUpload(props.file))
    {
      try
      {
        await props.UploadFile();
      }
      catch (e)
      {
        console.log((e as Error).message);
      }
    }
  }

  return (
    <Box sx={{  }}>
      <Stack sx={{ minWidth: 0 }} direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Stack sx={{ minWidth: 0 }} direction="row" alignItems="center" gap={2}>
          <Button data-cy={props.cypressData.chooseFileButton} style={{ outlineColor: 'blue' }} variant="outlined" onClick={() => fileRef.current?.click()} disabled={props.isUploading}>
            Choose File
          </Button>
          <Typography noWrap textOverflow="ellipsis">{props.file?.name ?? "No file selected"}</Typography>
        </Stack>
        <Button data-cy={props.cypressData.submitButton} style={{ outlineColor: 'blue' }} variant="contained" disabled={!CanUpload(props.file)} onClick={() => HandleUpload()}>
          {props.isUploading ? <CircularProgress size={24} color={"warning"} /> : "Upload"}
        </Button>
      </Stack>
      <input type="file" className="hidden"
        onChange={(e) => HandleChooseFiles(e.target.files)}
        ref={fileRef}
        data-cy={props.cypressData.fileInput}
        accept={props.accept}
      />
    </Box>
  )
}
