import { Box, Typography } from "@mui/material";
import { tableCellFontSize } from "../Styles";

export function LoginHeader() {
  return (
    <>
      <Box sx={{ backgroundSize: "cover", backgroundImage: "url('https://static.wixstatic.com/media/4f8b60_e0f29b247b224ddb96c3f898426d1a35~mv2.jpg/v1/fill/w_869,h_422,al_c,lg_1,q_85,enc_auto/4f8b60_e0f29b247b224ddb96c3f898426d1a35~mv2.jpg')" }}>
        <Box className="flex justify-center" sx={{ backgroundColor: "rgb(33, 53, 91, 0.5)" }}>
          <Typography variant="h5" color={"white"} textAlign="center" sx={{ marginY: 3, opacity: 1 }}>
            The software you need,<br />built by a team you can trust.
          </Typography>
        </Box>
      </Box>
      <div className="h-fit ml-30 mr-30 py-4 px-5 rounded-lg bg-white">
        <p className={`w-full text-center text-[#21355B] text-${tableCellFontSize}`}>
          If you are looking for the Integrity Inspired Solutions website, please go&nbsp;
          <a className="text-blue-600 visited:text-[#00C4FF] underline" href="https://www.integrityinspired.com/">here.</a>
        </p>
      </div>
    </>
  )
}