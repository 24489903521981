import { Company } from "../Store/CompanySlice";

export type InitiativeUserPair = { userId: string, initiativeId: string, dateModified?: string };
export const INITIATIVE_USER_PAIR = "lastInitiativeOnDashboardPage";

export function GetInitiativeUserPairs() {
  const pairsString = localStorage.getItem(INITIATIVE_USER_PAIR);
  if (pairsString)
  {
    const pairs: InitiativeUserPair[] = JSON.parse(pairsString);
    return pairs;
  }
  return [];
}

export function UpdateInitiativeUserPairs(userId: string, initiativeId: string) {
  const pair: InitiativeUserPair = { userId, initiativeId, dateModified: new Date().toISOString() };
  const pairs = GetInitiativeUserPairs();
  const matchingIndex = pairs.findIndex(p => p.userId === pair.userId);
  if (matchingIndex >= 0)
    pairs.splice(matchingIndex, 1, pair);
  else
    pairs.push(pair);
  localStorage.setItem(INITIATIVE_USER_PAIR, JSON.stringify(pairs));
}

export function GetMatchingInitiativeAndCompany(userId: string, allCompanies: Company[]) {
  let recentInitiativeId = "";
  const pairs = GetInitiativeUserPairs();
  const matchingPair = pairs.find(pair => pair.userId === userId);
  if (matchingPair)
    recentInitiativeId = matchingPair.initiativeId;

  let company = allCompanies.find(c => c.initiatives.find(i => i.id === recentInitiativeId));
  let initFromCompany = company?.initiatives.find(init => init.id === recentInitiativeId);
  if (!initFromCompany)
  {
    for (const comp of allCompanies)
    {
      if (initFromCompany)
        break;

      for (const init of comp.initiatives)
      {
        if (init.isActive)
        {
          initFromCompany = comp.initiatives.at(0);
          company = comp;
        }
      }
    }
  }
  return { selectedCompany: company, selectedInitiative: initFromCompany };
}
