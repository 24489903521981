import { Article } from "../../Store/ArticleSlice";
import { Company, Initiative } from "../../Store/CompanySlice";
import { User } from "../../Store/UserSlice";
import { DateInfo, DecisionData, LinkData } from "../CompanyService";
import { MakeDate } from "../DateHelpers";

export const ValidationFailedPrefix = 'Validation Failed: ';
export type Validation = { message: string, success: boolean };

export function FailValidation(message: string): Validation {
  return { success: false, message };
}

export function SuccessValidation(message: string): Validation {
  return { success: true, message };
}

export const InitiativeValidationReasons = {
  emptyTitle: "Cannot leave title blank.",
  emptyTotalItems: "Cannot leave total items blank.",
  startDateAfterTargetDate: "Start date must come before target completion date.",
  negativeOrZeroTotalItems: "Total items must be a positive value.",
  emptyCompany: "A company must be selected.",
  duplicateInitiativeTitle: "Initiative names must be unique."
}

export default function ValidateNewInitiative(initiative: Initiative, companyId: string, allCompanies: Company[]): Validation {
  if (!initiative.title)
    return { success: false, message: InitiativeValidationReasons.emptyTitle };
  if (!initiative.totalItems && initiative.totalItems !== 0)
    return { success: false, message: InitiativeValidationReasons.emptyTotalItems };

  const targetDateValidation = ValidateDateString(initiative.targetDate);
  if (!targetDateValidation.success && targetDateValidation.message !== DateValidationReasons.emptyDate)
    return targetDateValidation;

  const startDateValidation = ValidateDateString(initiative.startDate);
  if (!startDateValidation.success && startDateValidation.message !== DateValidationReasons.emptyDate)
    return startDateValidation;

  if (targetDateValidation.success && startDateValidation.success && new Date(initiative.startDate).getTime() > new Date(initiative.targetDate).getTime())
    return { success: false, message: InitiativeValidationReasons.startDateAfterTargetDate };

  if (initiative.totalItems < 0)
    return { success: false, message: InitiativeValidationReasons.negativeOrZeroTotalItems };

  const matchingCompany = allCompanies.find(company => company.id === companyId);
  if (!matchingCompany)
    return { success: false, message: InitiativeValidationReasons.emptyCompany };

  const matchingInitiative = matchingCompany.initiatives.find(init => init.title === initiative.title && init.id !== initiative.id);
  if (matchingInitiative)
    return { success: false, message: InitiativeValidationReasons.duplicateInitiativeTitle }

  return { success: true, message: "Successfully validated; all good!" };
}

export const DateValidationReasons = {
  invalidDate: "A valid date must be provided.",
  emptyDate: "A date was not provided."
}

export function ValidateDateString(dateString: string): Validation {
  if (!dateString)
    return { success: false, message: DateValidationReasons.emptyDate };
  if (dateString === "Invalid Date")
    return { success: false, message: DateValidationReasons.invalidDate };
  try
  {
    new Date(dateString);
  }
  catch (e)
  {
    return { success: false, message: DateValidationReasons.invalidDate };
  }
  return { success: true, message: "Date validated successfully." };
}

export function ValidateDateInfo(date: DateInfo | undefined): Validation {
  const invalidDateMessage = DateValidationReasons.invalidDate;

  if (date === null || date === undefined)
    return { success: false, message: DateValidationReasons.emptyDate };

  let month = date.month;
  if (!month || month < 1 || month > 12 || Number.isNaN(month))
    return { success: false, message: invalidDateMessage };//"A date must have a month between 1 and 12."};

  let day = date.day;
  if (!day || day < 1 || day > 31 || Number.isNaN(day))
    return { success: false, message: invalidDateMessage };//"A date must have a day between 1 and 31."};

  let year = date.year;    //TODO: there's probably a better way to validate years
  if (!year || year < 1900 || year > 2100 || Number.isNaN(year))
    return { success: false, message: invalidDateMessage };//"A date must have a year between 1900 and 2100."};

  return { success: true, message: "Date is all good!" }
}

export const CompanyValidationReasons = {
  duplicateCompanyName: "Cannot use the name of an existing company.",
  emptyName: "Cannot leave client name blank.",
  emptyTerms: "Cannot leave any terms blank.",
  startDateAfterEndDate: "Metrics start date must come before end date.",
}

export function ValidateCompany(newCompany: Company, companyList: Company[]): Validation {
  if (!newCompany.name)
    return FailValidation(CompanyValidationReasons.emptyName);

  const endDateValidation = ValidateDateString(newCompany.metricEndDate);
  if (!endDateValidation.success && endDateValidation.message !== DateValidationReasons.emptyDate)
    return endDateValidation;

  const startDateValidation = ValidateDateString(newCompany.metricStartDate);
  if (!startDateValidation.success && startDateValidation.message !== DateValidationReasons.emptyDate)
    return startDateValidation;

  if (endDateValidation.success && startDateValidation.success && new Date(newCompany.metricStartDate).getTime() > new Date(newCompany.metricEndDate).getTime())
    return { success: false, message: CompanyValidationReasons.startDateAfterEndDate };

  let matchingCompany = companyList.find(company => company.name.toUpperCase() === newCompany.name.toUpperCase() && newCompany.id !== company.id);
  if (matchingCompany)
    return FailValidation(CompanyValidationReasons.duplicateCompanyName);

  const terms = newCompany.terms;
  if (!terms.decisionTerm || !terms.documentTerm || !terms.initiativeTerm || !terms.linkTerm || !terms.teamTerm || !terms.throughputTerm || !terms.userTerm)
    return FailValidation(CompanyValidationReasons.emptyTerms);

  return SuccessValidation("Successfully validated; all good!");
}

export const UserValidationReasons = {
  emptyEmail: "Email cannot be left blank.",
  emptyCompany: "Company cannot be left blank.",
  duplicateEmail: "Cannot use the email of an existing user."
}

export function ValidateUser(newUser: User, allUsers: User[]): Validation {
  if (newUser)
  {
    if (!newUser.email)
      return { success: false, message: UserValidationReasons.emptyEmail };
    if (!newUser.companyId)
      return { success: false, message: UserValidationReasons.emptyCompany };

    let matchingUser = allUsers.find(user => user.email.toUpperCase() === newUser.email.toUpperCase() && newUser.id !== user.id)
    if (matchingUser)
      return { success: false, message: UserValidationReasons.duplicateEmail };

    return { success: true, message: "Successfully validated; all good!" };
  }
  return { success: false, message: "There was no user to validate." };
}

export function ValidateAdminUser(newUser: User, allUsers: User[]): Validation {
  const validation = ValidateUser(newUser, allUsers);
  if (validation.success)
  {
    if (!newUser.isAdmin && allUsers.find(user => user.isAdmin && user.id !== newUser.id) === undefined)
      return { success: false, message: "Cannot remove admin status if there are no other admins." }

    return { success: true, message: "Successfully validated admin user." }
  }
  else
    return validation;
}

export const DecisionValidationReasons = {
  invalidDate: "Decisions must have a valid date.",
  futureDate: "Decisions cannot have dates set in the future.",
  emptyDescription: "Decisions must have a description.",
  emptyResolution: "Decisions must have a resolution.",
  emptyParticipants: "Decisions must have at least one participant."
}

export function ValidateDecisions(decisions: DecisionData[]): Validation {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  for (const decision of decisions)
  {
    if (!ValidateDateInfo(decision.date).success)
      return { success: false, message: DecisionValidationReasons.invalidDate };
    if (MakeDate(decision.date) > today)
      return { success: false, message: DecisionValidationReasons.futureDate };
    /*if (!decision.description)
      return {success: false, message: DecisionValidationReasons.emptyDescription};
    if (!decision.resolution) 
      return {success: false, message: DecisionValidationReasons.emptyResolution};
    if (decision.participants.unmappables.length === 0 && decision.participants.mappables.length === 0)
      return { success: false, message: DecisionValidationReasons.emptyParticipants };*/
  }
  return { success: true, message: "Successfully validated decisions; all good!" };
}

export const LinkValidationReasons = {
  emptyUrl: "URL cannot be left blank."
}

export function ValidateLinks(links: LinkData[]): Validation {
  for (const link of links)
  {
    if (!link.url)
      return { success: false, message: LinkValidationReasons.emptyUrl }
  }
  return { success: true, message: "Successfully validated links; all good!" };
}

export const ArticleValidationReasons = {
  emptyTitle: "Title cannot be left blank.",
  emptyText: "Text cannot be left blank.",
  emptyUpdatedBy: "Updated by cannot be left blank."
}

export function ValidateArticle(newArticle: Article) {
  if (newArticle)
  {
    if (!newArticle.title)
      return { success: false, message: ArticleValidationReasons.emptyTitle };
    //if(!newArticle.text)
    //return {success: false, message: ArticleValidationReasons.emptyText};
    if (!newArticle.updatedBy)
      return { success: false, message: ArticleValidationReasons.emptyUpdatedBy };

    const dateValidation = ValidateDateInfo(newArticle.updatedDate);
    if (!dateValidation.success)
      return { success: false, message: dateValidation.message };

    return { success: true, message: "Successfully validated article, all good!" };
  }
  return { success: false, message: "There was no article to validate." };
}
