import { Company } from "../../Store/CompanySlice"
import { InitiativeRole, User } from "../../Store/UserSlice"
import { Fragment, useEffect, useState } from "react"
import { AdminEditInitiativesList } from "./AdminEditInitiativesList"
import { MakeClone } from "../../Services/Cloning"
import { BaseModal } from "../BaseModal"
import { ErrorSnackbar, SuccessSnackbar } from "../../Services/Snackbars"

export const EditUserInitiativesIds = {
  modal: "editUserInitiativesModal",
  saveChangesButton: "editUserInitiativesSaveButton",
  cancelChangesButton: "editUserInitiativesCancelButton"
}

interface EditUserInitiativesModalProps {
  user: User
  allCompanies: Company[]
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  SubmitUserData: (user: User) => Promise<boolean>
  expanded: boolean
}

export function EditUserInitiativesModal(props: EditUserInitiativesModalProps) {
  const [initiativeRoles, setInitiativeRoles] = useState<InitiativeRole[]>([]);
  const [companyId, setCompanyId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.isOpen)
    {
      setIsLoading(false);
      if (props.user.initiativeRoles)
        setInitiativeRoles(props.user.initiativeRoles);
      setCompanyId(props.user.companyId)
    }
  }, [props.isOpen, props.user.companyId, props.user.initiativeRoles])

  function UpdateInitiativeRoles(initiativeId: string, checkedInitiative: boolean, checkedIsTeamMember: boolean) {
    let initiativesClone = MakeClone(initiativeRoles);
    let matchingIdIndex = initiativesClone.findIndex(role => role.initiativeId === initiativeId);
    if (matchingIdIndex > -1)
      initiativesClone.splice(matchingIdIndex, 1);

    if (checkedInitiative) 
    {
      let newRole: InitiativeRole = {
        initiativeId: initiativeId,
        isTeamMember: checkedIsTeamMember
      }
      initiativesClone.push(newRole);
    }
    setInitiativeRoles(initiativesClone);
  }

  async function SaveEdit() {
    let userClone = MakeClone(props.user);
    if (userClone)
    {
      userClone.initiativeRoles = initiativeRoles;
      userClone.companyId = companyId;
      setIsLoading(true);

      let successfulSubmit = await props.SubmitUserData(userClone);
      if (successfulSubmit)
      {
        SuccessSnackbar("User initiative changes have been saved.");
        props.setIsOpen(false);
      }
      else
        ErrorSnackbar("Error occurred when saving user changes");
      setIsLoading(false);
    }
  }

  return (
    <>
      <BaseModal
        cypressData={{ modal: EditUserInitiativesIds.modal, closeModalButton: EditUserInitiativesIds.cancelChangesButton }}
        open={props.isOpen}
        onClose={() => props.setIsOpen(false)}
        maxWidth={"md"}
        title={`Selected Initiatives for ${props.user?.name ? props.user?.name : props.user?.email}`}
        subtitle={`Select Company to View Initiatives`}
        saveButton={{
          saveFunction: SaveEdit,
          cypressData: EditUserInitiativesIds.saveChangesButton,
          position: "top",
          spinner: isLoading
        }}
      >
        <div className="m-2 space-y-2 rounded-md bg-[#E4E1E5]">
          {
            props.allCompanies.map((company, index) => {
              return (
                <Fragment key={index}>
                  <AdminEditInitiativesList company={company} initiativeRoles={initiativeRoles} editable={true} expanded={props.expanded} user={props.user} updateInitiativeRoles={UpdateInitiativeRoles} />
                </Fragment>
              )
            })
          }
        </div>
      </BaseModal>
    </>
  )
}
