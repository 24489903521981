import { Typography, List, ListItem, Checkbox, Divider, ListItemText, Tooltip, ListItemIcon, ListItemAvatar, Box } from "@mui/material";
import { User, getUser } from "../../../Store/UserSlice";
import { Company, Initiative, IntegrityId } from "../../../Store/CompanySlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCheckboxSelect } from "../../../Hooks/useCheckboxSelect";
import { StepWizardChildProps } from "react-step-wizard";
import { useEffectOnce } from "../../../Hooks/useEffectOnce";
import { useAppDispatch } from "../../../Hooks/Hooks";
import { UserAvatar, UserWithAvatarUrl } from "../../User/UserAvatar";
import { IntegrityLogo } from "../../../Styles";
import { DocumentInfo, getAvatarDocumentInfos } from "../../../Store/DocumentSlice";
import { BaseStepStack } from "./InitiativeCopyBaseStepStack";
import { plural } from "pluralize";

interface TeamStepProps {
  initiativeUsers: User[]
  initiativeToCopy: Initiative
  company: Company
  updatedInitiative: Initiative
  setAssociatedUserIds: (value: string[]) => void
}

export const TeamStep: React.FC<Partial<StepWizardChildProps> & TeamStepProps> = ({ ...props }) => {
  const [userAvatars, setUserAvatars] = useState<DocumentInfo[]>([]);
  const dispatch = useAppDispatch();
  useEffectOnce(async () => {
    dispatch(getUser({}));
  });

  const FetchAvatars = useCallback(async () => {
    try
    {
      const avatarDocs = await dispatch(getAvatarDocumentInfos({})).unwrap();
      setUserAvatars(avatarDocs);
    }
    catch (e)
    {

    }
  }, [dispatch]);

  useEffect(() => {
    FetchAvatars();
  }, [FetchAvatars]);

  const allUserIds = useMemo(() => {
    return props.initiativeUsers.flatMap(l => l.id);
  }, [props.initiativeUsers]);

  const {
    isSelectAll,
    SelectAll,
    SelectThis,
    IsChecked
  } = useCheckboxSelect(allUserIds);

  const initiativeUsersWithAvatars: UserWithAvatarUrl[] = useMemo(() => props.initiativeUsers.map(u => { return { docUrl: userAvatars.find(a => a.blobName === u.avatarBlobName)?.tempUrl, ...u } }), [props.initiativeUsers, userAvatars]);

  function SelectUser(userId: string) {
    const checkedIds = SelectThis(userId);
    props.setAssociatedUserIds(checkedIds);
  }

  function SelectAllUsers() {
    const checkedIds = SelectAll();
    props.setAssociatedUserIds(checkedIds);
  }

  return (
    <BaseStepStack {...props}>
      {initiativeUsersWithAvatars.length ?
        <>
          <ListItem disablePadding>
            <ListItemIcon>
              <Checkbox checked={isSelectAll} onChange={() => SelectAllUsers()} />
            </ListItemIcon>
            <ListItemText sx={{ fontWeight: "bold" }} primary="Select All" />
          </ListItem>
          <Divider />
          <Box>
            {initiativeUsersWithAvatars.map((user, index) => {
              const check = IsChecked(user.id);
              const name = user.name || user.email;
              return (
                <List key={index}>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <Checkbox checked={check} value={user.name} onChange={() => SelectUser(user.id)} />
                    </ListItemIcon>
                    <ListItemAvatar sx={{ paddingX: 2 }}>
                      <UserAvatar user={user} />
                    </ListItemAvatar>
                    <ListItemIcon sx={{ marginLeft: -2, marginRight: -3 }}>
                      {user.companyId === IntegrityId &&
                        <img src={IntegrityLogo} className="max-h-[40px]" alt="Integrity Inspired Solutions logo" />
                      }
                    </ListItemIcon>
                    <ListItemText disableTypography sx={{ alignText: "center" }}
                      primary={
                        <Tooltip title={name} placement="bottom-start">
                          <Typography variant="body2" noWrap textOverflow="ellipsis" ><b>{name}</b></Typography>
                        </Tooltip>
                      }
                      secondary={
                        <Typography variant="body2" noWrap textOverflow="ellipsis" >{user.role}</Typography>
                      }
                    />
                  </ListItem>
                </List>
              )
            })}
          </Box>
        </>
        :
        <Typography>
          There are no {plural(props.company.terms.userTerm.toLowerCase())} assigned to the source {props.company.terms.initiativeTerm.toLowerCase()}.
        </Typography>
      }
    </BaseStepStack>
  );
};
