import { useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './Hooks/Hooks';
import { User, selectCurrentUser, selectIsLoggedIn } from './Store/UserSlice';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Content from './Layout/Content';
import { useEffectOnce } from './Hooks/useEffectOnce';
import { authenticateSession, selectSessionId } from './Store/SessionSlice';
import { Header } from './Layout/Header';
import { Footer } from './Layout/Footer';
import { CircularProgress } from '@mui/material';
import { IntegrityId } from './Store/CompanySlice';
import { GoToClientDashboard } from './Pages/ClientDashboardPage';

export function GoToHomePage(navigate: NavigateFunction, currentUser: User) {
  if (currentUser.companyId === IntegrityId && currentUser.isAdmin)
    navigate('/ClientPage');
  else
    GoToClientDashboard(navigate, currentUser.id, currentUser.companyId);
}

function App() {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const sessionId = useAppSelector(selectSessionId);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const onLoginPage = location.pathname === '/Login';
  const onResetPasswordPage = location.pathname === '/ResetPassword';
  const onDashboardPage = location.pathname === '/Dashboard';
  const [loading, setLoading] = useState(onDashboardPage);

  useEffectOnce(() => {
    if (!isLoggedIn && !onLoginPage && sessionId)
      dispatch(authenticateSession({ sessionId }));
  });

  useEffect(() => {
    if (isLoggedIn && currentUser)
    {
      if (currentUser.isPasswordReset)
        navigate('/ResetPassword');
      else if (onLoginPage)
        GoToHomePage(navigate, currentUser);
    }
    else
    {
      if (!sessionId && !onResetPasswordPage)
        navigate('/Login');
    }
  }, [isLoggedIn, sessionId, onResetPasswordPage, onLoginPage, currentUser, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2750);
  }, []);

  const showApp = isLoggedIn || onLoginPage || onResetPasswordPage;
  if (loading)
  {
    return (
      <div className="flex justify-center w-full h-full my-20">
        <CircularProgress size={50} color={"warning"} />
      </div>
    )
  }

  return (
    <>
      {showApp &&
        <div className="flex flex-col ">
          <div className="bg-[#E4E1E5] min-h-[90vh]">
            <Header />
            <div className="h-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Content />
                <SnackbarProvider maxSnack={1} anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }} />
              </LocalizationProvider>
            </div>
          </div>
          <div className="min-h-[10%]">
            <Footer />
          </div>
        </div>
      }
    </>
  );
}

export default App;
