import { Button, Autocomplete, TextField, Chip, CircularProgress, Stack, Grid } from "@mui/material"
import { labelStyle } from "../../Styles"
import { DateInfoInput } from "../DateInfoInput"
import { DecisionModalIds } from "./DecisionDataModal"
import { DateInfo, DecisionData, MappableDecisionParticipant, UnmappableDecisionParticipant } from "../../Services/CompanyService"
import AddIcon from "@mui/icons-material/Add";
import { RichText } from "../Quill/RichText"
import { Fragment, useEffect } from "react"
import { UserAvatar, UserWithAvatarUrl } from "../User/UserAvatar"
import { Company, Initiative } from "../../Store/CompanySlice"
import { plural } from "pluralize"
import { BaseModal } from "../BaseModal"
import { AccountField } from "../AccountField"
import { AccountActions } from "../AccountActions"
import { MakeClone } from "../../Services/Cloning"
import { v4 as UuidV4 } from "uuid";

interface DecisionUpsertModalProps {
  initiative: Initiative
  company: Company
  isOpen: boolean
  HandleClose: () => void
  decision: DecisionData
  isEditable: boolean
  isEditingMe: boolean
  isEditingAnyone: boolean
  isLoading: boolean
  canAddTag: boolean
  canAddParticipant: boolean
  handlers: {
    HandleChangeNewTag: (event: any, val: string | null) => void
    HandleAddTag: () => void
    HandleRemoveTag: (tag: string) => void
    HandleChangeNewUnmappedParticipant: (event: any, val: string | null) => void
    HandleChangeNewMappedParticipant: (value: { id: string, name: string }) => void
    HandleAddParticipant: () => void
    HandleRemoveUnmappedParticipant: (participantToRemove: UnmappableDecisionParticipant) => void
    HandleRemoveMappedParticipant: (participantToRemove: MappableDecisionParticipant) => void
    HandleStartEdit: (decisionId: string) => void
    HandleSaveEdit: (newDecision: DecisionData) => void
    HandleCancelEdit: () => void
    HandleAttemptDelete: (decisionId: string) => void
  }
  currentValues: DecisionData
  setCurrentValues: (values: DecisionData | undefined) => void
  titleRef: React.RefObject<HTMLTextAreaElement>
  allTags: string[]
  possibleParticipants: UserWithAvatarUrl[]
}

export function DecisionUpsertModal(props: DecisionUpsertModalProps) {

  interface ParticipantDisplayProps {
    participants: DecisionData["participants"]
  }

  function ParticipantsDisplay(myProps: ParticipantDisplayProps) {
    return (
      <div>
        {myProps.participants.mappables?.map((participant, index) => {
          const matchingUser = props.possibleParticipants.find(u => u.id === participant.id);
          if (matchingUser)
            return (
              <Grid container key={index}>
                <Grid item xs={12} sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: 1
                }}>
                  <Chip key={index}
                    avatar={<UserAvatar user={matchingUser} length={30} />}
                    label={matchingUser.name || matchingUser.email}
                    variant="outlined"
                    onDelete={() => props.handlers.HandleRemoveMappedParticipant(participant)}
                  />
                </Grid>
              </Grid>
            )
          else
            return <Fragment key={index}></Fragment>
        })}
        {props.currentValues.participants.unmappables?.map((participant, index) => {
          return (
            <Grid container key={index}>
              <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 1
              }}>
                <Chip key={index}
                  label={participant.name}
                  variant="outlined"
                  onDelete={() => props.handlers.HandleRemoveUnmappedParticipant(participant)}
                />
              </Grid>
            </Grid>
          )
        })}
      </div>
    )
  }

  const newId = UuidV4();
  const today = new Date();
  const todayInfo: DateInfo = { month: today.getMonth() + 1, day: today.getDate(), year: today.getFullYear() }

  useEffect(() => {
    if (props.isOpen)
    {
        if (props.decision)
        {
            let clone = MakeClone(props.decision);
            props.setCurrentValues(clone);
        }
        
        else
            props.setCurrentValues({
              id: newId,
              description: "",
              resolution: "",
              participants: { mappables: [], unmappables: [] },
              tags: [],
              date: todayInfo
            })
        }
    else
    {
        props.setCurrentValues(undefined);
    }
}, [props.isOpen, props.decision])

  return (
    <BaseModal
        open={props.isOpen}
        onClose={() => props.HandleClose()}
        cypressData={{ modal: DecisionModalIds.modal, closeModalButton: DecisionModalIds.closeModalButton }}
        title={`${plural(props.company.terms.decisionTerm)}`}
        subtitle={`${props.company.name}${props.initiative ? ` - ${props.initiative.title}` : ``}`}
        >
          <Stack gap={2.5}>
          {props.currentValues &&
            <><div>
            <AccountField title="Description" value={props.currentValues.description} onChange={(e) => props.setCurrentValues({ ...props.currentValues, description: e.target.value })} cypressData={"DecisionModalIds.editDescription"} />
          </div><div>
              <label className={labelStyle} htmlFor="resolution">Resolution</label>
              <RichText cypressData={DecisionModalIds.editResolution} initialValue={props.currentValues.resolution} valueSetter={(value: string) => props.setCurrentValues({ ...props.currentValues, resolution: value })} placeHolder={`Resolution...\n`} />
            </div></>
        }
          <Stack direction="row" justifyContent="space-between" gap={1}>
            <Stack direction="column" width="90%">
            <div>
              <label className={labelStyle} htmlFor="participants">Participants</label>
              <div className="flex">
                <Button data-cy={DecisionModalIds.addParticipantButton} disabled={!props.canAddParticipant} variant="outlined" onClick={() => props.handlers.HandleAddParticipant()}>
                  <AddIcon />
                </Button>
                <Autocomplete
                  data-cy={DecisionModalIds.editParticipants}
                  disablePortal
                  id="combo-box-participants"
                  options={props.possibleParticipants.map((user) => { return { id: user.id, name: user.name || user.email }; })}
                  getOptionLabel={(option) => typeof option === "string" ? option : option.name}
                  renderInput={(params) => <TextField {...params} label="New Participant" />}
                  sx={{ minWidth: "70%" }}
                  freeSolo
                  autoSelect
                  onInputChange={props.handlers.HandleChangeNewUnmappedParticipant}
                  onChange={(e: any, value: string | { name: string, id: string } | null) => {
                    if (value !== null)
                    {
                      if (typeof value === "string")
                        props.handlers.HandleChangeNewUnmappedParticipant(e, value);
                      else
                        props.handlers.HandleChangeNewMappedParticipant(value)
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter")
                    {
                      props.handlers.HandleAddParticipant();
                    }
                  }}
                />
              </div>
            </div>
            <ParticipantsDisplay participants={props.currentValues.participants} />
            </Stack>
            <Stack direction="column" width="90%">
            <div>
              <label className={labelStyle} htmlFor="tags">Tags</label>
              <div className="flex">
                <Button disabled={!props.canAddTag} variant="outlined" onClick={() => props.handlers.HandleAddTag()}>
                  <AddIcon />
                </Button>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={props.allTags.filter(tag => !props.currentValues.tags.find(t => t === tag))}
                  renderInput={(params) => <TextField {...params} label="New Tag" />}
                  sx={{ minWidth: "70%" }}
                  freeSolo
                  autoSelect
                  onInputChange={props.handlers.HandleChangeNewTag}
                />
              </div>
            </div>
            <div>
              {props.currentValues.tags.map((tag, index) => {
                return (
                  <Chip key={index} label={tag} variant="outlined" onDelete={() => props.handlers.HandleRemoveTag(tag)} />
                )
              })}
            </div>
            </Stack>
            </Stack>
            <DateInfoInput cypressData={DecisionModalIds.editDate} label="Date Resolved" date={props.currentValues.date} setDate={(value: DateInfo | undefined) => props.setCurrentValues({ ...props.currentValues, date: value ?? props.decision.date })} />
          </Stack>
          
          <>
            {props.isLoading &&
              <CircularProgress color={"warning"} />
            }
          </>
        <AccountActions isSaving={props.isLoading} Cancel={() => {props.handlers.HandleCancelEdit(); props.HandleClose()}} Save={() => {props.handlers.HandleSaveEdit(props.currentValues); props.HandleClose()}} cypressData={{
            cancelButton: "",
            saveButton: ""
          }} />
    </BaseModal>
  )
}
