import { useCallback, useState } from "react";
import { User } from "../Store/UserSlice";
import { UserWithAvatarUrl } from "../Components/User/UserAvatar";
import { useAppDispatch } from "./Hooks";
import { DocumentInfo, getCachedAvatarDocumentInfos } from "../Store/DocumentSlice";

export function useGetAvatar() {
  const [avatar, setAvatar] = useState<UserWithAvatarUrl>();
  const dispatch = useAppDispatch();

  const FetchAvatar = useCallback(async (user: User | undefined) => {
    let docs: DocumentInfo[] = [];
    try
    {
      docs = await dispatch(getCachedAvatarDocumentInfos({})).unwrap();
    }
    catch (e)
    {
      //console.log("unable to load avatar: " + (e as Error).message);
    }
    const matchingDoc = docs.find(doc => doc.blobName === user?.avatarBlobName);
    if (matchingDoc && user)
      setAvatar({ ...user, docUrl: matchingDoc?.tempUrl });
    else
      setAvatar(undefined);
  }, [dispatch]);

  return {
    avatar,
    FetchAvatar
  }
}