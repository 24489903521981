import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { DocumentInfo, deleteDocument, downloadDocument, getDocumentObjectUrls, updateDocument } from '../../Store/DocumentSlice';
import { useAppDispatch } from '../../Hooks/Hooks';
import { IconButton, ThemeProvider, createTheme } from '@mui/material';
import { AlertDialog } from '../AlertDialog';
import { useState } from 'react';
import { ErrorSnackbar, SuccessSnackbar } from '../../Services/Snackbars';

interface DocumentActionsProps {
  docInfo: DocumentInfo
  RefreshData: () => Promise<void>
  canDelete: boolean
  canArchive: boolean
  canUnarchive?: boolean
  disabled?: boolean
  documentTerm: string
}

const blueTheme = createTheme({
  palette: {
    primary: {
      main: "#3d5afe",
    }
  }
})

export function DocumentActions(props: DocumentActionsProps) {
  const dispatch = useAppDispatch();
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const [archiveAlertIsOpen, setArchiveAlertIsOpen] = useState(false);
  const [unarchiveAlertIsOpen, setUnarchiveAlertIsOpen] = useState(false);

  async function HandleDownload() {
    try
    {
      const docObjectUrlPairs = await dispatch(getDocumentObjectUrls({ documentInfos: [props.docInfo] })).unwrap();
      const firstPair = docObjectUrlPairs.at(0);
      if (firstPair)
        await dispatch(downloadDocument({ fileName: props.docInfo.metadata.fileName, objectUrl: firstPair.objUrl }));
    }
    catch (e)
    {
      console.log((e as Error).message);
    }
  }

  async function HandleOpenDoc() {
    try
    {
      const docObjectUrlPairs = await dispatch(getDocumentObjectUrls({ documentInfos: [props.docInfo] })).unwrap();
      const firstPair = docObjectUrlPairs.at(0);
      if (firstPair)
      {
        const link = document.createElement('a');
        link.href = firstPair.objUrl;
        link.setAttribute(
          "target",
          "_blank",
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    }
    catch (e)
    {
      console.log((e as Error).message);
      ErrorSnackbar(`Unable to open ${props.documentTerm.toLowerCase()}.`)
    }
  }

  async function HandleDelete() {
    try
    {
      const response = await dispatch(deleteDocument({ blobName: props.docInfo.blobName })).unwrap();
      if (response) await props.RefreshData();
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setDeleteAlertIsOpen(false);
  }

  async function HandleArchive(archive: boolean) {
    try
    {
      const response = await dispatch(updateDocument({ blobName: props.docInfo.blobName, archived: archive })).unwrap();
      if (response) await props.RefreshData();
      SuccessSnackbar(`${props.docInfo.metadata.fileName} ${archive ? "added to the archive." : "removed from the  archive."}`);
    }
    catch(e)
    {
      ErrorSnackbar(e);
    }
    setArchiveAlertIsOpen(false);
    setUnarchiveAlertIsOpen(false);
  }

  const iconSize = 20;

  return (
    <ThemeProvider theme={blueTheme}>
      <IconButton onClick={() => HandleDownload()} disabled={props.disabled} title="Download" color="primary">
        <DownloadIcon sx={{ fontSize: iconSize }} color="inherit"/>
      </IconButton>
      <IconButton onClick={() => HandleOpenDoc()} disabled={props.disabled} title="View" color="primary">
        <VisibilityIcon sx={{ fontSize: iconSize }} color="inherit"/>
      </IconButton>
      {props.canArchive &&
        <>
          <IconButton onClick={() => setArchiveAlertIsOpen(true)} disabled={props.disabled} title="Archive" color="primary">
            <ArchiveIcon sx={{ fontSize: iconSize, color: "inherit" }} />
          </IconButton>
          <AlertDialog title={`Archive this ${props.documentTerm.toLowerCase()}?`} description={`This ${props.documentTerm.toLowerCase()} will be initially hidden on the dashboard until unarchived.`} isOpen={archiveAlertIsOpen} setIsOpen={setArchiveAlertIsOpen}
            Confirm={() => HandleArchive(true)} Cancel={() => setArchiveAlertIsOpen(false)} cypressData={{ modal: "", confirmButton: "", cancelButton: "" }}
          />
        </>
      }
      {props.canUnarchive &&
        <>
          <IconButton onClick={() => setUnarchiveAlertIsOpen(true)} disabled={props.disabled} title="Unarchive" color="primary">
            <UnarchiveIcon sx={{ fontSize: iconSize, color: "inherit" }} />
          </IconButton>
          <AlertDialog title="Remove from archive?" description={`This ${props.documentTerm.toLowerCase()} will become immediately visible on the dashboard.`} isOpen={unarchiveAlertIsOpen} setIsOpen={setUnarchiveAlertIsOpen}
            Confirm={() => HandleArchive(false)} Cancel={() => setUnarchiveAlertIsOpen(false)} cypressData={{ modal: "", confirmButton: "", cancelButton: "" }}
          />
        </>
      }
      {props.canDelete &&
        <>
          <IconButton onClick={() => setDeleteAlertIsOpen(true)} disabled={props.disabled} title="Delete" color="primary">
            <DeleteIcon sx={{ fontSize: iconSize, color: "inherit" }} />
          </IconButton>
          <AlertDialog title={`Delete this ${props.documentTerm.toLowerCase()}?`} description={`Once removed, a ${props.documentTerm.toLowerCase()} cannot be recovered.`} isOpen={deleteAlertIsOpen} setIsOpen={setDeleteAlertIsOpen}
            Confirm={HandleDelete} Cancel={() => setDeleteAlertIsOpen(false)} cypressData={{  modal: "", confirmButton: "", cancelButton: "" }}
          />
        </>
      }
    </ThemeProvider>
  )
}