import { Stack, Typography } from "@mui/material";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { metricsFontLarge } from "../../../Styles";
import { plural } from "pluralize";
import { useMemo } from "react";
import { InsufficientThroughputSection } from "./InsufficientThroughputSection";
import { MaybeProbability } from "../../../Services/ProbabilitySimulationService";

interface ProbabilityMetricsProps {
  initiative: Initiative
  company: Company
  probability: MaybeProbability
}

export function ProbabilityMetrics(props: ProbabilityMetricsProps) {
  const itemsWithEndDates = useMemo(() => {
    return props.probability.itemsUsed.map(s => {
      return { ...s, endDate: s.progressDates.find(p => p.workflowColumnId === props.company.workflow.endDateColumnId)?.enterColumnDate }
    }).filter(s => s.endDate);
  }, [props.company, props.probability]);

  const sortedCompletedStories = itemsWithEndDates.sort((a, b) => {
    const dateA = a.endDate;
    const dateB = b.endDate;
    return new Date(dateA ?? new Date()).getTime() - new Date(dateB ?? new Date()).getTime();
  });
  const numberCompletedStories = sortedCompletedStories.length;

  const firstCompletionDate = sortedCompletedStories.at(0)?.endDate;
  const lastCompletionDate = sortedCompletedStories.at(-1)?.endDate;

  return (
    <Stack sx={{ paddingX: 2 }} gap={1} alignItems="center">
      <InsufficientThroughputSection company={props.company} completedItemCount={numberCompletedStories}>
        <>
          <Typography sx={{ ...metricsFontLarge, color: "black" }}>Probability of Completion<br />{props.initiative.targetDate ? new Date(props.initiative.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "Target Date"}</Typography>
          <Typography sx={{ fontSize: 38 }}>{props.probability.value !== undefined ? `${props.probability.value}%` : "N/A"}</Typography>
          {!props.probability.value &&
            <Typography sx={{ fontSize: 14, textAlign: "center", paddingX: 4 }}>Data may be insufficient or may indicate a very low probability of success</Typography>
          }
          <Typography sx={{ fontSize: 15, textAlign: "center" }}>
            Based on {numberCompletedStories} {plural(props.company.terms.throughputTerm.toLowerCase())} completed from {new Date(firstCompletionDate ?? "N/A").toLocaleDateString('en-US', { timeZone: 'UTC' })} to {new Date(lastCompletionDate ?? "N/A").toLocaleDateString('en-US', { timeZone: 'UTC' })}<br />Monte Carlo (10K Trials)
          </Typography>
        </>
      </InsufficientThroughputSection>
    </Stack>
  );
}