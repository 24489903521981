import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { IntegrityTheme } from "../Styles";

export function Footer() {
  return (
    <Box sx={{paddingX: 2}}>
      <ThemeProvider theme={IntegrityTheme}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <CssBaseline />
        <Typography color={IntegrityTheme.palette.lightGray.main} fontSize={"16px"} fontWeight={400} letterSpacing={"0.64px"} lineHeight={"28.8px"}>© 2023 Integrity Inspired Solutions</Typography>
      </ThemeProvider>
    </Box>
  )
}