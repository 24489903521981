import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ThemeProvider, Typography } from '@mui/material';
import { Company, Initiative, deleteLinkData, upsertLinkData } from '../../Store/CompanySlice';
import { TableHeaderStyle, blueTheme, defaultRowStyle } from '../../Styles';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../Hooks/Hooks';
import { User } from '../../Store/UserSlice';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { SearchBar } from '../SearchBar';
import { useSorter } from '../../Hooks/useSorter';
import { SortLabel } from '../SortLabel';
import { ErrorSnackbar, SuccessSnackbar } from '../../Services/Snackbars';
import { plural } from 'pluralize';
import { AddButton } from '../AddButton';
import { DeleteLinkDataRequest, LinkData, UpsertLinkDataRequest } from '../../Services/CompanyService';
import { LinkUpsertModal } from './LinkUpsertModal';
import { DeleteAlert } from '../DeleteAlert';
import { ValidateLinks, ValidationFailedPrefix } from '../../Services/Validation/Validation';

export const LinkManagementSectionIds = {
  upsertModal: {
    modal: "linkManagementSectionUpsertModal",
    closeModalButton: "linkManagementSectionUpsertCloseModalButton",
    title: "linkManagementSectionUpsertTitle",
    url: "linkManagementSectionUpsertUrl",
    saveButton: "linkManagementSectionUpsertSaveButton",
    cancelButton: "linkManagementSectionUpsertCancelButton",
  },
  deleteModal: {
    modal: "linkManagementSectionDeleteModal",
    confirmButton: "linkManagementSectionDeleteConfirmButton",
    cancelButton: "linkManagementSectionDeleteCancelButton"
  },
  table: "linkManagementSectionTable",
  addLinkButton: "linkManagementSectionAddLinkButton",
  editLinkButton: "linkManagementSectionEditLinkButton",
  deleteLinkButton: "linkManagementSectionDeleteLinkButton",
  keywordFilter: "linkManagementSectionKeywordFilter",
}

interface LinkManagementSectionProps {
  company: Company
  initiative: Initiative | undefined
  currentUser: User
  userHasUploadPermission: boolean
}

export function LinkManagementSection(props: LinkManagementSectionProps) {
  const dispatch = useAppDispatch();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [links, setLinks] = useState<LinkData[]>([]);
  const [linkToEdit, setLinkToEdit] = useState<LinkData>();
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isUpserting, setIsUpserting] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedLinks = useMemo(() => {
    return SortItems(links);
  }, [links, SortItems]);

  const filteredLinks = useMemo(() => {
    const upperKeyword = searchedKeyword.toUpperCase();
    return sortedLinks.filter(link => link.title.toUpperCase().includes(upperKeyword) || link.url.toUpperCase().includes(upperKeyword));
  }, [sortedLinks, searchedKeyword]);

  function LeaveEditMode() {
    setLinkToEdit(undefined);
    setIsLinkModalOpen(false);
  }

  function EnterEditMode(link: LinkData) {
    setLinkToEdit(link);
    setIsLinkModalOpen(true);
  }

  function EnterAddMode() {
    LeaveEditMode();
    setIsLinkModalOpen(true);
  }

  async function HandleSaveEdit(editedLink: LinkData) {
    if (editedLink)
    {
      const validation = ValidateLinks([editedLink]);
      if (!validation.success)
      {
        ErrorSnackbar(ValidationFailedPrefix + validation.message);
        return;
      }

      if (!isUpserting)
      {
        setIsUpserting(true);

        const linkRequest: UpsertLinkDataRequest = {
          initiativeId: props.initiative?.id,
          companyId: props.company.id,
          links: [editedLink]
        }
        try
        {
          await dispatch(upsertLinkData(linkRequest)).unwrap();
          SuccessSnackbar(`${props.company.terms.linkTerm} updated successfully.`);
        }
        catch (e)
        {
          ErrorSnackbar(e);
        }
        LeaveEditMode();
        setIsUpserting(false);
      }
    }
  }

  function HandleCancelEdit() {
    LeaveEditMode();
  }

  function EnterDeleteMode(link: LinkData) {
    setIsDeleteOpen(true);
    setLinkToEdit(link);
  }

  async function HandleDeleteLink(linkToDeleteId: string) {
    if (!isUpserting)
    {
      setIsUpserting(true);
      const deleteRequest: DeleteLinkDataRequest = {
        companyId: props.company.id,
        initiativeId: props.initiative?.id,
        linkId: linkToDeleteId
      }
      try
      {
        await dispatch(deleteLinkData(deleteRequest));
        SuccessSnackbar(`${props.company.terms.linkTerm} removed successfully.`)
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      LeaveDeleteMode();
      setIsUpserting(false);
    }
  }

  function HandleCancelDelete() {
    LeaveDeleteMode();
  }

  function LeaveDeleteMode() {
    setIsDeleteOpen(false);
    setLinkToEdit(undefined);
  }

  useEffect(() => {
    setLinks(props.initiative ? props.initiative.links : props.company.links);
  }, [props.company, props.initiative]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedLinks = filteredLinks.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const maxPages = Math.max(Math.ceil(filteredLinks.length / rowsPerPage), 1);
    if (page > maxPages - 1)
      setPage(maxPages - 1);
  }, [filteredLinks, rowsPerPage, page]);

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4">
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            {links.length !== 0 ?
              <SearchBar cypressData={LinkManagementSectionIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => { setSearchedKeyword(value); setPage(0) }} />
              :
              <Typography variant="body2">There are no {plural(props.company.terms.linkTerm.toLowerCase())} to display</Typography>
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            {props.userHasUploadPermission &&
              <AddButton cypressData={LinkManagementSectionIds.addLinkButton} HandleClick={() => { EnterAddMode() }} />
            }
          </Grid>
        </Grid>
        {links.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle>Title</TableHeaderStyle>
                    <TableHeaderStyle>URL</TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="dateCreated" heading="Created On" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="dateModified" heading="Last Modified" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody data-cy={LinkManagementSectionIds.table}>
                  {
                    slicedLinks.map((link, index) => {
                      return (
                        <TableRow className={defaultRowStyle}
                          sx={{
                            borderBottom: "1px solid black",
                            "& td": {
                              fontFamily: "Arial, Helvetica",
                              color: "#21345b"
                            }
                          }}
                          key={index}
                        >
                          <TableCell>
                            <Typography variant="caption">{link.title}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{link.url}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{link.dateCreated ? new Date(link.dateCreated).toLocaleString() : "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{link.dateModified ? new Date(link.dateModified).toLocaleString() : "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            {props.userHasUploadPermission &&
                              <>
                                <IconButton data-cy={LinkManagementSectionIds.editLinkButton} onClick={() => EnterEditMode(link)} color="primary">
                                  <EditIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                                <IconButton data-cy={LinkManagementSectionIds.deleteLinkButton} onClick={() => EnterDeleteMode(link)} color="primary">
                                  <DeleteIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={sortedLinks.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        }
        <LinkUpsertModal company={props.company} linkToEdit={linkToEdit} isOpen={isLinkModalOpen} handleClose={() => setIsLinkModalOpen(false)} isUpserting={isUpserting}
          Save={HandleSaveEdit} Cancel={HandleCancelEdit} cypressData={LinkManagementSectionIds.upsertModal}
        />
        <DeleteAlert isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} Delete={HandleDeleteLink} CancelDelete={HandleCancelDelete} id={linkToEdit?.id}
          title={`Delete this ${props.company.terms.linkTerm.toLowerCase()}?`} description={`Once removed, a ${props.company.terms.linkTerm.toLowerCase()} cannot be recovered.`}
          cypressData={LinkManagementSectionIds.deleteModal}
        />
      </div>
    </ThemeProvider>
  )
}
