import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { Company } from "../../Store/CompanySlice";
import { Fragment, useState } from "react";
import { IntegrityTheme, TableHeaderStyle, UserItem, defaultRowStyle, tableCellFontSize, tableHeaderFontSize } from "../../Styles";
import ExpandMore from '@mui/icons-material/ExpandMore'
import { InitiativeRole, User } from "../../Store/UserSlice";

interface AdminEditInitiativesListProps {
  company: Company
  initiativeRoles: InitiativeRole[]
  editable: boolean
  updateInitiativeRoles: (initiativeId: string, checkedInitiative: boolean, checkedIsTeamMember: boolean) => void
  expanded: boolean
  user: User
}

export function AdminEditInitiativesList(props: AdminEditInitiativesListProps)
{
  const [expanded, setExpanded] = useState(props.expanded);
  const isCompanyChecked = props.company?.initiatives.find(init => props.initiativeRoles.find(i => i.initiativeId === init.id) !== undefined);

  if (isCompanyChecked || props.editable) {
    return (
      <ThemeProvider theme={IntegrityTheme}>
        <UserItem> 
          <Accordion expanded={expanded}>
            <AccordionSummary className={"hover:bg-[#69D5C3]"} expandIcon={<ExpandMore />} onClick={() => setExpanded(!expanded)}>
              <p className={`text-xl ${isCompanyChecked ? "font-bold" : ""}`}>
                {props.company.name}
              </p>
            </AccordionSummary>
            <AccordionDetails>
            <TableContainer elevation={12} component={Paper}>
                <Table className="table-auto w-full outline outline-3 bg-gray-100">
                  <TableHead className="outline outline-1">
                    <TableRow sx={{
                      borderBottom: "2px solid black",
                      "& th": {
                        fontSize: tableHeaderFontSize,
                        fontWeight: "bold",
                        fontFamily: "Arial, Helvetica"
                      }
                    }}>
                      <TableHeaderStyle>Initiative</TableHeaderStyle>
                      <TableHeaderStyle>Team Member</TableHeaderStyle>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.company.initiatives.map((init, index) => {
                      let checkedInitRole = props.initiativeRoles.find(role => role.initiativeId === init.id);
                      return (
                        <Fragment key={index}>
                          <TableRow key={index} className={defaultRowStyle} sx={{
                            borderBottom: "1px solid black",
                            "& td": {
                              fontSize: tableCellFontSize,
                              fontFamily: "Arial, Helvetica",
                              color: "#21345b"
                            }
                          }}>
                            <TableCell><Checkbox color="darkBlue" checked={checkedInitRole?.initiativeId !== undefined} onChange={e => props.updateInitiativeRoles(init.id, e.target.checked, false)}/>{init.title}</TableCell>
                            <TableCell><Checkbox color="darkBlue" disabled={checkedInitRole?.initiativeId === undefined} checked={checkedInitRole?.isTeamMember !== false && checkedInitRole?.isTeamMember !== undefined} 
                            onChange={e => props.updateInitiativeRoles(init.id, true, e.target.checked)}/></TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </UserItem>
      </ThemeProvider>
    )
  }
  return <Fragment></Fragment>;
}
