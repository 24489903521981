import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { GetCompletedItems, GetItemsBySelectedCategoriesAndCompany, IsInitiativeExpired } from "../../../Services/CompanyService";
import { useState, useMemo } from "react";
import { AddDays, ForecastItemsCompleted } from "../../../Services/ProbabilitySimulationService";
import { metricsFontLarge, metricsFontSmall } from "../../../Styles";
import { Company } from "../../../Store/CompanySlice";
import { plural } from "pluralize";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';

interface ClientLevelItemProjectionMetricsProps {
  company: Company
}

export function ClientLevelItemProjectionMetrics(props: ClientLevelItemProjectionMetricsProps) {
  const bigTextSX = metricsFontLarge;
  const smallTextSX = metricsFontSmall;
  const [howManyDate, setHowManyDate] = useState(AddDays(new Date(), 30).toISOString().split("T")[0]);
  const [howManyInputProbability, setHowManyInputProbability] = useState(85);
  const expectedItemsCompleted = useMemo(() => {
    const activeInitiatives = props.company.initiatives.filter(i => i.isActive && !IsInitiativeExpired(i));
    const itemsByCategory = GetItemsBySelectedCategoriesAndCompany(props.company);
    const completedItems = GetCompletedItems(itemsByCategory, props.company.workflow, [
      { startDate: props.company.metricStartDate, endDate: props.company.metricEndDate }
    ]).filter(item => {
      const initiative = activeInitiatives.find(init => init.id === item.initiativeId);
      return !!initiative;
    });
    return ForecastItemsCompleted(howManyDate, completedItems, howManyInputProbability, props.company.workflow).value ?? 0
  }, [howManyDate, props.company, howManyInputProbability]);
  const itemTerm = props.company.terms.throughputTerm.toLowerCase();
  const shouldUsePlural = expectedItemsCompleted === 0 || expectedItemsCompleted > 1;
  const expectedItemsTerm = useMemo(() => shouldUsePlural ? plural(itemTerm) : itemTerm, [itemTerm, shouldUsePlural]);

  return (
    <Stack sx={{ paddingX: 2 }} gap={1} alignItems="center">
      <Typography sx={bigTextSX}>{`${expectedItemsCompleted === 0 ? "No" : expectedItemsCompleted} more ${expectedItemsTerm}`}</Typography>
      <Typography sx={smallTextSX}>{shouldUsePlural ? "are" : "is"} projected for delivery by</Typography>
      <DatePicker
        sx={{ width: "60%" }} 
        value={dayjs(howManyDate)} 
        onChange={(value) => setHowManyDate(value ? value.format("YYYY-MM-DD") : howManyDate)}
        format="M/D/YYYY"
        slotProps={{
          actionBar: {actions: ["today", "cancel"], 
          style: {justifyContent: "center", background: "#F3F1F3"}},
          textField: {
              variant: "standard",
              InputProps: {
                disableUnderline: true,
                sx: metricsFontLarge
              }
            },
          field: { shouldRespectLeadingZeros: true }
        }}
      />
      <Typography sx={smallTextSX}>with a certainty of</Typography>
      <Select disableUnderline variant="standard" sx={bigTextSX} value={howManyInputProbability.toString()} onChange={(e) => setHowManyInputProbability(parseInt(e.target.value))}>
        {["75", "85", "95"].map((percent, i) =>
          <MenuItem value={percent} key={i}>
            {percent}%
          </MenuItem>
        )}
      </Select>
    </Stack>
  )
}