import { Box, CircularProgress, IconButton, ThemeProvider } from "@mui/material";
import { DashboardSection } from "../Components/DashboardSection";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { Initiative, IntegrityId, selectAllCompanies } from "../Store/CompanySlice";
import { selectCurrentUser } from "../Store/UserSlice";
import Masonry from "@mui/lab/Masonry";
import { plural } from "pluralize";
import { AgileBitesBanner } from "../Components/AgileBitesBanner";
import { IntegrityTheme } from "../Styles";
import { useGetLogo } from "../Hooks/useGetLogo";
import { RSSFeed, getRSSFeed } from "../Services/RSS";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { DashboardLinkItem } from "../Components/Links/DashboardLinkItem";
import { IsInitiativeExpired, LinkData } from "../Services/CompanyService";
import EditIcon from "@mui/icons-material/Edit";
import InventoryIcon from '@mui/icons-material/Inventory';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DocumentInfo, getDocumentInfos } from "../Store/DocumentSlice";
import { DashboardDocumentsData } from "../Components/Documents/DashboardDocumentsData";
import { GetMatchingCompany, UpdateCompanyUserPairs } from "../Services/CompanyUserPairService";
import { DashboardArchivedInitiativesItem } from "../Components/Initiative/DashboardArchivedInitiativesItem";
import { AccountHeader } from "../Components/AccountHeader";
import { ClientLevelMetricsGrid } from "../Components/Metrics/ClientLevelMetrics/ClientLevelMetricsGrid";
import { GoToInitiativeDashboard } from "./InitiativeDashboardPage";
import { DashboardActiveInitiativesSection } from "../Components/Client/DashboardActiveInitiativesSection";
import { GoToClientAccountPage } from "./ClientAccountPage";
import { DashboardArticleItem } from "../Components/Articles/DashboardArticleItem";
import { Article, getArticle } from "../Store/ArticleSlice";
import { ErrorSnackbar } from "../Services/Snackbars";

export function GoToClientDashboard(navigate: NavigateFunction, currentUserId: string, companyId: string) {
  UpdateCompanyUserPairs(currentUserId, companyId);
  navigate("/ClientDashboard");
}

export function ClientDashboardPage() {
  const navigate = useNavigate();
  const [clientDocuments, setClientDocuments] = useState<DocumentInfo[]>();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const allCompanies = useAppSelector(selectAllCompanies);
  const [companyArticles, setCompanyArticles] = useState<Article[]>([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [podcastFeed, setPodcastFeed] = useState<RSSFeed>();
  const mostRecentPodcast = podcastFeed?.items.at(0);
  const isIntegrityAdmin = (currentUser?.isAdmin && currentUser.companyId === IntegrityId) ?? false;

  useEffectOnce(async () => {
    try
    {
      const feed = await dispatch(getRSSFeed({ url: "https://anchor.fm/s/af939c48/podcast/rss" })).unwrap();
      setPodcastFeed(feed);
    }
    catch (e)
    {
      console.log("Unable to show podcast feed: " + (e as Error).message);
    }
  })

  const { selectedCompany } = useMemo(() => {
    let company = undefined;
    if (currentUser)
      company = GetMatchingCompany(currentUser, allCompanies);
    return { selectedCompany: company };
  }, [currentUser, allCompanies]);

  const {
    FetchLogo,
    companyLogo
  } = useGetLogo();

  const GetDocs = useCallback(async () => {
    setIsLoadingPage(true);
    if (selectedCompany) 
    {
      try
      {
        const clientDocs = await dispatch(getDocumentInfos({ companyId: selectedCompany.id })).unwrap();
        setClientDocuments(clientDocs);
      }
      catch (e)
      {
        console.log("Unable to load documents: " + (e as Error).message);
      }
    }
    setIsLoadingPage(false);
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    GetDocs();
  }, [GetDocs]);

  const GetData = useCallback(async () => {
    setIsLoadingPage(true);
    try
    {
      setCompanyArticles(await dispatch(getArticle({companyId: selectedCompany?.id ?? "", userCompanyId: currentUser?.companyId ?? ""})).unwrap());
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoadingPage(false);
  }, [selectedCompany]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  useEffect(() => {
    FetchLogo(selectedCompany);
  }, [FetchLogo, selectedCompany]);

  function AdminSelectInitiative(initiativeId: string) {
    if (currentUser)
      GoToInitiativeDashboard(navigate, currentUser.id, initiativeId);
  }

  const linkSection = (links: LinkData[]) => (
    <div>
      <DashboardLinkItem linkData={links} />
    </div>
  );

  const archiveData = useMemo(() => selectedCompany?.initiatives.filter(i => i.isActive && IsInitiativeExpired(i)) ?? [], [selectedCompany]);
  const archiveSection = (initiatives: Initiative[]) => (
    <div>
      <DashboardArchivedInitiativesItem archivedInitiatives={initiatives} select={AdminSelectInitiative} />
    </div>
  );
  const docSection = (docs: DocumentInfo[]) => (
    <div>
      <DashboardDocumentsData docInfos={docs} getDocs={GetDocs} canDelete={false} canArchive={false} documentTerm={selectedCompany?.terms.documentTerm ?? "Document"} />
    </div>
  );

  return (
    <>
      {isLoadingPage &&
        <div className="flex justify-center my-4">
          <CircularProgress color="warning" />
        </div>
      }

      {!isLoadingPage && currentUser &&
        <ThemeProvider theme={IntegrityTheme}>
          <Box sx={{ marginX: 8 }}>
            <AccountHeader currentUser={currentUser} companyLogo={companyLogo}
              endIcon={
                isIntegrityAdmin ?
                  <IconButton title="Edit Client" onClick={() => GoToClientAccountPage(navigate, selectedCompany?.id ?? "")}>
                    <EditIcon sx={{ fontSize: 20, color: "black" }} />
                  </IconButton>
                  :
                  undefined
              }
            />
            {mostRecentPodcast &&
              <AgileBitesBanner feed={mostRecentPodcast} />
            }
            {selectedCompany && currentUser &&
              <Box sx={{ marginY: 4 }}>
                <DashboardActiveInitiativesSection company={selectedCompany} onClick={AdminSelectInitiative} />
                <ClientLevelMetricsGrid selectedCompany={selectedCompany} />
                <Masonry columns={{ xs: 1, sm: 2, md: 2, xl: 3 }} spacing={4} sx={{ width: "auto" }}>
                  {selectedCompany.links.length > 0 &&
                    <DashboardSection title={`${plural(selectedCompany.terms.linkTerm)}`} expandable={true}
                      items={linkSection(selectedCompany.links)} />
                  }
                  {clientDocuments && clientDocuments?.length > 0 &&
                    <DashboardSection title={`${plural(selectedCompany.terms.documentTerm)}`} expandable={true} button={<IconButton title="View Archive"><InventoryIcon /></IconButton>}
                      items={docSection(clientDocuments)} />
                  }
                  {companyArticles.length > 0 &&
                      <DashboardSection title="Articles" expandable={true}
                        items={companyArticles.map((article, index) => <DashboardArticleItem key={index} article={article} company={selectedCompany}/>)}
                      />
                    }
                  {archiveData && archiveData.length > 0 &&
                    <DashboardSection title={`Archived/Closed ${plural(selectedCompany.terms.initiativeTerm)}`} expandable={true}
                      items={archiveSection(archiveData)} />
                  }
                </Masonry>
              </Box>
            }
          </Box>
        </ThemeProvider>
      }
    </>
  )
}