
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TableSortLabel } from '@mui/material';
import { SortConfig } from '../Hooks/useSorter';

interface SortLabelProps {
  sortConfig: SortConfig
  sortKey: string
  heading: string
  RequestSort: (sortKey: string) => void
  disabled?: boolean
}

export function SortLabel(props: SortLabelProps) {
  return (
    <TableSortLabel
      sx={{
        ".MuiTableSortLabel-icon": {
          opacity: '1 !important',
          fontSize: 26
        },
        "&.Mui-active": {
          ".MuiTableSortLabel-icon": {
            //fontSize: 26
          }
        }
      }}
      IconComponent={props.sortConfig.key === props.sortKey ? ArrowDownwardIcon : ImportExportIcon}
      onClick={() => props.RequestSort(props.sortKey)} active={props.sortConfig.key === props.sortKey}
      direction={props.sortConfig.direction}
      disabled={props.disabled}
    >
      {props.heading}
    </TableSortLabel>
  )
}
