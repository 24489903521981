import { Company } from "../../Store/CompanySlice";
import { useEffect, useState } from "react";
import { ThroughputData, ThroughputProgressDate } from "../../Services/CompanyService";
import { ValidateDateInfo, ValidationFailedPrefix } from "../../Services/Validation/Validation";
import { BaseModal } from "../BaseModal";
import { FileUpload } from "../FileUpload";
import { Typography } from "@mui/material";
import { ErrorSnackbar } from "../../Services/Snackbars";
import { ThroughputValidationReasons, ValidateEditCompanyThroughput } from "../../Services/Validation/ThroughputValidation";
import { v4 } from "uuid";
import { AddLeadingYearDigits, MakeDateString } from "../../Services/DateHelpers";
import { plural } from "pluralize";
import { CapitalizeAll } from "../../Services/Capitalize";
import { CompanyThroughputPreview } from "./CompanyThroughputPreview";

const fileHeaders = {
  key: {
    name: "KEY", required: false
  },
  category: {
    name: "CATEGORY", required: false
  },
  title: {
    name: "TITLE", required: false
  }
}

export const UploadThroughputIds = {
  modal: "uploadThroughputModal",
  fileUpload: {
    fileInput: "uploadThroughputFileInput",
    chooseFileButton: "uploadThroughputChooseFileButton",
    submitButton: "uploadThroughputSubmitButton"
  },
  closeModalButton: "uploadThroughputCloseModalButton"
}

interface UploadThroughputModalProps {
  company: Company
  isOpen: boolean
  HandleClose: () => void
  SubmitUpdateThroughput: (companyId: string, dataList: ThroughputData[], emptyDataCheck: boolean) => Promise<boolean>
}

type Warning = { message: string, line?: number };
type ParseResult = { success: true, parsedData: ThroughputData[] } | { success: false, failureReasons: Warning[] };

export function UploadCompanyThroughputModal(props: UploadThroughputModalProps) {
  const [fileData, setFileData] = useState<ThroughputData[]>([]);
  const [fileWarning, setFileWarning] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const existingThroughput = props.company.throughput;

  useEffect(() => {
    setFileData([]);
    setFileWarning("");
    setFile(null);
  }, [props.isOpen])

  function ParseFile(fileContent: string | ArrayBuffer | null): ParseResult {
    let parseData: ThroughputData[] = [];
    let warnings: Warning[] = [];

    function FailParse(reason?: string): ParseResult {
      if (reason)
        warnings.push({ message: reason });
      return { success: false, failureReasons: warnings };
    }

    if (!fileContent || typeof (fileContent) !== 'string')
      return FailParse("Something went wrong when trying to load that file.");

    const lines = fileContent.split("\n");
    if (lines.length < 2)
      return FailParse("File too small; either the headers are missing or there is no data.");

    const positionMap = new Map<string, number>();
    const headersLine = lines.at(0);
    if (headersLine)
    {
      const headers = headersLine.split(",");
      for (let col = 0; col < headers.length; col++)
      {
        const columnName = headers[col].toUpperCase().replaceAll(" ", "").trim();
        //if (columnName)
        positionMap.set(columnName, col);
      }

      for (const header of Object.values(fileHeaders))
      {
        if (!positionMap.has(header.name) && header.required)
          warnings.push({ message: `Header missing: ${header.name}.` });
      }
      if (warnings.length)
        return FailParse();
    }


    function GetWord(header: string, words: string[]) {
      const trimmedHeader = header.toUpperCase().replaceAll(" ", "").trim();
      const position = positionMap.get(trimmedHeader);
      if (position === undefined)
        return;
      else
        return words.at(position)?.trim();
    }

    function ParseDate(dateString: string) {
      const dateWords = dateString.split('/');
      const month = parseInt(dateWords.at(0) ?? "");
      const day = parseInt(dateWords.at(1) ?? "");
      const year = parseInt(AddLeadingYearDigits(parseInt(dateWords.at(2) ?? "")));
      let date = "";
      if (month && day && year)
      {
        const dateInfo = { day, month, year };
        if (ValidateDateInfo(dateInfo).success)
          date = MakeDateString(dateInfo);
      }
      return date;
    }

    function ParseLine(lineCount: number) {
      const line = lines.at(lineCount)?.trim();
      if (!line)
        return;

      const wordsInLine: string[] = [];
      let inQuotes = false;
      let word = "";
      for (let i = 0; i < line.length; i++)
      {
        const letter = line[i];
        const endOfLine = i === line.length - 1;
        if (letter === "," && !inQuotes)
        {
          wordsInLine.push(word.trim());
          word = "";
        }
        else if (letter === "\"")
        {
          if (inQuotes)
          {
            if (line.at(i + 1) === "\"")
            {
              word += letter;
              i++;
            }
            else
              inQuotes = false;
          }
          else
            inQuotes = true;
        }
        else
          word += letter;

        if (endOfLine)
        {
          wordsInLine.push(word.trim());
          word = "";
        }
      }

      if (wordsInLine.length !== positionMap.size)
      {
        warnings.push({ message: `${wordsInLine.length} columns detected; expected ${positionMap.size} columns.`, line: lineCount });
        return;
      }

      const keyString = GetWord(fileHeaders.key.name, wordsInLine);
      const key = keyString ? keyString : v4();
      
      const categoryString = GetWord(fileHeaders.category.name, wordsInLine);
      const categoryId = props.company.categories.find(c => c.title.toLowerCase() === categoryString?.toLowerCase())?.id ?? props.company.categories.find(c => c.id === props.company.defaultCategoryId)?.id ?? "";
      
      const initiativeString = GetWord(props.company.terms.initiativeTerm, wordsInLine);
      const initiativeId = props.company.initiatives.find(i => i.title.toLowerCase() === initiativeString?.toLowerCase())?.id ?? "";
      
      const title = GetWord(fileHeaders.title.name, wordsInLine) ?? "";

      const progressDates: ThroughputProgressDate[] = [];
      for(const column of props.company.workflow.columns)
      {
        const dateString = GetWord(column.title, wordsInLine) ?? "";
        progressDates.push({workflowColumnId: column.id, enterColumnDate: ParseDate(dateString)});
      }

      const matchingThroughput = existingThroughput.find(t => t.key === key);
      const id = matchingThroughput?.id ?? v4();
      const dataEntry: ThroughputData = { id, key, categoryId, initiativeId, title, progressDates };
      const validate = ValidateEditCompanyThroughput([props.company], props.company.id, [dataEntry]);
      if (validate.success)
        parseData.push(dataEntry);
      else
        warnings.push({ message: validate.message, line: lineCount });
    }


    for (let i = 1; i < lines.length; i++)
      ParseLine(i);

    if (warnings.length)
      return FailParse();
    
    return { success: true, parsedData: parseData };
  }

  function ReceiveFile(file: File | null) {
    if (!file)
      return;

    let splitName = file.name.split('.');
    let extension = splitName[splitName.length - 1];
    if (extension !== 'csv')
    {
      setFile(null);
      setFileData([]);
      setFileWarning("");
      ErrorSnackbar(ValidationFailedPrefix + ThroughputValidationReasons.invalidFileType);
      return;
    }

    setFile(file);

    const reader = new FileReader();
    reader.onload = function (e) {

      const response = ParseFile(reader.result);
      if (response.success)
      {
        setFileData(response.parsedData);
        setFileWarning("");
      }
      else
      {
        let warnings = response.failureReasons;
        let warningMessage = "";
        if (warnings.length)
        {
          warningMessage = 'Warning: This file contains data that is not properly formatted.';
          warningMessage += `\n${warnings.map(w => `${w.line ? `Line ${w.line + 1}) ` : ""}${w.message}`).join('\n')}`
        }

        setFileWarning(warningMessage);
        setFileData([]);
      }
    }
    reader.readAsText(file);
  }

  async function UploadFile() {
    if (!isUploading)
    {
      setIsUploading(true);
      const response = await props.SubmitUpdateThroughput(props.company.id, fileData, true);
      setIsUploading(false);
      if (response)
        props.HandleClose();
    }
  }

  return (
    <BaseModal
      cypressData={{ modal: UploadThroughputIds.modal, closeModalButton: UploadThroughputIds.closeModalButton }}
      open={props.isOpen}
      onClose={() => props.HandleClose()}
      title={`Upload ${CapitalizeAll(plural(props.company.terms.throughputTerm))}`}
      subtitle={`${props.company.name}`}
      maxWidth={"lg"}
    >
      <div className="space-y-4">
        {fileData.length === 0 &&
          <Typography>Submit a .csv file formatted as follows:</Typography>
        }
        <CompanyThroughputPreview company={props.company} throughput={fileData}/>
        <Typography>{fileWarning}</Typography>
        <div className="flex">
          <FileUpload cypressData={UploadThroughputIds.fileUpload} accept={'.csv'} file={file} setFile={ReceiveFile} isUploading={isUploading} UploadFile={UploadFile} invalidFile={fileWarning !== "" || fileData.length === 0} />
        </div>
      </div>
    </BaseModal >
  )
}