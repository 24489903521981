import { Fragment, useEffect, useMemo, useState } from "react";
import { FindItemsRemainingByInitiative, GetCompletedItems, GetItemsBySelectedCategoriesAndInitiative } from "../../Services/CompanyService";
import { InitiativeFilter } from "../../Services/Filters";
import { Company, Initiative, IntegrityId } from "../../Store/CompanySlice";
import { defaultRowStyle, IntegrityTheme, probabilityColors, tableButtonFontSize, tableCellFontSize, TableHeaderStyle, tooltipStyle } from "../../Styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Stack, ThemeProvider } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { User } from "../../Store/UserSlice";
import { MakeClone } from "../../Services/Cloning";
import { useSorter } from "../../Hooks/useSorter";
import { usePaginator } from "../../Hooks/usePaginator";
import { Paginator } from "../Paginator";
import { initPageStateEnum } from "../../Pages/InitiativesPage";
import { ProgressBar } from "./ProgressBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SortLabel } from "../SortLabel";
import { ForecastCompletionProbability } from "../../Services/ProbabilitySimulationService";
import { useEffectOnce } from "../../Hooks/useEffectOnce";
import { GoToInitiativeDashboard } from "../../Pages/InitiativeDashboardPage";

export const InitiativeTableIds = {
  table: "initiativesTable",
  totalItems: 'initiativeTableTotalItems',
  initiativeTitle: 'initiativesTableTitle',
  companyName: 'initiativesTableCompanyName',
  startDate: "initiativesTableStartDate",
  targetDate: "initiativesTableTargetDate",
  isActive: "initiativesTableIsActive",
}

interface InitiativesProps {
  companyList: Company[]
  currentUser: User
  radioStatus: string
  addInitiative: boolean
  setAddInitiative: (value: boolean) => void
  searchedComp: string
  setSearchedComp: (value: string) => void
  searchedInit: string
  setSearchedInit: (value: string) => void
  state: initPageStateEnum
  setState: (newState: initPageStateEnum) => void
}

export interface InitCompanyDisplay {
  initiative: Initiative
  company: Company
  companyName: string
  initiativeTitle: string
  startDateTime: Date
  targetDateTime: Date
  probabilityValue: number | undefined
  probabilityStatus: string
  itemsRemaining: number
}

export interface SortConfig {
  key: string
  direction: 'asc' | 'desc'
}

export default function InitiativesTable(props: InitiativesProps) {
  const [displayItems, setDisplayItems] = useState<InitCompanyDisplay[]>([])
  const { PaginateItems, ...paginator } = usePaginator();
  const [initiativesLoaded, setInitiativesLoaded] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffectOnce(() => {
    const initName = searchParams.get("initiativeName");
    if (initName)
      props.setSearchedInit(initName);
    else
      props.setSearchedInit("");
  });

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  //needs to be before filtering, since filtering does not affect probability
  const probabilityCalculatedInitiatives = useMemo(() => {
    const sortedCompanies = MakeClone(props.companyList).sort((a, b) => a.name.localeCompare(b.name));
    let allInitCompanyDisplays: InitCompanyDisplay[] = [];
    for (const company of sortedCompanies)
    {
      const filteredInits = company.initiatives.sort((a, b) => a.title.localeCompare(b.title));
      for (const init of filteredInits)
      {
        const itemsRemaining = FindItemsRemainingByInitiative(company, init);
        const itemsBySelectedCategories = GetItemsBySelectedCategoriesAndInitiative(company, init);
        const completedItems = GetCompletedItems(itemsBySelectedCategories, company.workflow, [
          { startDate: company.metricStartDate, endDate: company.metricEndDate }
        ]);
        const probability = ForecastCompletionProbability(init.targetDate, completedItems, itemsRemaining, company.workflow);
        allInitCompanyDisplays.push({
          initiative: init, company: company,
          initiativeTitle: init.title,
          itemsRemaining: itemsRemaining,
          probabilityValue: probability.value,
          probabilityStatus: probability.status,
          companyName: company.name,
          startDateTime: new Date(init.startDate),
          targetDateTime: new Date(init.targetDate)
        });
      }
    }
    return allInitCompanyDisplays;
  }, [props.companyList])

  const filteredItems = useMemo(() => {
    const searchedItems = probabilityCalculatedInitiatives
      .filter(item => item.initiativeTitle.toLowerCase().includes(props.searchedInit.toLowerCase()) && item.companyName.toLowerCase().includes(props.searchedComp.toLowerCase()));
    const filteredInitiatives = searchedItems.map(item => item.initiative);

    const matchingInitiatives = InitiativeFilter(filteredInitiatives, props.radioStatus);
    setInitiativesLoaded(true);

    return searchedItems.filter(item => matchingInitiatives.find(init => init.id === item.initiative.id));
  }, [probabilityCalculatedInitiatives, props.searchedComp, props.searchedInit, props.radioStatus]);

  const sortedItems = useMemo(() => {
    return SortItems(filteredItems);
  }, [filteredItems, SortItems]);

  useEffect(() => {
    const paginatedItems = PaginateItems(sortedItems);
    setDisplayItems(paginatedItems);
  }, [sortedItems, PaginateItems]);

  const RequestSort = (key: string) => {
    UpdateSortConfig(key);
    ResetPageNumber();
  }

  function GetHealthIndicator(probability: number | undefined) {
    if (probability)
    {
      if (probability >= 85)
        return probabilityColors.green;
      if (probability >= 51)
        return probabilityColors.yellow;
      if (probability >= 0)
        return probabilityColors.red;
    }
    return probabilityColors.gray;
  }

  function ResetPageNumber() {
    paginator.HandlePaginationChange(null, 1);
  }

  function SaveInitiativeInURL(initiativeId: string) {
    const initiatives = props.companyList.flatMap(c => c.initiatives);
    const matchingInitiative = initiatives.find(i => i.id === initiativeId);
    if (matchingInitiative)
      setSearchParams({ initiativeName: matchingInitiative.title });
  }

  function AdminSelectInitiative(initiativeId: string) {
    if (props.currentUser)
    {
      SaveInitiativeInURL(initiativeId);
      GoToInitiativeDashboard(navigate, props.currentUser.id, initiativeId);
    }
  }

  const totalInits = displayItems.length;

  const userCompanyId = props.currentUser?.companyId;
  const isIntegrityUser = userCompanyId === IntegrityId;

  return (
    <>
      <ThemeProvider theme={IntegrityTheme}>
        <div className="grid grid-cols-1 w-full h-auto">
          {totalInits !== 0 &&
            <div className="col-span-1 mt-2">
              <TableContainer elevation={12} component={Paper}>
                <Table className="table-fixed w-full outline outline-3 bg-gray-100" size="small" sx={{ minWidth: 1100 }}>
                  <TableHead className="outline outline-1">
                    <TableRow sx={{
                      borderBottom: "2px solid black",
                      "& th": {
                        fontSize: 18,//tableHeaderFontSize,
                        fontWeight: "bold",
                        fontFamily: "Arial, Helvetica"
                      }
                    }}>
                      {isIntegrityUser &&
                        <TableHeaderStyle sx={{ width: "10vw" }}>
                          <SortLabel sortConfig={sortConfig} heading="Company" sortKey='companyName' RequestSort={RequestSort} />
                        </TableHeaderStyle>
                      }
                      <TableHeaderStyle sx={{ width: "13vw" }}>
                        <SortLabel sortConfig={sortConfig} heading="Title" sortKey='initiativeTitle' RequestSort={RequestSort} />
                      </TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "10vw" }}>
                        <SortLabel sortConfig={sortConfig} heading="Start Date" sortKey='startDateTime' RequestSort={RequestSort} />
                      </TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "13vw" }}>
                        <SortLabel sortConfig={sortConfig} heading="Target Completion" sortKey='targetDateTime' RequestSort={RequestSort} />
                      </TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "10vw" }}>Completed Items / Total Items</TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "10vw" }}>Percent Completed</TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "13vw" }}>
                        <SortLabel sortConfig={sortConfig} heading="On-Time Completion Probability" sortKey='probabilityValue' RequestSort={RequestSort} />
                      </TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "7vw" }}>Active Status</TableHeaderStyle>
                      <TableHeaderStyle sx={{ width: "10vw" }}></TableHeaderStyle>
                    </TableRow>
                  </TableHead>
                  <TableBody data-cy={InitiativeTableIds.table}>
                    {displayItems.map((displayItem, index) => {
                      const itemsCompleted = displayItem.initiative.totalItems - displayItem.itemsRemaining;
                      const probability = { value: displayItem.probabilityValue, status: displayItem.probabilityStatus };
                      const healthIndicator = GetHealthIndicator(probability.value);
                      const tooltipMessage = probability.value === undefined ? probability.status :
                        probability.value === 0 ? "Data may be insufficient or may indicate a very low probability of success" :
                          probability.value + "%";
                      const completedPercentage = Math.round(Math.min((itemsCompleted / displayItem.initiative.totalItems), 1) * 100);
                      const itemsCompletedTooltipMessage = `${displayItem.company.terms.initiativeTerm} does not have any items`;
                      return (
                        <Fragment key={index}>
                          <TableRow key={index} className={defaultRowStyle} sx={{
                            borderBottom: "1px solid black",
                            "& td": {
                              fontSize: tableCellFontSize,
                              fontFamily: "Arial, Helvetica",
                              color: "#21345b"
                            }
                          }}>
                            {isIntegrityUser &&
                              <TableCell data-cy={InitiativeTableIds.companyName} sx={{ fontSize: tableCellFontSize }}>{displayItem.company.name}</TableCell>
                            }
                            <TableCell data-cy={InitiativeTableIds.initiativeTitle}>{displayItem.initiativeTitle}</TableCell>
                            <TableCell data-cy={InitiativeTableIds.startDate}>{displayItem.initiative.startDate ? new Date(displayItem.initiative.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "N/A"}</TableCell>
                            <TableCell data-cy={InitiativeTableIds.targetDate}>{displayItem.initiative.targetDate ? new Date(displayItem.initiative.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "N/A"}</TableCell>
                            <TableCell data-cy={InitiativeTableIds.totalItems}>{itemsCompleted} / {displayItem.initiative.totalItems}</TableCell>
                            {displayItem.initiative.totalItems > 0 ?
                              <TableCell align="left" sx={{ width: "25%" }}>
                                <ProgressBar bgcolor="blue" completed={completedPercentage} />
                              </TableCell>
                              :
                              <TableCell align="left" sx={{ width: "25%" }} className={tooltipStyle} title={completedPercentage > 0 ? `${completedPercentage.toString()}%` : itemsCompletedTooltipMessage}>
                                <span>NA</span>
                                <i className="material-icons" style={{ fontSize: '15px', marginLeft: '15px', marginTop: '10px' }}>info_outline</i>
                              </TableCell>
                            }
                            <TableCell className={tooltipStyle} title={tooltipMessage}>
                              {probability.value !== undefined ?
                                <ProgressBar key={index} bgcolor={healthIndicator} completed={probability.value} />
                                :
                                <>
                                  <span>NA</span>
                                  <i className="material-icons" style={{ fontSize: '15px', marginLeft: '15px', marginTop: '10px' }}>info_outline</i>
                                </>
                              }
                            </TableCell>
                            <TableCell data-cy={InitiativeTableIds.isActive}>{displayItem.initiative.isActive ? "Active" : "Inactive"}</TableCell>
                            <TableCell>
                              <Stack direction="row">
                                <IconButton title="View Dashboard" color="primary" onClick={() => AdminSelectInitiative(displayItem.initiative.id)}>
                                  <VisibilityIcon sx={{ fontSize: tableButtonFontSize }} />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {props.currentUser?.companyId === IntegrityId && props.currentUser.isAdmin &&
                <Paginator paginator={{ PaginateItems, ...paginator }} />
              }
            </div>
          }
        </div>
        {totalInits === 0 && initiativesLoaded === true && <div className="m-2 p-2 text-3xl font-bold">No Initiatives to Display</div>}
      </ThemeProvider>
    </>
  )
}