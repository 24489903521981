import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Company } from "../../../Store/CompanySlice";
import { Box, Grid, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import { plural } from "pluralize";
import { Capitalize } from "../../../Services/Capitalize";
import { metricsFontLarge, tooltipStyle } from "../../../Styles";
import { ItemColor } from "../InitiativeLevelMetrics/MetricsGrid";
import { DisplayInfo } from "./ClientLevelMetricsGrid";


interface ClientItemDisplayMetricsProps {
  company: Company
  displayInfo: DisplayInfo
  categoryMap: Map<string, { color: ItemColor, categoryTitle: string }>
}

export function ClientItemDisplayMetrics(props: ClientItemDisplayMetricsProps) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const handleChangePage = (
    event: any,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const indexOfLastItem = page * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedItems = useMemo(() => props.displayInfo?.items.slice(indexOfFirstItem, indexOfLastItem) ?? [], [props.displayInfo, indexOfFirstItem, indexOfLastItem]);
  const remainingSpaces = useMemo(() => (props.displayInfo?.items.length ?? 0) > rowsPerPage ? Array.from(new Array(rowsPerPage - slicedItems.length)) : [], [rowsPerPage, slicedItems, props.displayInfo]);
  const textFontSize = 12;

  const ref = useRef<HTMLElement>();
  const [width, setWidth] = useState<number | undefined>(0);

  useEffect(() => {
    setWidth(ref.current?.offsetWidth);

    const getwidth = () => {
      setWidth(ref.current?.offsetWidth);
    };
    window.addEventListener("resize", getwidth);

    return () => window.removeEventListener("resize", getwidth);
  }, []);

  const initiativeColumnCount = useMemo(() => {
    if (width)
    {
      if (width > 330)
      {
        if (width > 362)
          return 6;
        else
          return 7;
      }
    }
    return 8;
  }, [width]);

  return (
    <Box sx={{ width: "100%", height: "100%" }} ref={ref}>
      <Stack justifyContent="space-between" sx={{ height: "100%", width: "100%" }}>
        {props.displayInfo && props.displayInfo.items.length > 0 ?
          <>
            <Stack alignContent="start" sx={{ height: "100%", width: "100%" }} gap={.5}>
              {slicedItems.map((item, index) => {
                let dateDisplay = "";
                if (item.startDate)
                {
                  const splitStartDate = item.startDate.split("-");
                  dateDisplay += parseInt(splitStartDate[1]).toString() + "/" + parseInt(splitStartDate[2]).toString();
                  if (item.endDate)
                  {
                    const splitEndDate = item.endDate.split("-");
                    dateDisplay += " - " + parseInt(splitEndDate[1]).toString() + "/" + parseInt(splitEndDate[2]).toString();
                  }
                }
                return (
                  <Fragment key={index}>
                    {item.id === props.displayInfo.firstStartedItemId &&
                      <Grid container wrap="nowrap">
                        <Grid item xs display="flex">
                          <Typography variant="caption" sx={{ marginX: 1 }}>{props.displayInfo.startColumnName} {Capitalize(plural(props.company.terms.throughputTerm ?? "Item"))}</Typography>
                        </Grid>
                        <Grid item xs={4} display="flex" justifyContent="end" sx={{ paddingRight: 1 }} zeroMinWidth>
                          <Typography noWrap variant="caption">Begin</Typography>
                        </Grid>
                      </Grid>
                    }
                    {item.id === props.displayInfo.firstCompletedItemId &&
                      <Grid container sx={{ marginTop: index !== 0 ? 1 : 0 }} wrap="nowrap">
                        <Grid item xs display="flex">
                          <Typography variant="caption" sx={{ marginX: 1 }}>{props.displayInfo.endColumnName} {Capitalize(plural(props.company.terms.throughputTerm ?? "Item"))}</Typography>
                        </Grid>
                        <Grid item xs={4} display="flex" justifyContent="end" sx={{ paddingRight: 1 }} zeroMinWidth>
                          <Typography noWrap variant="caption">Begin - End</Typography>
                        </Grid>
                      </Grid>
                    }
                    <Box sx={{ marginLeft: 2, marginTop: (index === 0 && item.id !== props.displayInfo.firstStartedItemId && item.id !== props.displayInfo.firstCompletedItemId) ? 2 : 0 }}>
                      <Tooltip className={tooltipStyle} sx={{ width: "100%" }} title={""} key={index}>
                        <Grid container wrap="nowrap" columns={24}>
                          <Grid item xs zeroMinWidth>
                            <Tooltip title={item.title}>
                              <Typography noWrap variant="body2" sx={{ color: item.color.code, "& .MuiChip-label": { fontSize: textFontSize } }}>{item.title}</Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={7} zeroMinWidth display="flex" justifyContent="start" sx={{ paddingX: 1 }}>
                            <Tooltip title={item.initiativeTitle}>
                              <Typography noWrap variant="caption">{item.initiativeTitle}</Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={initiativeColumnCount} display="flex" justifyContent="end" sx={{ paddingRight: 1 }} zeroMinWidth>
                            <Typography noWrap variant="caption">{dateDisplay}</Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Box>
                  </Fragment>
                )
              })}
              {remainingSpaces.map((i, index) =>
                <Stack direction="row" justifyContent="space-between" alignItems="center" key={index}>
                  <Typography variant="body2"> </Typography>
                </Stack>
              )}
            </Stack>
            {props.displayInfo.items.length > rowsPerPage &&
              <Box display="flex" justifyContent="end">
                <Pagination
                  count={Math.ceil(props.displayInfo.items.length / rowsPerPage)}
                  onChange={handleChangePage}
                  page={page}
                  showFirstButton
                  showLastButton
                  size="small"
                  sx={{ alignSelf: "flex-end" }}
                />
              </Box>
            }
          </>
          :
          <>
            <Box />
            <Typography alignContent="center" sx={metricsFontLarge}>
              N/A
            </Typography>
            <Box />
          </>
        }
      </Stack>
    </Box>
  )
}
