import { IconButton } from "@mui/material"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface VisibilityToggleProps {
  ToggleFunc: (...args: any[]) => void
  isVisible: boolean
  title?: string
}

export function VisiblityToggle(props: VisibilityToggleProps) {

  return (
    <IconButton size={"small"} onClick={() => props.ToggleFunc()} title={"Show/Hide" + (props.title ?? "")}>
      {props.isVisible ? <VisibilityOffIcon fontSize={"inherit"} /> : <VisibilityIcon fontSize={"inherit"} />}
    </IconButton>
  )
}
