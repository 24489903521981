import { useEffect, useState } from "react";
import { Article } from "../../Store/ArticleSlice";
import { Company, Initiative, IntegrityId } from "../../Store/CompanySlice";
import { MakeClone } from "../../Services/Cloning";
import { v4 as UuidV4 } from "uuid";
import { DateInfo } from "../../Services/CompanyService";
import { Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { AccountActions } from "../AccountActions";
import { AccountField } from "../AccountField";
import { BaseModal } from "../BaseModal";
import { RichText } from "../Quill/RichText";
import { User } from "../../Store/UserSlice";
import { ArticleModalIds } from "./ArticleDataModal";
import FolderIcon from "@mui/icons-material/Folder";
import { DocumentManagementModal } from "../Documents/DocumentManagementModal";

interface ArticleUpsertModalProps {
    company: Company
    initiative: Initiative | undefined
    currentUser: User
    isOpen: boolean
    handleClose: () => void
    articleToEdit: Article | undefined
    isUpserting: boolean
    Save: (editedArticle: Article) => Promise<void>
    Cancel: () => void
    cypressData: {
        modal: string
        closeModalButton: string
        saveButton: string
        cancelButton: string
        newTitle: string
        newText: string
        isIntegrityOnly: string
    }
}

export function ArticleUpsertModal(props: ArticleUpsertModalProps)
{
    const [articleClone, setArticleClone] = useState<Article>();
    const newId = UuidV4();
    const today = new Date();
    const todayInfo: DateInfo = { month: today.getMonth() + 1, day: today.getDate(), year: today.getFullYear() }
    const [isDocModalOpen, setIsDocModalOpen] = useState(false);

    useEffect(() => {
        setArticleClone(props.articleToEdit);
    }, [props.articleToEdit])

    useEffect(() => {
        if (props.isOpen)
        {
            if (props.articleToEdit)
            {
                let clone = MakeClone(props.articleToEdit);
                clone.updatedBy = props.currentUser.name ?? "";
                clone.updatedDate = todayInfo;
                setArticleClone(clone);
            }
            
            else
                setArticleClone({
                    id: newId,
                    title: "",
                    text: "",
                    updatedBy: props.currentUser.name ?? "",
                    updatedDate: todayInfo,
                    companyId: props.company.id,
                    initiativeId: props.initiative?.id,
                    isIntegrityOnly: false 
                })
            }
        else
        {
            setArticleClone(undefined);
        }
    }, [props.isOpen, props.articleToEdit])

    function Close() {
        setIsDocModalOpen(false);
    }

    return (
        <><BaseModal
            open={props.isOpen}
            onClose={props.handleClose}
            title={`${props.articleToEdit ? "Edit" : "Add"} Article`}
            subtitle={props.articleToEdit?.title}
            cypressData={{ modal: props.cypressData.modal, closeModalButton: props.cypressData.closeModalButton }}
            >
            {articleClone &&
                <>
                <Grid item xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                    <Button data-cy={ArticleModalIds.documents} variant="contained" onClick={() => setIsDocModalOpen(true)} sx={{ fontSize: "1.2rem" }}>
                        <FolderIcon sx={{ color: "white", fontSize: "inherit", marginRight: 1 }} />
                        <Typography variant="button">Related Docs</Typography>
                    </Button>
                </Grid>
                <Stack gap={2}>
                    <AccountField title="Title" cypressData={props.cypressData.newTitle} value={articleClone.title} onChange={(e) => { setArticleClone({ ...articleClone, title: e.target.value }); } } />
                    <RichText cypressData={props.cypressData.newText} initialValue={articleClone.text} valueSetter={(value: string) => setArticleClone({ ...articleClone, text: value })}></RichText>
                    <div className="flex items-center justify-start">
                        <Checkbox data-cy={props.cypressData.isIntegrityOnly} color="darkBlue" checked={articleClone.isIntegrityOnly} onChange={e => { setArticleClone({ ...articleClone, isIntegrityOnly: e.target.checked }); } } />
                        <Typography variant="subtitle2">Integrity Only</Typography>
                    </div>
                    <AccountActions isSaving={props.isUpserting} Cancel={props.Cancel} Save={() => props.Save(articleClone)} cypressData={{ cancelButton: props.cypressData.cancelButton, saveButton: props.cypressData.saveButton }} />
                </Stack></>}
            </BaseModal>
        <DocumentManagementModal articleWithDocsId={articleClone?.id} userHasUploadPermission={props.currentUser.companyId === IntegrityId} company={props.company} currentUser={props.currentUser} title={"Related Documentation"} initiative={props.initiative} isOpen={isDocModalOpen} HandleClose={Close} />
        </>
    )
}