import ReactQuill from "react-quill"

interface ReadOnlyRichTextProps {
  cypressData: string
  text: string
}

export function ReadOnlyRichText(props: ReadOnlyRichTextProps) {

  return (
    <div data-cy={props.cypressData}>
    <ReactQuill value={props.text} readOnly={true} modules={{ toolbar: false }}
      style={{
        maxHeight: `500px`,
        overflow: "auto"
      }}
      theme="bubble"
    />
    </div>
  )
}
