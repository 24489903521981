import { AlertDialog } from "./AlertDialog"

export const DeleteAlertIds = {
  modal: "deleteAlertModal",
  confirmButton: "deleteAlertConfirmButton",
  cancelButton: "deleteAlertCancelButton"
}

interface DeleteAlertProps {
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  Delete: (id: string) => void,
  CancelDelete: () => void,
  id: string | undefined,
  title: string,
  description: string
  cypressData?: {
    modal: string,
    confirmButton: string,
    cancelButton: string
  }
}

export function DeleteAlert(props: DeleteAlertProps) {

  return (
    <AlertDialog title={props.title} description={props.description} isOpen={props.isOpen} setIsOpen={props.setIsOpen}
      cypressData={props.cypressData ?? DeleteAlertIds} Confirm={() => props.id ? props.Delete(props.id) : props.CancelDelete()} Cancel={props.CancelDelete}
    />
  )
}

