import { IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import { UserAvatar, UserWithAvatarUrl } from "./User/UserAvatar";
import EmailIcon from '@mui/icons-material/Email';
import { IntegrityId } from "../Store/CompanySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { IntegrityLogo } from "../Styles";

interface DashboardTeamItemProps {
  user: UserWithAvatarUrl
}

export function DashboardTeamItem(props: DashboardTeamItemProps) {
  const name = props.user.name || props.user.email;
  const integrityLinkedin = "https://www.linkedin.com/company/integrity-inspired-solutions/mycompany/";
  const integrityFacebook = "https://www.facebook.com/integrityinspired";
  const isIntegrity = props.user.companyId === IntegrityId;
  const userLinkedin = props.user.linkedin ?? (isIntegrity ? integrityLinkedin : undefined);
  const userFacebook = props.user.facebook ?? (isIntegrity ? integrityFacebook : undefined);

  return (
    <ListItem sx={{ marginY: 1 }}
      secondaryAction={
        <>
          {userLinkedin &&
            <Tooltip title="Linkedin">
              <IconButton edge="end" aria-label="linkedin" sx={{ marginRight: 2 }} href={userLinkedin} target="_blank">
                <FontAwesomeIcon icon={faLinkedin} className="text-[#0e76a8]" />
              </IconButton>
            </Tooltip>
          }
          {userFacebook &&
            <Tooltip title="Facebook">
              <IconButton edge="end" aria-label="facebook" sx={{ marginRight: 2 }} href={userFacebook} target="_blank">
                <FontAwesomeIcon icon={faFacebook} className="text-[#4968ad]" />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title={props.user.email}>
            <IconButton edge="end" aria-label="email" sx={{ marginRight: 2 }} href={"mailto: " + props.user.email}>
              <EmailIcon />
            </IconButton>
          </Tooltip>
        </>
      }
    >
      <ListItemAvatar sx={{ paddingX: 2 }}>
        <UserAvatar user={props.user} />
      </ListItemAvatar>
      <ListItemIcon sx={{ marginLeft: -2, marginRight: -3 }}>
        {props.user.companyId === IntegrityId &&
          <img src={IntegrityLogo} className="max-h-[40px]" alt="Integrity Inspired Solutions logo" />
        }
      </ListItemIcon>
      <ListItemText disableTypography
        primary={
          <Tooltip title={name} placement="bottom-start">
            <Typography variant="body2" noWrap textOverflow="ellipsis" ><b>{name}</b></Typography>
          </Tooltip>
        }
        secondary={
          <Typography variant="body2" noWrap textOverflow="ellipsis" >{props.user.role}</Typography>
        }
      />
    </ListItem>
  )
}
