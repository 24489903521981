import { Dialog, Typography } from "@mui/material";
import { DashboardSection } from "../DashboardSection";
import { DocumentInfo } from "../../Store/DocumentSlice";

interface ArchivedDocsModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  clientDocs: DocumentInfo[]
  initiativeDocs: DocumentInfo[]
  clientDocSection: JSX.Element
  initiativeDocSection: JSX.Element
}

export function ArchivedDocsModal(props: ArchivedDocsModalProps) {

  const clientDocSection = props.clientDocSection;
  const initiativeDocSection = props.initiativeDocSection;

  return (
    <Dialog open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      {props.clientDocs.length === 0 && props.initiativeDocs.length === 0 ?
        <Typography sx={{margin: 2}}>There are no archived documents</Typography>
        :
        <DashboardSection title="Archive" items={props.clientDocs.length > 0 ? (props.initiativeDocs.length > 0 ? [clientDocSection, initiativeDocSection] : clientDocSection) : initiativeDocSection} tooltipMessage={""} />
      }
    </Dialog>
  )
}