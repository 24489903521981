import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteDocument, DeleteDocumentRequest, DownloadDocument, DownloadDocumentRequest, GetAllDocumentInfos, GetAllDocumentInfosRequest, GetDocumentInfosByTags, GetDocumentInfosByTagsRequest, GetDocumentObjectUrls, GetDocumentObjectUrlsRequest, UpdateDocument, UpdateDocumentRequest, UploadDocument, UploadDocumentRequest } from "../Services/DocumentService";
import { RootState } from "./Store";
import { InCypress } from "../Services/CypressDetection";

const DATA_CONTAINER = "client-portal-data";
const AVATAR_CONTAINER = "client-portal-avatars";
const AGILE_BITES_CONTAINER = "client-portal-agilebites";
const LOGO_CONTAINER = "client-portal-logos";

const SAS_EXPIRATION_MINUTES = 5;

export interface DocumentInfo {
  tempUrl: string
  blobName: string
  createdOn: number | undefined
  lastModified: number | undefined
  metadata: {
    fileName: string
    shortDescription?: string
    longDescription?: string
    archived?: boolean
  }
  tags: {
    companyId?: string
    initiativeId?: string
    articleId?: string
  }
}

export interface DocumentState {
  documents: DocumentInfo[]
  avatars: {
    docInfos: DocumentInfo[]
    lastRetrieved: number
  }
  logos: {
    docInfos: DocumentInfo[]
    lastRetrieved: number
  }
}

const initialState: DocumentState = {
  documents: [],
  avatars: {
    docInfos: [],
    lastRetrieved: 0
  },
  logos: {
    docInfos: [],
    lastRetrieved: 0
  }
}

export const uploadDocument = createAsyncThunk(
  'documents/uploadDocument',
  async (args: UploadDocumentRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await UploadDocument(args, DATA_CONTAINER, sessionId);
  }
)

export const updateDocument = createAsyncThunk(
  'documents/updateDocuments',
  async (args: UpdateDocumentRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UpdateDocument(args, DATA_CONTAINER, sessionId);
    return response;
  }
)

export const getDocumentInfos = createAsyncThunk(
  'documents/getDocumentInfos',
  async (args: GetDocumentInfosByTagsRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetDocumentInfosByTags(args, DATA_CONTAINER, sessionId);
    return response.documents;
  }
)

export const getDocumentObjectUrls = createAsyncThunk(
  'documents/getDocumentObjectUrls',
  async (args: GetDocumentObjectUrlsRequest) => {
    const response = await GetDocumentObjectUrls(args);
    return response.docUrlPair;
  }
)

export const downloadDocument = createAsyncThunk(
  'documents/downloadDocument',
  async (args: DownloadDocumentRequest) => {
    const response = await DownloadDocument(args);
    return response;
  }
)

export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (args: DeleteDocumentRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await DeleteDocument(args, DATA_CONTAINER, sessionId);
    return response;
  }
)

export const getAvatarDocumentInfos = createAsyncThunk(
  'documents/getAvatarDocumentInfos',
  async (args: GetAllDocumentInfosRequest, { getState }) => {
    if(InCypress())
      return [];
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetAllDocumentInfos(args, AVATAR_CONTAINER, sessionId);
    return response.documents;
  }
)

export const getCachedAvatarDocumentInfos = createAsyncThunk(
  'documents/getCachedAvatarDocumentInfos',
  async (args: GetAllDocumentInfosRequest, { getState, dispatch }) => {
    const avatarSlice = (getState() as RootState).documents.avatars;
    const lastRetrieved = avatarSlice.lastRetrieved;
    const now = Date.now();
    const expirationTime = lastRetrieved + SAS_EXPIRATION_MINUTES * 60 * 1000;
    if (now > expirationTime)
    {
      try
      {
        const newAvatars = await dispatch(getAvatarDocumentInfos(args)).unwrap();
        return newAvatars;
      }
      catch (e)
      {
        //console.log("Unable to get avatars: " + (e as Error).message);
      }
    }
    const oldAvatars = avatarSlice.docInfos;
    return oldAvatars;
  }
)

export const uploadAvatar = createAsyncThunk(
  'documents/uploadAvatar',
  async (args: UploadDocumentRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UploadDocument(args, AVATAR_CONTAINER, sessionId);
    return response.blobName;
  }
)

export const getAgileBitesDocumentInfos = createAsyncThunk(
  'documents/getAgileBitesDocumentInfos',
  async (args: GetAllDocumentInfosRequest, { getState }) => {
    if(InCypress())
      return [];
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetAllDocumentInfos(args, AGILE_BITES_CONTAINER, sessionId);
    return response.documents;
  }
)

export const getLogoDocumentInfos = createAsyncThunk(
  'documents/getLogoDocumentInfos',
  async (args: GetAllDocumentInfosRequest, { getState }) => {
    if(InCypress())
      return [];
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetAllDocumentInfos(args, LOGO_CONTAINER, sessionId);
    return response.documents;
  }
)

export const getCachedLogoDocumentInfos = createAsyncThunk(
  'documents/getCachedLogoDocumentInfos',
  async (args: GetAllDocumentInfosRequest, { getState, dispatch }) => {
    const logoSlice = (getState() as RootState).documents.logos;
    const lastRetrieved = logoSlice.lastRetrieved;
    const now = Date.now();
    const expirationTime = lastRetrieved + SAS_EXPIRATION_MINUTES * 60 * 1000;
    if (now > expirationTime)
    {
      try
      {
        const newLogos = await dispatch(getLogoDocumentInfos(args)).unwrap();
        return newLogos;
      }
      catch (e)
      {
        //console.log("Unable to get logos: " + (e as Error).message);
      }
    }
    const oldLogos = logoSlice.docInfos;
    return oldLogos;
  }
)

export const uploadLogo = createAsyncThunk(
  'documents/uploadLogo',
  async (args: UploadDocumentRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UploadDocument(args, LOGO_CONTAINER, sessionId);
    return response.blobName;
  }
)

export const documentSlice = createSlice({
  name: "documents",
  initialState: initialState,
  reducers: {
    clearAvatars: (state) => {
      state.avatars.docInfos = [];
      state.avatars.lastRetrieved = 0;
    },
    clearLogos: (state) => {
      state.logos.docInfos = [];
      state.logos.lastRetrieved = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvatarDocumentInfos.fulfilled, (state, action) => {
        state.avatars.docInfos = action.payload;
        state.avatars.lastRetrieved = Date.now();
      })
      .addCase(getLogoDocumentInfos.fulfilled, (state, action) => {
        state.logos.docInfos = action.payload;
        state.logos.lastRetrieved = Date.now();
      })
  }
});

export const { clearAvatars, clearLogos } = documentSlice.actions;

export default documentSlice.reducer;
