import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { useState, useMemo } from "react";
import { ForecastCompletionDate } from "../../../Services/ProbabilitySimulationService";
import { metricsFontLarge, metricsFontSmall } from "../../../Styles";
import { plural } from "pluralize";
import { FindItemsRemainingByInitiative, GetCompletedItems, GetItemsBySelectedCategoriesAndInitiative } from "../../../Services/CompanyService";
import { InsufficientThroughputSection } from "./InsufficientThroughputSection";

interface CompletionDateMetricsProps {
  company: Company
  initiative: Initiative
}

export function CompletionDateMetrics(props: CompletionDateMetricsProps) {
  const [whenInputProbability, setWhenInputProbability] = useState(85);
  const itemsRemaining = useMemo(() => Math.max(FindItemsRemainingByInitiative(props.company, props.initiative), 0), [props.company, props.initiative]);
  const { expectedCompletionDate, completedItems } = useMemo(() => {
    const itemsByCategory = GetItemsBySelectedCategoriesAndInitiative(props.company, props.initiative);
    const completedItems = GetCompletedItems(itemsByCategory, props.company.workflow, [
      { startDate: props.company.metricStartDate, endDate: props.company.metricEndDate }
      //does not check for start and target date of initiative to allow for historical data; these items cannot be used to calc. items remaining
    ]);
    return { expectedCompletionDate: ForecastCompletionDate(completedItems, itemsRemaining, whenInputProbability, props.company.workflow), completedItems };
  }, [props.initiative, props.company, itemsRemaining, whenInputProbability]);
  const bigTextSX = metricsFontLarge;
  const smallTextSX = metricsFontSmall;
  const itemTerm = props.company.terms.throughputTerm.toLowerCase();

  const shouldUsePluralRemaining = itemsRemaining === 0 || itemsRemaining > 1;
  const remainingItemsTerm = useMemo(() => shouldUsePluralRemaining ? plural(itemTerm) : itemTerm, [shouldUsePluralRemaining, itemTerm]);

  const shouldUsePluralProjected = props.initiative.totalItems === 0 || props.initiative.totalItems > 1;
  const projectedItemsTerm = useMemo(() => shouldUsePluralProjected ? plural(itemTerm) : itemTerm, [shouldUsePluralProjected, itemTerm]);

  return (
    <>
      <Stack sx={{ paddingX: 2 }} gap={1} alignItems="center">
        <InsufficientThroughputSection company={props.company} completedItemCount={completedItems.length}>
          {itemsRemaining === 0 ?
            <>
              <Typography sx={bigTextSX}>There are no more {remainingItemsTerm}<br />left to complete</Typography>
            </>
            :
            <>
              <Typography sx={bigTextSX}>Out of the {props.initiative.totalItems} projected {projectedItemsTerm},<br />the {itemsRemaining} remaining {remainingItemsTerm}</Typography>
              <Typography sx={smallTextSX}>{shouldUsePluralRemaining ? "are" : "is"} projected for delivery by</Typography>
              <Typography sx={bigTextSX}>{expectedCompletionDate.value?.toLocaleDateString('en-US', { timeZone: 'UTC' }) ?? "N/A"}</Typography>
              <Typography sx={smallTextSX}>with a certainty of</Typography>
              {/* <Typography sx={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}><input type="number" min={10} max={100} className="text-2xl font-bold w-[75%] text-center border-2" value={inputProbability} onChange={(e) => setInputProbability(parseInt(e.target.value))}></input>%</Typography> */}
              <Select disableUnderline variant="standard" sx={bigTextSX} value={whenInputProbability.toString()} onChange={(e) => setWhenInputProbability(parseInt(e.target.value))}>
                {["75", "85", "95"].map((percent, i) =>
                  <MenuItem value={percent} key={i}>
                    {percent}%
                  </MenuItem>
                )}
              </Select>
            </>
          }
        </InsufficientThroughputSection>
      </Stack>
    </>
  );
}