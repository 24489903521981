import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material"
import { Category, Company } from "../../../Store/CompanySlice"
import { BaseModal } from "../../BaseModal"
import { AccountField } from "../../AccountField"
import { useEffect, useState } from "react"
import { MakeClone } from "../../../Services/Cloning"
import { AccountActions } from "../../AccountActions"
import { v4 } from "uuid"

interface CategoryUpsertModalProps {
  company: Company
  isOpen: boolean
  handleClose: () => void
  categoryToEdit: Category | undefined
  isUpserting: boolean
  Save: (editedCategory: Category) => Promise<void>
  Cancel: () => void
  cypressData: {
    modal: string,
    closeModalButton: string,
    title: string,
    includeInSims: string,
    saveButton: string,
    cancelButton: string
  }
}

export function CategoryUpsertModal(props: CategoryUpsertModalProps) {
  const [categoryClone, setCategoryClone] = useState<Category>();

  useEffect(() => {
    if (props.isOpen)
    {
      if (props.categoryToEdit)
        setCategoryClone(MakeClone(props.categoryToEdit));
      else
        setCategoryClone({
          id: v4(),
          title: "",
          includeInSims: true
        });
    }
    else
    {
      setCategoryClone(undefined);
    }
  }, [props.isOpen, props.categoryToEdit])

  return (
    <BaseModal
      open={props.isOpen}
      onClose={props.handleClose}
      cypressData={{ modal: props.cypressData.modal, closeModalButton: props.cypressData.closeModalButton }}
      title={`${props.categoryToEdit ? "Edit" : "Add"} ${props.company.terms.throughputTerm} Category`}
      subtitle={props.categoryToEdit?.title}
    >
      {categoryClone &&
        <Stack gap={2}>
          <AccountField title="Title" cypressData={props.cypressData.title} value={categoryClone?.title} onChange={(e) => { setCategoryClone({ ...categoryClone, title: e.target.value }) }} />
          <FormGroup sx={{ marginLeft: 1 }}>
            <FormControlLabel control={<Checkbox checked={categoryClone.includeInSims} onChange={e => setCategoryClone({ ...categoryClone, includeInSims: e.target.checked })} />} label="Include in Simulations" />
          </FormGroup>
          <AccountActions cypressData={{ saveButton: props.cypressData.saveButton, cancelButton: props.cypressData.cancelButton }} isSaving={props.isUpserting} Cancel={props.Cancel} Save={() => props.Save(categoryClone)} />
        </Stack>
      }
    </BaseModal >
  )
}
