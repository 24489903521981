import { Box, Container, IconButton, InputAdornment, Stack, TextField, CircularProgress, Typography, styled } from "@mui/material";
import { BaseModal } from "../BaseModal";
import { CancelButton, SubmitButton, tableCellFontSize } from "../../Styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { usePasswordUpdater } from "../../Hooks/usePasswordUpdater";
import { User, authenticateUserPassword } from "../../Store/UserSlice";
import { useAppDispatch } from "../../Hooks/Hooks";
import { ErrorSnackbar } from "../../Services/Snackbars";

interface ChangePasswordModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  currentUser: User
  userToEdit: User
  cypressData: {
    modal: string
    openModal: string
    closeModal: string
    submitButton: string
    cancelButton: string
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }
}

const CustomTextField = styled(TextField)({
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    top: '100%'
  }
});

export function ChangePasswordModal(props: ChangePasswordModalProps) {
  const dispatch = useAppDispatch();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const isSelfEdit = props.userToEdit.id === props.currentUser.id;

  const {
    isLoading,
    newPassword,
    confirmPassword,
    newPasswordValidationText,
    setNewPasswordValidationText,
    confirmPasswordValidationText,
    setConfirmPasswordValidationText,
    HandleNewPasswordUpdate,
    HandleConfirmPasswordUpdate,
    canSubmitPassword,
    HandleSubmitResetPassword,
    HandleAdminSubmitResetPassword,
    ClearFields
  } = usePasswordUpdater(isSelfEdit);

  const disable = isAuthenticating || isLoading || newPassword === "" || ((confirmPassword === "" || currentPassword === "") && isSelfEdit);

  async function HandleSubmit(event?: React.KeyboardEvent<HTMLDivElement>, key?: string) {
    if(event)
      key = event.key;
    if (key === 'Enter')
    {
      if(event)
        event.preventDefault();

      if(!isAuthenticating)
      {
        setIsAuthenticating(true);

        if (!disable && (!isSelfEdit || await AuthenticateCurrentPassword()))
        {
          if (isSelfEdit)
            await HandleSubmitResetPassword(undefined);
          else
            await HandleAdminSubmitResetPassword(props.userToEdit);
          HandleClose();
        }

        setIsAuthenticating(false);
      }

    }
  }

  async function AuthenticateCurrentPassword() {
    try
    {
      if (await dispatch(authenticateUserPassword({ password: currentPassword })).unwrap())
        return true;
    }
    catch (e)
    {
      ErrorSnackbar(e)
    }
    return false;
  }

  function HandleClose() {
    props.setIsOpen(false);
    ClearFields();
    setCurrentPassword("");
    setNewPasswordValidationText("");
    setConfirmPasswordValidationText("");
  }

  return (
    <BaseModal
      title={!isSelfEdit ? `Set Password` : `Change Password`}
      subtitle={!isSelfEdit ? `${props.userToEdit.name ?? props.userToEdit.email}` : undefined}
      open={props.isOpen}
      onClose={() => HandleClose()}
      cypressData={{
        modal: props.cypressData.modal,
        closeModalButton: props.cypressData.closeModal
      }}
    >
      <Container component="main" maxWidth="md">
        <Box component="form" sx={{
          display: "flex",
          flexDirection: "column"
        }}>
          <Stack gap={3} sx={{ marginY: 2 }}>
            <div className="flex justify-center">
              {isSelfEdit ?
                <TextField data-cy={props.cypressData.oldPassword} sx={{ width: "90%" }}
                  color="darkBlue"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  name="password"
                  label="Current Password"
                  value={currentPassword}
                  type={currentPasswordShown ? 'text' : 'password'}
                  onChange={e => setCurrentPassword(e.target.value)}
                  onKeyDown={e => HandleSubmit(e)}
                  InputProps={{
                    style: { fontSize: tableCellFontSize },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setCurrentPasswordShown(!currentPasswordShown)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {currentPasswordShown ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                :
                <Typography sx={{ width: "90%" }}>{`This user will be required to change their password once they log in with this new password.`}</Typography>
              }
            </div>
            <div className="flex justify-center">
              <CustomTextField data-cy={props.cypressData.newPassword} sx={{ width: "90%", marginY: 4 }}
                color="darkBlue"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                name="password"
                label="New Password"
                value={newPassword}
                type={newPasswordShown ? 'text' : 'password'}
                onChange={e => { HandleNewPasswordUpdate(e.target.value) }}
                onKeyDown={e => { HandleSubmit(e) }}
                error={!!newPasswordValidationText}
                helperText={newPasswordValidationText}
                InputProps={{
                  style: { fontSize: tableCellFontSize },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setNewPasswordShown(!newPasswordShown)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {newPasswordShown ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            {isSelfEdit &&
              <div className="flex justify-center">
                <CustomTextField data-cy={props.cypressData.confirmPassword} sx={{ width: "90%" }}
                  color="darkBlue"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  name="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  type={confirmPasswordShown ? 'text' : 'password'}
                  onChange={e => HandleConfirmPasswordUpdate(e.target.value)}
                  onKeyDown={e => HandleSubmit(e)}
                  error={!!confirmPasswordValidationText}
                  helperText={confirmPasswordValidationText}
                  InputProps={{
                    style: { fontSize: tableCellFontSize },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {confirmPasswordShown ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            }
            <div className="flex justify-center">
              <SubmitButton data-cy={props.cypressData.submitButton} sx={{ py: 1, width: "90%" }}
                disabled={disable || !canSubmitPassword || isLoading} onClick={() => HandleSubmit(undefined,'Enter')} >
                {isLoading ? <CircularProgress color="warning" size={24} /> : `${isSelfEdit ? "Change Password" : "Set Password"}`}
              </SubmitButton>
            </div>
            <div className="flex justify-center">
              <CancelButton data-cy={props.cypressData.cancelButton} sx={{ width: "90%" }} disabled={isLoading} onClick={() => { HandleClose() }}>
                Cancel
              </CancelButton>
            </div>
          </Stack>
        </Box>
      </Container>
    </BaseModal>
  )
}