import { Fragment, useMemo, useState } from "react";
import { Company } from "../../../Store/CompanySlice";
import { Box, Grid, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import { plural } from "pluralize";
import { Capitalize } from "../../../Services/Capitalize";
import { metricsFontLarge, tooltipStyle } from "../../../Styles";
import { DisplayInfo } from "./MetricsGrid";

interface ItemDisplayMetricsProps {
  company: Company
  displayInfo: DisplayInfo
}

export function ItemDisplayMetrics(props: ItemDisplayMetricsProps) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const handleChangePage = (
    event: any,
    newPage: number,
  ) => {
    setPage(newPage);
  };


  const indexOfLastItem = page * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedItems = useMemo(() => props.displayInfo?.items.slice(indexOfFirstItem, indexOfLastItem) ?? [], [props.displayInfo, indexOfFirstItem, indexOfLastItem]);
  const remainingSpaces = useMemo(() => (props.displayInfo?.items.length ?? 0) > rowsPerPage ? Array.from(new Array(rowsPerPage - slicedItems.length)) : [], [rowsPerPage, slicedItems, props.displayInfo]);
  const textFontSize = 12;

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%", width: "100%" }}>
      {props.displayInfo && props.displayInfo.items.length > 0 ?
        <>
          <Stack alignContent="start" sx={{ height: "100%", width: "100%" }} gap={.5}>
            {slicedItems.map((item, index) => {
              const tooltipMessage = item.title;
              let dateDisplay = "";
              if (item.startDate)
              {
                const splitStartDate = item.startDate.split("-");
                dateDisplay += parseInt(splitStartDate[1]).toString() + "/" + parseInt(splitStartDate[2]).toString();
                if (item.endDate)
                {
                  const splitEndDate = item.endDate.split("-");
                  dateDisplay += " - " + parseInt(splitEndDate[1]).toString() + "/" + parseInt(splitEndDate[2]).toString();
                }
              }
              return (
                <Fragment key={index}>
                  {item.id === props.displayInfo.firstStartedItemId &&
                    <Grid container wrap="nowrap">
                      <Grid item xs display="flex">
                        <Typography variant="caption" sx={{ marginX: 1 }}>{props.displayInfo.startColumnName} {Capitalize(plural(props.company.terms.throughputTerm ?? "Item"))}</Typography>
                      </Grid>
                      <Grid item xs={4} display="flex" justifyContent="end" sx={{ paddingRight: 1 }} zeroMinWidth>
                        <Typography noWrap variant="caption">Begin</Typography>
                      </Grid>
                    </Grid>
                  }
                  {item.id === props.displayInfo.firstCompletedItemId &&
                    <Grid container sx={{ marginTop: index !== 0 ? 1 : 0 }} wrap="nowrap">
                      <Grid item xs display="flex">
                        <Typography variant="caption" sx={{ marginX: 1 }}>{props.displayInfo.endColumnName} {Capitalize(plural(props.company.terms.throughputTerm ?? "Item"))}</Typography>
                      </Grid>
                      <Grid item xs={4} display="flex" justifyContent="end" sx={{ paddingRight: 1 }} zeroMinWidth>
                        <Typography noWrap variant="caption">Begin - End</Typography>
                      </Grid>
                    </Grid>
                  }
                  <Box sx={{ marginLeft: 2, marginTop: (index === 0 && item.id !== props.displayInfo.firstStartedItemId && item.id !== props.displayInfo.firstCompletedItemId) ? 2 : 0 }}>
                    <Tooltip className={tooltipStyle} sx={{ width: "100%" }} title={tooltipMessage} key={index}>
                      <Grid container wrap="nowrap">
                        <Grid item xs zeroMinWidth>
                          <Typography noWrap variant="body2" sx={{ color: item.color.code, "& .MuiChip-label": { fontSize: textFontSize } }}>{item.title}</Typography>
                        </Grid>
                        <Grid item xs={4} display="flex" justifyContent="end" sx={{ paddingRight: 1 }} zeroMinWidth>
                          <Typography noWrap variant="caption">{dateDisplay}</Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Box>
                </Fragment>
              )
            })}
            {remainingSpaces.map((i, index) =>
              <Stack direction="row" justifyContent="space-between" alignItems="center" key={index}>
                <Typography variant="body2"> </Typography>
              </Stack>
            )}
          </Stack>
          {props.displayInfo.items.length > rowsPerPage &&
            <Box display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(props.displayInfo.items.length / rowsPerPage)}
                onChange={handleChangePage}
                page={page}
                showFirstButton
                showLastButton
                size="small"
                sx={{ alignSelf: "flex-end" }}
              />
            </Box>
          }
        </>
        :
        <>
          <Box />
          <Typography alignContent="center" sx={metricsFontLarge}>
            N/A
          </Typography>
          <Box />
        </>
      }
    </Stack>
  )
}
