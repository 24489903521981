import { ListItemText, Typography, Tooltip, IconButton, TableBody, Table, TableCell, TableHead, TableRow, Paper, TableContainer, ThemeProvider } from "@mui/material";
import { Company, Initiative } from "../../Store/CompanySlice";
import { TableHeaderStyle, blueTheme, defaultRowStyle } from "../../Styles";
import { UserAvatar, UserWithAvatarUrl } from "./UserAvatar";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks/Hooks";
import { DocumentInfo, getAvatarDocumentInfos } from "../../Store/DocumentSlice";
import { User, selectAllUsers, upsertUserInfo } from "../../Store/UserSlice";
import { MakeClone } from "../../Services/Cloning";
import { UpsertUserInfoRequest } from "../../Services/UserService";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface UserManagementSectionProps {
  company: Company
  initiative: Initiative
}

export function UserManagementSection(props: UserManagementSectionProps) {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector(selectAllUsers);
  const companyUsers = allUsers.filter(u => u.companyId === props.company?.id);
  const [userAvatars, setUserAvatars] = useState<DocumentInfo[]>([]);
  const FetchAvatars = useCallback(async () => {
    try
    {
      const avatarDocs = await dispatch(getAvatarDocumentInfos({})).unwrap();
      setUserAvatars(avatarDocs);
    }
    catch (e)
    {

    }
  }, [dispatch]);

  useEffect(() => {
    FetchAvatars();
  }, [FetchAvatars]);

  const companyUsersWithAvatars: UserWithAvatarUrl[] = useMemo(() => companyUsers.map(u => { return { docUrl: userAvatars.find(a => a.blobName === u.avatarBlobName)?.tempUrl, ...u } }), [companyUsers, userAvatars]);

  function HandleSelectUser(userId: string) {
    const userClone = MakeClone(companyUsers.find(u => u.id === userId));
    if (userClone)
    {
      const initiativeRole = userClone.initiativeRoles.find(r => r.initiativeId === props.initiative.id);
      if (initiativeRole)
      {
        const index = userClone.initiativeRoles.findIndex(r => r.initiativeId === props.initiative.id);
        userClone.initiativeRoles.splice(index, 1);
      }
      else 
      {
        userClone.initiativeRoles.push({ initiativeId: props.initiative.id, isTeamMember: false });
      }
      SaveUser(userClone);
    }
  }

  function HandleSetTeamMember(userId: string) {
    const userClone = MakeClone(companyUsers.find(u => u.id === userId));
    if (userClone)
    {
      const index = userClone.initiativeRoles.findIndex(r => r.initiativeId === props.initiative.id);
      const initiativeRole = userClone.initiativeRoles.at(index);
      userClone.initiativeRoles.splice(index, 1);
      if (initiativeRole)
      {
        const isTeamMember = !initiativeRole.isTeamMember;
        userClone.initiativeRoles.push({ initiativeId: initiativeRole.initiativeId, isTeamMember: isTeamMember });
        SaveUser(userClone);
      }
    }
  }

  async function SaveUser(user: User) {
    const userRequest: UpsertUserInfoRequest = {
      user: user
    }
    await dispatch(upsertUserInfo(userRequest)).unwrap();
  }

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4"></div>
      <TableContainer component={Paper} className="my-2">
        <Table className="w-full outline outline-3 bg-gray-100" size="small">
          <colgroup>
            <col style={{ width: '2vw' }} />
            <col style={{ width: '20vw' }} />
            <col style={{ width: '6vw' }} />
            <col style={{ width: '15vw' }} />
          </colgroup>
          <TableHead className="outline outline-1">
            <TableRow sx={{
              borderBottom: "1px solid black",
              "& th": {
                fontWeight: "bold",
                fontFamily: "Arial, Helvetica"
              }
            }}>
              <TableHeaderStyle></TableHeaderStyle>
              <TableHeaderStyle></TableHeaderStyle>
              <TableHeaderStyle>Add to Initiative</TableHeaderStyle>
              <TableHeaderStyle>Team Member</TableHeaderStyle>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyUsersWithAvatars.map((user, index) => {
              const foundRole = user.initiativeRoles.find(r => r.initiativeId === props.initiative.id);
              const isTeamMember = foundRole?.isTeamMember;
              const name = user.name || user.email;
              return (
                <TableRow className={defaultRowStyle}
                  sx={{
                    borderBottom: "1px solid black",
                    "& td": {
                      fontFamily: "Arial, Helvetica",
                      color: "#21345b"
                    }
                  }}
                  key={index}
                >
                  <TableCell>
                    <UserAvatar user={user} />
                  </TableCell>
                  <TableCell>
                    <ListItemText disableTypography sx={{ alignText: "center" }}
                      primary={
                        <Tooltip title={name} placement="bottom-start">
                          <Typography variant="body2" noWrap textOverflow="ellipsis" ><b>{name}</b></Typography>
                        </Tooltip>
                      }
                      secondary={
                        <Typography variant="body2" noWrap textOverflow="ellipsis" >{user.role}</Typography>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => HandleSelectUser(user.id)}>
                      {foundRole ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton disabled={!foundRole} onClick={() => HandleSetTeamMember(user.id)}>
                      {isTeamMember ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  )
}