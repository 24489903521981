import { Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { ScrollToTop } from "../Components/ScrollToTop";
import { ScrollToTopButton } from "../Components/ScrollToTopButton";
import { TOCSection, TableOfContents } from "../Components/TableOfContents";
import { Fragment, useMemo } from "react";

interface TOCPageTemplateProps {
  sections: TOCSection[]
  children: JSX.Element | (JSX.Element | undefined)[] | undefined
}

export function TableOfContentsPageTemplate(props: TOCPageTemplateProps) {

  const contents = useMemo(() => props.sections.filter(s => s.content), [props.sections]);

  return (
    <>
      <ScrollToTop>
        <ScrollToTopButton />
      </ScrollToTop>
      <TableOfContents contents={props.sections}>
        <>
          {props.children}
          <Grid container sx={{
            display: 'flex',
            justifyContent: "space-between",
            placeItems: 'start',
            flexDirection: 'row'
          }}>
            <Card variant="outlined" sx={{ borderRadius: 2, borderWidth: 0, width: "100%" }}>
              <Stack gap={2} sx={{ marginY: 2, marginX: 4 }}>
                {contents.map(
                  (sec, index) => <Fragment key={index}>
                    {(index > 0) ? <Divider id={sec.title.replaceAll(" ", "")} sx={{ marginY: 2 }} flexItem /> : <div id={sec.title.replaceAll(" ", "")} />}
                    {!sec.hideTitleHeader &&
                      <Typography sx={{ fontWeight: "bold" }}>{sec.title}</Typography>
                    }
                    {sec.content}
                  </Fragment>
                )}
              </Stack>
            </Card>
          </Grid>
        </>
      </TableOfContents>
    </>
  );
}
