import { Typography, TextField, Checkbox, Box } from "@mui/material";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { MakeClone } from "../../../Services/Cloning";
import { BaseStepStack } from "./InitiativeCopyBaseStepStack";

interface BasicInformationStepProps {
  initiativeToCopy: Initiative
  updatedInitiative: Initiative
  company: Company
  setUpdatedInitiative: (value: Initiative) => void
}

export const BasicInformationStep: React.FC<Partial<StepWizardChildProps> & BasicInformationStepProps> = ({ setUpdatedInitiative, ...props }) => {
  const [newInitiativeClone, setNewInitiativeClone] = useState<Initiative>(MakeClone(props.updatedInitiative));
  const [isDescriptionSelected, setIsDescriptionSelected] = useState(false);
  const [disabledDescription, setDisabledDescription] = useState(props.initiativeToCopy.description);

  useEffect(() => {
    if (newInitiativeClone)
      setUpdatedInitiative(newInitiativeClone);
  }, [newInitiativeClone, setUpdatedInitiative]);

  function ToggleDescription() {
    if (isDescriptionSelected)
    {
      setDisabledDescription(newInitiativeClone.description);
      newInitiativeClone.description = "";
    }
    else
    {
      newInitiativeClone.description = disabledDescription;
    }
    setIsDescriptionSelected(!isDescriptionSelected);
  }

  const [errorText, setErrorText] = useState("");

  function UpdateTitle(newTitle: string){
    const emptyTitle = newTitle === "";
    const duplicateTitle = props.company.initiatives.find(i => i.title.toLowerCase() === newTitle.toLowerCase());
    setErrorText(emptyTitle ? "Title cannot be empty." : (duplicateTitle ? "Title must be unique." : ""));

    setNewInitiativeClone({ ...newInitiativeClone, title: newTitle });
  }

  return (
    <BaseStepStack disableNext={!!errorText} {...props}>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>Title</Typography>
        <TextField id="title" error={!!errorText} helperText={errorText} sx={{ width: "35%" }} value={newInitiativeClone.title ?? ""} onChange={(e) => UpdateTitle(e.target.value)} />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>
          <Checkbox checked={isDescriptionSelected} onChange={() => ToggleDescription()} />
          Description
        </Typography>
        <TextField multiline maxRows={5} sx={{ width: "50%" }} disabled={!isDescriptionSelected} value={isDescriptionSelected ? newInitiativeClone.description : disabledDescription} onChange={(e) => setNewInitiativeClone({ ...newInitiativeClone, description: e.target.value })} />
      </Box>
    </BaseStepStack>
  )
};