import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ThroughputData, DecisionData, UpsertCompanyInfo, UpsertCompanyInfoRequest, UpsertInitiativeInfo, UpsertInitiativeInfoRequest, UpsertDecisionDataRequest, UpsertDecisionData, DeleteDecisionDataRequest, DeleteDecisionData, GetCompany, GetCompanyRequest, LinkData, UpsertLinkData, UpsertLinkDataRequest, DeleteLinkData, DeleteLinkDataRequest, UpsertCategoryRequest, UpsertCategory, DeleteCategoryRequest, DeleteCategory, CloneInitiative, CloneInitiativeRequest, CloneInitiativeResponse, ThroughputProgressDate, DeleteCompanyThroughputRequest, DeleteCompanyThroughput, UpsertCompanyThroughputRequest, UpsertCompanyThroughput, UpsertClientWorkflowRequest, UpsertClientWorkflow } from "../Services/CompanyService"
import { RootState } from "./Store"
import { MakeClone } from "../Services/Cloning"
import { CloneUserRoles } from "./UserSlice"

export interface ShallowCompany {
  id: string
  name: string
  terms: Term,
  isActive: boolean
  metricStartDate: string
  metricEndDate: string
  displayMetrics: ClientMetricsDisplay
  logoBlobName?: string
  defaultCategoryId?: string
}

export interface Company extends ShallowCompany {
  initiatives: Initiative[]
  links: LinkData[]
  categories: Category[]
  throughput: ThroughputData[]
  workflow: Workflow
}

export interface Category {
  id: string
  title: string
  includeInSims: boolean
  dateCreated?: string
  createdById?: string
  dateModified?: string
  modifiedById?: string
}

export interface ShallowInitiative {
  id: string
  title: string
  description: string
  targetDate: string
  startDate: string
  totalItems: number
  isActive: boolean
  displayMetrics: MetricsDisplay
  defaultDocumentId?: string
}

export interface Initiative extends ShallowInitiative {
  decisions: DecisionData[]
  links: LinkData[]
}

export interface MetricsDisplay {
  completionProbability: boolean
  projectedCompletionDate: boolean
  itemProjection: boolean
  itemsCompleted: boolean
  cycleTime: boolean
  deliveredItems: boolean
}

export interface ClientMetricsDisplay {
  initiativesInFocus: boolean
  itemProjection: boolean
  cycleTime: boolean
  deliveredItems: boolean
}

export interface Workflow {
  columns: WorkflowColumn[]
  startDateColumnId: string
  endDateColumnId: string
  dateCreated?: string
  createdById?: string
  dateModified?: string
  modifiedById?: string
}

export interface WorkflowColumn {
  id: string
  title: string
  ordering: number
}

export interface Term {
  teamTerm: string
  userTerm: string
  initiativeTerm: string
  decisionTerm: string
  linkTerm: string
  documentTerm: string
  throughputTerm: string
}

export interface CompanyState {
  companies: Company[]
}

const initialState: CompanyState = {
  companies: []
}

export const IntegrityId = "53beceb7-054b-4740-830f-98a1dc0cc991"; //We should probably change how we handle this in the future

export const getCompany = createAsyncThunk(
  'companies/getCompany',
  async (args: GetCompanyRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await GetCompany(args, sessionId);
    return response.companies;
  }
)

export const upsertCompanyInfo = createAsyncThunk(
  'companies/upsertCompanyInfo',
  async (args: UpsertCompanyInfoRequest, { getState }): Promise<ShallowCompany> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await UpsertCompanyInfo(args, sessionId);

    return MakeClone(args.company);
  }
)

export const upsertInitiativeInfo = createAsyncThunk(
  'companies/upsertInitiativeInfo',
  async (args: UpsertInitiativeInfoRequest, { getState }): Promise<{ initiative: ShallowInitiative, companyId: string }> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await UpsertInitiativeInfo(args, sessionId);

    return { initiative: MakeClone(args.initiative), companyId: args.companyId };
  }
)

export const cloneInitiative = createAsyncThunk(
  'companies/cloneInitiative',
  async (args: CloneInitiativeRequest, { getState, dispatch }): Promise<CloneInitiativeResponse> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    let response = await CloneInitiative(args, sessionId);
    dispatch(CloneUserRoles({ usersToUpdateIds: args.teamMemberIds, sourceInitiativeId: args.sourceInitiativeId, newInitiativeId: response.cloneId }));
    return response;
  }
)

export const upsertClientWorkflow = createAsyncThunk(
  'companies/upsertClientWorkflow',
  async (args: UpsertClientWorkflowRequest, { getState }): Promise<{ companyId: string, data: Workflow }> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UpsertClientWorkflow(args, sessionId);

    const modifiedWorkflow = MakeClone(args.workflow);
    modifiedWorkflow.dateCreated = response.workflowTimestamps.dateCreated;
    modifiedWorkflow.createdById = response.workflowTimestamps.createdById;
    modifiedWorkflow.dateModified = response.workflowTimestamps.dateModified;
    modifiedWorkflow.modifiedById = response.workflowTimestamps.modifiedById;

    return { companyId: args.companyId, data: modifiedWorkflow };
  }
)

export const upsertCompanyThroughput = createAsyncThunk(
  'companies/upsertCompanyThroughput',
  async (args: UpsertCompanyThroughputRequest, { getState }): Promise<{ companyId: string, throughput: ThroughputData[] }> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UpsertCompanyThroughput(args, sessionId);
    return { companyId: args.companyId, throughput: response.throughput };
  }
)

export const deleteCompanyThroughput = createAsyncThunk(
  'companies/deleteCompanyThroughput',
  async (args: DeleteCompanyThroughputRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await DeleteCompanyThroughput(args, sessionId);
  }
)

export const upsertLinkData = createAsyncThunk(
  'companies/upsertLinkData',
  async (args: UpsertLinkDataRequest, { getState }): Promise<{ initiativeId: string | undefined, companyId: string, data: LinkData[] }> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UpsertLinkData(args, sessionId);

    //attach timestamps to links
    const newData = MakeClone(args.links);
    for (const data of newData)
    {
      const matchingTimestamps = response.linkTimestamps.find(lts => lts.id === data.id);
      if (matchingTimestamps)
      {
        data.dateCreated = matchingTimestamps.dateCreated;
        data.createdById = matchingTimestamps.createdById;
        data.dateModified = matchingTimestamps.dateModified;
        data.modifiedById = matchingTimestamps.modifiedById;
      }
    }

    return { initiativeId: args.initiativeId, companyId: args.companyId, data: newData };
  }
)

export const deleteLinkData = createAsyncThunk(
  'companies/deleteLinkData',
  async (args: DeleteLinkDataRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await DeleteLinkData(args, sessionId);
  }
)

export const upsertDecisionData = createAsyncThunk(
  'companies/upsertDecisionData',
  async (args: UpsertDecisionDataRequest, { getState }): Promise<{ initiativeId: string, companyId: string, data: DecisionData[] }> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await UpsertDecisionData(args, sessionId);

    return { initiativeId: args.initiativeId, companyId: args.companyId, data: args.decisions };
  }
)

export const deleteDecisionData = createAsyncThunk(
  'companies/deleteDecisionData',
  async (args: DeleteDecisionDataRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await DeleteDecisionData(args, sessionId);
  }
)

export const upsertCategory = createAsyncThunk(
  'companies/upsertCategory',
  async (args: UpsertCategoryRequest, { getState }): Promise<{ companyId: string, data: Category }> => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    const response = await UpsertCategory(args, sessionId);

    const modifiedCategory = MakeClone(args.category);
    modifiedCategory.dateCreated = response.categoryTimestamps.dateCreated;
    modifiedCategory.createdById = response.categoryTimestamps.createdById;
    modifiedCategory.dateModified = response.categoryTimestamps.dateModified;
    modifiedCategory.modifiedById = response.categoryTimestamps.modifiedById;

    return { companyId: args.companyId, data: modifiedCategory };
  }
)

export const deleteCategory = createAsyncThunk(
  'companies/deleteCategory',
  async (args: DeleteCategoryRequest, { getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await DeleteCategory(args, sessionId);
  }
)

export const companySlice = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: {
    clearCompanies: (state) => {
      state.companies = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.fulfilled, (state, action) => {
        const newCompanies = action.payload;
        for (const company of newCompanies)
        {
          const companyIndex = state.companies.findIndex(c => c.id === company.id);
          if (companyIndex > -1)
            state.companies.splice(companyIndex, 1, company);
          else
            state.companies.push(company);
        }
      })
      .addCase(upsertCompanyInfo.fulfilled, (state, action) => {
        const requestCompany = action.payload;
        const newWorkflow = action.meta.arg.newWorkflow;
        const foundCompany = state.companies.find(company => company.id === requestCompany.id);
        if (foundCompany)
        {
          foundCompany.name = requestCompany.name;
          foundCompany.terms = requestCompany.terms;
          foundCompany.isActive = requestCompany.isActive;
          foundCompany.logoBlobName = requestCompany.logoBlobName;
          foundCompany.defaultCategoryId = requestCompany.defaultCategoryId;
          foundCompany.metricStartDate = requestCompany.metricStartDate;
          foundCompany.metricEndDate = requestCompany.metricEndDate;
          foundCompany.displayMetrics = requestCompany.displayMetrics;
        }
        else
        {
          if(!newWorkflow)
            throw Error("A new workflow is required when creating a company.");
         
          const newCategory = action.meta.arg.newCategory;
          const newCompany: Company = {
            id: requestCompany.id,
            name: requestCompany.name,
            terms: requestCompany.terms,
            isActive: requestCompany.isActive,
            logoBlobName: requestCompany.logoBlobName,
            defaultCategoryId: requestCompany.defaultCategoryId,
            metricStartDate: requestCompany.metricStartDate,
            metricEndDate: requestCompany.metricEndDate,
            displayMetrics: requestCompany.displayMetrics,
            initiatives: [],
            links: [],
            categories: newCategory ? [newCategory] : [],
            throughput: [],
            workflow: newWorkflow
          }
          state.companies.push(newCompany);
        }
      })
      .addCase(upsertInitiativeInfo.fulfilled, (state, action) => {
        const requestInitiative = action.payload.initiative;
        const companyId = action.payload.companyId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingInitiative = matchingCompany.initiatives.find(init => init.id === requestInitiative.id);
          if (matchingInitiative)
          {
            matchingInitiative.title = requestInitiative.title;
            matchingInitiative.description = requestInitiative.description;
            matchingInitiative.targetDate = requestInitiative.targetDate;
            matchingInitiative.startDate = requestInitiative.startDate;
            matchingInitiative.totalItems = requestInitiative.totalItems;
            matchingInitiative.isActive = requestInitiative.isActive;
            matchingInitiative.displayMetrics = requestInitiative.displayMetrics;
            matchingInitiative.defaultDocumentId = requestInitiative.defaultDocumentId;
          }
          else
          {
            const newInitiative: Initiative = {
              id: requestInitiative.id,
              title: requestInitiative.title,
              description: requestInitiative.description,
              targetDate: requestInitiative.targetDate,
              startDate: requestInitiative.startDate,
              totalItems: requestInitiative.totalItems,
              isActive: requestInitiative.isActive,
              displayMetrics: requestInitiative.displayMetrics,
              defaultDocumentId: requestInitiative.defaultDocumentId,
              decisions: [],
              links: []
            }
            matchingCompany.initiatives.push(newInitiative);
          }
        }
      })
      .addCase(cloneInitiative.fulfilled, (state, action) => {
        const requestData = action.meta.arg;
        const matchingCompany = state.companies.find(c => c.id === requestData.companyId);
        if (!matchingCompany)
          throw Error("Can't find company.")
        const matchingInitiative = matchingCompany.initiatives.find(i => i.id === requestData.sourceInitiativeId);
        if (!matchingInitiative)
          throw Error("Can't find source initiative.");
        const newInitiative: Initiative = {
          id: action.payload.cloneId,
          title: requestData.title,
          description: requestData.description,
          startDate: matchingInitiative.startDate,
          targetDate: matchingInitiative.targetDate,
          totalItems: matchingInitiative.totalItems,
          isActive: matchingInitiative.isActive,
          decisions: matchingInitiative.decisions.filter(d => requestData.decisionIds.includes(d.id)),
          links: matchingInitiative.links.filter(l => requestData.linkIds.includes(l.id)),
          displayMetrics: matchingInitiative.displayMetrics
        }
        matchingCompany.initiatives.push(newInitiative);
      })
      .addCase(upsertClientWorkflow.fulfilled, (state, action) => {
        const workflow = action.payload.data;
        const companyId = action.payload.companyId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
          matchingCompany.workflow = workflow;
      })
      .addCase(upsertCompanyThroughput.fulfilled, (state, action) => {
        function CreateThroughput(id: string, key: string, categoryId: string, initiativeId: string, title: string, progressDates: ThroughputProgressDate[], dateModified?: string, modifiedById?: string, dateCreated?: string, createdById?: string): ThroughputData {
          return { id, key, categoryId, initiativeId, title, progressDates, dateModified, modifiedById, dateCreated, createdById };
        }

        const companyId = action.payload.companyId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const throughputClone = MakeClone(matchingCompany.throughput);
          for (const item of action.payload.throughput)
          {
            const newThroughput = CreateThroughput(item.id, item.key, item.categoryId, item.initiativeId, item.title, item.progressDates ?? [], item.dateModified, item.modifiedById, item.dateCreated, item.createdById);
            const matchingIndex = throughputClone.findIndex(entry => entry.id === item.id);
            if (matchingIndex > -1)
              throughputClone[matchingIndex] = newThroughput;
            else
              throughputClone.push(newThroughput);
          }
          matchingCompany.throughput = throughputClone;
        }
      })
      .addCase(deleteCompanyThroughput.fulfilled, (state, action) => {
        const companyId = action.meta.arg.companyId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const throughputClone = MakeClone(matchingCompany.throughput);
          const throughputId = action.meta.arg.throughputId;

          const dataIndex = throughputClone.findIndex(entry => throughputId === entry.id);

          if (dataIndex > -1)
            throughputClone.splice(dataIndex, 1);

          matchingCompany.throughput = throughputClone;
        }
      })
      .addCase(upsertDecisionData.fulfilled, (state, action) => {
        const companyId = action.payload.companyId;
        const initiativeId = action.payload.initiativeId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingInit = matchingCompany.initiatives.find(init => init.id === initiativeId);
          if (matchingInit)
          {
            const decisionsClone = MakeClone(matchingInit.decisions);
            for (const data of action.payload.data)
            {
              const dataIndex = decisionsClone.findIndex(entry => data.id === entry.id);

              if (dataIndex > -1)
                decisionsClone[dataIndex] = data;
              else
                decisionsClone.push(data);
            }
            matchingInit.decisions = decisionsClone;
          }
        }
      })
      .addCase(deleteDecisionData.fulfilled, (state, action) => {
        const companyId = action.meta.arg.companyId;
        const initiativeId = action.meta.arg.initiativeId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingInit = matchingCompany.initiatives.find(init => init.id === initiativeId);
          if (matchingInit)
          {
            const decisionsClone = MakeClone(matchingInit.decisions);
            for (const decisionId of action.meta.arg.decisionIds)
            {
              const dataIndex = decisionsClone.findIndex(entry => decisionId === entry.id);

              if (dataIndex > -1)
                decisionsClone.splice(dataIndex, 1);
            }
            matchingInit.decisions = decisionsClone;
          }
        }
      })
      .addCase(upsertLinkData.fulfilled, (state, action) => {

        function UpsertLinks(originalLinks: LinkData[], linksToUpsert: LinkData[]) {
          const linksClone = MakeClone(originalLinks);
          for (const data of linksToUpsert)
          {
            const dataIndex = linksClone.findIndex(entry => data.id === entry.id);
            if (dataIndex > -1)
              linksClone[dataIndex] = data;
            else
              linksClone.unshift(data);
          }
          return linksClone;
        }

        const companyId = action.payload.companyId;
        const initiativeId = action.payload.initiativeId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingInit = matchingCompany.initiatives.find(init => init.id === initiativeId);
          if (matchingInit)
            matchingInit.links = UpsertLinks(matchingInit.links, action.payload.data);
          else
            matchingCompany.links = UpsertLinks(matchingCompany.links, action.payload.data);
        }
      })
      .addCase(deleteLinkData.fulfilled, (state, action) => {
        function DeleteLinks(originalLinks: LinkData[], linkToDeleteId: string) {
          const linksClone = MakeClone(originalLinks);
          const dataIndex = linksClone.findIndex(entry => linkToDeleteId === entry.id);
          if (dataIndex > -1)
            linksClone.splice(dataIndex, 1);

          return linksClone;
        }

        const companyId = action.meta.arg.companyId;
        const initiativeId = action.meta.arg.initiativeId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingInit = matchingCompany.initiatives.find(init => init.id === initiativeId);
          if (matchingInit)
            matchingInit.links = DeleteLinks(matchingInit.links, action.meta.arg.linkId);
          else
            matchingCompany.links = DeleteLinks(matchingCompany.links, action.meta.arg.linkId);
        }
      })

      .addCase(upsertCategory.fulfilled, (state, action) => {
        const category = action.payload.data;
        const companyId = action.payload.companyId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingCategory = matchingCompany.categories.find(c => c.id === category.id);
          if (matchingCategory)
          {
            matchingCategory.title = category.title;
            matchingCategory.includeInSims = category.includeInSims;
          }
          else
          {
            matchingCompany.categories.push(category);
          }
        }
      })

      .addCase(deleteCategory.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.categoryId;
        const companyId = action.meta.arg.companyId;
        const matchingCompany = state.companies.find(company => company.id === companyId);
        if (matchingCompany)
        {
          const matchingIndex = matchingCompany.categories.findIndex(c => c.id === categoryId);
          if (matchingIndex > -1)
            matchingCompany.categories.splice(matchingIndex, 1);
        }
      })
  }
});

export const { clearCompanies } = companySlice.actions;

export const selectAllCompanies = (state: RootState) => state.companies.companies;

export default companySlice.reducer;