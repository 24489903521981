import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { tableCellFontSize } from "../Styles";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { selectCurrentUser } from "../Store/UserSlice";
import { useEffect, useMemo, useState } from "react";
import { getLogoDocumentInfos, uploadLogo } from "../Store/DocumentSlice";
import { useEditCompanyAccount } from "../Hooks/useEditCompanyAccount";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { EditImage, FileUrlPair } from "../Components/EditImage";
import { MakeClone } from "../Services/Cloning";
import { ClientMetricsDisplay, Workflow, upsertCompanyInfo } from "../Store/CompanySlice";
import { AccountField } from "../Components/AccountField";
import { AccountHeader } from "../Components/AccountHeader";
import { AccountActions } from "../Components/AccountActions";
import { plural } from "pluralize";
import { ErrorSnackbar } from "../Services/Snackbars";
import { useGetLogo } from "../Hooks/useGetLogo";
import { DocumentManagementSection } from "../Components/Documents/DocumentManagementSection";
import { LinkManagementSection } from "../Components/Links/LinkManagementSection";
import { CategoryManagementSection } from "../Components/Throughput/Categories/CategoryManagementSection";
import { ArticleManagementSection } from "../Components/Articles/ArticleManagementSection";
import { TOCSection } from "../Components/TableOfContents";
import { TableOfContentsPageTemplate } from "./TableOfContentsPageTemplate";
import { GoToClientDashboard } from "./ClientDashboardPage";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { CapitalizeAll } from "../Services/Capitalize";
import { ThroughputManagementSection } from "../Components/Throughput/ThroughputManagementSection";
import { AccountDatePicker } from "../Components/AccountDatePicker";
import { ClientWorkflowManagementSection } from "../Components/Client/Workflow/ClientWorkflowManagementSection";
import { initPageStateEnum } from "./InitiativesPage";
import { InitiativeManagementSection } from "../Components/Initiative/InitiativeManagementSection";
import { GoToHomePage } from "../App";

export function GoToClientAccountPage(navigate: NavigateFunction, companyId?: string) {
  let where = '/ClientAccount';
  if (companyId)
    where += "?company=" + companyId;
  navigate(where);
}

export const ClientAccountPageIds = {
  name: "ClientAccountPageIdsName",
  metricStartDate: "ClientAccountPageIdsMetricStartDate",
  metricEndDate: "ClientAccountPageIdsMetricEndDate",
  saveButton: "ClientAccountPageSaveButton",
  cancelButton: "ClientAccountPageCancelButton"
}

export function ClientAccountPage() {

  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [addInitiative, setAddInitiative] = useState(false);
  const [state, setState] = useState(initPageStateEnum.start);

  const {
    SaveEdit,
    CancelEdit,
    setCloneForEditing,
    cloneForEditing,
    companyToEdit,
    isAddingNewCompany,
    isSavingCompany,
    newLogoFileUrl,
    setNewLogoFileUrl
  } = useEditCompanyAccount();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!companyToEdit)
    {
      ErrorSnackbar(Error());
      if(currentUser)
        GoToHomePage(navigate, currentUser);
    }
  }, [companyToEdit, navigate, currentUser]);

  const { FetchLogo, companyLogo } = useGetLogo();
  useEffect(() => {
    FetchLogo(companyToEdit);
  }, [FetchLogo, companyToEdit]);

  async function HandleLogoSave(newPair: FileUrlPair) {
    if (companyToEdit && newPair.file)
    {
      try
      {
        const newBlobName = await dispatch(uploadLogo({ file: newPair.file })).unwrap();
        await dispatch(getLogoDocumentInfos({}));
        const newCompany = MakeClone(companyToEdit);
        newCompany.logoBlobName = newBlobName;
        await dispatch(upsertCompanyInfo({ company: newCompany }));
      }
      catch (e)
      {
        console.log((e as Error).message);
      }
    }
  }

  function UpdateWorkflow(workflow: Workflow) {
    let newInitClone = MakeClone(cloneForEditing);
    if (newInitClone)
      newInitClone.workflow = workflow;
    setCloneForEditing(newInitClone);
  }

  type MappedMetric = { title: string, propertyName: keyof ClientMetricsDisplay };
  const metricsMapping: MappedMetric[] = [
    {
      title: `${CapitalizeAll(companyToEdit?.terms.throughputTerm ?? "Item")} Projection`,
      propertyName: "itemProjection"
    },
    {
      title: "Cycle Time",
      propertyName: "cycleTime"
    },
    {
      title: `${CapitalizeAll(plural(companyToEdit?.terms.throughputTerm ?? "Item"))} In Focus`,
      propertyName: "initiativesInFocus"
    },
    {
      title: `Delivered ${CapitalizeAll(plural(companyToEdit?.terms.throughputTerm ?? "Item"))}`,
      propertyName: "deliveredItems"
    }
  ];

  const displayMetrics: (MappedMetric & { show: boolean })[] = metricsMapping.map(m => {
    return { title: m.title, propertyName: m.propertyName, show: cloneForEditing?.displayMetrics[m.propertyName] ?? false };
  });

  const allMetricsSelected = useMemo(() => {
    for (const metric of displayMetrics)
    {
      if (!metric.show)
        return false;
    }
    return true;
  }, [displayMetrics]);


  const clientTerminology = (cloneForEditing &&
    <>
      <Typography variant="body2">Client Terminology</Typography>
      <TextField label="Initiative Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.initiativeTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, initiativeTerm: e.target.value } })} />
      <TextField label="User Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.userTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, userTerm: e.target.value } })} />
      <TextField label="Team Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.teamTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, teamTerm: e.target.value } })} />
      <TextField label="Decision Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.decisionTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, decisionTerm: e.target.value } })} />
      <TextField label="Document Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.documentTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, documentTerm: e.target.value } })} />
      <TextField label="Link Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.linkTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, linkTerm: e.target.value } })} />
      <TextField label="Throughput Term" sx={{ fontSize: tableCellFontSize }} value={cloneForEditing.terms.throughputTerm} onChange={e => setCloneForEditing({ ...cloneForEditing, terms: { ...cloneForEditing.terms, throughputTerm: e.target.value } })} />
    </>
  );

  const clientProperties = (cloneForEditing && companyToEdit && currentUser &&
    <>
      <Typography sx={{ fontWeight: "bold" }}>Client Info</Typography>
      <AccountField label="Client Name" cypressData={ClientAccountPageIds.name} value={cloneForEditing.name} onChange={e => setCloneForEditing({ ...cloneForEditing, name: e.target.value })} />
      <FormGroup sx={{ marginLeft: 1 }}>
        <FormControlLabel control={<Checkbox checked={cloneForEditing.isActive} onChange={e => setCloneForEditing({ ...cloneForEditing, isActive: e.target.checked })} />} label="Active" />
      </FormGroup>

      <Typography variant="body2">Metrics Date Range</Typography>
      <Stack direction="row" gap={4} alignItems="center" sx={{ marginBottom: 2 }}>
        <AccountDatePicker label="Start Date" cypressData={ClientAccountPageIds.metricStartDate} dateString={cloneForEditing.metricStartDate} setDateString={(value) => setCloneForEditing({ ...cloneForEditing, metricStartDate: value })} />
        <Typography variant="h4" textAlign="center">-</Typography>
        <AccountDatePicker label="End Date" cypressData={ClientAccountPageIds.metricEndDate} dateString={cloneForEditing.metricEndDate} setDateString={(value) => setCloneForEditing({ ...cloneForEditing, metricEndDate: value })} />
      </Stack>
      <Box sx={{ marginBottom: 2 }}>
        <Typography sx={{ marginBottom: 1 }} variant="body2">Metrics Display</Typography>
        <FormGroup sx={{ marginLeft: 1 }}>
          <FormControlLabel label="Show All"
            control={
              <Checkbox checked={allMetricsSelected} onChange={e => {
                const newDisplayMetrics = MakeClone(cloneForEditing.displayMetrics);
                for (const metric of displayMetrics)
                  newDisplayMetrics[metric.propertyName] = e.target.checked;
                setCloneForEditing({ ...cloneForEditing, displayMetrics: newDisplayMetrics });
              }} />
            }
          />
          <Divider />
          {displayMetrics.map((metric, index) =>
            <FormControlLabel key={index} label={metric.title}
              control={
                <Checkbox checked={metric.show}
                  onChange={e => {
                    const newDisplayMetrics = MakeClone(cloneForEditing.displayMetrics);
                    newDisplayMetrics[metric.propertyName] = e.target.checked;
                    setCloneForEditing({ ...cloneForEditing, displayMetrics: newDisplayMetrics });
                  }}
                />
              }
            />
          )}
        </FormGroup>
      </Box>
    </>
  );

  const clientInitiatives = (!isAddingNewCompany && cloneForEditing &&
    <>
    {companyToEdit && currentUser &&
    <InitiativeManagementSection company={companyToEdit} currentUser={currentUser} 
      addInitiative={addInitiative} setAddInitiative={setAddInitiative}
      state={state} setState={setState}></InitiativeManagementSection>
    }
  </>
  );

  const clientDocuments = (!isAddingNewCompany && companyToEdit && currentUser &&
    <Stack>
      <DocumentManagementSection articleWithDocsId={undefined} company={companyToEdit} initiative={undefined} currentUser={currentUser} userHasUploadPermission={true} />
    </Stack>
  );

  const clientLinks = (!isAddingNewCompany && companyToEdit && currentUser &&
    <Stack>
      <LinkManagementSection company={companyToEdit} initiative={undefined} currentUser={currentUser} userHasUploadPermission={true} />
    </Stack>
  );

  const clientItemCategories = (!isAddingNewCompany && companyToEdit && currentUser &&
    <Stack>
      <CategoryManagementSection company={companyToEdit} currentUser={currentUser} userHasUploadPermission={true} />
    </Stack>
  );

  const clientArticles = (!isAddingNewCompany && companyToEdit && currentUser &&
    <Stack>
      <ArticleManagementSection company={companyToEdit} currentUser={currentUser} userHasUploadPermission={true} initiative={undefined} />
    </Stack>
  );

  const clientThroughput = (!isAddingNewCompany && companyToEdit && currentUser &&
    <Stack>
      <ThroughputManagementSection company={companyToEdit} />
    </Stack>
  );

  const workflowSection = (cloneForEditing && companyToEdit && !isAddingNewCompany &&
    <ClientWorkflowManagementSection workflow={cloneForEditing.workflow} UpdateWorkflow={UpdateWorkflow} company={cloneForEditing} />
  );

  const sections: TOCSection[] = [
    {
      title: "View Dashboard",
      content: undefined,
      onClick: () => { if (currentUser && companyToEdit) GoToClientDashboard(navigate, currentUser.id, companyToEdit.id) },
      endIcon: <OpenInNew />,
      hideWhenAdding: true
    },
    {
      title: "Client Info",
      content: <>
        {clientProperties}
        {clientTerminology}
        <AccountActions cypressData={{ saveButton: ClientAccountPageIds.saveButton, cancelButton: ClientAccountPageIds.cancelButton }} Save={SaveEdit} Cancel={CancelEdit} isSaving={isSavingCompany} />
      </>,
      hideTitleHeader: true
    },
    {
      title: CapitalizeAll(plural(companyToEdit?.terms.initiativeTerm ?? "Initiative")),
      content: clientInitiatives,
      hideWhenAdding: true
    },
    { title: CapitalizeAll(plural(companyToEdit?.terms.documentTerm ?? "Document")), content: clientDocuments, hideWhenAdding: true },
    { title: "Articles", content: clientArticles, hideWhenAdding: true },
    { title: CapitalizeAll(plural(companyToEdit?.terms.linkTerm ?? "Link")), content: clientLinks, hideWhenAdding: true },
    { title: "Workflow Columns", content: workflowSection, hideWhenAdding: true, hideTitleHeader: true },
    { title: CapitalizeAll(companyToEdit?.terms.throughputTerm ?? "Item") + " Categories", content: clientItemCategories, hideWhenAdding: true },
    { title: CapitalizeAll(plural(companyToEdit?.terms.throughputTerm ?? "Item")), content: clientThroughput, hideWhenAdding: true }

  ].filter(s => !isAddingNewCompany || !s.hideWhenAdding);

  return (
    <TableOfContentsPageTemplate sections={sections}>
      {currentUser &&
        <AccountHeader editableCompanyLogo={isAddingNewCompany ? undefined : <EditImage company={companyLogo} newFileUrl={newLogoFileUrl} setNewFileUrl={setNewLogoFileUrl} onUpload={HandleLogoSave} avatarLength={150} />}
          subtitle={companyToEdit ? (isAddingNewCompany ? <p>Adding new client</p> : <p>Editing: <span className="text-2xl font-bold">{companyToEdit.name}</span></p>) : ""} currentUser={currentUser}
        />
      }
    </TableOfContentsPageTemplate>
  );
}
