import { useState } from 'react';
import ReactQuill from "react-quill";

interface RichTextProps {
  cypressData: string
  initialValue: string
  valueSetter: (value: string) => void
  theme?: "bubble" | "snow"
  placeHolder?: string
  myRef?: React.RefObject<any>
}

export function RichText(props: RichTextProps) {
  const [text, setText] = useState(props.initialValue);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
    ],
  },

    formats = [
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent'
    ]

  function handleChange(value: string) {
    props.valueSetter(value);
    setText(value);
  }

  return (
    <div data-cy={props.cypressData} >
      <ReactQuill value={text}
        modules={modules}
        formats={formats}
        onChange={handleChange}
        theme={props.theme}
        placeholder={props.placeHolder}
        ref={props.myRef}
      />
    </div>
  )
}

