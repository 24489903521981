import { useState } from "react";
import { useAppDispatch } from "../Hooks/Hooks"
import { authenticateUser } from "../Store/UserSlice";
import { CancelButton, IntegrityTheme, SubmitLoadingButton, tableCellFontSize, tableHeaderFontSize } from "../Styles";
import { Box, Checkbox, Container, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { ResetPasswordLinkModal } from "../Components/User/ResetPasswordLinkModal";
import { LoginHeader } from "../Layout/LoginHeader";

export const LoginPageIds = {
  email: "email",
  password: "password",
  submitButton: "submitButton"
}

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [failMessage, setFailMessage] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  async function HandleLogin(key: any) {
    if (key === 'Enter')
    {
      await Login();
    }
  }

  async function Login() {
    if (!isLoading)
    {
      setIsLoading(true);
      try
      {
        const response = await dispatch(authenticateUser({ creds: { username: userEmail, password: password } })).unwrap();
        if (!response.success)
          setFailMessage(response.cause);
      }
      catch (e)
      {
        const error = e as Error;
        console.log(error.message);
      }
      setIsLoading(false);
    }
  }

  return (
    <ThemeProvider theme={IntegrityTheme}>
      <LoginHeader />
      <Container disableGutters component="main" maxWidth="xs" className="bg-gray-100 pb-4 mt-[1%] mb-[1%] rounded-md shadow-md">
        <Box component="form" onSubmit={HandleLogin} noValidate sx={{
          marginTop: 3,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          height: "auto",
          borderRadius: 3
        }} >
          <Grid item sx={{ backgroundColor: "#2E4A7F", color: "white", borderRadius: 2, marginBottom: 2, padding: 2 }}>
            <Typography component="h1" variant="h5" sx={{
              fontSize: tableHeaderFontSize,
              padding: 2,
              textAlign: "center",
              width: "100%"
            }}>
              Sign in
            </Typography>
          </Grid>
          <Stack display="flex" justifyItems="center" alignSelf="center" sx={{ width: "90%" }}>
            <TextField
              InputProps={{ style: { fontSize: tableCellFontSize } }}
              color="darkBlue"
              margin="normal"
              required
              data-cy={LoginPageIds.email}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus value={userEmail}
              onChange={e => setUserEmail(e.target.value)}
              onKeyDown={e => HandleLogin(e.key)}
            />
            <TextField
              InputProps={{ style: { fontSize: tableCellFontSize } }}
              color="darkBlue"
              margin="normal"
              required
              name="password"
              label="Password"
              data-cy={LoginPageIds.password}
              autoComplete="current-password"
              value={password}
              type={passwordShown ? 'text' : 'password'}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => HandleLogin(e.key)}
            />
            <FormControlLabel sx={{ alignSelf: "start" }} control={<Checkbox value="show" color="darkBlue" onChange={togglePasswordVisibility} />} label="Show Password" />
            <SubmitLoadingButton data-cy={LoginPageIds.submitButton} disabled={isLoading || !password || !userEmail} sx={{ my: 3 }}
              onClick={() => Login()} loading={isLoading}>
              <span>Login</span>
            </SubmitLoadingButton>
            <CancelButton onClick={() => setIsOpen(true)}>
              Forgot password?
            </CancelButton>
            {!isLoading && failMessage &&
              <Typography textAlign="center" color="red" sx={{ marginTop: 2 }}>{failMessage}</Typography>
            }
          </Stack>
          <ResetPasswordLinkModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
