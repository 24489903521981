import { Company } from "../../Store/CompanySlice"
import { Capitalize } from "../Capitalize";
import { MakeClone } from "../Cloning";
import { ThroughputData } from "../CompanyService"
import { Validation, FailValidation, SuccessValidation } from "./Validation"

export const ThroughputValidationReasons = {
  startDateBeforeReadyDate: "Start date cannot precede ready date.",
  endDateWithoutStartDate: "Cannot have a completion date without a starting date.",
  impossibleDateOrder: "An item cannot enter a later column in the workflow before an earlier column.",
  duplicateKey: "An item with this key already exists.",
  emptyKey: "Key cannot be empty.",
  invalidFileType: "File can only be of type '.csv'.",
  emptyCategory: "Category cannot be empty.",
  emptyInitiative: "Initiative must be selected."
}

export function CompanyThroughputValidationReasons(terms: Company["terms"]) {
  return {
    startDateBeforeReadyDate: "Start date cannot precede ready date.",
    endDateWithoutStartDate: "Cannot have a completion date without a starting date.",
    impossibleDateOrder: `${Capitalize(terms.throughputTerm)} cannot enter a later column in the workflow before an earlier column.`,
    duplicateKey: `${Capitalize(terms.throughputTerm)} with this key already exists.`,
    emptyKey: "Key cannot be empty.",
    invalidFileType: "File can only be of type '.csv'.",
    emptyCategory: "Category cannot be empty.",
    emptyInitiative: `${Capitalize(terms.initiativeTerm)} must be selected.`
  }
}

export function ValidateCompanyThroughput(dataList: ThroughputData[], company: Company): Validation {
  for (const entry of dataList)
  {
    if (!entry.key)
      return FailValidation(CompanyThroughputValidationReasons(company.terms).emptyKey);
    if (!entry.categoryId)
      return FailValidation(CompanyThroughputValidationReasons(company.terms).emptyCategory);
    if (!entry.initiativeId || !company.initiatives.find(i => i.id === entry.initiativeId))
      return FailValidation(CompanyThroughputValidationReasons(company.terms).emptyInitiative);
  }

  return SuccessValidation("All data is valid.")
}

export function ValidateFileCompanyThroughput(companyList: Company[], companyId: string, newDataList: ThroughputData[]): Validation {
  if (newDataList.length === 0)
    return FailValidation("No throughput was provided.");

  return ValidateEditCompanyThroughput(companyList, companyId, newDataList);
}

export function ValidateEditCompanyThroughput(companyList: Company[], companyId: string, newDataList: ThroughputData[]): Validation {
  const company = companyList.find(c => c.id === companyId);
  if(!company)
    return FailValidation("Company could not be found.");

  const dataValidation = ValidateCompanyThroughput(newDataList, company);
  if (!dataValidation.success)
    return dataValidation;

  //const selectsValidation = ValidateCompanyAndInitiative(companyList, companyId, initiativeId);
  //if (!selectsValidation.success)
  //return selectsValidation;

  const duplicateValidation = ValidateDuplicateCompanyThroughput(newDataList, company);
  if (!duplicateValidation.success)
    return duplicateValidation;

  const dateValidation = ValidateCompanyThroughputDates(newDataList, company);
  if (!dateValidation.success)
    return dateValidation;

  return SuccessValidation("Successfully validated throughput data; all good!");
}

function ValidateDuplicateCompanyThroughput(newDataList: ThroughputData[], company: Company): Validation {
  const existingThroughput = company.throughput;

  const idKeys = existingThroughput.map(t => { return { id: t.id, key: t.key } });
  for (const item of newDataList)
  {
    const matchingPair = idKeys.find(t => t.id === item.id);
    if (matchingPair)
      matchingPair.key = item.key;
    else
      idKeys.push({ id: item.id, key: item.key });
  }

  const pairMap = new Map();
  for (const pair of idKeys)
  {
    if (pairMap.has(pair.key))
      return FailValidation(CompanyThroughputValidationReasons(company.terms).duplicateKey);
    pairMap.set(pair.key, true);
  }

  return SuccessValidation("No duplicate throughput keys were detected.");
}

function ValidateCompanyThroughputDates(newDataList: ThroughputData[], company: Company): Validation {
  const workflow = company.workflow;
  if (!workflow)
    return FailValidation("No workflow detected to compare with.");

  for (const data of newDataList)
  {
    const progressDates = (MakeClone(data.progressDates) ?? []).filter(p => p.enterColumnDate);
    const progressWithOrder = progressDates.map(p => { return { ...p, ordering: workflow.columns.find(c => c.id === p.workflowColumnId)?.ordering ?? 0 } }).filter(d => d.ordering !== 0);
    progressWithOrder.sort((a, b) => a.ordering - b.ordering);
    for (const progress of progressWithOrder)
    {
      const orderedAfter = progressWithOrder.filter(p => p.ordering > progress.ordering);
      for (const other of orderedAfter)
      {
        if (new Date(progress.enterColumnDate).getTime() > new Date(other.enterColumnDate).getTime())
          return FailValidation(CompanyThroughputValidationReasons(company.terms).impossibleDateOrder);
      }
    }

    const startDate = progressDates.find(p => p.workflowColumnId === workflow.startDateColumnId);
    const endDate = progressDates.find(p => p.workflowColumnId === workflow.endDateColumnId);
    if (endDate && !startDate)
      return FailValidation(CompanyThroughputValidationReasons(company.terms).endDateWithoutStartDate);
  }

  return SuccessValidation("No workflow date conflicts detected.");
}
