import { Box, Stack } from "@mui/material";
import { DashboardSection } from "../../DashboardSection";
import Masonry from "@mui/lab/Masonry";
import plural from "pluralize";
import { CapitalizeAll } from "../../../Services/Capitalize";
import { InitiativesInFocusChart } from "./InitiativesInFocusChart";
import { Company } from "../../../Store/CompanySlice";
import { Fragment, useMemo } from "react";
import { GetItemsBySelectedCategoriesAndInitiative, GetCompletedItems, IsInitiativeExpired, ThroughputData } from "../../../Services/CompanyService";
import { ClientItemDisplayMetrics } from "./ClientItemDisplayMetrics";
import { ClientLevelCycleTimeMetrics } from "./ClientLevelCycleTimeMetrics";
import { ClientLevelItemProjectionMetrics } from "./ClientLevelItemProjectionMetrics";
import { ItemColor } from "../InitiativeLevelMetrics/MetricsGrid";
import { GetDisplayItems } from "../../../Services/ItemDisplayService";

export interface InitiativeThroughputData {
  initiativeId: string
  initiativeTitle: string
  total: number
}
type DisplayItem = ThroughputData & {
  color: ItemColor,
  categoryTitle: string,
  startDate: string | undefined,
  endDate: string | undefined,
  initiativeTitle: string
};

export interface DisplayInfo {
  items: DisplayItem[]
  startColumnName: string
  endColumnName: string
  firstStartedItemId: string
  firstCompletedItemId: string
}

interface ClientLevelMetricsGridProps {
  selectedCompany: Company
}

export function ClientLevelMetricsGrid(props: ClientLevelMetricsGridProps) {

  const initiativeThoughput = useMemo(() => {
    const throughput: InitiativeThroughputData[] = [];

    props.selectedCompany.initiatives.forEach(initiative => {
      if (initiative.isActive && !IsInitiativeExpired(initiative))
      {
        const throughputByCategory = GetItemsBySelectedCategoriesAndInitiative(props.selectedCompany, initiative);
        const completedThroughput = GetCompletedItems(throughputByCategory, props.selectedCompany.workflow, [
          { startDate: props.selectedCompany.metricStartDate, endDate: props.selectedCompany.metricEndDate },
          { startDate: initiative.startDate, endDate: initiative.targetDate }
        ]);
        const numberOfThroughput = completedThroughput.length;
        if (numberOfThroughput)
          throughput.push({ initiativeId: initiative.id, initiativeTitle: initiative.title, total: numberOfThroughput });
      }
    });
    return throughput;
  }, [props.selectedCompany]);

  const categoryColors: ItemColor[] = useMemo(() => [
    { code: "#8cd18f", name: "Green" },
    { code: "#f55351", name: "Red" },
    { code: "#e388d6", name: "Pink" },
    { code: "#f7a16a", name: "Orange" },
    { code: "#4fa0bd", name: "Blue" }
  ], []);

  const categoryMap = useMemo(() => {
    const theMap = new Map<string, { color: ItemColor, categoryTitle: string }>();
    let colorIndex = 0;
    const categories = props.selectedCompany ? props.selectedCompany.categories : [];
    for (const category of categories)
    {
      theMap.set(category.id, { color: categoryColors.at(colorIndex) ?? { code: "#ffffff", name: "White" }, categoryTitle: category.title });
      colorIndex++;
    }
    return theMap;
  }, [props.selectedCompany, categoryColors]);

  const itemDisplaySubtitle = useMemo(() => {
    const categories: JSX.Element[] = [];
    categoryMap.forEach(({ color, categoryTitle }) => {
      categories.push(<p style={{ color: color.code }}>{color.name}: {categoryTitle}</p>);
    });
    return <Stack sx={{ marginTop: 0 }}>
      {categories.map((category, index) => <Fragment key={index}>{category}</Fragment>)}
    </Stack>;
  }, [categoryMap]);

  const projectionTooltipMessage = `Choose a date and percent probability to see the number of ${plural(props.selectedCompany.terms.throughputTerm.toLowerCase())} that can be completed.`;
  const cycleTimeTooltipMessage = `The number of days it took to complete each ${props.selectedCompany.terms.throughputTerm.toLowerCase()}.`;

  const displayInfo: DisplayInfo | undefined = useMemo(() => {
    const activeInitiatives = props.selectedCompany.initiatives.filter(i => i.isActive && !IsInitiativeExpired(i));
    const clientThroughput = props.selectedCompany.throughput;
    return GetDisplayItems(clientThroughput, props.selectedCompany, activeInitiatives, categoryMap);
  }, [props.selectedCompany, categoryMap]);

  const sections: (false | JSX.Element)[] = [
    (props.selectedCompany.displayMetrics.itemProjection &&
      <DashboardSection title={`${CapitalizeAll(props.selectedCompany.terms.throughputTerm)} Projection`} expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center", paddingY: 3 }}>
          <ClientLevelItemProjectionMetrics company={props.selectedCompany} />
        </Stack>}
        tooltipMessage={projectionTooltipMessage}
      />
    ),
    (props.selectedCompany.displayMetrics.cycleTime &&
      <DashboardSection title="Cycle Time" expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center", paddingY: 3 }}>
          <ClientLevelCycleTimeMetrics company={props.selectedCompany} />
        </Stack>}
        tooltipMessage={cycleTimeTooltipMessage}
      />
    ),
    (props.selectedCompany.displayMetrics.initiativesInFocus &&
      <DashboardSection title={`${CapitalizeAll(plural(props.selectedCompany.terms.throughputTerm))} In Focus`} expandable={true} borderWidth={2} centerItems
        items={<Stack sx={{ alignItems: "center" }}>
          <InitiativesInFocusChart company={props.selectedCompany} initiativeThroughput={initiativeThoughput} useMinWidth />
        </Stack>}
      />
    ),
    (props.selectedCompany.displayMetrics.deliveredItems && !!displayInfo && displayInfo.items.length > 0 &&
      <DashboardSection title={`Delivered ${CapitalizeAll(plural(props.selectedCompany.terms.throughputTerm))}`} expandable={true} borderWidth={2} centerItems
        items={
          <ClientItemDisplayMetrics company={props.selectedCompany} displayInfo={displayInfo} categoryMap={categoryMap} />
        }
        tooltipSubtitle={itemDisplaySubtitle}
      />
    )
  ];

  if(sections.filter(s => s).length === 0)
    return <></>;
  return (
    <Box sx={{ marginY: 4 }}>
      <DashboardSection title={"Metrics"} hideDividers={true}
        items={
          <Masonry columns={{ xs: 1, sm: 2, lg: 3, xl: 4 }} spacing={4} sx={{ width: "auto", marginX: 1 }}>
            {sections.map((section, index) =>
              <Fragment key={index}>{section}</Fragment>
            )}
          </Masonry>
        }
      />
    </Box>
  );
}