import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthenticateSession, AuthenticateSessionRequest, EndSession, EndSessionRequest } from "../Services/SessionService";
import { RootState } from "./Store";
import { SetUpUserAfterAuthenticate, signOut } from "./UserSlice";
import { clearCompanies } from "./CompanySlice";
import { clearArticles } from "./ArticleSlice";
import { clearAvatars, clearLogos } from "./DocumentSlice";

export interface SessionState {
  sessionId: string
}

const initialState: SessionState = {
  sessionId: ""
}

export const authenticateSession = createAsyncThunk(
  'session/authenticateSession',
  async (args: AuthenticateSessionRequest, { dispatch }) => {
    try
    {
      const response = await AuthenticateSession(args);
      if (!response.user)
        throw Error("User could not be found with the given session id.");
      else
      {
        const user = response.user;
        await SetUpUserAfterAuthenticate(user, dispatch);
      }
    }
    catch (e)
    {
      dispatch(clearSessionId());
    }
  }
)

export const endSession = createAsyncThunk(
  'session/endSession',
  async (args: EndSessionRequest, { dispatch }) => {
    dispatch(clearSessionId());
    await EndSession(args);
  }
)

export const logOut = createAsyncThunk(
  'session/logOut',
  async (args: {}, { dispatch, getState }) => {
    const sessionId = (getState() as RootState).sessions.sessionId;
    await dispatch(endSession({ sessionId }));
    dispatch(clearCompanies());
    dispatch(clearArticles());
    dispatch(clearAvatars());
    dispatch(clearLogos());
    dispatch(signOut());
  }
)

export const sessionSlice = createSlice({
  name: "sessions",
  initialState: initialState,
  reducers: {
    setSessionId: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.sessionId = id;
    },
    clearSessionId: (state) => {
      state.sessionId = "";
    }
  },
  extraReducers: (builder) => {
  },
});

export const { setSessionId, clearSessionId } = sessionSlice.actions;

export const selectSessionId = (state: RootState) => state.sessions.sessionId;

export default sessionSlice.reducer;