import { CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ThemeProvider, Typography } from "@mui/material";
import { Company, Initiative } from "../../Store/CompanySlice";
import { User } from "../../Store/UserSlice";
import { TableHeaderStyle, blueTheme, defaultRowStyle } from "../../Styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SearchBar } from "../SearchBar";
import { AddButton } from "../AddButton";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "../../Hooks/Hooks";
import { Article, getArticle, upsertArticle } from "../../Store/ArticleSlice";
import { useSorter } from "../../Hooks/useSorter";
import { ErrorSnackbar, SuccessSnackbar } from "../../Services/Snackbars";
import { plural } from "pluralize";
import { SortLabel } from "../SortLabel";
import { UpsertArticleRequest } from "../../Services/ArticleService";
import { ReadOnlyRichText } from "../Quill/ReadOnlyRichText";
import { ValidateArticle, ValidationFailedPrefix } from "../../Services/Validation/Validation";
import { ArticleUpsertModal } from "./ArticleUpsertModal";

export const ArticleManagementSectionIds = {
  upsertModal: {
    modal: "articleManagementSectionModal",
    closeModalButton: "articleManagementSectionCloseModalButton",
    saveButton: "articleManagementSectionSaveButton",
    cancelButton: "articleManagementSectionCancelButton",
    newTitle: "articleManagementSectionNewTitle",
    newText: "articleManagementSectionNewText",
    isIntegrityOnly: 'articleManagementSectionIsIntegrityOnly'
  },
  keywordFilter: "articleManagementSectionKeywordFilter",
  addButton: "articleManagementSectionAddButton",
  editButton: "articleManagementSectionEditButton"
}

interface ArticleManagementSectionProps {
  company: Company
  initiative: Initiative | undefined
  currentUser: User
  userHasUploadPermission: boolean
}

export function ArticleManagementSection(props: ArticleManagementSectionProps)
 {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [articleInfos, setArticleInfos] = useState<Article[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articleToEdit, setArticleToEdit] = useState<Article>();
  const [isUpserting, setIsUpserting] = useState(false);
  const currentInitiativeId = props.initiative?.id;

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GetData = useCallback(async () => {
    setIsLoading(true);
    try
    {
      const companyArticles = await dispatch(getArticle({companyId: props.company.id, initiativeId: currentInitiativeId ?? undefined, userCompanyId: props.currentUser.companyId})).unwrap();
      setArticleInfos(companyArticles);
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoading(false);
  }, [props.company.id, currentInitiativeId]);

  function LeaveEditMode() {
    setArticleToEdit(undefined);
    setIsModalOpen(false);
  }

  function EnterEditMode(article?: Article) {
    setArticleToEdit(article);
    setIsModalOpen(true); 
  }

  async function HandleSaveEdit(editedArticle: Article) {
    if (editedArticle)
    {
      const validation = ValidateArticle(editedArticle);
      if (!validation.success)
      {
        ErrorSnackbar(ValidationFailedPrefix + validation.message);
        return;
      }

      if (!isUpserting)
      {
        setIsUpserting(true);

        const articleRequest: UpsertArticleRequest = {articles: [editedArticle]}
        try
        {
          await dispatch(upsertArticle(articleRequest)).unwrap();
          SuccessSnackbar("Article updated successfully.");
        }
        catch (e)
        {
          ErrorSnackbar(e);
        }
        LeaveEditMode();
        GetData();
        setIsUpserting(false);
      }
    }
  }

  useEffect(() => {
    GetData();
  }, [GetData]);

  function HandleCancelEdit() {
    LeaveEditMode();
  }

  const filteredArticles = useMemo(() => {
    const upperKeyword = searchedKeyword.toUpperCase();
    return articleInfos?.filter(article =>
      (article.title.toUpperCase().includes(upperKeyword)
        || article.text.toUpperCase().includes(upperKeyword)
      )
    );
  }, [articleInfos, searchedKeyword]);

  const sortedArticles = useMemo(() => {
    if (filteredArticles)
      return SortItems(filteredArticles);
  }, [filteredArticles, SortItems]);

  useEffect(() => {
    if (filteredArticles)
    {
      const maxPages = Math.max(Math.ceil(filteredArticles.length / rowsPerPage), 1);
      if (page > maxPages - 1)
        setPage(maxPages - 1);
    }
  }, [filteredArticles, rowsPerPage, page]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedArticles = sortedArticles?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4">
        {isLoading && articleInfos?.length === 0 &&
          <div className="flex justify-center w-full h-full my-2">
            <CircularProgress size={20} color={"warning"} />
          </div>
        }
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            {articleInfos?.length !== 0 ?
              <SearchBar cypressData={""} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => { setSearchedKeyword(value); setPage(0) }} />
              :
              <Typography variant="body2">There are no {plural(props.company.terms.documentTerm.toLowerCase())} to display</Typography>
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            {props.userHasUploadPermission &&
              <AddButton cypressData={ArticleManagementSectionIds.addButton} HandleClick={() => EnterEditMode()} />
            }
          </Grid>
        </Grid>
        {articleInfos?.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle sx={{ width: "30vw" }}>Title</TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "80vw" }}>Content</TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="updatedDate" heading="Updated On" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="updatedBy" heading="Updated By" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "10vw" }}>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    slicedArticles?.map((article, index) => {
                      let truncatedArticleText: string = "";
                      let articleText: string = "";
                      if (article.text.length > 175)
                      {
                        articleText = article.text.slice(0, 175);
                        truncatedArticleText = articleText.slice(0, articleText.lastIndexOf(" "));
                      }
                      return (
                        <TableRow className={defaultRowStyle}
                          sx={{
                            borderBottom: "1px solid black",
                            "& td": {
                              fontFamily: "Arial, Helvetica",
                              color: "#21345b"
                            }
                          }}
                          key={index}
                        >
                          <TableCell>
                            <Typography variant="caption">{article.title}</Typography>
                          </TableCell>
                          <TableCell>
                            <ReadOnlyRichText cypressData={""} text={article.text.length > 175 ? truncatedArticleText + "..." : article.text} />
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{article.updatedDate.month + "/" + article.updatedDate.day + "/" + article.updatedDate.year}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{article.updatedBy ?? ""}</Typography>
                          </TableCell>
                          <TableCell>
                            {props.userHasUploadPermission &&
                              <IconButton data-cy={ArticleManagementSectionIds.editButton} color="primary" onClick={() => EnterEditMode(article)}>
                                <EditIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {sortedArticles &&
            <TablePagination
              component="div"
              count={sortedArticles.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />}
          </>
        }
        <ArticleUpsertModal company={props.company} initiative={props.initiative} currentUser={props.currentUser} isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} articleToEdit={articleToEdit} isUpserting={false} Save={HandleSaveEdit} Cancel={HandleCancelEdit} cypressData={ArticleManagementSectionIds.upsertModal}></ArticleUpsertModal>
      </div>
    </ThemeProvider>
  )}

