import { Category } from "../../Store/CompanySlice";
import { FailValidation, SuccessValidation, Validation } from "./Validation";

export const CategoryValidationReasons = {
  emptyTitle: "Cannot leave title blank."
};

export function ValidateCategory(category: Category): Validation {
  if(!category.title)
    return FailValidation(CategoryValidationReasons.emptyTitle);
  return SuccessValidation("Successfully validated category.");
}
