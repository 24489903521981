import { InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { VisiblityToggle } from "./User/VisibilityToggle";

export function AccountField(props: {
  cypressData: string
  value: string | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  title?: string
  label?: string
  multiline?: boolean
  password?: boolean
}) {
  const [passwordShown, setPasswordShown] = useState(!props.password);
  function ToggleShownPassword() {
    setPasswordShown(!passwordShown);
  }

  return (
    <div>
      <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
      <TextField
        label={props.label}
        type={passwordShown ? 'text' : 'password'}
        inputProps={{
          'data-cy': props.cypressData
        }}
        InputProps={{
          startAdornment: props.password ? (
            <InputAdornment position="start">
              <VisiblityToggle isVisible={!!passwordShown} ToggleFunc={() => ToggleShownPassword()} />
            </InputAdornment>
          )
            : undefined
        }}
        multiline={props.multiline} maxRows={5} className="px-4 py-2" sx={{ width: "100%" }} value={props.value} onChange={props.onChange} />
    </div>
  );
}