import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { useMemo } from "react";

interface DateInputProps {
  cypressData: string,
  dateString: string,
  setDateString: (value: string) => void,
  disabled?: boolean
  label?: string
  sx?: SxProps<Theme>
}

export function DateInput(props: DateInputProps) {
  const myDayjs: Dayjs | null = useMemo(() => props.dateString ? dayjs(props.dateString) : null, [props.dateString]);

  function HandleDateChange(newDayjs: Dayjs | null) {
    if (newDayjs)
    {
      const newDateString = newDayjs.format("YYYY-MM-DD");
      if (newDateString === "Invalid Date")
        props.setDateString("");
      else
        props.setDateString(newDateString);
    }
    else
      props.setDateString("");
  }

  return (
    <div className='flex space-x-2' data-cy={props.cypressData}>
      <DatePicker sx={props.sx} slotProps={{actionBar: {actions: ["clear", "today", "cancel"], style: {justifyContent: "center", background: "#F3F1F3"}}}} label={props.label ?? ""} disabled={props.disabled} value={myDayjs} onChange={(newDayjs) => HandleDateChange(newDayjs)} />
    </div>
  )
}