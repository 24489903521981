import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Company } from "../../Store/CompanySlice";
import { ProgressBar } from "../Initiative/ProgressBar";
import { probabilityColors, tooltipStyle } from "../../Styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FindItemsRemainingByInitiative, GetCompletedItems, GetItemsBySelectedCategoriesAndInitiative, IsInitiativeExpired } from "../../Services/CompanyService";
import { ForecastCompletionProbability } from "../../Services/ProbabilitySimulationService";
import { InitCompanyDisplay } from "../Initiative/InitiativesTable";
import { useMemo } from "react";
import { plural } from "pluralize";
import { DashboardSection } from "../DashboardSection";

interface DashboardActiveInitiativesSectionProps {
  company: Company
  onClick: (initiativeId: string) => void
}

export function DashboardActiveInitiativesSection(props: DashboardActiveInitiativesSectionProps) {

  function GetHealthIndicator(probability: number | undefined) {
    if (probability)
    {
      if (probability >= 85)
        return probabilityColors.green;
      if (probability >= 51)
        return probabilityColors.yellow;
      if (probability >= 0)
        return probabilityColors.red;
    }
    return probabilityColors.gray;
  }

  const probabilityCalculatedInitiatives = useMemo(() => {
    const allInitCompanyDisplays: InitCompanyDisplay[] = [];
    const activeInits = props.company.initiatives.filter(i => i.isActive && !IsInitiativeExpired(i));
    for (const init of activeInits)
    {
      const itemsRemaining = FindItemsRemainingByInitiative(props.company, init);
      const itemsBySelectedCategories = GetItemsBySelectedCategoriesAndInitiative(props.company, init);
      const completedItems = GetCompletedItems(itemsBySelectedCategories, props.company.workflow, [
        { startDate: props.company.metricStartDate, endDate: props.company.metricEndDate }
      ]);
      const probability = ForecastCompletionProbability(init.targetDate, completedItems, itemsRemaining, props.company.workflow);
      allInitCompanyDisplays.push({
        initiative: init,
        company: props.company,
        initiativeTitle: init.title,
        itemsRemaining: itemsRemaining,
        probabilityValue: probability.value,
        probabilityStatus: probability.status,
        companyName: props.company.name,
        startDateTime: new Date(init.startDate),
        targetDateTime: new Date(init.targetDate)
      });
    }
    return allInitCompanyDisplays;
  }, [props.company]);

  const tableCellSx = { height: 'auto', padding: 0, paddingLeft: 2, paddingBottom: 1, border: 0 };

  if (probabilityCalculatedInitiatives.length === 0)
    return <></>;
  else
    return (
      <Box sx={{ marginY: 4 }}>
        <DashboardSection
          title={`Active ${plural(props.company.terms.initiativeTerm)}`}
          items={
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ height: 20 }}>
                    <TableCell sx={tableCellSx}></TableCell>
                    <TableCell sx={tableCellSx}>Start Date</TableCell>
                    <TableCell sx={tableCellSx}>Target Date</TableCell>
                    <TableCell sx={tableCellSx}>Percent Completed</TableCell>
                    <TableCell sx={tableCellSx}>On-Time Completion Probability</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {probabilityCalculatedInitiatives.map((item, index) => {
                    const itemsCompleted = item.initiative.totalItems - item.itemsRemaining;
                    const hasTargetDate = item.initiative.targetDate !== "";
                    const probability = { value: item.probabilityValue, status: item.probabilityStatus };
                    const healthIndicator = GetHealthIndicator(probability.value);
                    const tooltipMessage = probability.value === undefined ? probability.status :
                      probability.value === 0 && hasTargetDate ? "Data may be insufficient or may indicate a very low probability of success" :
                      !hasTargetDate ? `${item.company.terms.initiativeTerm} does not have a target date.` :
                        probability.value + "%";
                    const completedPercentage = Math.round(Math.min((itemsCompleted / item.initiative.totalItems), 1) * 100);
                    const itemsCompletedTooltipMessage = `${props.company.terms.initiativeTerm} does not have any items`;
                    return (
                      <TableRow sx={{ fontSize: 20, '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                        <TableCell align="left" sx={{ width: "20%" }}>
                          <Button sx={{ alignSelf: "flex-start" }} startIcon={<VisibilityIcon />} onClick={() => props.onClick(item.initiative.id)}>
                            {item.initiative.title}
                          </Button>
                        </TableCell>
                        <TableCell align="left" sx={{ width: "15%", fontSize: 15 }}>
                          {item.initiative.startDate ? new Date(item.initiative.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "N/A"}
                        </TableCell>
                        <TableCell align="left" sx={{ width: "15%", fontSize: 15 }}>
                          {item.initiative.targetDate ? new Date(item.initiative.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "N/A"}
                        </TableCell>
                        {item.initiative.totalItems > 0 ?
                          <TableCell align="left" sx={{ width: "25%" }}>
                            <ProgressBar bgcolor="blue" completed={completedPercentage} />
                          </TableCell>
                          :
                          <TableCell align="left" sx={{ width: "25%" }} className={tooltipStyle} title={completedPercentage > 0 ? `${completedPercentage.toString()}%` : itemsCompletedTooltipMessage}>
                            <span>NA</span>
                            <i className="material-icons" style={{ fontSize: '15px', marginLeft: '15px', marginTop: '10px' }}>info_outline</i>
                          </TableCell>
                        }
                        <TableCell className={tooltipStyle} title={tooltipMessage} sx={{ width: "25%" }}>
                          {(probability.value !== undefined && hasTargetDate) ?
                            <ProgressBar key={index} bgcolor={healthIndicator} completed={probability.value} />
                            :
                            <Stack alignItems="center" direction="row">
                              <span>NA</span>
                              <i className="material-icons" style={{ fontSize: '15px', marginLeft: '15px' }}>info_outline</i>
                            </Stack>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        />
      </Box>
    )
}
