import { Box, Container, TextField, Typography } from "@mui/material";
import { DecisionModalIds } from "../Decisions/DecisionDataModal";
import { BaseModal } from "../BaseModal";
import { LoginPageIds } from "../../Pages/LoginPage";
import { SubmitButton, tableCellFontSize, tableHeaderFontSize } from "../../Styles";
import { useState } from "react";
import { emailUser } from "../../Store/UserSlice";
import { useAppDispatch } from "../../Hooks/Hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { validateReCAPTCHA } from "../../Services/ReCAPTCHA";

interface ResetPasswordModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export function ResetPasswordLinkModal(props: ResetPasswordModalProps) {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const SITE_KEY = "6Lfh5uwnAAAAAANcnrZZJAkL0sE1pQPUDwUNY0a5";

  async function HandleReset(key: any) {
    if (key === 'Enter')
      await Reset();
  }

  async function Reset() {
    dispatch(emailUser({ recipientAddress: userEmail }));

    setMessage("Check your email for password reset instructions.");
    setDisabled(true);
  }

  function HandleClose() {
    setMessage("");
    setUserEmail("");
    setDisabled(false);
    setCaptchaSuccess(false);
    props.setIsOpen(false);
  }

  async function HandleCaptchaSubmit(token: string | null) {
    if (token)
    {
      try
      {
        const success = await dispatch(validateReCAPTCHA({ token })).unwrap();
        setCaptchaSuccess(success);
      }
      catch(e)
      {
        console.log((e as Error).message);
        setCaptchaSuccess(false);
      }
    }
    else
    {
      setCaptchaSuccess(false);
    }
  }

  return (
    <>
      <BaseModal
        open={props.isOpen}
        onClose={() => HandleClose()}
        cypressData={{ modal: DecisionModalIds.modal, closeModalButton: DecisionModalIds.closeModalButton }}
        title={"Reset Password"}
      >
        <Container component="main" maxWidth="xs" className="bg-gray-100 py-4 mt-[1%] mb-[1%] rounded-md shadow-md">
          <Box component="form"
            onSubmit={HandleReset}
            noValidate sx={{
              marginTop: 3,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              height: "auto",
              borderRadius: 3
            }} >
            <Typography component="h1" variant="h5" sx={{ fontSize: tableHeaderFontSize, mb: 2, textAlign: "center" }}>
              Enter your email :
            </Typography>
            <TextField
              InputProps={{ style: { fontSize: tableCellFontSize } }}
              color="darkBlue"
              margin="normal"
              required
              fullWidth
              autoFocus value={userEmail}
              disabled={disabled}
              data-cy={LoginPageIds.email}
              label="Email Address"
              name="email"
              onChange={e => setUserEmail(e.target.value)}
              onKeyDown={e => HandleReset(e.key)}
            />
            <ReCAPTCHA sitekey={SITE_KEY} onChange={HandleCaptchaSubmit} />
            <SubmitButton disabled={disabled || !userEmail || !captchaSuccess} sx={{ mt: 3, mb: 3 }}
              onClick={(e) => Reset()}
            >
              Send Reset Link
            </SubmitButton>
            {message &&
              <div className="outline rounded outline-blue-600 p-2 flex justify-center w-[100%]">
                <Typography textAlign="center" className="text-blue-600">{message}</Typography>
              </div>
            }
          </Box>
        </Container>
      </BaseModal>
    </>
  )
}
