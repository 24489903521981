import { Box, Button, Chip, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { DecisionData } from "../../Services/CompanyService"
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReadOnlyRichText } from "../Quill/ReadOnlyRichText";
import { Fragment, useCallback, useMemo, useState } from "react";
import { UserAvatar, UserWithAvatarUrl } from "../User/UserAvatar";

interface DashboardDecisionItemProps {
  decision: DecisionData
  teamUsers: UserWithAvatarUrl[]
}

export function DashboardDecisionItem(props: DashboardDecisionItemProps) {

  const descriptionText = props.decision.description.slice(0, 175);
  const truncatedDescriptionText = descriptionText.slice(0, descriptionText.lastIndexOf(" "));
  const resolutionText = props.decision.resolution.slice(0, 175);
  const truncatedResolutionText = resolutionText.slice(0, resolutionText.lastIndexOf(" "));

  const [isOpen, setIsOpen] = useState(false);

  const findUserParticipant = useCallback((participantId: string) => {
    return props.teamUsers.find(user => user.id === participantId);
  }, [props.teamUsers]);

  const mappables = useMemo(() => {
    return props.decision.participants.mappables.map((participant, index) => {
      let userParticipant = findUserParticipant(participant.id);
      if (userParticipant)
        return (
          <Chip key={index} variant="outlined" label={userParticipant.name || userParticipant.email} avatar={<UserAvatar user={userParticipant} length={30} />} />
        )
      else
        return <Fragment key={index}></Fragment>
    });
  }, [props.decision.participants.mappables, findUserParticipant]);

  const unmappables = useMemo(() => {
    return props.decision.participants.unmappables?.map((participant, index) => {
        return (
          <Chip key={index} variant="outlined" label={participant.name} />
        )
      });
  }, [props.decision.participants.unmappables]);

  const combinedParticipantChips = mappables.concat(unmappables);

  return (
    <>
      <ListItem sx={{
        marginLeft: 1,
        marginTop: 1
      }}>
        <Stack direction="row" spacing={.5}>
          <ListItemIcon>
            <CheckCircleIcon sx={{ color: "#69D5C3", fontSize: 35, marginTop: 1.5 }} />
          </ListItemIcon>
          <ListItemText>
            {isOpen ?
              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="body2">{props.decision.description}</Typography>
                <ReadOnlyRichText cypressData={""} text={props.decision.resolution} />
                <Grid container sx={{marginY: 3}}>
                  {combinedParticipantChips?.map((chip,index) =>
                    <Grid item xs={5} key={index} sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginTop: 1
                    }}>
                      {chip}
                    </Grid>
                  )}
                </Grid>
                <div className="text-sm mt-4 ml-4 h-4">
                  Posted:   {props.decision.date.month + "/" + props.decision.date.day + "/" + props.decision.date.year}
                  <Button sx={{ color: "blue", fontSize: 16 }} style={{ position: 'absolute', right: 25, textTransform: "none" }} onClick={() => setIsOpen(false)}>Less...</Button>
                </div>
              </Box>
              :
              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="body2">{props.decision.description.length > 175 ? truncatedDescriptionText + "..." : props.decision.description}</Typography>
                <ReadOnlyRichText cypressData={""} text={props.decision.resolution.length > 175 ? truncatedResolutionText + "..." : props.decision.resolution} />
                <Button sx={{ color: "blue", fontSize: 16, height: 10 }} style={{ position: 'absolute', right: 25, textTransform: "none" }} onClick={() => setIsOpen(true)}>More...</Button>
              </Box>
            }
          </ListItemText>
        </Stack>
      </ListItem>
    </>
  )
}
