import { Workflow } from "../Store/CompanySlice";
import { MakeClone } from "./Cloning";
import { ThroughputData } from "./CompanyService";

export interface MaybeProbability {
  value: number | undefined
  status: string
  itemsUsed: ThroughputData[]
}

interface MaybeDate {
  value: Date | undefined
  status: string
}

type GroupedItems = { date: string, itemsCompleted: number };

function HasTargetDatePassed(targetDate: Date) {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return targetDate < today;
}

export function ForecastCompletionProbability(targetDateString: string, stories: ThroughputData[], itemsRemaining: number, workflow: Workflow): MaybeProbability {
  const targetDate = new Date(targetDateString + "T00:00:00");
  if (HasTargetDatePassed(targetDate))
    return { value: undefined, status: "Target date has already passed", itemsUsed: stories };

  const itemGroups = PreprocessThroughput(stories, workflow);
  if (itemsRemaining < 1) return { value: undefined, status: "All items completed", itemsUsed: stories};
  if (itemGroups.length === 0 || itemGroups.every(item => item.itemsCompleted === 0))
    return { value: undefined, status: "No data available to calculate probability", itemsUsed: stories };
  const endDates = PredictCompletionDates(itemsRemaining, itemGroups);
  return { value: GetCompletionProbability(endDates, targetDate), status: "", itemsUsed: stories };
}

export function ForecastCompletionDate(stories: ThroughputData[], itemsRemaining: number, inputProbability: number, workflow: Workflow): MaybeDate {
  const itemGroups = PreprocessThroughput(stories, workflow);
  if (itemGroups.length === 0 || itemGroups.every(item => item.itemsCompleted === 0))
    return { value: undefined, status: "No data available to calculate completion date" };

  const endDates = PredictCompletionDates(itemsRemaining, itemGroups);
  return { value: GetCompletionDate(endDates, inputProbability), status: "" };
}

export function ForecastItemsCompleted(targetDateString: string, stories: ThroughputData[], inputProbability: number, workflow: Workflow) {
  const targetDate = new Date(targetDateString + "T00:00:00");
  if (HasTargetDatePassed(targetDate))
    return { value: undefined, status: "Target date has already passed" };

  const itemGroups = PreprocessThroughput(stories, workflow);
  if (itemGroups.length === 0 || itemGroups.every(item => item.itemsCompleted === 0))
    return { value: undefined, status: "No data available to calculate items completed" };

  const itemCounts = PredictItemsCompletedByDate(targetDate, itemGroups);
  return { value: GetItemsCompleted(itemCounts, inputProbability), status: "" };
}

export function AddDays(date: Date, days: number) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function PredictCompletionDates(itemsRemaining: number, historicalData: GroupedItems[]): Date[] {
  let endDates: Date[] = [];
  let today = new Date();
  today.setHours(0, 0, 0, 0);      //remove timestamp for comparison reasons, as the dateInfos don't use timestamps

  for (let i = 0; i < 10000; i++)
  {
    let incompleteItems = itemsRemaining;
    let days = 0;
    while (incompleteItems > 0)
    {
      const index = Math.floor(Math.random() * historicalData.length);
      const items = historicalData[index].itemsCompleted;
      incompleteItems = incompleteItems - items;
      days++;
    }
    let endDate = AddDays(today, days);
    endDates.push(endDate);
  }
  return endDates;
}

function PredictItemsCompletedByDate(targetDate: Date, historicalData: GroupedItems[]) {
  let results: number[] = [];
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  const dayDifference = Math.ceil((targetDate.getTime() - today.getTime()) / 1000 / 60 / 60 / 24);

  for (let i = 0; i < 10000; i++)
  {
    let itemsCompleted = 0;
    let days = 0;
    while (days < dayDifference)
    {
      const index = Math.floor(Math.random() * historicalData.length);
      const items = historicalData[index].itemsCompleted;
      itemsCompleted += items;
      days++;
    }
    results.push(itemsCompleted);
  }
  return results;
}

function GetCompletionProbability(endDates: Date[], targetDate: Date) {
  let successDates = endDates.filter(function (date) {
    return date <= targetDate
  });

  let probability = Math.round((successDates.length / endDates.length) * 100);
  if (probability === 100)
    return 99;
  else
    return probability;
}

function GetCompletionDate(endDates: Date[], inputProbability: number) {
  endDates.sort((a, b) => a.getTime() - b.getTime());
  const percentOfDates = endDates.slice(0, Math.floor(endDates.length * inputProbability / 100));
  return percentOfDates.at(-1);
}

function GetItemsCompleted(itemCounts: number[], inputProbability: number) {
  itemCounts.sort((a, b) => a - b).reverse();
  const percentOfCounts = itemCounts.slice(0, Math.floor(itemCounts.length * inputProbability / 100));
  return percentOfCounts.at(-1);
}

function PreprocessThroughput(throughput: ThroughputData[], workflow: Workflow) {
  const itemGroups: GroupedItems[] = [];
  if (throughput.every(story => !story.progressDates.find(p => p.workflowColumnId === workflow.endDateColumnId)?.enterColumnDate))
    return [];

  throughput.forEach((story) => {
    const completionDate = story.progressDates.find(p => p.workflowColumnId === workflow.endDateColumnId)?.enterColumnDate;
    if (completionDate)
    {
      const dateString = new Date(completionDate).toLocaleDateString('en-US', { timeZone: 'UTC' }) ?? "";
      const matchingPair = itemGroups.find(pair => pair.date === dateString);
      if (matchingPair)
        matchingPair.itemsCompleted++;
      else
        itemGroups.push({ date: dateString, itemsCompleted: 1 })
    }
  });

  const throughputWithZeros = AddZeroEntries(itemGroups);
  return throughputWithZeros;
}

function AddZeroEntries(itemGroups: GroupedItems[]): GroupedItems[] {
  let throughput = MakeClone(itemGroups).sort(function compare(a: GroupedItems, b: GroupedItems) {
    let dateA = new Date(a.date).getTime();
    let dateB = new Date(b.date).getTime();
    return dateA - dateB;
  });

  const firstThroughput = throughput.at(0);
  if (!firstThroughput)
    throw Error("Cannot add zero entries if throughput list is empty.");
  const startDate = firstThroughput.date;

  const lastThroughput = throughput.at(-1);
  if (!lastThroughput)
    throw Error("Cannot add zero entries if throughput list is empty.");
  const lastDate = new Date(lastThroughput.date);

  let currentDate = new Date(startDate);
  while (currentDate.getTime() < lastDate.getTime())
  {
    const currentDateTime = currentDate.getTime();
    const matchingDate = throughput.find(data => currentDateTime === new Date(data.date).getTime());
    if (!matchingDate)
      throughput.push({ date: currentDate.toLocaleDateString('en-US', { timeZone: 'UTC' }), itemsCompleted: 0 });

    currentDate = AddDays(currentDate, 1);
  }
  return throughput;
}
