import { Stack, Typography } from "@mui/material";
import { Company } from "../../../Store/CompanySlice";
import { useMemo } from "react";
import { GetMaxCycleTime } from "../../../Services/CycleTimeService";
import { metricsFontLarge, metricsFontSmall } from "../../../Styles";
import { plural } from "pluralize";
import { GetCompletedItems, GetItemsBySelectedCategoriesAndCompany, IsInitiativeExpired } from "../../../Services/CompanyService";
import { InsufficientThroughputSection } from "../InitiativeLevelMetrics/InsufficientThroughputSection";

interface ClientLevelCycleTimeMetricsProps {
  company: Company
}

export function ClientLevelCycleTimeMetrics(props: ClientLevelCycleTimeMetricsProps) {
  const activeInitiatives = props.company.initiatives.filter(i => i.isActive && !IsInitiativeExpired(i));
  const itemsBySelectedCategories = GetItemsBySelectedCategoriesAndCompany(props.company);
  const completedItems = GetCompletedItems(itemsBySelectedCategories, props.company.workflow, [
      { startDate: props.company.metricStartDate, endDate: props.company.metricEndDate }
    ]).filter(item => {
      const initiative = activeInitiatives.find(init => init.id === item.initiativeId);
      return !!initiative;
    });
  const cycleDays = useMemo(() => {
    return GetMaxCycleTime(completedItems, 85, props.company.workflow);
  }, [props.company]);

  const bigTextSX = metricsFontLarge;
  const smallTextSX = metricsFontSmall;

  return (
    <>
      <Stack sx={{ paddingX: 2 }} gap={1} alignItems="center">
      <InsufficientThroughputSection company={props.company} completedItemCount={completedItems.length}>
          <>
            <Typography sx={bigTextSX}>{cycleDays} days or less</Typography>
            <Typography sx={smallTextSX}>were required to complete</Typography>
            <Typography sx={bigTextSX}>85%</Typography>
            <Typography sx={smallTextSX}>of {plural(props.company.terms.throughputTerm.toLowerCase())}</Typography>
          </>
        </InsufficientThroughputSection>
      </Stack>
    </>
  )
}