import { useNavigate, useSearchParams } from "react-router-dom"
import { SuccessSnackbar, ErrorSnackbar } from "../Services/Snackbars"
import { ValidateCompany, ValidationFailedPrefix } from "../Services/Validation/Validation"
import { Company, selectAllCompanies, upsertCompanyInfo } from "../Store/CompanySlice"
import { useAppDispatch, useAppSelector } from "./Hooks"
import { useMemo, useState } from "react"
import { FileUrlPair } from "../Components/EditImage"
import { MakeClone } from "../Services/Cloning"
import { v4 } from "uuid"
import { CreateDefaultWorkflow } from "../Services/CompanyService"

type EditCompanyAccount = {
  cloneForEditing: Company | undefined
  setCloneForEditing: (value: Company | undefined) => void
  isSavingCompany: boolean
  isAddingNewCompany: boolean
  companyToEdit: Company | undefined
  SaveEdit: () => void
  CancelEdit: () => void
  newLogoFileUrl: FileUrlPair
  setNewLogoFileUrl: (value: FileUrlPair) => void
}

export function useEditCompanyAccount(): EditCompanyAccount {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSavingCompany, setIsSavingCompany] = useState(false);
  const [queryParameters] = useSearchParams();
  const allCompanies = useAppSelector(selectAllCompanies);
  const [newLogoFileUrl, setNewLogoFileUrl] = useState<FileUrlPair>({ url: "", file: null });

  const { companyToEdit, isAddingNewCompany } = useMemo(() => {
    const queryCompanyId = queryParameters.get("company");
    if (queryCompanyId)
    {
      const matchingCompany = allCompanies.find(company => company.id === queryCompanyId);
      if (matchingCompany)
        return { companyToEdit: matchingCompany, isAddingNewCompany: false };
    }
    else
    {
      const defaultCategoryId = v4();
      const newCompany: Company = {
        id: v4(),
        name: "",
        initiatives: [],
        links: [],
        categories: [
          {
            id: defaultCategoryId,
            title: "User Story",
            includeInSims: true
          }
        ],
        throughput: [],
        workflow: CreateDefaultWorkflow(),
        terms: {
          teamTerm: "Term",
          userTerm: "User",
          initiativeTerm: "Initiative",
          decisionTerm: "Decision",
          linkTerm: "Link",
          documentTerm: "Document",
          throughputTerm: "Item"
        },
        isActive: true,
        defaultCategoryId,
        metricStartDate: (new Date()).toISOString().split("T")[0],
        metricEndDate: (new Date()).toISOString().split("T")[0],
        displayMetrics: {
          initiativesInFocus: true,
          itemProjection: true,
          cycleTime: true,
          deliveredItems: true
        }
      }
      return { companyToEdit: newCompany, isAddingNewCompany: true };
    }
    return { companyToEdit: undefined, isAddingNewCompany: false };
  }, [allCompanies, queryParameters]);

  const [cloneForEditing, setCloneForEditing] = useState<Company | undefined>(MakeClone(companyToEdit));

  async function SaveEdit() {
    let editedCompany = MakeClone(cloneForEditing);
    if (editedCompany)
    {
      const validation = ValidateCompany(editedCompany, allCompanies);
      if (validation.success)
      {
        setIsSavingCompany(true);
        try
        {
          await dispatch(upsertCompanyInfo({ company: editedCompany, newWorkflow: isAddingNewCompany ? editedCompany.workflow : undefined, newCategory: isAddingNewCompany ? editedCompany.categories.find(c => c.id === editedCompany?.defaultCategoryId) : undefined })).unwrap();
          SuccessSnackbar("Changes have been saved.");
          ReturnToClientPage();
        }
        catch (e)
        {
          ErrorSnackbar(e);
        }
        setIsSavingCompany(false);
      }
      else
        ErrorSnackbar(ValidationFailedPrefix + validation.message);
    }
  }

  async function ReturnToClientPage() {
    navigate('/ClientPage');
  }

  function CancelEdit() {
    setCloneForEditing(companyToEdit);
    ReturnToClientPage();
  }

  return {
    SaveEdit,
    CancelEdit,
    setCloneForEditing,
    cloneForEditing,
    companyToEdit,
    isSavingCompany,
    isAddingNewCompany,
    newLogoFileUrl,
    setNewLogoFileUrl
  }
}