import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../Hooks/Hooks";
import { useSorter } from "../../Hooks/useSorter";
import { Company, Initiative, IntegrityId } from "../../Store/CompanySlice";
import { DateInfo, DecisionData } from "../../Services/CompanyService";
import { ThemeProvider } from "@emotion/react";
import { CircularProgress, Grid, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, IconButton, TablePagination, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { plural } from "pluralize";
import { blueTheme, TableHeaderStyle, defaultRowStyle, tableCellFontSize } from "../../Styles";
import { AddButton } from "../AddButton";
import { ReadOnlyRichText } from "../Quill/ReadOnlyRichText";
import { SearchBar } from "../SearchBar";
import EditIcon from '@mui/icons-material/Edit';
import { useEditDecision } from "../../Hooks/useEditDecision";
import { ShallowUser, getShallowUser } from "../../Store/UserSlice";
import { getCachedAvatarDocumentInfos } from "../../Store/DocumentSlice";
import { UserWithAvatarUrl } from "../User/UserAvatar";
import { CompareDateInfos, MakeDateString } from "../../Services/DateHelpers";
import { v4 as UuidV4 } from "uuid";
import { DecisionUpsertModal } from "./DecisionUpsertModal";
import { DateInfoInput } from "../DateInfoInput";
import { DecisionModalIds } from "./DecisionDataModal";
import { MakeClone } from "../../Services/Cloning";
import { SortLabel } from "../SortLabel";

interface DecisionManagementSectionProps {
    initiative: Initiative
    company: Company
}

export function DecisionManagementSection(props: DecisionManagementSectionProps)
 {
    const [selectedInitiative, setSelectedInitiative] = useState<Initiative>(props.initiative);
    const [queryTags, setQueryTags] = useState<string[]>([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    function ClearFilters() {
        setSearchedKeyword("");
        setQueryTags([]);
      }

    function SnapToTitle() {
    setTimeout(() => {
        titleRef.current?.focus();
        }, 1);
    }

    const allTags = useMemo(() => {
        let tags: string[] = [];
        for (const decision of props.initiative.decisions)
        {
          for (const tag of decision.tags)
          {
            if (!tags.find(t => t === tag))
              tags.push(tag);
          }
        }
        return tags;
      }, [props.initiative]);
    
      useEffect(() => {
        setQueryTags(prevState => prevState.filter(tag => allTags.find(t => t === tag)));
      }, [allTags]);

      const UpdateQueryTags = (tag: string | string[]) => {
        let selTags = MakeClone(queryTags);
        if (Array.isArray(tag))
          selTags = tag;
        else
        {
          const index = selTags.findIndex(t => t === tag);
          if (index >= 0)
            selTags.splice(index, 1);
          else
            selTags.push(tag);
        }
        setQueryTags(selTags);
      }
    
    const {
        cloneForEditing,
        setCloneForEditing,
        isEditing,
        isLoading,
        canAddTag,
        canAddParticipant,
        LeaveEditMode,
        DeleteDecision,
        CancelDelete,
        HandleStartEdit,
        HandleCancelEdit,
        HandleSaveEdit,
        HandleAddEmptyDecision,
        HandleAttemptDelete,
        HandleChangeNewTag,
        HandleAddTag,
        HandleRemoveTag,
        HandleChangeNewUnmappedParticipant,
        HandleChangeNewMappedParticipant,
        HandleAddParticipant,
        HandleRemoveUnmappedParticipant,
        HandleRemoveMappedParticipant
      } = useEditDecision(props.company, selectedInitiative, setSelectedInitiative, setIsDeleteOpen, ClearFilters, SnapToTitle);
    
  const dispatch = useAppDispatch();

  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [decisionToEdit, setDecisionToEdit] = useState<DecisionData>();
  const [isUpserting, setIsUpserting] = useState(false);
  const currentInitiativeId = props.initiative?.id;
  const [possibleParticipants, setPossibleParticipants] = useState<UserWithAvatarUrl[]>();
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const [startDate, setStartDate] = useState<DateInfo>();
  const [endDate, setEndDate] = useState<DateInfo>();

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const newId = UuidV4();
  const today = new Date();
  const todayInfo: DateInfo = { month: today.getMonth() + 1, day: today.getDate(), year: today.getFullYear() }

  function EnterEditMode(decision?: DecisionData) {
    if (decision)
        setDecisionToEdit(decision);
    else 
        setDecisionToEdit({
            id: newId,
            description: "",
            resolution: "",
            participants: {
                mappables: [],
                unmappables: []},
            date: todayInfo,
            tags: [] 
    })
    setIsModalOpen(true);
    setTimeout(() => {
        titleRef.current?.focus();
      }, 1); 
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const FindInParticipants = useCallback((participants: DecisionData["participants"], keyword: string) => {
    for (const participant of participants.unmappables)
    {
      if (participant.name.toUpperCase().includes(keyword.toUpperCase()))
        return true;
    }
    if (possibleParticipants)
    {
      for (const participant of participants.mappables)
      {
        const matchingUser = possibleParticipants.find(p => participant.id === p.id);
        if (matchingUser?.name?.toUpperCase().includes(keyword.toUpperCase()))
          return true;
      }
    }
    return false;
  }, [possibleParticipants]);

  const filteredDecisions = useMemo(() => {
    const upperKeyword = searchedKeyword.toUpperCase();
    return selectedInitiative.decisions.filter(
        d => {
          const searchMatches = d.description.toUpperCase().includes(upperKeyword)
            || d.resolution.toUpperCase().includes(upperKeyword)
            || FindInParticipants(d.participants, searchedKeyword);
          const tagMatches = queryTags.length ? d.tags.some(tag => queryTags.find(t => t === tag)) : true;
          const afterStart = startDate ? CompareDateInfos(d.date, startDate) >= 0 : true;
          const beforeEnd = endDate ? CompareDateInfos(d.date, endDate) <= 0 : true;
  
          return searchMatches && tagMatches && afterStart && beforeEnd;
        }
      );
    }, [selectedInitiative, searchedKeyword, queryTags, startDate, endDate, FindInParticipants]);
  
  const sortedDecisions = useMemo(() => {
    if (filteredDecisions)
      return SortItems(filteredDecisions);
  }, [filteredDecisions, SortItems]);

  useEffect(() => {
    if (filteredDecisions)
    {
      const maxPages = Math.max(Math.ceil(filteredDecisions.length / rowsPerPage), 1);
      if (page > maxPages - 1)
        setPage(maxPages - 1);
    }
  }, [filteredDecisions, rowsPerPage, page]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedDecisions = sortedDecisions?.slice(indexOfFirstItem, indexOfLastItem);

  function AddEmailsToDuplicates(array: ShallowUser[]) {
    let duplicateIndices = new Set();
    array.forEach((user, index) => {
      if (user.name)
      {
        array.forEach((u, i) => {
          if (u.name === user.name && i !== index)
          {
            duplicateIndices.add(i);
            duplicateIndices.add(index);
          }
        })
      }
    })

    let newArray = array.map((user, index) => duplicateIndices.has(index) ? { ...user, name: `${user.name} (${user.email})` } : user);
    return newArray;
  }

  useEffect(() => {
    async function GetPossibleParticipants() {
      try
      {
        const users = await dispatch(getShallowUser({ companyId: props.company.id })).unwrap();
        const integrityUsers = await dispatch(getShallowUser({ companyId: IntegrityId })).unwrap();
        const allAvatars = await dispatch(getCachedAvatarDocumentInfos({})).unwrap();
        const allUsers = AddEmailsToDuplicates(users.concat(integrityUsers));
        const usersWithAvatars = allUsers.map(user => {
          const matchingDoc = allAvatars.find(doc => doc.blobName === user.avatarBlobName);
          if (matchingDoc)
            return { ...user, docUrl: matchingDoc.tempUrl }
          return user;
        });
        setPossibleParticipants(usersWithAvatars);
      }
      catch (e)
      {
        console.log((e as Error).message);
        setPossibleParticipants([]);
      }
    }
      GetPossibleParticipants();
  }, [dispatch, props.company.id]);

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4">
        {selectedInitiative.decisions.length === 0 &&
          <div className="flex justify-center w-full h-full my-2">
            <CircularProgress size={20} color={"warning"} />
          </div>
        }
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            {selectedInitiative.decisions.length !== 0 &&
              <div className="flex space-x-2">
              <SearchBar cypressData={""} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => { setSearchedKeyword(value); setPage(0) }} />
              <FormControl fullWidth>
                <InputLabel id="tag-select-label">Select Tags</InputLabel>
                <Select sx={{ fontSize: tableCellFontSize }} data-cy={DecisionModalIds.tagSelect}
                    multiple labelId="tag-select-label" label="Select Tags"
                    value={queryTags} onChange={(e) => UpdateQueryTags(e.target.value)}
                    renderValue={(selected) => selected.map((x) => x).join(', ')}
                    disabled={isEditing}
                    >
                    {allTags.map((tag, index) => {
                    return (
                    <MenuItem sx={{ fontSize: tableCellFontSize }} key={index} value={tag}>
                        <Checkbox checked={queryTags.findIndex(t => t === tag) >= 0} />
                        <ListItemText>{tag}</ListItemText>
                    </MenuItem>
                    );
                })}
                </Select>
            </FormControl>
            <DateInfoInput cypressData="" date={startDate} setDate={setStartDate} label="Start Date" disabled={isEditing} />
            <DateInfoInput cypressData="" date={endDate} setDate={setEndDate} label="End Date" disabled={isEditing} />
            </div>
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
              <AddButton cypressData={""} HandleClick={() => EnterEditMode()} />
          </Grid>
        </Grid>
        {slicedDecisions?.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle sx={{ width: "30vw" }}>Description</TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "80vw" }}>{props.company.terms.decisionTerm}</TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "25vx" }}>
                        <SortLabel sortKey="date" heading="Posted" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle sx={{ width: "10vw" }}>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    slicedDecisions?.map((decision, index) => {
                      let truncatedDecisionText: string = "";
                      let decisionText: string = "";
                      if (decision.resolution.length > 175)
                      {
                        decisionText = decision.resolution.slice(0, 175);
                        truncatedDecisionText = decisionText.slice(0, decisionText.lastIndexOf(" "));
                      }
                      return (
                        <><TableRow className={defaultRowStyle}
                              sx={{
                                  borderBottom: "1px solid black",
                                  "& td": {
                                      fontFamily: "Arial, Helvetica",
                                      color: "#21345b"
                                  }
                              }}
                              key={index}
                            >
                            <TableCell>
                                <Typography variant="body2">{decision.description}</Typography>
                            </TableCell>
                            <TableCell>
                                <ReadOnlyRichText cypressData={""} text={decision.resolution.length > 175 ? truncatedDecisionText + "..." : decision.resolution} />
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption">{decision.date.month + "/" + decision.date.day + "/" + decision.date.year}</Typography>
                            </TableCell>
                            <TableCell>
                                <IconButton data-cy={""} color="primary" onClick={() => EnterEditMode(decision)}>
                                    <EditIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                            </TableCell>
                    </TableRow>
                  </>
                )})}
                </TableBody>
              </Table>
            </TableContainer>
            {sortedDecisions &&
            <TablePagination
              component="div"
              count={sortedDecisions.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />}
          </>
        }
        {!isLoading && filteredDecisions.length === 0 &&
            <Typography sx={{ marginBottom: 2.5 }} className="m-2 p-2 text-2xl font-bold">There are no {plural(props.company.terms.decisionTerm.toLowerCase())} to display</Typography>
        }
        {decisionToEdit && possibleParticipants &&
        <DecisionUpsertModal decision={decisionToEdit} isOpen={isModalOpen} HandleClose={() => setIsModalOpen(false)} isEditable={true} isEditingMe={true} isEditingAnyone={true} isLoading={false} canAddTag={true} canAddParticipant={true}
            handlers={{
                HandleChangeNewTag,
                HandleAddTag,
                HandleRemoveTag,
                HandleChangeNewUnmappedParticipant,
                HandleChangeNewMappedParticipant,
                HandleAddParticipant,
                HandleRemoveUnmappedParticipant,
                HandleRemoveMappedParticipant,
                HandleStartEdit,
                HandleSaveEdit,
                HandleCancelEdit,
                HandleAttemptDelete
            }}
            currentValues={cloneForEditing ?? decisionToEdit}
            setCurrentValues={(decision: DecisionData | undefined) => setCloneForEditing(decision)}
            titleRef={titleRef}
            allTags={allTags}
            possibleParticipants={possibleParticipants} initiative={selectedInitiative} company={props.company}></DecisionUpsertModal>
        }
      </div>
    </ThemeProvider>
  )}