import { DatePicker } from "@mui/x-date-pickers";
import { DateInfo } from "../Services/CompanyService";
import { MakeDate, MakeDateInfo } from "../Services/DateHelpers";
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

interface DateInfoInputProps {
  cypressData: string,
  date: DateInfo | undefined,
  setDate: (value: DateInfo | undefined) => void,
  disabled?: boolean
  label?: string
}

export function DateInfoInput(props: DateInfoInputProps) {
  const myDayjs: Dayjs | null = props.date ? dayjs(MakeDate(props.date)) : null;

  function HandleDateChange(newDayjs: Dayjs | null) {
    if (newDayjs)
    {
      const dateString = newDayjs.format("YYYY-MM-DD");
      props.setDate(MakeDateInfo(dateString));
    }
    else
      props.setDate(undefined);
  }

  return (
    <div className='flex space-x-2' data-cy={props.cypressData}>
      <DatePicker slotProps={{actionBar: {actions: ["clear", "today", "cancel"], style: {justifyContent: "center", background: "#F3F1F3"}}}} label={props.label ?? ""} disabled={props.disabled} value={myDayjs} onChange={(newDayjs) => HandleDateChange(newDayjs)} />
    </div>
  )
}