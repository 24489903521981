import { useCallback, useState } from "react";

export type PaginatorType = {
  page: number
  totalPages: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  PaginateItems: <T>(items: T[]) => T[]
  HandleRowsPerPageChange: (value: string) => void
  HandlePaginationChange: (event: any, page: number) => void
}

type PaginatorOptions = {
  rowsPerPageOptionsOverride: number[]
  initialPage: number
}

export function usePaginator(options?: Partial<PaginatorOptions>): PaginatorType
{
  const defaultOptions: PaginatorOptions = { rowsPerPageOptionsOverride: [5,10,25], initialPage: 1 };
  const currentOptions = { ...defaultOptions, ...options };
  const [page, setPage] = useState(currentOptions.initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = currentOptions.rowsPerPageOptionsOverride;
  const [totalPages, setTotalPages] = useState(0);

  function HandleRowsPerPageChange(value: string)
  {
    setRowsPerPage(parseInt(value,10)); setPage(1);
  }

  function HandlePaginationChange(event: any, page: number)
  {
    setPage(page);
  }

  const PaginateItems = useCallback(<T extends unknown>(items: T[]): T[] =>
  {
    const endIndex = (page * rowsPerPage);
    const startIndex = endIndex - rowsPerPage;
    const newTotalPages = Math.ceil(items.length/rowsPerPage);
    setTotalPages(newTotalPages);

    if(newTotalPages < page && newTotalPages > 0)
    {
      setPage(newTotalPages);
    }

    return items.slice(startIndex,endIndex);
  },[page,rowsPerPage]);

  return {
    page,
    totalPages,
    rowsPerPage,
    rowsPerPageOptions,
    PaginateItems,
    HandleRowsPerPageChange,
    HandlePaginationChange
  }
}
