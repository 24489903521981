import { Button, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ThemeProvider, Typography } from "@mui/material";
import { Company, deleteCompanyThroughput, selectAllCompanies, upsertCompanyThroughput } from "../../Store/CompanySlice";
import { SearchBar } from "../SearchBar";
import { AddButton } from "../AddButton";
import { plural } from "pluralize";
import { IntegrityTheme, TableHeaderStyle, blueTheme, defaultRowStyle } from "../../Styles";
import { useAppDispatch, useAppSelector } from "../../Hooks/Hooks";
import { useEffect, useMemo, useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from '@mui/icons-material/Upload';
import { ThroughputData } from "../../Services/CompanyService";
import { SortLabel } from "../SortLabel";
import { DeleteAlert } from "../DeleteAlert";
import { useSorter } from "../../Hooks/useSorter";
import { MakeClone } from "../../Services/Cloning";
import { ErrorSnackbar, SuccessSnackbar } from "../../Services/Snackbars";
import { ValidationFailedPrefix } from "../../Services/Validation/Validation";
import { ValidateEditCompanyThroughput, ValidateFileCompanyThroughput } from "../../Services/Validation/ThroughputValidation";
import { v4 } from "uuid";
import { EditCompanyThroughputModal } from "./EditCompanyThroughputModal";
import { CapitalizeAll } from "../../Services/Capitalize";
import { UploadCompanyThroughputModal } from "./UploadCompanyThroughputModal";

export const ThroughputManagementSectionIds = {
  table: "ThroughputManagementSectionTable",
  title: "ThroughputManagementSectionTitle",
  addButton: "ThroughputManagementSectionAddButton",
  editButton: "ThroughputManagementSectionEditButton",
  deleteButton: "ThroughputManagementSectionDeleteButton",
  downloadButton: "ThroughputManagementSectionDownloadButton",
  uploadButton: "ThroughputManagementSectionUploadButton",
  keywordFilter: "ThroughputManagementSectionKeywordFilter",
  deleteModal: {
    modal: "ThroughputManagementSectionDeleteModal",
    confirmButton: "ThroughputManagementSectionDeleteModalConfirmButton",
    cancelButton: "ThroughputManagementSectionDeleteModalCancelButton"
  }
}

interface ThroughputManagementSectionProps {
  company: Company
}

export function ThroughputManagementSection(props: ThroughputManagementSectionProps) {
  const dispatch = useAppDispatch();
  const allCompanies = useAppSelector(selectAllCompanies);
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [throughputToEdit, setThroughputToEdit] = useState<ThroughputData>();

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter('completionDate');

  const displayThroughputList: (ThroughputData & { categoryTitle: string, initiativeTitle?: string, startDate?: string, completionDate?: string })[] = useMemo(() => {
    const query = searchedKeyword.toUpperCase();
    const startColumn = props.company.workflow.startDateColumnId;
    const endColumn = props.company.workflow.endDateColumnId;
    const throughputClone = MakeClone(props.company.throughput);
    const sortedThroughput = SortItems(throughputClone.map(t => {
      return {
        ...t,
        categoryTitle: props.company.categories.find(c => c.id === t.categoryId)?.title ?? "",
        initiativeTitle: props.company.initiatives.find(i => i.id === t.initiativeId)?.title ?? "",
        startDate: t.progressDates.find(p => p.workflowColumnId === startColumn)?.enterColumnDate ?? "",
        completionDate: t.progressDates.find(p => p.workflowColumnId === endColumn)?.enterColumnDate ?? ""
      };
    }).filter(story =>
      story.title?.toUpperCase().includes(query)
      || story.key?.toUpperCase().includes(query)
      || story.categoryTitle.toUpperCase().includes(query)
      || story.initiativeTitle.toUpperCase().includes(query)
    ));
    return sortedThroughput;
  }, [props.company, SortItems, searchedKeyword]);


  useEffect(() => {
    const maxPages = Math.max(Math.ceil(displayThroughputList.length / rowsPerPage), 1);
    if (page > maxPages - 1)
      setPage(maxPages - 1);
  }, [displayThroughputList, rowsPerPage, page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function SubmitUpdateThroughput(companyId: string, dataList: ThroughputData[], emptyDataCheck: boolean = true): Promise<boolean> {
    const validation = emptyDataCheck ? ValidateFileCompanyThroughput(allCompanies, companyId, dataList) : ValidateEditCompanyThroughput(allCompanies, companyId, dataList);
    if (validation.success)
    {
      try
      {
        await dispatch(upsertCompanyThroughput({ companyId: companyId, dataList: dataList })).unwrap();
        SuccessSnackbar("Throughput data changes have been saved.");
        return true;
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
    }
    else
      ErrorSnackbar(ValidationFailedPrefix + validation.message);

    return false;
  }

  function AddItem() {
    const newThroughput: ThroughputData = {
      id: v4(),
      key: "",
      title: "",
      categoryId: props.company.defaultCategoryId ?? "",
      initiativeId: "",
      progressDates: [
        {
          workflowColumnId: props.company.workflow.startDateColumnId,
          enterColumnDate: (new Date()).toISOString().split("T")[0]
        }
      ]
    }
    setThroughputToEdit(newThroughput);
    setIsEditOpen(true);
  }

  function HandleCloseEdit() {
    setIsEditOpen(false);
    setThroughputToEdit(undefined);
  }

  function HandleDownload() {
    function WrapInQuotes(inputString: string | undefined) {
      if (inputString && (inputString.includes(",") || inputString.includes(`"`)))
        return `"${inputString}"`;
      return inputString;
    }

    function FormatPhrase(inputString: string | undefined) {
      return WrapInQuotes(inputString?.replaceAll(`"`, `""`));
    }

    function FormatDate(dateString: string | undefined) {
      const splitDate = dateString?.split("-");
      if (!splitDate || splitDate.length !== 3)
        return "";

      const year = splitDate[0];
      const month = splitDate[1];
      const day = splitDate[2];
      return `${month}/${day}/${year}`;
    }

    let contentString = `Key,Title,Category,${CapitalizeAll(props.company.terms.initiativeTerm)},`;
    contentString += props.company.workflow.columns.map(c => c.title).join(",");

    for (const item of displayThroughputList)
    {
      contentString += `\n${FormatPhrase(item.key)},${FormatPhrase(item.title)},`;
      contentString += `${FormatPhrase(props.company.categories.find(c => c.id === item.categoryId)?.title ?? "")},`;
      contentString += `${FormatPhrase(props.company.initiatives.find(i => i.id === item.initiativeId)?.title ?? "")},`;
      contentString += props.company.workflow.columns.map(c => `${FormatDate(item.progressDates.find(p => c.id === p.workflowColumnId)?.enterColumnDate)}`).join(",");
    }

    const blob = new Blob([contentString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `throughput-${(new Date()).toLocaleDateString()}`);
    link.click();
  }

  function HandleAttemptDelete(throughputId: string) {
    setIsDeleteOpen(true);
    setThroughputToEdit(MakeClone(props.company.throughput.find(t => t.id === throughputId)));
  }

  async function Delete(throughputId: string) {
    try
    {
      await dispatch(deleteCompanyThroughput({ companyId: props.company.id, throughputId })).unwrap();
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }

    setIsDeleteOpen(false);
    setThroughputToEdit(undefined);
  }

  function CancelDelete() {
    setIsDeleteOpen(false);
    setThroughputToEdit(undefined);
  }

  const slicedThroughputList = useMemo(() => {
    const indexOfLastItem = (page + 1) * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    return displayThroughputList.slice(indexOfFirstItem, indexOfLastItem)
  }, [page, rowsPerPage, displayThroughputList]);

  return (
    <>
      <div className="flex flex-col col-span-4">
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            {props.company.throughput.length !== 0 ?
              <SearchBar cypressData={ThroughputManagementSectionIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => { setSearchedKeyword(value); setPage(0) }} />
              :
              <Typography variant="body2">There are no {plural(props.company.terms.throughputTerm.toLowerCase())} to display</Typography>
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            <ThemeProvider theme={IntegrityTheme}>
              <Stack direction="row" gap={2}>
                {displayThroughputList.length > 0 &&
                  <Button data-cy={ThroughputManagementSectionIds.downloadButton} variant="contained" onClick={() => HandleDownload()}
                    style={{ outlineColor: 'blue' }} size="small" startIcon={<DownloadIcon sx={{ fontSize: "inherit" }} />}
                  >
                    <Typography variant="button">Download</Typography>
                  </Button>
                }
                <Button data-cy={ThroughputManagementSectionIds.uploadButton} variant="contained" onClick={() => setIsUploadOpen(true)}
                  style={{ outlineColor: 'blue' }} size="small" startIcon={<UploadIcon sx={{ fontSize: "inherit" }} />}
                >
                  <Typography variant="button">Upload</Typography>
                </Button>
                <AddButton cypressData={ThroughputManagementSectionIds.addButton} HandleClick={() => { AddItem() }} />
              </Stack>
            </ThemeProvider>
          </Grid>
        </Grid>
        {props.company.throughput.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Key" sortKey='key' RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Title" sortKey='title' RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Category" sortKey='categoryTitle' RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading={CapitalizeAll(props.company.terms.initiativeTerm)} sortKey='initiativeTitle' RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Start Date" sortKey='startDate' RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortConfig={sortConfig} heading="Completion Date" sortKey='completionDate' RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody data-cy={ThroughputManagementSectionIds.table}>
                  {slicedThroughputList.map((throughput, index) => {
                    return (
                      <TableRow className={defaultRowStyle}
                        sx={{
                          borderBottom: "1px solid black",
                          "& td": {
                            fontFamily: "Arial, Helvetica",
                            color: "#21345b"
                          }
                        }}
                        key={index}
                      >
                        <>
                          <TableCell>
                            <Typography variant="caption">{throughput.key}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography data-cy={ThroughputManagementSectionIds.title} variant="caption">{throughput.title}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{props.company.categories.find(c => c.id === throughput.categoryId)?.title}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">{props.company.initiatives.find(i => i.id === throughput.initiativeId)?.title}</Typography>
                          </TableCell>
                          {throughput.startDate ?
                            <TableCell>
                              <Typography variant="caption">{new Date(throughput.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</Typography>
                            </TableCell>
                            :
                            <TableCell></TableCell>
                          }
                          {throughput.completionDate ?
                            <TableCell>
                              <Typography variant="caption">{new Date(throughput.completionDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</Typography>
                            </TableCell>
                            :
                            <TableCell></TableCell>
                          }
                          <TableCell>
                            <ThemeProvider theme={blueTheme}>
                              <IconButton data-cy={ThroughputManagementSectionIds.editButton} onClick={() => { setIsEditOpen(true); setThroughputToEdit(throughput); }} color="primary">
                                <EditIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                              <IconButton data-cy={ThroughputManagementSectionIds.deleteButton} onClick={() => HandleAttemptDelete(throughput.id)} color="primary">
                                <DeleteIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </ThemeProvider>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={displayThroughputList.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        }
      </div>
      {props.company &&
        <>
          {throughputToEdit &&
            <EditCompanyThroughputModal isOpen={isEditOpen} story={throughputToEdit} HandleCloseEdit={HandleCloseEdit} SubmitUpdateThroughput={SubmitUpdateThroughput} company={props.company} UpdateSearchbar={setSearchedKeyword} />
          }
          <UploadCompanyThroughputModal isOpen={isUploadOpen} HandleClose={() => setIsUploadOpen(false)} SubmitUpdateThroughput={SubmitUpdateThroughput} company={props.company} />
        </>
      }
      <DeleteAlert cypressData={ThroughputManagementSectionIds.deleteModal} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} Delete={Delete} CancelDelete={CancelDelete} id={throughputToEdit?.id} title={`Delete this ${props.company.terms.throughputTerm.toLowerCase()}?`} description={`Once removed, the ${props.company.terms.throughputTerm.toLowerCase()} cannot be recovered.`} />
    </>
  );
}
