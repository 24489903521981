import { Avatar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ShallowCompany } from "../../Store/CompanySlice";
import { GoToClientDashboard } from "../../Pages/ClientDashboardPage";
import { useAppSelector } from "../../Hooks/Hooks";
import { selectCurrentUser } from "../../Store/UserSlice";

export interface CompanyWithLogoUrl extends ShallowCompany {
  docUrl?: string
}

interface ClientLogoProps {
  company: CompanyWithLogoUrl
  length?: number
}

export function ClientLogo(props: ClientLogoProps) {
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate(); 

  return (
    <>
    {currentUser ?
      <Link onClick={() => GoToClientDashboard(navigate, currentUser.id, props.company.id)} to={"../ClientDashboard"}>
      <Avatar alt={props.company.name} src={props.company.docUrl} sx={{borderRadius: 0, width: props.length, height: "100%"}}>
        {props.company.name}
      </Avatar>
      </Link>
      :
      <Avatar alt={props.company.name} src={props.company.docUrl} sx={{borderRadius: 0, width: props.length, height: "100%"}}>
        {props.company.name}
      </Avatar>
    }
    </>
  )
}
