import { Button, Typography } from "@mui/material";

export function AccountButton(props: {
  title: string
  cypressData: string
  buttonText: string
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => React.MouseEventHandler<HTMLAnchorElement> | undefined | void
}) {
  return (
    <div>
      <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
      <Button data-cy={props.cypressData} sx={{ textTransform: "none" }} onClick={props.onClick}>{props.buttonText}</Button>
    </div>
  );
}