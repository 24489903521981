import { Box, Card, Chip, Grid, Link, Stack, Typography } from "@mui/material";
import { IntegrityTheme } from "../Styles";
import { RSSFeedItem } from "../Services/RSS";
import { useState } from "react";
import { DocumentInfo, getAgileBitesDocumentInfos } from "../Store/DocumentSlice";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { useAppDispatch } from "../Hooks/Hooks";

interface AgileBitesBannerProps {
  feed: RSSFeedItem
}

export function AgileBitesBanner(props: AgileBitesBannerProps) {
  const dispatch = useAppDispatch();
  const bitRate = 128;

  const [logoPicDoc, setLogoPicDoc] = useState<DocumentInfo>();
  const [logoTextDoc, setLogoTextDoc] = useState<DocumentInfo>();

  useEffectOnce(async () => {
    try
    {
      const docs = await dispatch(getAgileBitesDocumentInfos({})).unwrap();
      const logoPic = docs.find(doc => doc.metadata.fileName === "agilebiteslogo.png");
      const logoText = docs.find(doc => doc.metadata.fileName === "agilebitestext.png");

      setLogoPicDoc(logoPic);
      setLogoTextDoc(logoText);
    }
    catch (e)
    {
      console.log("Unable to load Agile Bites banner: " + (e as Error).message);
    }
  });

  return (
    (logoPicDoc && logoTextDoc) ?
      <Link href={props.feed.link} target="_blank" underline="none">
        <Card variant="outlined" sx={{ padding: 1, borderRadius: 2, backgroundColor: IntegrityTheme.palette.darkBlue.main }}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ paddingX: 2 }}>
                <Stack direction="row" alignItems="center" gap={2} sx={{ minHeight: "100%" }}>
                  <Box>
                    <img className=" max-h-[40px]" src={logoPicDoc?.tempUrl} alt="Agile Bites logo"/>
                  </Box>
                  <Box>
                    <img className="py-1 max-h-[40px]" src={logoTextDoc?.tempUrl} alt="Agile Bites"/>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Box component={Grid} item display={{ xs: "none", md: "block" }}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Chip color="lightBlue" size="small" sx={{ height: "50%", marginY: 1 }}
                  label={
                    <Typography color="white" variant="caption" sx={{ fontSize: 9 }}>NEW EPISODE</Typography>
                  }
                />
                <Box>
                  <Typography color="white" variant="subtitle1" sx={{ marginBottom: -1 }}>
                    {props.feed.title}
                  </Typography>
                  <Stack direction="row" gap={1}>
                    <Typography color="white" variant="caption">
                      {(new Date(props.feed.pubDate)).toLocaleDateString()}
                    </Typography>
                    <Typography color="white" variant="caption">
                      |
                    </Typography>
                    <Typography color="white" variant="caption">
                      {Math.ceil(parseInt(props.feed.enclosure.length) * 8 / bitRate / 1000 / 60)} min
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Card>
      </Link>
      :
      <>
      </>
  )
}