import { ThemeProvider } from "@emotion/react";
import { Box, CircularProgress, Container, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { CancelButton, IntegrityTheme, SubmitButton, tableCellFontSize, tableHeaderFontSize } from "../Styles";
import { useNavigate } from "react-router-dom";
import { ErrorSnackbar } from "../Services/Snackbars";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { useMemo, useState } from "react";
import { authenticatePasswordResetToken, selectCurrentUser } from "../Store/UserSlice";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { LoginHeader } from "../Layout/LoginHeader";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { usePasswordUpdater } from "../Hooks/usePasswordUpdater";
import { IntegrityId } from "../Store/CompanySlice";
import { GoToHomePage } from "../App";

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token") ?? undefined;
  const currentUser = useAppSelector(selectCurrentUser);

  const {
    isLoading,
    newPassword,
    confirmPassword,
    newPasswordValidationText,
    confirmPasswordValidationText,
    HandleNewPasswordUpdate,
    HandleConfirmPasswordUpdate,
    canSubmitPassword,
    HandleSubmitResetPassword
  } = usePasswordUpdater(true);

  useEffectOnce(async () => {
    try
    {
      if (token)
      {
        const valid = (await dispatch(authenticatePasswordResetToken({ token })).unwrap());
        setIsValid(valid);
      }
      if (currentUser)
        setIsValid(true);
    }
    catch (e)
    {
      ErrorSnackbar(e);
      GoToLoginPage();
    }
  })

  const disable = useMemo(() => {
    if (newPassword === "" || confirmPassword === "")
      return true;
  }, [newPassword, confirmPassword])

  async function HandleSubmit(key: any) {
    if (key === 'Enter' && newPassword !== "" && confirmPassword !== "")
    {
      await HandleSubmitResetPassword(token);
      if (currentUser)
        GoToHomePage(navigate, currentUser);
      else
        GoToLoginPage();
    }
  }

  function GoToLoginPage() {
    navigate('/Login');
  }

  return (
    <>
      <ThemeProvider theme={IntegrityTheme}>
        <LoginHeader />
        {isValid &&
          <>
            <Container disableGutters component="main" maxWidth="xs" className="bg-gray-100 pb-4 mt-[2%] mb-[1%] rounded-md shadow-md">
              <Box component="form" sx={{
                marginBottom: 2,
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                height: "auto",
                borderRadius: 3
              }}>
                <Grid item sx={{ backgroundColor: "#2E4A7F", color: "white", borderRadius: 2, marginBottom: 2, padding: 2 }}>
                  <Typography component="h1" variant="h5" sx={{
                    fontSize: tableHeaderFontSize,
                    padding: 2,
                    textAlign: "center",
                    width: "100%"
                  }}>
                    Reset Password
                  </Typography>
                </Grid>
                <Stack display="flex" justifyItems="center" alignSelf="center" sx={{ width: "90%" }}>
                  <TextField
                    color="darkBlue"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    name="password"
                    label="New Password"
                    value={newPassword}
                    type={newPasswordShown ? 'text' : 'password'}
                    onChange={e => { HandleNewPasswordUpdate(e.target.value) }}
                    onKeyDown={e => HandleSubmit(e.key)}
                    error={!!newPasswordValidationText}
                    helperText={newPasswordValidationText}
                    InputProps={{
                      style: { fontSize: tableCellFontSize },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setNewPasswordShown(!newPasswordShown)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {newPasswordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    color="darkBlue"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    name="password"
                    label="Confirm Password"
                    value={confirmPassword}
                    type={confirmPasswordShown ? 'text' : 'password'}
                    onChange={e => HandleConfirmPasswordUpdate(e.target.value)}
                    onKeyDown={e => HandleSubmit(e.key)}
                    error={!!confirmPasswordValidationText}
                    helperText={confirmPasswordValidationText}
                    InputProps={{
                      style: { fontSize: tableCellFontSize },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {confirmPasswordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <SubmitButton sx={{ my: 3 }} disabled={disable || !canSubmitPassword || isLoading} onClick={() => HandleSubmit('Enter')}>
                    {isLoading ? <CircularProgress color="warning" size={24} /> : "Submit"}
                  </SubmitButton>
                  {!currentUser?.isPasswordReset &&
                    <CancelButton onClick={() => GoToLoginPage()}>
                      Return to Login Page
                    </CancelButton>
                  }
                </Stack>
              </Box>
            </Container>
          </>
        }
      </ThemeProvider>
      {(token === "" || !isValid) &&
        <Stack alignItems="center" sx={{ marginY: 4 }}>
          <CircularProgress size={100} />
        </Stack>
      }
    </>
  )
}