import { combineReducers, configureStore } from '@reduxjs/toolkit';
import companySlice from './CompanySlice';
import userSlice from './UserSlice';
import documentSlice from './DocumentSlice';
import articleSlice from './ArticleSlice';
import sessionSlice from './SessionSlice';
import storageSession from 'redux-persist/lib/storage/session';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['sessions']
}

const sessionPersistConfig = {
  key: "session",
  storage: storageSession
}

const rootReducer = combineReducers({
  sessions: persistReducer(sessionPersistConfig, sessionSlice),
  users: userSlice,
  companies: companySlice,
  documents: documentSlice,
  articles: articleSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;