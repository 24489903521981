import { Box, Button, Card, Checkbox, Divider, FormControlLabel, FormGroup, Grid, MenuItem, Stack, ThemeProvider, Typography } from "@mui/material";
import { ChangePasswordModal } from "../Components/User/ChangePasswordModal";
import { IntegrityTheme, labelStyle, tableButtonFontSize } from "../Styles";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { User, selectAllUsers, selectCurrentUser, upsertUserInfo } from "../Store/UserSlice";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { getAvatarDocumentInfos, uploadAvatar } from "../Store/DocumentSlice";
import { selectAllCompanies, IntegrityId } from "../Store/CompanySlice";
import { MakeClone } from "../Services/Cloning";
import { SuccessSnackbar, ErrorSnackbar } from "../Services/Snackbars";
import { ValidateUser, ValidationFailedPrefix } from "../Services/Validation/Validation";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { EditImage, FileUrlPair } from "../Components/EditImage";
import { GetInitiativesService } from "../Services/GetInitiativesService";
import { AccountField } from "../Components/AccountField";
import { AccountButton } from "../Components/AccountButton";
import { AccountHeader } from "../Components/AccountHeader";
import { AccountActions } from "../Components/AccountActions";
import { useGetLogo } from "../Hooks/useGetLogo";
import { useGetAvatar } from "../Hooks/useGetAvatar";
import { EditUserInitiativesButton } from "../Components/User/EditUserInitiativesButton";
import { plural } from "pluralize";
import { v4 } from "uuid";
import { ValidatePassword } from "../Services/Validation/PasswordValidation";
import { AccountSelect } from "../Components/AccountSelect";
import { GoToInitiativeDashboard } from "./InitiativeDashboardPage";
import { GoToUsersPage } from "./UsersPage";
import { GoToIntegrityPage } from "./IntegrityPage";
import { GoToHomePage } from "../App";

export function GoToUserAccountPage(navigate: NavigateFunction, userId?: string, initialPage?: number) {
  let where = "/UserAccount";
  if(userId)
    where += "?user=" + userId;
  if (initialPage !== undefined)
  {
    if(userId)
      where += "&";
    else
      where += "?";
    where += "initialPage=" + initialPage.toString();
  }
  navigate(where);
}

export const UserAccountPageIds = {
  email: "UserAccountPageIdsEmail",
  password: "UserAccountPageIdsPassword",
  name: "UserAccountPageIdsName",
  phoneNumber: "UserAccountPageIdsPhoneNumber",
  linkedin: "UserAccountPageIdslinkedin",
  facebook: "UserAccountPageIdsfacebook",
  role: "UserAccountPageIdsRole",
  saveButton: "UserAccountPageIdsSaveButton",
  cancelButton: "UserAccountPageIdsCancelButton",
  companySelect: "UserAccountPageIdsCompanySelect",
  changePassword: {
    modal: "UserAccountPageIdsChangePasswordModal",
    openModal: "UserAccountPageIdsChangePasswordOpenModal",
    closeModal: "UserAccountPageIdsChangePasswordCloseModal",
    submitButton: "UserAccountPageIdsChangePasswordSaveButton",
    cancelButton: "UserAccountPageIdsChangePasswordCancelButton",
    oldPassword: "UserAccountPageIdsChangePasswordOldPassword",
    newPassword: "UserAccountPageIdsChangePasswordNewPassword",
    confirmPassword: "UserAccountPageIdsChangePasswordConfirmPassword"
  }
}

export function UserAccountPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const allUsers = useAppSelector(selectAllUsers);
  const allCompanies = useAppSelector(selectAllCompanies);
  const [fileUrlPair, setFileUrlPair] = useState<FileUrlPair>({ url: "", file: null });
  const [userClone, setUserClone] = useState<User>();
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [queryParameters] = useSearchParams();
  const [newPassword, setNewPassword] = useState("");

  const { userToEdit, isAddingNewUser } = useMemo(() => {
    const queryUserId = queryParameters.get("user");
    if (queryUserId)
    {
      const matchingUser = allUsers.find(user => user.id === queryUserId);
      if (matchingUser)
        return { userToEdit: matchingUser, isAddingNewUser: false };
    }
    else
    {
      const newUser: User = {
        id: v4(),
        email: "",
        companyId: "",
        initiativeRoles: [],
        name: "",
        phoneNumber: "",
        role: "",
        isAdmin: false,
        isActive: true,
        isPasswordReset: true
      };

      return { userToEdit: newUser, isAddingNewUser: true };
    }
    return { userToEdit: undefined, isAddingNewUser: false };
  }, [allUsers, queryParameters]);

  const userCloneCompany = useMemo(() => {
    return allCompanies.find(c => c.id === userClone?.companyId);
  }, [userClone, allCompanies]);

  const ReturnFromWhenceYouCame = useCallback(() => {
    const queryInitialPage = queryParameters.get("initialPage");
    if(queryInitialPage === null && currentUser)
      GoToHomePage(navigate, currentUser);
    else
    {
      const initialPage = queryInitialPage !== null ? parseInt(queryInitialPage) : undefined;
      if (userToEdit?.companyId === IntegrityId)
        GoToIntegrityPage(navigate, initialPage);
      else
        GoToUsersPage(navigate, initialPage);
    }
  }, [navigate, userToEdit, currentUser, queryParameters]);

  useEffect(() => {
    if (!userToEdit)
    {
      ErrorSnackbar(Error());
      ReturnFromWhenceYouCame();
    }
  }, [ReturnFromWhenceYouCame, userToEdit, currentUser]);

  useEffect(() => {
    setUserClone(userToEdit);
  }, [userToEdit]);

  const { FetchLogo, companyLogo } = useGetLogo();
  useEffect(() => {
    FetchLogo(userCloneCompany);
  }, [FetchLogo, userCloneCompany]);

  const { FetchAvatar, avatar } = useGetAvatar();
  useEffect(() => {
    FetchAvatar(userToEdit);
  }, [FetchAvatar, userToEdit]);

  const isIntegrity = userToEdit?.companyId === IntegrityId;
  const isIntegrityAdmin = (userToEdit?.isAdmin && isIntegrity) ?? false;
  const showInactive = isIntegrityAdmin;

  const displayInitiatives = useMemo(() => {
    if (userToEdit)
      return GetInitiativesService(userToEdit, allCompanies, isIntegrityAdmin, showInactive);
  }, [isIntegrityAdmin, allCompanies, showInactive, userToEdit]);

  async function SaveEdit() {
    if (!userClone)
    {
      ErrorSnackbar(new Error());
      return;
    }

    if (!isSavingUser && CanSubmitUserData(userClone))
    {
      setIsSavingUser(true);
      if (userClone.linkedin && !userClone.linkedin?.includes("http"))
        userClone.linkedin = "https://" + userClone.linkedin;
      if (userClone.facebook && !userClone.facebook?.includes("http"))
        userClone.facebook = "https://" + userClone.facebook;

      const success = await SubmitUserData(userClone);
      if (success)
      {
        SuccessSnackbar("User changes have been saved.");
        ReturnFromWhenceYouCame();
      }
      setIsSavingUser(false);
    }
  }

  function CancelEdit() {
    setUserClone(userToEdit);
    ReturnFromWhenceYouCame();
  }

  function CanSubmitUserData(user: User): boolean {
    if (isAddingNewUser)
    {
      const passwordValidation = ValidatePassword(newPassword);
      if (!passwordValidation.success)
      {
        ErrorSnackbar(ValidationFailedPrefix + passwordValidation.message);
        return false;
      }
    }

    const userValidation = ValidateUser(user, allUsers);
    if (userValidation.success)
      return true;
    else
      ErrorSnackbar(ValidationFailedPrefix + userValidation.message);

    return false;
  }

  async function SubmitUserData(user: User): Promise<boolean> {
    if (CanSubmitUserData(user))
    {
      try
      {
        await dispatch(upsertUserInfo({ user: user, newUserPassword: newPassword })).unwrap();
        return true;
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
    }
    return false;
  }

  function UserSelectInitiative(initiativeId: string) {
    if (currentUser)
    {
      GoToInitiativeDashboard(navigate, currentUser.id, initiativeId);
    }
  }

  async function HandleAvatarSave(newPair: FileUrlPair) {
    if (userToEdit && newPair.file)
    {
      try
      {
        const newBlobName = await dispatch(uploadAvatar({ file: newPair.file })).unwrap();
        await dispatch(getAvatarDocumentInfos({}));
        const newUser = MakeClone(userToEdit);
        newUser.avatarBlobName = newBlobName;
        await dispatch(upsertUserInfo({ user: newUser }));
      }
      catch (e)
      {
        console.log((e as Error).message);
      }
    }
  }

  return (
    <>
      {userClone && currentUser && userToEdit &&
        <>
          <ThemeProvider theme={IntegrityTheme}>
            <Box sx={{ marginX: 8, marginY: 4 }}>
              <AccountHeader currentUser={currentUser} companyLogo={isAddingNewUser ? undefined : companyLogo}
                subtitle={currentUser.id === userToEdit.id ? "Welcome to the Integrity Client Portal" : (isAddingNewUser ? <p>Adding new user</p> : <p>Now editing: <b>{userToEdit.name || userToEdit.email}</b></p>)}
              />
              <Grid container sx={{
                display: 'flex',
                justifyContent: "space-between",
                placeItems: 'start',
                flexDirection: 'row'
              }}>
                {!isAddingNewUser &&
                  <Card variant="outlined" sx={{ borderRadius: 2, borderWidth: 0, width: "20%" }}>
                    <Grid item sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: 2
                    }}>
                      <Stack display="flex" justifyContent="center" sx={{ marginY: 2 }}>
                        <EditImage user={avatar} newFileUrl={fileUrlPair} setNewFileUrl={setFileUrlPair} onUpload={HandleAvatarSave} avatarLength={120} />
                      </Stack>
                    </Grid>
                  </Card>
                }
                <Card variant="outlined" sx={{ borderRadius: 2, borderWidth: 0, width: isAddingNewUser ? "100%" : "77%" }}>
                  <Grid container display="flex" columns={12} justifyContent="space-between" wrap="nowrap">
                    <Grid item xs={6}>
                      <Stack gap={2} sx={{ marginBottom: 2 }}>
                        {userClone &&
                          <Stack gap={3} sx={{ marginX: 10, marginY: 3 }}>
                            <AccountField title="Full Name" cypressData={UserAccountPageIds.name} value={userClone.name} onChange={e => setUserClone({ ...userClone, name: e.target.value })} />
                            <AccountField title="Email" cypressData={UserAccountPageIds.email} value={userClone.email} onChange={e => setUserClone({ ...userClone, email: e.target.value })} />
                            {isAddingNewUser ?
                              <>
                                <AccountField title="Password" cypressData={UserAccountPageIds.password} value={newPassword} onChange={e => setNewPassword(e.target.value)} password />
                                <AccountSelect title="Company" cypressData={UserAccountPageIds.companySelect} value={userCloneCompany?.id ?? ""} onChange={e => setUserClone({...userClone, companyId: e.target.value}) }
                                  options={allCompanies.map((item, i) => {
                                    return (
                                      <MenuItem value={item.id} key={i}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                />
                              </>
                              :
                              <AccountButton title="Password" cypressData={UserAccountPageIds.changePassword.openModal} onClick={e => { setPasswordModalIsOpen(true) }} buttonText="Change password" />
                            }
                            <AccountField title="Phone Number" cypressData={UserAccountPageIds.phoneNumber} value={userClone.phoneNumber} onChange={e => setUserClone({ ...userClone, phoneNumber: e.target.value })} />
                            {currentUser.isAdmin &&
                              <AccountField title="Role" cypressData={UserAccountPageIds.role} value={userClone.role} onChange={e => setUserClone({ ...userClone, role: e.target.value })} />
                            }
                            <AccountField title="Linkedin (optional)" cypressData={UserAccountPageIds.linkedin} value={userClone.linkedin} onChange={e => setUserClone({ ...userClone, linkedin: e.target.value })} />
                            <AccountField title="Facebook (optional)" cypressData={UserAccountPageIds.facebook} value={userClone.facebook} onChange={e => setUserClone({ ...userClone, facebook: e.target.value })} />
                            {currentUser.isAdmin && currentUser.companyId === IntegrityId &&
                              <FormGroup>
                                <FormControlLabel control={<Checkbox checked={userClone.isActive} onChange={e => setUserClone({ ...userClone, isActive: e.target.checked })} />} label="Active" />
                                <FormControlLabel disabled={userClone.id === currentUser.id} control={<Checkbox checked={userClone.isAdmin} onChange={e => setUserClone({ ...userClone, isAdmin: e.target.checked })} />} label="Admin" />
                              </FormGroup>
                            }
                          </Stack>
                        }
                        <AccountActions cypressData={{ saveButton: UserAccountPageIds.saveButton, cancelButton: UserAccountPageIds.cancelButton }} Save={SaveEdit} Cancel={CancelEdit} isSaving={isSavingUser} />
                      </Stack>
                    </Grid>
                    {!isAddingNewUser &&
                      <>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Grid item xs={6}>
                          <Stack gap={2} sx={{ marginY: 2, marginX: 4 }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
                              <label className={labelStyle}>Current Work</label>
                              <div>
                                {currentUser.isAdmin &&
                                  <EditUserInitiativesButton title={"Assign " + plural(userCloneCompany?.terms.initiativeTerm ?? "Initiative")} user={userClone} allCompanies={userClone.companyId === IntegrityId ? allCompanies : (userCloneCompany ? [userCloneCompany] : [])} SubmitUserData={SubmitUserData} expanded={userClone.companyId !== IntegrityId} />
                                }
                              </div>
                            </Stack>
                            <Divider />
                            {displayInitiatives && displayInitiatives.length > 0
                              ? displayInitiatives.map((i, key) =>
                                <Fragment key={key}>
                                  <Stack>
                                    {i.initiatives.length > 0 && isIntegrity &&
                                      <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: 1 }}>{i.companyName}</Typography>
                                    }
                                    {i.initiatives.map((init, index) =>
                                      <Button key={index} sx={{ alignSelf: "flex-start" }} startIcon={<VisibilityIcon sx={{ fontSize: tableButtonFontSize }} />} onClick={() => UserSelectInitiative(init.id)}>
                                        {init.title}
                                      </Button>
                                    )}
                                  </Stack>
                                </Fragment>
                              )
                              :
                              <Typography>N/A</Typography>
                            }
                          </Stack>
                        </Grid>
                      </>
                    }
                  </Grid>
                </Card>
              </Grid>
            </Box>
          </ThemeProvider>
          <ChangePasswordModal isOpen={passwordModalIsOpen} setIsOpen={setPasswordModalIsOpen} currentUser={currentUser} userToEdit={userClone} cypressData={UserAccountPageIds.changePassword} />
        </>
      }
    </>
  )
}