import { Workflow } from "../Store/CompanySlice";
import { ThroughputData } from "./CompanyService";

export function GetMaxCycleTime(stories: ThroughputData[], inputProbability: number, workflow: Workflow) {
  const cycleTimes = [];
  for(const story of stories)
  {
    const startDateString = story.progressDates.find(p => p.workflowColumnId === workflow.startDateColumnId)?.enterColumnDate;
    const completionDateString = story.progressDates.find(p => p.workflowColumnId === workflow.endDateColumnId)?.enterColumnDate;
    if(startDateString && completionDateString)
    {
      const startDate = new Date(startDateString);
      const completionDate = new Date(completionDateString);

      const dayDifference = Math.ceil((completionDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
      cycleTimes.push(dayDifference);
    }
  }

  cycleTimes.sort((a, b) => a - b);
  const percentOfTimes = cycleTimes.slice(0, Math.ceil(cycleTimes.length * inputProbability / 100));
  return percentOfTimes.at(-1);
}