import { Checkbox, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Category, Company, deleteCategory, upsertCategory, upsertCompanyInfo } from '../../../Store/CompanySlice';
import { TableHeaderStyle, defaultRowStyle } from '../../../Styles';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../Hooks/Hooks';
import { User } from '../../../Store/UserSlice';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { SearchBar } from '../../SearchBar';
import { useSorter } from '../../../Hooks/useSorter';
import { SortLabel } from '../../SortLabel';
import { ErrorSnackbar, SuccessSnackbar } from '../../../Services/Snackbars';
import { plural } from 'pluralize';
import { AddButton } from '../../AddButton';
import { DeleteAlert } from '../../DeleteAlert';
import { ValidationFailedPrefix } from '../../../Services/Validation/Validation';
import { CategoryUpsertModal } from './CategoryUpsertModal';
import { ValidateCategory } from '../../../Services/Validation/CategoryValidation';
import { DeleteCategoryRequest, UpsertCategoryRequest } from '../../../Services/CompanyService';
import { Capitalize } from '../../../Services/Capitalize';
import { MakeClone } from '../../../Services/Cloning';

export const CategoryManagementSectionIds = {
  upsertModal: {
    modal: "categoryManagementSectionUpsertModal",
    closeModalButton: "categoryManagementSectionUpsertCloseModalButton",
    title: "categoryManagementSectionUpsertTitle",
    includeInSims: "categoryManagementSectionUpsertIncludeInSims",
    saveButton: "categoryManagementSectionUpsertSaveButton",
    cancelButton: "categoryManagementSectionUpsertCancelButton",
  },
  deleteModal: {
    modal: "categoryManagementSectionDeleteModal",
    confirmButton: "categoryManagementSectionDeleteConfirmButton",
    cancelButton: "categoryManagementSectionDeleteCancelButton"
  },
  table: "categoryManagementSectionTable",
  title: "categoryManagementSectionTitle",
  defaultChecked: "categoryManagementSectionDefaultChecked",
  defaultUnchecked: "categoryManagementSectionDefaultUnchecked",
  addButton: "categoryManagementSectionAddButton",
  editButton: "categoryManagementSectionEditButton",
  deleteButton: "categoryManagementSectionDeleteButton",
  keywordFilter: "categoryManagementSectionKeywordFilter",
}

interface CategoryManagementSectionProps {
  company: Company
  currentUser: User
  userHasUploadPermission: boolean
}

export function CategoryManagementSection(props: CategoryManagementSectionProps) {
  const dispatch = useAppDispatch();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryToEdit, setCategoryToEdit] = useState<Category>();
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isUpserting, setIsUpserting] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loadingDefaultCategory, setLoadingDefaultCategory] = useState("");

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedCategories = useMemo(() => {
    return SortItems(categories);
  }, [categories, SortItems]);

  const filteredCategories = useMemo(() => {
    const upperKeyword = searchedKeyword.toUpperCase();
    return sortedCategories.filter(category => category.title.toUpperCase().includes(upperKeyword));
  }, [sortedCategories, searchedKeyword]);

  function LeaveEditMode() {
    setCategoryToEdit(undefined);
    setIsCategoryModalOpen(false);
  }

  function EnterEditMode(category: Category) {
    setCategoryToEdit(category);
    setIsCategoryModalOpen(true);
  }

  function EnterAddMode() {
    LeaveEditMode();
    setIsCategoryModalOpen(true);
  }

  async function HandleSaveEdit(editedCategory: Category) {
    const validation = ValidateCategory(editedCategory);
    if (!validation.success)
    {
      ErrorSnackbar(ValidationFailedPrefix + validation.message);
      return;
    }

    if (!isUpserting)
    {
      setIsUpserting(true);

      const categoryRequest: UpsertCategoryRequest = {
        companyId: props.company.id,
        category: editedCategory
      }
      try
      {
        await dispatch(upsertCategory(categoryRequest)).unwrap();
        SuccessSnackbar(`${Capitalize(props.company.terms.throughputTerm)} category updated successfully.`);
        LeaveEditMode();
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      setIsUpserting(false);
    }
  }

  function HandleCancelEdit() {
    LeaveEditMode();
  }

  function EnterDeleteMode(category: Category) {
    setIsDeleteOpen(true);
    setCategoryToEdit(category);
  }

  async function HandleDeleteCategory(categoryToDeleteId: string) {
    if (!isUpserting)
    {
      setIsUpserting(true);
      const deleteRequest: DeleteCategoryRequest = {
        companyId: props.company.id,
        categoryId: categoryToDeleteId
      }
      try
      {
        await dispatch(deleteCategory(deleteRequest)).unwrap();
        SuccessSnackbar(`${Capitalize(props.company.terms.throughputTerm)} category removed successfully.`)
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      LeaveDeleteMode();
      setIsUpserting(false);
    }
  }

  function HandleCancelDelete() {
    LeaveDeleteMode();
  }

  function LeaveDeleteMode() {
    setIsDeleteOpen(false);
    setCategoryToEdit(undefined);
  }

  async function HandleChangeDefaultCategory(category: Category) {
    const editedCompany: Company = {
      ...props.company,
      defaultCategoryId: category.id
    }
    setLoadingDefaultCategory(category.id);
    try
    {
      await dispatch(upsertCompanyInfo({ company: editedCompany })).unwrap();
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setLoadingDefaultCategory("");
  }

  function HandleChangeInSims(category: Category) {
    setCategoryToEdit(category);
    let categoryToEditClone = MakeClone(category);
      if (categoryToEditClone) 
      {
        categoryToEditClone.includeInSims = !categoryToEditClone.includeInSims;
        HandleSaveEdit(categoryToEditClone);
      }
  }

  useEffect(() => {
    setCategories(props.company.categories ?? []);
  }, [props.company]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedCategories = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const maxPages = Math.max(Math.ceil(filteredCategories.length / rowsPerPage), 1);
    if (page > maxPages - 1)
      setPage(maxPages - 1);
  }, [filteredCategories, rowsPerPage, page]);

  const blueTheme = createTheme({
    palette: {
      primary: {
        main: "#3d5afe",
      }
    }
  });

  return (
    <ThemeProvider theme={blueTheme}>
      <div className="flex flex-col col-span-4">
        <Grid container sx={{ marginY: 2 }} alignItems="end">
          <Grid item xs={10}>
            {categories.length !== 0 ?
              <SearchBar cypressData={CategoryManagementSectionIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => { setSearchedKeyword(value); setPage(0) }} />
              :
              <Typography variant="body2">There are no {plural(props.company.terms.throughputTerm.toLowerCase())} categories to display</Typography>
            }
          </Grid>
          <Grid item xs={2} display={"flex"} justifyContent={"end"}>
            {props.userHasUploadPermission &&
              <AddButton cypressData={CategoryManagementSectionIds.addButton} HandleClick={() => { EnterAddMode() }} />
            }
          </Grid>
        </Grid>
        {categories.length !== 0 &&
          <>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100" size="small">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "1px solid black",
                    "& th": {
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle></TableHeaderStyle>
                    <TableHeaderStyle>Title</TableHeaderStyle>
                    <TableHeaderStyle>Include in Simulations</TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="dateCreated" heading="Created On" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="dateModified" heading="Last Modified" sortConfig={sortConfig} RequestSort={UpdateSortConfig} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody data-cy={CategoryManagementSectionIds.table}>
                  {slicedCategories.map((category, index) => {
                    const isDefault = category.id === props.company.defaultCategoryId;
                    return (
                      <TableRow className={defaultRowStyle}
                        sx={{
                          borderBottom: "1px solid black",
                          "& td": {
                            fontFamily: "Arial, Helvetica",
                            color: "#21345b"
                          }
                        }}
                        key={index}
                      >
                        <TableCell>
                          <IconButton data-cy={isDefault ? CategoryManagementSectionIds.defaultChecked : CategoryManagementSectionIds.defaultUnchecked} title={isDefault ? "This is the default category" : "Mark as default"} onClick={() => HandleChangeDefaultCategory(category)} disabled={!!loadingDefaultCategory}>
                            {loadingDefaultCategory === category.id ?
                              <CircularProgress color="warning" size="20px" />
                              :
                              (isDefault ?
                                <StarIcon />
                                :
                                <StarBorderIcon />
                              )
                            }
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption" data-cy={CategoryManagementSectionIds.title}>{category.title}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption"><Checkbox checked={category.includeInSims} onClick={() => HandleChangeInSims(category)}/></Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">{category.dateCreated ? new Date(category.dateCreated).toLocaleString() : "N/A"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">{category.dateModified ? new Date(category.dateModified).toLocaleString() : "N/A"}</Typography>
                        </TableCell>
                        <TableCell>
                          {props.userHasUploadPermission &&
                            <>
                              <IconButton data-cy={CategoryManagementSectionIds.editButton} onClick={() => EnterEditMode(category)} color="primary">
                                <EditIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                              <IconButton data-cy={CategoryManagementSectionIds.deleteButton} onClick={() => EnterDeleteMode(category)} color="primary">
                                <DeleteIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={sortedCategories.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        }
        <CategoryUpsertModal company={props.company} categoryToEdit={categoryToEdit} isOpen={isCategoryModalOpen} handleClose={() => setIsCategoryModalOpen(false)} isUpserting={isUpserting}
          Save={HandleSaveEdit} Cancel={HandleCancelEdit} cypressData={CategoryManagementSectionIds.upsertModal}
        />
        <DeleteAlert isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} Delete={HandleDeleteCategory} CancelDelete={HandleCancelDelete} id={categoryToEdit?.id}
          title={`Delete this ${props.company.terms.throughputTerm.toLowerCase()} category?`} description={`Once removed, a category cannot be recovered.`}
          cypressData={CategoryManagementSectionIds.deleteModal}
        />
      </div>
    </ThemeProvider>
  )
}
