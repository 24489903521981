import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LocalizeUrl } from "./Http";

interface ValidateReCAPTCHARequest {
  token: string
}

interface ValidateReCAPTCHAResponse {
  success: boolean
}

export const validateReCAPTCHA = createAsyncThunk(
  'reCAPTCHA/validateReCAPTCHA',
  async (args: ValidateReCAPTCHARequest) => {
    const response = await ValidateReCAPTCHA(args);
    return response.success;
  }
)


async function ValidateReCAPTCHA(request: ValidateReCAPTCHARequest): Promise<ValidateReCAPTCHAResponse> {
  const baseUrl = LocalizeUrl("/api/ValidateReCAPTCHA");
  const response = await axios.post(baseUrl, request);
  return response.data;
}
