import { ListItem, ListItemText, Stack, Button, Typography } from "@mui/material"
import { Initiative } from "../../Store/CompanySlice"
import VisibilityIcon from "@mui/icons-material/Visibility";

interface DashboardArchivedInitiativesItemProps {
  archivedInitiatives: Initiative[]
  select: (initiativeId: string) => void
}

export function DashboardArchivedInitiativesItem(props: DashboardArchivedInitiativesItemProps) {
  return (
    <ListItem>
      <ListItemText>
        {props.archivedInitiatives.map((init, index) => {
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" key={index} sx={{ marginY: 1 }}>
              <Button sx={{ alignSelf: "flex-start" }} startIcon={<VisibilityIcon />} onClick={() => props.select(init.id)}>
                {init.title}
              </Button>
              <Typography variant="caption">{new Date(init.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</Typography>
            </Stack>
          );
        })}
      </ListItemText>
    </ListItem>
  );
}