import { useMemo, useState } from "react";
import { GetPasswordValidationFailureString } from "../Services/Validation/PasswordValidation";
import { ErrorSnackbar, SuccessSnackbar } from "../Services/Snackbars";
import { FailValidation, SuccessValidation } from "../Services/Validation/Validation";
import { useAppDispatch } from "./Hooks";
import { User, upsertUserInfo, upsertUserPassword } from "../Store/UserSlice";
import { MakeClone } from "../Services/Cloning";

type PasswordUpdater = {
  isLoading: boolean,
  newPassword: string
  confirmPassword: string
  newPasswordValidationText: string
  setNewPasswordValidationText: (value: string) => void
  confirmPasswordValidationText: string
  setConfirmPasswordValidationText: (value: string) => void
  HandleNewPasswordUpdate: (password: string) => void
  HandleConfirmPasswordUpdate: (confirmedPassword: string) => void
  canSubmitPassword: boolean
  HandleSubmitResetPassword: (value?: string) => Promise<void>
  HandleAdminSubmitResetPassword: (userToEdit: User) => Promise<void>,
  ClearFields: () => void
}

export function usePasswordUpdater(requireValidation: boolean): PasswordUpdater {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordValidationText, setNewPasswordValidationText] = useState("");
  const [confirmPasswordValidationText, setConfirmPasswordValidationText] = useState("");

  function ClearFields() {
    setNewPassword("");
    setConfirmPassword("");
  }

  function HandleNewPasswordUpdate(newPassword: string) {
    setNewPassword(newPassword);
    if (requireValidation)
    {
      const newValidationText = GetPasswordValidationFailureString(newPassword);
      setNewPasswordValidationText(newValidationText);
      let newConfirmText = "";
      if (confirmPassword !== newPassword)
        newConfirmText = "Passwords must match.";
      setConfirmPasswordValidationText(newConfirmText);
    }
  }

  function HandleConfirmPasswordUpdate(confirmPassword: string) {
    setConfirmPassword(confirmPassword);
    if (requireValidation)
    {
      let newConfirmText = "";
      if (confirmPassword !== newPassword)
        newConfirmText = "Passwords must match.";
      setConfirmPasswordValidationText(newConfirmText);
    }
  }

  const canSubmitPassword = useMemo(() => {
    if (requireValidation)
    {
      if (newPasswordValidationText !== "")
        return FailValidation(newPasswordValidationText);
      if (confirmPasswordValidationText !== "")
        return FailValidation(confirmPasswordValidationText);
    }
    else
    {
      if (newPassword === "")
        return FailValidation("Cannot leave password empty.");
    }
    return SuccessValidation("");
  }, [newPasswordValidationText, confirmPasswordValidationText, newPassword, requireValidation]);

  async function HandleSubmitResetPassword(token?: string) {
    if (!canSubmitPassword.success)
      return;

    setIsLoading(true);
    try
    {
      await dispatch(upsertUserPassword({ password: newPassword, token: token })).unwrap();
      SuccessSnackbar("Password changed successfully.");
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoading(false);
    ClearFields();
  }

  async function HandleAdminSubmitResetPassword(userToEdit: User) {
    if (!canSubmitPassword.success)
      return;

    if (!userToEdit)
      return;

    setIsLoading(true);
    const updatedUser = MakeClone(userToEdit);
    updatedUser.isPasswordReset = true;
    try
    {
      await dispatch(upsertUserInfo({ user: updatedUser, newUserPassword: newPassword }));
      SuccessSnackbar(`Password has been reset for ${updatedUser.name ?? updatedUser.email}.`);
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoading(false);
    ClearFields();
  }

  return {
    isLoading,
    newPassword,
    confirmPassword,
    newPasswordValidationText,
    setNewPasswordValidationText,
    confirmPasswordValidationText,
    setConfirmPasswordValidationText,
    HandleNewPasswordUpdate,
    HandleConfirmPasswordUpdate,
    canSubmitPassword: canSubmitPassword.success,
    HandleSubmitResetPassword,
    HandleAdminSubmitResetPassword,
    ClearFields
  }
}

