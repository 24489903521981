import { Slide, Box, Divider, List, ListItem, ListItemText, styled, Typography, ListItemButton } from "@mui/material";
import React from "react";
import { TOCSection } from "./TableOfContents";

interface TableOfContentsDrawerProps {
  sections: TOCSection[]
  open: boolean
  handleDrawerClose: () => void
  setAnimEnd: (value: boolean) => void
  handleSelect: (e: React.MouseEvent<HTMLDivElement>, section: TOCSection) => void
}

export function TableOfContentsDrawer(props: TableOfContentsDrawerProps) {

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar, // necessary for content to be below app bar
    justifyContent: 'flex-end',
  }));

  return (
    <Slide direction="right" in={props.open} mountOnEnter unmountOnExit onExited={() => props.setAnimEnd(true)}>
      <Box>
        <DrawerHeader>
          <ListItem>
            <ListItemText>
              Contents [<Typography display="inline" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' }, cursor: "pointer", color: "blue" }} onClick={props.handleDrawerClose}>hide</Typography>]
            </ListItemText>
          </ListItem>
        </DrawerHeader>
        <Divider />
        <List>
          {props.sections.map((section, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={(e) => props.handleSelect(e, section)}>
                <ListItemText primary={section.title} />
                {section.endIcon}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Slide>
  );
}