import { Box, Stack } from "@mui/material"
import { Company } from "../../Store/CompanySlice"
import { DocumentInfo } from "../../Store/DocumentSlice"
import { BaseModal } from "../BaseModal"
import { AccountField } from "../AccountField"
import { useEffect, useState } from "react"
import { MakeClone } from "../../Services/Cloning"
import { AccountActions } from "../AccountActions"
import { FileUpload } from "../FileUpload"
import { CancelButton } from "../../Styles"

interface DocumentUpsertModalProps {
  company: Company
  isOpen: boolean
  handleClose: () => void
  documentToEdit: DocumentInfo | undefined
  isUpserting: boolean
  Upload: (file: File | null, shortDescription: string, longDescription: string) => Promise<void>
  Save: (editedDocument: DocumentInfo) => Promise<void>
  Cancel: () => void
  cypressData: {
    modal: string
    closeModalButton: string
    newShortDescription: string
    newLongDescription: string
    saveButton: string
    cancelButton: string
    fileUpload: {
      chooseFileButton: string
      submitButton: string
      fileInput: string
    }
  }
}

export function DocumentUpsertModal(props: DocumentUpsertModalProps) {
  const [documentClone, setDocumentClone] = useState<DocumentInfo>();
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (props.isOpen)
    {
      if (props.documentToEdit)
        setDocumentClone(MakeClone(props.documentToEdit));
      else
        setDocumentClone({
          tempUrl: "",
          blobName: "",
          createdOn: undefined,
          lastModified: undefined,
          metadata: {
            fileName: "",
            shortDescription: "",
            longDescription: ""
          },
          tags: {
          }
        });
    }
    else
    {
      setDocumentClone(undefined);
      setFile(null);
    }
  }, [props.isOpen, props.documentToEdit])

  return (
    <BaseModal
      open={props.isOpen}
      onClose={props.handleClose}
      cypressData={{ modal: props.cypressData.modal, closeModalButton: props.cypressData.closeModalButton }}
      title={`${props.documentToEdit ? "Edit" : "Upload"} ${props.company.terms.documentTerm}`}
      subtitle={props.documentToEdit?.metadata.shortDescription || props.documentToEdit?.metadata.fileName}
    >
      {documentClone &&
        <Stack gap={2}>
          <AccountField title="Title" cypressData={props.cypressData.newShortDescription} value={documentClone?.metadata.shortDescription} onChange={(e) => { setDocumentClone({ ...documentClone, metadata: { ...documentClone.metadata, shortDescription: e.target.value } }) }} />
          <AccountField title="Description" cypressData={props.cypressData.newLongDescription} value={documentClone?.metadata.longDescription} onChange={(e) => { setDocumentClone({ ...documentClone, metadata: { ...documentClone.metadata, longDescription: e.target.value } }) }} />
          {props.documentToEdit ?
            <AccountActions cypressData={{ saveButton: props.cypressData.saveButton, cancelButton: props.cypressData.cancelButton }} isSaving={props.isUpserting} Cancel={props.Cancel} Save={() => props.Save(documentClone)} />
            :
            <Stack direction="row" alignItems="center" sx={{ minWidth: 0 }}>
              <CancelButton data-cy={props.cypressData.cancelButton} sx={{ width: "15%", marginRight: 4 }} disabled={props.isUpserting} size="small" onClick={() => props.Cancel()}>Cancel</CancelButton>
              <Box sx={{ width: "85%"}}>
                <FileUpload
                  cypressData={props.cypressData.fileUpload}
                  file={file} setFile={setFile} isUploading={props.isUpserting} UploadFile={() => props.Upload(file, documentClone.metadata.shortDescription ?? "", documentClone.metadata.longDescription ?? "")}
                />
              </Box>
            </Stack>
          }
        </Stack>
      }
    </BaseModal>
  )
}
