import { Button, CircularProgress, Grid, IconButton, Input, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Company, Initiative, upsertInitiativeInfo } from '../../Store/CompanySlice';
import { DocumentUpload } from './DocumentUpload';
import { TableHeaderStyle, defaultRowStyle, tableButtonFontSize, tableCellFontSize } from '../../Styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentInfo, getDocumentInfos, updateDocument } from '../../Store/DocumentSlice';
import { useAppDispatch } from '../../Hooks/Hooks';
import { BaseModal } from '../BaseModal';
import { User } from '../../Store/UserSlice';
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import InventoryIcon from '@mui/icons-material/Inventory';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { MakeClone } from '../../Services/Cloning';
import { UpdateDocumentRequest } from '../../Services/DocumentService';
import { DocumentActions } from './DocumentActions';
import { SearchBar } from '../SearchBar';
import { useSorter } from '../../Hooks/useSorter';
import { SortLabel } from '../SortLabel';
import { ErrorSnackbar } from '../../Services/Snackbars';
import { plural } from 'pluralize';

export const DocumentManagementModalIds = {
  modal: "documentManagementModal",
  closeModalButton: "documentManagementModalCloseModalButton",
  saveDocumentEdit: "documentManagementModalSaveEdit",
  cancelDocumentEdit: "documentManagementModalCancelEdit",
  editDocument: "documentManagementModalEditDocument",
  editShortDescription: "documentManagementModalEditShortDescription",
  editLongDescription: "documentManagementModalEditLongDescription",
  editCurrentIsDefault: "documentManagementModalEditisDefault",
  keywordFilter: "documentManagementModalKeywordFilter",
  documentUpload: {
    chooseFileButton: "documentManagementModalChooseNewDocButton",
    submitButton: "documentManagementModalNewDocSubmitButton",
    fileInput: "documentManagementModalFileInput",
    newShortDescription: "documentManagementModalNewShortDescription",
    newLongDescription: "documentManagementModalNewLongDescription"
  }
}

interface DocumentManagementModalProps {
  articleWithDocsId: string | undefined
  company: Company
  initiative: Initiative | undefined
  currentUser: User
  userHasUploadPermission: boolean
  title: string
  isOpen: boolean
  HandleClose: () => void
}

export function DocumentManagementModal(props: DocumentManagementModalProps) {
  enum State {
    start,
    add,
    edit
  }
  const dispatch = useAppDispatch();
  const [docInfos, setDocInfos] = useState<DocumentInfo[]>([]);
  const [state, setState] = useState<State>(State.start);
  const [documentToEdit, setDocumentToEdit] = useState<DocumentInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDefaultDoc, setLoadingDefaultDoc] = useState<DocumentInfo>();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [showArchived, setShowArchived] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const currentInitiativeId = props.initiative?.id;
  const defaultDocId = props.initiative?.defaultDocumentId;

  const {
    UpdateSortConfig,
    SortItems,
    sortConfig
  } = useSorter();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GetData = useCallback(async () => {
    setIsLoading(true);
    try
    {
      const docs = await dispatch(getDocumentInfos({ companyId: props.company.id, initiativeId: currentInitiativeId, articleId: props.articleWithDocsId })).unwrap();
      setDocInfos(docs);
    }
    catch (e)
    {
      ErrorSnackbar(e);
    }
    setIsLoading(false);
  }, [dispatch, props.articleWithDocsId, props.company.id, currentInitiativeId]);

  const defaultDocIndex = useMemo(() => {
    const foundIndex = docInfos.findIndex(doc => doc.blobName === defaultDocId);
    if (foundIndex >= 0)
      return foundIndex;
  }, [docInfos, defaultDocId]);

  const isEditing = state === State.edit;

  const LeaveEditMode = useCallback(() => {
    setState(State.start);
    setDocumentToEdit(undefined);
  }, [State.start]);

  function EnterEditMode(doc: DocumentInfo) {
    if (state === State.start)
    {
      setDocumentToEdit(doc);
      setState(State.edit);
    }
  }

  async function HandleSaveEdit() {
    let documentClone = MakeClone(documentToEdit);
    if (documentClone)
    {
      const docRequest: UpdateDocumentRequest = {
        blobName: documentClone.blobName,
        shortDescription: documentClone.metadata.shortDescription,
        longDescription: documentClone.metadata.longDescription
      }
      await dispatch(updateDocument(docRequest));
    }
    GetData();
    LeaveEditMode();
  }

  function HandleCancelEdit() {
    LeaveEditMode();
  }

  useEffect(() => {
    if (props.isOpen)
      GetData();
    else
      setDocInfos([]);
  }, [props.isOpen, GetData]);

  async function HandleChangeDefaultDoc(doc: DocumentInfo) {
    if (props.initiative !== undefined)
    {
      const newDocId = props.initiative.defaultDocumentId === doc.blobName ? "" : doc.blobName;
      const editedInitiative: Initiative = {
        ...props.initiative,
        defaultDocumentId: newDocId
      }
      setLoadingDefaultDoc(doc);
      try
      {
        await dispatch(upsertInitiativeInfo({ initiative: editedInitiative, companyId: props.company.id })).unwrap();
      }
      catch (e)
      {
        ErrorSnackbar(e);
      }
      setLoadingDefaultDoc(undefined);
    }
  }

  const filteredDocs = useMemo(() => {
    const upperKeyword = searchedKeyword.toUpperCase();
    return docInfos.filter(doc =>
      (doc.metadata.fileName.toUpperCase().includes(upperKeyword)
        || doc.metadata.shortDescription?.toUpperCase().includes(upperKeyword)
        || doc.metadata.longDescription?.toUpperCase().includes(upperKeyword)
      )
      &&
      (doc.metadata.archived === showArchived
        || showArchived
      )
    );
  }, [docInfos, searchedKeyword, showArchived]);

  const sortedDocs = useMemo(() => {
    return SortItems(filteredDocs);
  }, [filteredDocs, SortItems]);

  useEffect(() => {
    const maxPages = Math.max(Math.ceil(filteredDocs.length / rowsPerPage),1);
    if(page > maxPages-1)
      setPage(maxPages-1);
  },[filteredDocs, rowsPerPage, page]);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const slicedDocs = sortedDocs.slice(indexOfFirstItem, indexOfLastItem);

  const showDefaultDocColumn = props.initiative && !props.articleWithDocsId && props.userHasUploadPermission;

  return (
    <BaseModal
      cypressData={{ modal: DocumentManagementModalIds.modal, closeModalButton: DocumentManagementModalIds.closeModalButton }}
      open={props.isOpen}
      onClose={() => props.HandleClose()}
      title={`${props.title}`}
      maxWidth="lg"
      subtitle={`${props.company.name}${props.initiative ? ` - ${props.initiative.title}` : ``}`}
    >
      <div className="flex flex-col col-span-4 ">
        {props.userHasUploadPermission &&
          <DocumentUpload cypressData={DocumentManagementModalIds.documentUpload} company={props.company} initiative={props.initiative} articleId={props.articleWithDocsId} GetData={GetData} />
        }
        {isLoading && docInfos.length === 0 &&
          <div className="flex justify-center w-full h-full my-2">
            <CircularProgress size={20} color={"warning"} />
          </div>
        }
        {!isLoading && docInfos.length === 0 &&
          <p className="m-2 p-2 text-2xl font-bold">There are no {plural(props.company.terms.documentTerm.toLowerCase())} to display</p>
        }
        {docInfos.length !== 0 &&
          <>
            <Grid container sx={{ marginY: 2 }} alignItems="center">
              <Grid item xs={10}>
                <SearchBar cypressData={DocumentManagementModalIds.keywordFilter} placeholder="Keyword" value={searchedKeyword} setValue={(value: string) => {setSearchedKeyword(value); setPage(0)}} disabled={isEditing} />
              </Grid>
              <Grid item xs={2}>
                <Button onClick={() => setShowArchived(!showArchived)} variant={showArchived ? "contained" : "outlined"} startIcon={<InventoryIcon />}>{showArchived ? "Hide" : "Show"} Archived</Button>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className="my-2">
              <Table className="w-full outline outline-3 bg-gray-100">
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "2px solid black",
                    "& th": {
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    {showDefaultDocColumn &&
                      <TableHeaderStyle></TableHeaderStyle>
                    }
                    <TableHeaderStyle>File Name</TableHeaderStyle>
                    <TableHeaderStyle>Title</TableHeaderStyle>
                    <TableHeaderStyle>Description</TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="createdOn" heading="Created On" sortConfig={sortConfig} RequestSort={UpdateSortConfig} disabled={isEditing} />
                    </TableHeaderStyle>
                    <TableHeaderStyle>
                      <SortLabel sortKey="lastModified" heading="Last Modified" sortConfig={sortConfig} RequestSort={UpdateSortConfig} disabled={isEditing} />
                    </TableHeaderStyle>
                    {props.userHasUploadPermission &&
                      <TableHeaderStyle>Edit</TableHeaderStyle>
                    }
                    <TableHeaderStyle>Actions</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    slicedDocs.map((doc, index) => {
                      const isEdit = isEditing && doc.metadata.fileName === documentToEdit?.metadata.fileName;
                      const documentActions = <DocumentActions docInfo={doc}
                        canDelete={props.userHasUploadPermission}
                        canArchive={props.userHasUploadPermission && !doc.metadata.archived}
                        canUnarchive={props.userHasUploadPermission && doc.metadata.archived}
                        RefreshData={GetData}
                        disabled={isEditing || !!loadingDefaultDoc}
                        documentTerm={props.company.terms.documentTerm}
                      />;

                      const fileNameDisplay =
                        <Stack direction="row" alignItems="center" gap={1}>
                          {doc.metadata.archived &&
                            <InventoryIcon sx={{ fontSize: 18 }} titleAccess='Archived' />
                          }
                          <Typography>{doc.metadata.fileName}</Typography>
                        </Stack>;

                      const isDefault = index === defaultDocIndex;
                      const defaultDocSetter =
                        <IconButton title={isDefault ? "Unmark as default" : "Mark as default"} onClick={() => HandleChangeDefaultDoc(doc)} disabled={!!loadingDefaultDoc || isEditing}>
                          {loadingDefaultDoc?.blobName === doc.blobName ?
                            <CircularProgress color="warning" size="20px" />
                            :
                            (isDefault ?
                              <StarIcon />
                              :
                              <StarBorderIcon />
                            )
                          }
                        </IconButton>


                      return (
                        <TableRow className={defaultRowStyle}
                          sx={{
                            borderBottom: "1px solid black",
                            "& td": {
                              fontSize: "1.1rem",
                              fontFamily: "Arial, Helvetica",
                              color: "#21345b"
                            }
                          }}
                          key={index}
                        >
                          {isEdit ?
                            <>
                              {showDefaultDocColumn &&
                                <TableCell>{defaultDocSetter}</TableCell>
                              }
                              <TableCell>{fileNameDisplay}</TableCell>
                              <TableCell>
                                <Input sx={{ fontSize: tableCellFontSize }} data-cy={DocumentManagementModalIds.editShortDescription} value={documentToEdit.metadata.shortDescription} onChange={(e) => setDocumentToEdit({ ...documentToEdit, metadata: { ...documentToEdit.metadata, shortDescription: e.target.value } })} />
                              </TableCell>
                              <TableCell>
                                <Input sx={{ fontSize: tableCellFontSize }} data-cy={DocumentManagementModalIds.editLongDescription} value={documentToEdit.metadata.longDescription} onChange={(e) => setDocumentToEdit({ ...documentToEdit, metadata: { ...documentToEdit.metadata, longDescription: e.target.value } })} />
                              </TableCell>
                              <TableCell>
                                <Typography variant="caption">{doc.createdOn ? new Date(doc.createdOn).toLocaleString() : "N/A"}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="caption">{doc.lastModified ? new Date(doc.lastModified).toLocaleString() : "N/A"}</Typography>
                              </TableCell>
                              {props.userHasUploadPermission &&
                                <TableCell>
                                  <IconButton data-cy={DocumentManagementModalIds.saveDocumentEdit} onClick={() => HandleSaveEdit()}>
                                    <DoneIcon sx={{ fontSize: tableButtonFontSize }} />
                                  </IconButton>
                                  <IconButton data-cy={DocumentManagementModalIds.cancelDocumentEdit} onClick={() => HandleCancelEdit()}>
                                    <CancelIcon sx={{ fontSize: tableButtonFontSize }} />
                                  </IconButton>
                                </TableCell>
                              }
                              <TableCell>{documentActions}</TableCell>
                            </>
                            :
                            <>
                              {showDefaultDocColumn &&
                                <TableCell>{defaultDocSetter}</TableCell>
                              }
                              <TableCell>{fileNameDisplay}</TableCell>
                              <TableCell><Typography>{doc.metadata.shortDescription}</Typography></TableCell>
                              <TableCell><Typography>{doc.metadata.longDescription}</Typography></TableCell>
                              <TableCell>
                                <Typography variant="caption">{doc.createdOn ? new Date(doc.createdOn).toLocaleString() : "N/A"}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="caption">{doc.lastModified ? new Date(doc.lastModified).toLocaleString() : "N/A"}</Typography>
                              </TableCell>
                              {props.userHasUploadPermission &&
                                <TableCell>
                                  <IconButton data-cy={DocumentManagementModalIds.editDocument} disabled={isEditing || !!loadingDefaultDoc} onClick={() => EnterEditMode(doc)}>
                                    <EditIcon sx={{ fontSize: tableButtonFontSize }} />
                                  </IconButton>
                                </TableCell>
                              }
                              <TableCell>{documentActions}</TableCell>
                            </>
                          }
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={sortedDocs.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        }
      </div>
    </BaseModal>
  )
}
