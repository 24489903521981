import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { useMemo, useState } from "react";
import { AddDays, ForecastItemsCompleted } from "../../../Services/ProbabilitySimulationService";
import { metricsFontLarge, metricsFontSmall } from "../../../Styles";
import dayjs from 'dayjs';
import { DatePicker } from "@mui/x-date-pickers";
import { plural } from "pluralize";
import { GetCompletedItems, GetItemsBySelectedCategoriesAndInitiative } from "../../../Services/CompanyService";
import { InsufficientThroughputSection } from "./InsufficientThroughputSection";

interface HowManyItemsMetricsProps {
  company: Company
  initiative: Initiative
}

export function HowManyItemsMetrics(props: HowManyItemsMetricsProps) {
  const bigTextSX = metricsFontLarge;
  const smallTextSX = metricsFontSmall;
  const [howManyDate, setHowManyDate] = useState(props.initiative.targetDate !== "" ? props.initiative.targetDate : AddDays(new Date(), 30).toISOString().split("T")[0]);
  const [howManyInputProbability, setHowManyInputProbability] = useState(85);
  const { expectedItemsCompleted, completedItems } = useMemo(() => {
    const itemsByCategory = GetItemsBySelectedCategoriesAndInitiative(props.company, props.initiative);
    const completedItems = GetCompletedItems(itemsByCategory, props.company.workflow, [
      { startDate: props.company.metricStartDate, endDate: props.company.metricEndDate }
      //does not check for start and target date of initiative to allow for historical data; these items cannot be used to calc. items remaining
    ])
    return { expectedItemsCompleted: ForecastItemsCompleted(howManyDate, completedItems, howManyInputProbability, props.company.workflow).value ?? 0, completedItems };
  }, [howManyDate, props.initiative, props.company, howManyInputProbability]);
  const itemTerm = props.company.terms.throughputTerm.toLowerCase();
  const shouldUsePlural = expectedItemsCompleted === 0 || expectedItemsCompleted > 1;
  const expectedItemsTerm = useMemo(() => shouldUsePlural ? plural(itemTerm) : itemTerm, [itemTerm, shouldUsePlural]);

  return (
    <Stack sx={{ paddingX: 2 }} gap={1} alignItems="center">
      <InsufficientThroughputSection company={props.company} completedItemCount={completedItems.length}>
        <Typography sx={bigTextSX}>{`${expectedItemsCompleted === 0 ? "No" : expectedItemsCompleted} more ${expectedItemsTerm}`}</Typography>
        <Typography sx={smallTextSX}>{shouldUsePlural ? "are" : "is"} projected for delivery by</Typography>
        <DatePicker sx={{ width: "60%" }} value={dayjs(howManyDate)} onChange={(value) => setHowManyDate(value ? value.format("YYYY-MM-DD") : howManyDate)}
          format="M/D/YYYY"
          slotProps={{
            actionBar: {
              actions: ["today", "cancel"],
              style: { justifyContent: "center", background: "#F3F1F3" }
            },
            textField: {
              variant: "standard",
              InputProps: {
                disableUnderline: true,
                sx: metricsFontLarge
              }
            },
            field: { shouldRespectLeadingZeros: true }
          }}
        />
        <Typography sx={smallTextSX}>with a certainty of</Typography>
        <Select disableUnderline variant="standard" sx={bigTextSX} value={howManyInputProbability.toString()} onChange={(e) => setHowManyInputProbability(parseInt(e.target.value))}>
          {["75", "85", "95"].map((percent, i) =>
            <MenuItem value={percent} key={i}>
              {percent}%
            </MenuItem>
          )}
        </Select>
      </InsufficientThroughputSection>
    </Stack>
  )
}