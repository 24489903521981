import { Typography, Table, TableBody, TableRow, Paper, TableContainer, TableHead, Checkbox, ListItem, ListItemText } from "@mui/material";
import { plural } from "pluralize";
import { StyleTableCell, StyledTableRow, TableHeaderStyle, tableCellFontSize, tableHeaderFontSize } from "../../../Styles";
import { ReadOnlyRichText } from "../../Quill/ReadOnlyRichText";
import { Company, Initiative } from "../../../Store/CompanySlice";
import { MakeDate } from "../../../Services/DateHelpers";
import { useMemo } from "react";
import { useCheckboxSelect } from "../../../Hooks/useCheckboxSelect";
import { MakeClone } from "../../../Services/Cloning";
import { StepWizardChildProps } from "react-step-wizard";
import { BaseStepStack } from "./InitiativeCopyBaseStepStack";

interface DecisionStepProps {
  initiativeToCopy: Initiative
  company: Company
  updatedInitiative: Initiative
  setUpdatedInitiative: (value: Initiative) => void
}

export const DecisionStep: React.FC<Partial<StepWizardChildProps> & DecisionStepProps> = ({ setUpdatedInitiative, ...props }) => {
  const allDecisionIds = useMemo(() => {
    return props.initiativeToCopy.decisions.flatMap(l => l.id);
  }, [props.initiativeToCopy]);

  const {
    isSelectAll,
    SelectAll,
    SelectThis,
    IsChecked
  } = useCheckboxSelect(allDecisionIds);

  function HandleCopy(decisionId?: string) {
    const newInitiativeClone = MakeClone(props.updatedInitiative);
    if (decisionId)
    {
      const checkedIds = SelectThis(decisionId);
      if(checkedIds.includes(decisionId))
      {
        const matchingDecision = props.initiativeToCopy.decisions.find(d => d.id === decisionId);
        if(matchingDecision)
          newInitiativeClone.decisions.push(MakeClone(matchingDecision));
      }
      else
      {
        const removalIndex = newInitiativeClone.decisions.findIndex(d => d.id === decisionId);
        if(removalIndex > -1)
          newInitiativeClone.decisions.splice(removalIndex, 1);
      }
    }
    else
    {
      const checkedIds = SelectAll();
      if (checkedIds.length === allDecisionIds.length)
        newInitiativeClone.decisions = MakeClone(props.initiativeToCopy.decisions);
      else
        newInitiativeClone.decisions = [];
    }
    setUpdatedInitiative(newInitiativeClone);
  }

  return (
    <BaseStepStack {...props}>
      {props.initiativeToCopy.decisions.length > 0 ?
        <>
          <ListItem disablePadding>
            <ListItemText sx={{ fontWeight: "bold" }}>
              <Checkbox sx={{ marginX: 2 }} checked={isSelectAll} onChange={() => HandleCopy()} />
              Select All</ListItemText>
          </ListItem>
          <TableContainer component={Paper}>
            <Table className="table-auto h-5 w-full outline outline-3 bg-gray-100">
              <TableHead className="outline outline-1">
                <TableRow sx={{
                  borderBottom: "2px solid black",
                  "& th": {
                    fontSize: tableHeaderFontSize,
                    fontWeight: "bold",
                    fontFamily: "Arial, Helvetica"
                  }
                }}>
                  <TableHeaderStyle sx={{ width: "10%" }}>Copy</TableHeaderStyle>
                  <TableHeaderStyle sx={{ width: "16%" }}>Description</TableHeaderStyle>
                  <TableHeaderStyle sx={{ width: "60%" }}>Resolution</TableHeaderStyle>
                  <TableHeaderStyle sx={{ width: "15%", textAlign: "center" }}>Date</TableHeaderStyle>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.initiativeToCopy?.decisions.map((decision, index) => {
                  const check = IsChecked(decision.id);
                  return (
                    <StyledTableRow key={index} sx={{
                      borderBottom: "1px solid black",
                      "& td": {
                        fontSize: tableCellFontSize,
                        fontFamily: "Arial, Helvetica",
                        color: "#21345b"
                      }
                    }}>
                      <StyleTableCell>
                        <Checkbox sx={{ marginLeft: 2 }} checked={check} onChange={() => HandleCopy(decision.id)}></Checkbox>
                      </StyleTableCell>
                      <StyleTableCell>
                        <ReadOnlyRichText cypressData={""} text={decision.description} />
                      </StyleTableCell>
                      <StyleTableCell>
                        <ReadOnlyRichText cypressData={""} text={decision.resolution} />
                      </StyleTableCell>
                      <StyleTableCell>
                        <Typography sx={{ textAlign: "center" }}>{MakeDate(decision.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</Typography>
                      </StyleTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        :
        <Typography>There are no {plural(props.company.terms.decisionTerm.toLowerCase())} on the source {props.company.terms.initiativeTerm.toLowerCase()}.</Typography>
      }
    </BaseStepStack>
  );
}