import axios from "axios";
import { LocalizeUrl } from "./Http";
import { Article } from "../Store/ArticleSlice";
import { SerializeAxiosError } from "./Error";
import { InCypress } from "./CypressDetection";

export interface GetArticleRequest {
  companyId: string
  initiativeId?: string
  userCompanyId: string
}

interface GetArticleResponse {
  articles: Article[]
}

export async function GetArticle(request: GetArticleRequest, sessionId: string): Promise<GetArticleResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/GetArticle");
    const response = await axios.post(baseUrl, { ...request, sessionId });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertArticleRequest {
  articles: Article[]
}

interface UpsertArticleResponse {
}

export async function UpsertArticle(request: UpsertArticleRequest, sessionId: string): Promise<UpsertArticleResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertArticle");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}
