import { IntegrityTheme, TableHeaderStyle, defaultRowStyle, tableButtonFontSize, tableCellFontSize, tableHeaderFontSize } from "../Styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { User, getUser, selectAllUsers, selectCurrentUser } from "../Store/UserSlice";
import { IntegrityId, selectAllCompanies } from "../Store/CompanySlice";
import { useAppDispatch, useAppSelector } from "../Hooks/Hooks";
import { Grid, IconButton, ThemeProvider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEditUser } from "../Hooks/useEditUser";
import { UserFilter } from "../Services/Filters";
import { AddButton } from "../Components/AddButton";
import { SearchBar } from "../Components/SearchBar";
import { Paginator } from "../Components/Paginator";
import { usePaginator } from "../Hooks/usePaginator";
import { ActiveRadioSet } from "../Components/ActiveRadioSet";
import { useEffectOnce } from "../Hooks/useEffectOnce";
import { UserAvatar, UserWithAvatarUrl } from "../Components/User/UserAvatar";
import { getAvatarDocumentInfos } from "../Store/DocumentSlice";
import { ErrorSnackbar } from "../Services/Snackbars";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { GoToUserAccountPage } from "./UserAccountPage";

export function GoToUsersPage(navigate: NavigateFunction, initialPage?: number) {
  let where = "/Users";
  if (initialPage !== undefined)
    where += "?initialPage=" + initialPage.toString();
  navigate(where);
}

export const UsersPageIds = {
  company: "usersPageCompany",
  initiativeIds: "usersPageInitiativeIds",
  email: "usersPageEmail",
  password: "usersPagePassword",
  name: "usersPageName",
  phone: "usersPagePhone",
  role: "usersPageRole",
  isAdmin: "usersPageIsAdmin",
  isTeamMember: "usersPageIsTeamMember",
  isActive: "usersPageIsActive",
  addButton: "usersPageAddButton",
  editButton: "usersPageEditButton",
  saveChangesButton: "usersPageSaveChangesButton",
  cancelChangesButton: "usersPageCancelChangesButton",
  deleteButton: "usersPageDeleteButton",
  keywordFilter: "usersPageKeywordFilter",
  table: "usersPageTable",
  selectCompany: "usersPageSelectCompany",
  radioIds: {
    active: "userPageRadioActive",
    inactive: "userPageRadioInactive",
    all: "userPageRadioAll"
  }
}

export default function UsersPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const allCompanies = useAppSelector(selectAllCompanies);
  const allUsers = useAppSelector(selectAllUsers);
  const currentUser = useAppSelector(selectCurrentUser);
  const [radioValue, setRadioValue] = useState("active");
  const [nonIntegrityUsers, setNonIntegrityUsers] = useState<User[]>([]);

  const [userAvatars, setUserAvatars] = useState<UserWithAvatarUrl[]>([]);
  const [userAvatarNames, setUserAvatarNames] = useState<string[]>([]);

  const initialPage = useMemo(() => {
    const queryPage = queryParameters.get("initialPage") ?? "1";
    return parseInt(queryPage);
  }, [queryParameters]);

  const {
    SetupEditUser,
    usersList,
    searchedKeyword,
    setSearchedKeyword
  } = useEditUser(userAvatars);

  const { PaginateItems, ...paginator } = usePaginator({ initialPage });

  useEffect(() => {
    SetupEditUser(allUsers);
  }, [allUsers, SetupEditUser])

  useEffectOnce(() => {
    async function GetUsers() {
      if (currentUser?.isAdmin)
      {
        try
        {
          if (currentUser.companyId === IntegrityId)
            await dispatch(getUser({})).unwrap();
          else
            await dispatch(getUser({ companyId: currentUser.companyId })).unwrap();
        }
        catch (e)
        {
          ErrorSnackbar(e);
        }
      }
    }

    GetUsers();
  });

  useEffect(() => {
    const otherCompanyUsers = allUsers.filter(u => u.companyId !== IntegrityId);
    otherCompanyUsers.sort((a: User, b: User) => {
      let nameA = a.name ?? "";
      let nameB = b.name ?? "";
      return nameA.toUpperCase() < nameB.toUpperCase() ? 1 : -1;
    });
    otherCompanyUsers.sort((a: User, b: User) => {
      let companyA = allCompanies.find(c => c.id === a.companyId);
      let companyB = allCompanies.find(c => c.id === b.companyId);

      if (companyA && companyB)
        return companyA.name.toUpperCase() > companyB.name.toUpperCase() ? 1 : -1;
      return 0;
    });
    const filteredUsers = UserFilter(otherCompanyUsers, radioValue).filter(u => u.email.toUpperCase().includes(searchedKeyword.toUpperCase()) || u.name?.toUpperCase().includes(searchedKeyword.toUpperCase()));
    const paginatedUsers = PaginateItems(filteredUsers);
    setNonIntegrityUsers(otherCompanyUsers);
    SetupEditUser(paginatedUsers);

  }, [allUsers, radioValue, PaginateItems, searchedKeyword, SetupEditUser, allCompanies]);

  const GetAvatars = useCallback(async (users: User[]) => {
    if (users.map(user => user.avatarBlobName).length > 0)
    {
      const currentAvatarCount = userAvatars.length;
      setTimeout(async () => {
        try
        {
          const docs = await dispatch(getAvatarDocumentInfos({})).unwrap();
          const pairs: UserWithAvatarUrl[] = [];
          for (const user of users)
          {
            const matchingDoc = docs.find(doc => doc.blobName === user.avatarBlobName);
            if (matchingDoc)
            {
              const newPair = { ...user, docUrl: matchingDoc.tempUrl };
              pairs.push(newPair);
            }
          }
          setUserAvatars(pairs);
        }
        catch (e)
        {
          console.log((e as Error).message);
        }
      }, currentAvatarCount ? 2000 : 1);
    }
  }, [dispatch, userAvatars.length]);

  useEffect(() => {
    let newAvatarDetected = false;
    let names: string[] = [];
    for (const user of allUsers)
    {
      if (user.avatarBlobName)
      {
        names.push(user.avatarBlobName);
        if (!userAvatarNames.find(name => name === user.avatarBlobName) || userAvatarNames.length === 0)
          newAvatarDetected = true;
      }
    }

    if (newAvatarDetected)
    {
      setUserAvatarNames(names);
      GetAvatars(allUsers);
    }

  }, [GetAvatars, allUsers, userAvatarNames]);

  return (
    <ThemeProvider theme={IntegrityTheme}>
      <div className="mx-[2%] mb-2">
        <div className="flex flex-row justify-content:space-between">
          {currentUser?.isAdmin &&
            <>
              <Grid container sx={{
                display: 'flex',
                placeItems: 'center',
                flexDirection: 'row',
                p: 1,
                mt: 2,
                mb: 1,
                ml: 2,
                mr: 2,
                borderRadius: 1
              }}>
                <Grid item xs={3} sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                  <SearchBar cypressData={UsersPageIds.keywordFilter} value={searchedKeyword} setValue={setSearchedKeyword} placeholder={"Keyword in Name or Email"} />
                </Grid>
                <Grid item xs={6} sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  {currentUser?.companyId === IntegrityId &&
                    <ActiveRadioSet cypressData={UsersPageIds.radioIds} name="usersPage" setRadioValue={setRadioValue} listItems={nonIntegrityUsers} filterFunc={UserFilter} />
                  }
                </Grid>
                <Grid item xs={3} sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <AddButton cypressData={UsersPageIds.addButton} HandleClick={() => GoToUserAccountPage(navigate, undefined, paginator.page)} />
                </Grid>
              </Grid>
            </>
          }
        </div>
        <div className="grid grid-cols-1 w-full h-auto">
          <div className="col-span-1 mt-2">
            <TableContainer elevation={12} component={Paper}>
              <Table className="table-auto w-full outline outline-3 bg-gray-100">
                <colgroup>
                  <col style={{ width: '8vw' }} />
                  {currentUser?.companyId === IntegrityId &&
                    <col style={{ width: '10vw' }} />
                  }
                  <col style={{ width: '12vw' }} />
                  <col style={{ width: '12vw' }} />
                  <col style={{ width: '12vw' }} />
                  <col style={{ width: '12vw' }} />
                  <col style={{ width: '6vw' }} />
                  <col style={{ width: '6vw' }} />
                  <col style={{ width: '8vw' }} />
                </colgroup>
                <TableHead className="outline outline-1">
                  <TableRow sx={{
                    borderBottom: "2px solid black",
                    "& th": {
                      fontSize: tableHeaderFontSize,
                      fontWeight: "bold",
                      fontFamily: "Arial, Helvetica"
                    }
                  }}>
                    <TableHeaderStyle>Avatar</TableHeaderStyle>
                    {currentUser?.companyId === IntegrityId &&
                      <TableHeaderStyle>Company</TableHeaderStyle>
                    }
                    <TableHeaderStyle>Name</TableHeaderStyle>
                    <TableHeaderStyle>Email</TableHeaderStyle>
                    <TableHeaderStyle>Phone</TableHeaderStyle>
                    <TableHeaderStyle>Role</TableHeaderStyle>
                    <TableHeaderStyle>Admin Status</TableHeaderStyle>
                    <TableHeaderStyle>Active Status</TableHeaderStyle>
                    <TableHeaderStyle>Edit</TableHeaderStyle>
                  </TableRow>
                </TableHead>
                <TableBody data-cy={UsersPageIds.table}>
                  {usersList.map((companyUser, key) => {
                    const displayCompany = allCompanies.find(c => c.id === companyUser.companyId);
                    return (
                      <TableRow className={defaultRowStyle} key={key} sx={{
                        borderBottom: "1px solid black",
                        "& td": {
                          fontSize: tableCellFontSize,
                          fontFamily: "Arial, Helvetica",
                          color: "#21345b"
                        }
                      }}>
                        <TableCell><UserAvatar user={userAvatars.find(user => user.id === companyUser.id) ?? companyUser} /></TableCell>
                        {currentUser?.companyId === IntegrityId &&
                          <TableCell data-cy={UsersPageIds.company}>{displayCompany?.name}</TableCell>}
                        <TableCell data-cy={UsersPageIds.name}>{companyUser.name}</TableCell>
                        <TableCell data-cy={UsersPageIds.email}>{companyUser.email}</TableCell>
                        <TableCell data-cy={UsersPageIds.phone}>{companyUser.phoneNumber}</TableCell>
                        <TableCell data-cy={UsersPageIds.role}>{companyUser.role}</TableCell>
                        <TableCell data-cy={UsersPageIds.isAdmin}>{companyUser.isAdmin ? "Admin" : "User"}</TableCell>
                        <TableCell data-cy={UsersPageIds.isActive}>{companyUser.isActive ? "Active" : "Inactive"}</TableCell>
                        <TableCell>
                          <IconButton data-cy={UsersPageIds.editButton} onClick={() => GoToUserAccountPage(navigate, companyUser.id, paginator.page)}>
                            <EditIcon sx={{ fontSize: tableButtonFontSize }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {currentUser?.companyId === IntegrityId && currentUser.isAdmin &&
              <Paginator paginator={{ PaginateItems, ...paginator }} />}
          </div>
        </div>
      </div>
    </ThemeProvider >
  )
}
