import axios from "axios";
import { LocalizeUrl } from "./Http";
import { ShallowUser, User } from "../Store/UserSlice";
import { SerializeAxiosError } from "./Error";
import { InCypress } from "./CypressDetection";

export interface GetUserRequest {
  userId?: string
  companyId?: string
  initiativeId?: string
}

interface GetUserResponse {
  users: (User | ShallowUser)[]
}

export async function GetUser(request: GetUserRequest, omitAdminFields: boolean, sessionId: string): Promise<GetUserResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/GetUser");
    const response = await axios.post(baseUrl, { ...request, omitAdminFields, sessionId });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertUserInfoRequest {
  user: User
  newUserPassword?: string
}

interface UpsertUserInfoResponse {
}

export async function UpsertUserInfo(request: UpsertUserInfoRequest, sessionId: string): Promise<UpsertUserInfoResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertUser");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface UpsertUserPasswordRequest {
  password: string
  token?: string
}

interface UpsertUserPasswordResponse {

}

export async function UpsertUserPassword(request: UpsertUserPasswordRequest, sessionId: string): Promise<UpsertUserPasswordResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/UpsertUserPassword");
    const response = await axios.post(baseUrl, { ...request, sessionId, inCypress: InCypress() });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface AuthenticateUserRequest {
  creds: {
    username: string,
    password: string
  }
}

interface AuthenticateUserResponse {
  user: User | undefined
  isUserActive: boolean
  sessionId: string
}

export async function AuthenticateUser(request: AuthenticateUserRequest): Promise<AuthenticateUserResponse> {
  const baseUrl = LocalizeUrl("/api/AuthenticateUser");

  //If azure goes down:
  //const response = {data: {companyId: IntegrityId, initiativeIds: [], userId: "", isAdmin: true, isActive: true, status: "SUCCESS"}}
  const response = await axios.post(baseUrl, request);
  return response.data;
}

export interface EmailUserRequest {
  recipientAddress: string
}

interface EmailUserResponse {
}

export async function EmailUser(request: EmailUserRequest): Promise<EmailUserResponse> {
  const baseUrl = LocalizeUrl("/api/SendEmail");
  const response = await axios.post(baseUrl, request);
  return response.data;
}

export interface AuthenticatePasswordResetTokenRequest {
  token: string
}

interface AuthenticatePasswordResetTokenResponse {
  isValid: boolean
}

export async function AuthenticatePasswordResetToken(request: AuthenticatePasswordResetTokenRequest): Promise<AuthenticatePasswordResetTokenResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/AuthenticatePasswordResetToken");
    const response = await axios.post(baseUrl, request);
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

export interface AuthenticateUserPasswordRequest {
  password: string
}

interface AuthenticateUserPasswordResponse {
  isValid: boolean
}

export async function AuthenticateUserPassword(request: AuthenticateUserPasswordRequest, sessionId: string): Promise<AuthenticateUserPasswordResponse> {
  try
  {
    const baseUrl = LocalizeUrl("/api/AuthenticateUserPassword");
    const response = await axios.post(baseUrl, { sessionId, ...request });
    return response.data;
  }
  catch (e)
  {
    throw SerializeAxiosError(e);
  }
}

